import { useEffect, useState } from "react";
import "./invoicetemp3style.scss";
import PublicFetch from "../../../utils/PublicFetch";
import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";

function Invoicetemplate3({
  invoice_details1,
  invoice_details2,
  invoice_table_header,
  invoice_table_data,
  amount_in_words,
  sub_total,
  total,
  permanent_made,
  balance_due,
  invoice_no,
  Invoice_date,
  Invoice_type,
  invoice_number,
  billto,
  // setInvoice2,
  settemplate4,
  consignee_name,
  customer_name,
  customer_address,
  customer_email,
}) {
  const [companyInfodata, setCompanyInfodata] = useState();
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [defaultbank, setdefaultbank] = useState();
  console.log("fkkf", consignee_name);

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const allCurrency = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/currency`)
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of cuurency", res.data.data);
          res?.data?.data?.forEach((item, index) => {
            if (item.currency_is_default === 1) {
              console.log("default currency", item);
              setDefaultCurrency(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getallbanks = async () => {
    try {
      const allbanks = await PublicFetch.get(`${ACCOUNTS}/bank`);
      console.log("getting all bank details", allbanks.data.data);
      // setAllbankdetails(allbanks.data.data)
      allbanks?.data?.data?.forEach((item, index) => {
        if (item.bank_default === 1) {
          console.log("default bankk", item);
          setdefaultbank(item);
          // setDefaultCurrency(item);
        }
      });
    } catch (err) {
      console.log("error to fetching  bank details", err);
    }
  };

  let temp4 = "uploads/invoiceTemplate/template4.png";

  useEffect(() => {
    companyinfo();
    allCurrency();
    settemplate4(temp4);
    getallbanks()

    // allCurrency();
    // getallbanks();
  }, []);

  // blue 2nd
  return (
    <>
      <div>
        <div>
          <div id="pageborder1st"></div>
          <table className="invoice_header2">
            {companyInfodata &&
              companyInfodata.length > 0 &&
              companyInfodata?.map((item, index) => {
                return (
                  <thead className="invoice_header border-0">
                    {/* <div className="d-flex justify-content-center">
                <h1 className="header_invoicename"> INVOICE</h1>
              </div> */}

                    <tr className="invoice_header invoicetmp3border">
                      <div className="header_invoicetemp4">
                        <div className="header__address_wrapper">
                          <div className="header__address_logo_temp2">
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/${item.company_logo}`}
                              alt=""
                            />
                          </div>
                        </div>

                        {/* <div className="header__invoice">
                        <h1 className="h1font_size">{Invoice_type} INVOICE </h1>
                        <div>
                         

                          <span>{invoice_number}</span>
                        </div>
                      </div> */}
                      </div>
                    </tr>
                    <br></br>

                    {/* <tr className="invoice_header  invoicetmp3border">
                    <div className="d-flex  justify-content-center   ">
                      <div className=" ">
                        <div className="header__address_content">
                          <h4 className="cmpny_heading_colortmp3">
                            Cargo Plus Middle East Co WLL
                          </h4>
                          <div className="address_width font_weight d-flex  justify-content-center">
                            {item.company_address}
                            <br />
                            {item.company_country}
                            <br />
                            Phone :{item.company_phone}
                            <br />
                            {item.company_email}
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </tr> */}

                    <div className="invoice_header ">
                      <h5 className="h1font_sizetmp3 d-flex justify-content-center">
                        {Invoice_type} INVOICE{" "}
                      </h5>
                    </div>
                    <div className="row ">
                      <div className="col-4"></div>
                      <div className="col-4">
                        <div className="d-flex justify-content-center ">
                          <span className="txnumber shadow-sm">
                           
                            {/* TRN#1003389238723234477 */}
                            {invoice_no}
                          </span>
                        </div>
                      </div>
                      <div className="col-4"></div>
                    </div>

                    <br></br>

                    {/* <tr className="invoice_header">
                <div className="row ">
                  <div className=" col-3">
                    <div className="header__address_content">
                      <label className="label_color1"> Invoice To</label>
                      <div className="address_width font_weight ">
                        <br />
                       {customer_name}
                        <br />
                        {customer_email}
                   
                        <br />
                        {customer_address}
                      </div>
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="header__address_content">
                      <label className="label_color1"> Delivery To</label>
                      <div className="address_width font_weight ">
                        <br />
                      <label>{consignee_name} </label>   
                        <br />
                   
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="">
                      <div className="header__address_content">
                        <div className="address_width font_weight ">
                          <br /> <br />
                        Invoice No:  {invoice_no }
                          <br />
                          Invoice Date:  { Invoice_date}
                          <br />
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tr> */}
                  </thead>
                );
              })}

            <br />
            <div className="invoice_detailstemp4 px-2 ">
              <div className="invoice_details__col invoice_details__col_2">
                <table>
                  <tbody>
                    {invoice_details1}
                    {/* <tr>
                      <td className="font_weight"> Customer Name </td>
                      <td className=""> : </td>
                      <td className=""> Jnewtst-00023 </td>
                    </tr>
                    <tr>
                      <td className="font_weight">Address </td>
                      <td className=""> : </td>
                      <td className=""> usdfu213 </td>
                    </tr>
                    <tr>
                      <td className="font_weight"> Phone #</td>
                      <td className=""> : </td>
                      <td className=""> Cochin Sea </td>
                    </tr>

                    <tr>
                      <td className="font_weight"> Contact Person </td>
                      <td className=""> : </td>
                      <td className=""> Cochin Sea </td>
                    </tr>
                    <tr>
                      <td className="font_weight"> TRN </td>
                      <td className=""> : </td>
                      <td className=""> Test Test </td>
                    </tr>
                    <tr>
                      <td className="font_weight"> Ship Address </td>
                      <td className=""> : </td>
                      <td className=""> testondone </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>

              <div className="invoice_details__col invoice_details__col_2">
                <table>
                  <tbody>
                    {invoice_details2}
                    {/* <tr>
                      <td className="font_weight"> Invoice # </td>
                      <td className=""> : </td>
                      <td className="">2</td>
                    </tr>
                    <tr>
                      <td className="font_weight">Date </td>
                      <td className=""> : </td>
                      <td className=""> 234 </td>
                    </tr>
                    <tr>
                      <td className="font_weight"> Division </td>
                      <td className=""> : </td>
                      <td className=""> 23 </td>
                    </tr>

                    <tr>
                      <td className="font_weight"> SO # </td>
                      <td className=""> : </td>
                      <td className=""> tested </td>
                    </tr>
                    <tr>
                      <td className="font_weight"> DO # </td>
                      <td className=""> : </td>
                      <td className=""> testmode </td>
                    </tr>
                    <tr>
                      <td className="font_weight">Payment Terms </td>
                      <td className=""> : </td>
                      <td className=""> modeterms </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>

              {/* <div className="invoice_details__col invoice_details__col_2">
                <table>
                  <tbody>
                  <tr>
                      <td className="font_weight"> Gross weight </td>
                      <td className=""> : </td>
                      <td className=""> ejnd Sea </td>
                    </tr>
                    <tr>
                      <td className="font_weight"> No of Pieces </td>
                      <td className=""> : </td>
                      <td className=""> ejnd Sea </td>
                    </tr>
                  <tr>
                      <td className="font_weight"> chargable weight </td>
                      <td className=""> : </td>
                      <td className=""> ejnd Sea </td>
                    </tr>
                    <tr>
                      <td className="font_weight"> Carrrier </td>
                      <td className=""> : </td>
                      <td className=""> ejnd Sea </td>
                    </tr>
                    <tr>
                      <td className="font_weight"> Mode </td>
                      <td className=""> : </td>
                      <td className=""> ejnd Sea </td>
                    </tr>
                    </tbody>
                </table>
              </div> */}
            </div>
            {/* <br /> */}

            <tbody>
              {/* listing table start */}
              <table
                className="details_tablenewtmp3 invoice_header2 "
                cellSpacing={0}
              >
                <thead className="invoice_header2 ">
                  {invoice_table_header}
                  <th
                    scope="col"
                    className="font_weight_qt  p-3 invoiceth_widthslno border_white"
                  >
                    SL.No
                  </th>
                  <th
                    scope="col"
                    className="font_weight_qt  task_width text_align_words invoiceth_widthdescrip"
                  >
                    TASKS & DESCRIPTION
                  </th>

                  <th
                    scope="col"
                    className="font_weight_qt text_align_number invoiceth_widthslno"
                  >
                    AMOUNT
                  </th>
                </thead>
                <tbody>
                  {invoice_table_data}

                  {/* <tr>
                    <td className=" p-3 borderright">1 </td>
                    <td className=" borderright">servicenme </td>

                    <td className="text_align_words  borderright">983.00 </td>
                  </tr>

                  <tr className="">
                    <td className=" p-3 borderright">2 </td>
                    <td className="borderright ">servicenme </td>

                    <td className="text_align_words borderright">8399.00 </td>
                  </tr> */}

                  <tr className="">
                    <td className=" p-3 "> </td>
                    <td className="  payableamt borderright">Total </td>

                    <td className="text_align_words borderright">{total}  </td>
                  </tr>

                  <tr className="">
                    <td className=" p-3"> </td>
                    <td className="text_align_words payableamt  borderright">
                      Payable Amount{" "}
                    </td>

                    <td className="text_align_words">{total} </td>
                  </tr>
                  <tr className="">
                    <td className="  payableamt borderright">In Words </td>
                    <td className=" text_align_words  payableamt">
                      {defaultCurrency?.currency_name} {amount_in_words}
                      {/* one thousand two hundred only */}
                    </td>

                    <td className=""> </td>
                  </tr>
                </tbody>
              </table>
              {/* listing table end */}
            </tbody>
            <br></br>
            <footer>
              <div className=" shadow-sm terms mx-1">
                <div className="">
                  <span className=" payableamt"> Terms and conditions </span>
                  <br />
                  <span>terms and conditions sale invoice</span>
                </div>
              </div>

              <div>
                  <p>Thanks For your Business</p>
                </div>
                <div className=" px-3">
                  <p>Bank Details</p>
                  <div className="invoice_details__colbank  ">
                    <table>
                      {/* {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => { */}

                      {companyInfodata &&
                        companyInfodata.length > 0 &&
                        companyInfodata?.map((item, index) => {
                          return (
                            <tbody className="">
                              <tr>
                                <td style={{ fontWeight: 600 }}>Name</td>
                                <td>:</td>
                                <td>{item.company_name} </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 600 }}>Bank Name</td>
                                <td>:</td>
                                <td>{defaultbank?.bank_name} </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 600 }}>Branch</td>
                                <td>:</td>
                                <td>{defaultbank?.bank_branch} </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 600 }}>Account No</td>
                                <td>:</td>
                                <td>{defaultbank?.bank_account_number} </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 600 }}>IBAN No</td>
                                <td>:</td>
                                <td>{defaultbank?.bank_iban_no} </td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  </div>
                  {/* <div className="invoice_details__col invoice_details__col_2">
              <table>
                <tbody></tbody>
              </table>
            </div> */}
                </div>

              {/* <div className="sub_total_wrappernew1">
                <div className="sub_total_wrapper__col sub_total_wrapper__col_1">
                  <div style={{ width: "100%" }}>
                    <div className="invoicetemp2_total_fontsize">Total In Words</div>
                    <div className="sub_total_words">
                    {defaultCurrency?.currency_name} {amount_in_words}
                    </div>
                  </div>
                </div>

                <div className="sub_total_wrapper__col sub_total_wrapper__col_2">
                <div style={{ width: "100%" }}>
                  <table className="invoice_header">
                    <tbody className="invoice_header">
                    
                      <tr className="">
                        <td style={{ fontWeight: 600 }} className="px-1">Total</td>
                        <td style={{ fontWeight: 600 }} className="px-1">{defaultCurrency?.currency_code}  {total} </td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>

               
              </div> */}
            </footer>
          </table>
        </div>
      </div>
    </>
  );
}
export default Invoicetemplate3;
