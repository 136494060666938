import React, { useState } from "react";
import PrintReports from "../../../../components/Reports-Print/Printreport";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { ACCOUNTS } from "../../../../api/bootapi";
import PublicFetch from "../../../../utils/PublicFetch";
import moment from "moment"

function TrialbalancePrint() {
  const [searchParams] = useSearchParams();

  console.log("searchParamdateasper", searchParams.get("dateasper"));
  console.log("searchParamsaccount", searchParams.get("accountgroup"));

  const [dateFrom, setDateFrom] = useState();
  const [accgroup, setAccgroup] = useState();
  const [tableData, setTableData] = useState([]);
 
  useEffect(()=>{
    setDateFrom(searchParams.get("dateasper"))
    setAccgroup(searchParams.get("accountgroup"))
    if(dateFrom && accgroup){
        GetTrialbalance()
    }
  
  },[dateFrom,accgroup ])

  const GetTrialbalance = (data) => {
    let accdate_as_per = moment(dateFrom);

    PublicFetch.post(`${ACCOUNTS}/reports/trial-balance`, {
      date_as_per: accdate_as_per,
      acc_group_id: parseInt(accgroup)
    })
      .then((res) => {
        console.log("trialbalnce iss", res);
        if (res?.data?.success) {
          let temp = [];
          let debitTotal = 0;
          let creditTotal = 0;
          console.log("dfncdfjn", res?.data?.data);
          setTableData(res?.data?.data);
          res?.data?.data.map((itm, indx) => {
            console.log("fnvmf", itm.group_dr_total);
            debitTotal += itm?.group_dr_total;
            creditTotal += itm?.group_cr_total;
          });
        //   setDebitTotal(debitTotal);
        //   setCreditTotal(creditTotal);

          // temp.push(res?.data?.data);
          // setTableData([...temp]);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <div>
      <PrintReports
        report_name={"AgeingReport"}
        table_header_data={
          <>
            <tr className="">
              <th className="head_border" style={{ textAlign: "left" }}>
                SL NO.
              </th>
              <th className="" style={{ textAlign: "left" }}>
                ACCOUNT
              </th>
              <th className="" style={{ textAlign: "left" }}>
                DEBIT
              </th>
              <th className="tsk_exp" style={{ textAlign: "left" }}>
                CREDIT
              </th>
            </tr>
          </>
        }
        table_print_data={
            <>
              {tableData &&
                tableData?.map((item, indx) => {
                  console.log("dailyydatata", item);
                  return (
                    <tr>
                      <td>{indx + 1} </td>
                      <td>{item?.company} </td>
                      <td>{item?.contact} </td>
                      <td>{item?.email} </td>
                      <td>{item?.contact_date} </td>
                      <td>{item?.next_followup} </td>
                      <td>{item?.report_status} </td>
                      <td>{item?.remark} </td>
                    </tr>
                  );
                })}
            </>
          }
      />
    </div>
  );
}

export default TrialbalancePrint;
