import React from 'react'
import PrintReports from '../../../components/Reports-Print/Printreport'

function AgentreportPrint() {
  return (
    <div>

        <PrintReports 
         report_name={"DailysalesReport"}
         table_header_data={
           <>
             <tr className="">
               <th className="head_border" style={{ textAlign: "left" }}>
                 SL No
               </th>
               <th className="" style={{ textAlign: "left" }}>
               JOB NO
               </th>
               <th className="" style={{ textAlign: "left" }}>
               CUSTOMER
               </th>
               <th className="tsk_exp" style={{ textAlign: "left" }}>
               CURRENCY
               </th>
               <th className="" style={{ textAlign: "left" }}>
               TOTAL COST (Fx)	
               </th>
               <th className="" style={{ textAlign: "left" }}>
               TOTAL COST (Lx)
               </th>
              
             </tr>
           </>
         }
        />
    </div>
  )
}

export default AgentreportPrint