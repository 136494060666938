import React from 'react'
import PrintReports from '../../../components/Reports-Print/Printreport';

function AwbBlReportPrint() {
  return (
    <div>

        <PrintReports
        report_name={"AWBBLREPORT"}
        />
    </div>
  )
}

export default AwbBlReportPrint;