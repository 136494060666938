import { Spin } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import CustomerLayout from "../layouts/CustomerLayout";
import Layout from "../layouts/layout";
import styles from "./loadingPage.module.scss";

function LoadingPage() {
  const { pathname } = useLocation();
  const url = pathname.split("/");
  return (
    <>
      <div className="">
        <p>
          Loading..... <Spin />
        </p>
      </div>
      <div className={`${styles.LoadingPageStyle} container-fluid d-none `}>
        <div className="row align-items-center pt-5">
          <div className="col-12">
            <div className="container p-5 text-center ">
              <h1 className={`${styles.LoadingText}`}>Cargo Plus</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoadingPage;
