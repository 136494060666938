import React, { useEffect, useState } from "react";
// import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
// import PublicFetch from "../../utils/PublicFetch";
import "../invoiceStyle.scss";
import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import PublicFetch from "../../../utils/PublicFetch";
import Invoicetmp4 from "../../../assets/logo/CargoPlusinvoicelogo.png"

function InvoicePrintTemp4({
  invoice_details1,
  invoice_details2,
  invoice_table_header,
  invoice_table_data,
  amount_in_words,
  sub_total,
  total,
  permanent_made,
  balance_due,
  invoice_no,
  Invoice_type,
  invoice_number,
  billto,
  // settemp1,
  billcustomername,
  bankDetails,
  invoiceHeaderImg,
  bankDetailsView = true,
  viewBillDetails = true,
  totalView = true,
  totalInWordsView = true,
  grandTotalViewPurchaseBill = false,
  grandTotalView,
  billToCust,
  invoice_date,
  jobNo,
  contentSucess,
  totaldetails
}) {
  const [companyInfodata, setCompanyInfodata] = useState();
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [defaultbank, setdefaultbank] = useState();

  // const [temp1,settemp1] =useState()

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const allCurrency = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/currency`)
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of cuurency", res.data.data);
          res?.data?.data?.forEach((item, index) => {
            if (item.currency_is_default === 1) {
              console.log("default currency", item);
              setDefaultCurrency(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getallbanks = async () => {
    try {
      const allbanks = await PublicFetch.get(`${ACCOUNTS}/bank`);
      console.log("getting all bank details", allbanks.data.data);
      // setAllbankdetails(allbanks.data.data)
      allbanks?.data?.data?.forEach((item, index) => {
        if (item.bank_default === 1) {
          console.log("default bankk", item);
          // setdefaultbank(item);
          // setDefaultCurrency(item);
        }
      });
    } catch (err) {
      console.log("error to fetching  bank details", err);
    }
  };

  useEffect(() => {
    if (bankDetails) {
      setdefaultbank(bankDetails);
    }
  }, [bankDetails]);

  useEffect(() => {
    companyinfo();
    allCurrency();
    getallbanks();
    // settemp1(template12)
  }, []);
  return (
    <div>
      {contentSucess=== true?
      <div>
        {/* div to set page border on all pages */}
        <div id="pageborder"></div>
        {/* page border div ends */}
        <table className="invoice_header">
          {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => {
              return (
                <thead className="invoice_header">
                  <tr className="invoice_header">
                    <div className="header_invoicetemp4">
                    <div className="header__address_wrapper_invoicetemp4 widthfirstheader">
                       <h2></h2>
                      </div>

                      <div className="header__address_wrapper_invoicetemp4 widthsecondheader">
                        <h3 className="cmpnyname_color m-0" >
                        CARGO PLUS
                          {/* {companyInfodata?.[0].company_name } */}
                           </h3>
                           <h6 className="cmpny_middleeast">MIDDLE EAST CO. WLL</h6>
                      </div>
                      <div className="header__address_logo_invoicetemp4 widththirdheader">
                     <img src={Invoicetmp4} height={130} width={125}/>
                      </div>
                      <div className="header__address_width_invoicetemp4 widthfourthheader">
                      <div>{companyInfodata?.[0].company_address} </div> 
                     {/* <div>Al Farwaniyah 85000 Kuwait</div> */}
                     <div>{ companyInfodata?.[0].company_country} </div>
                     <div>Phone :{companyInfodata?.[0].company_phone} </div>

                     {companyInfodata?.[0].company_email}
                     <div>  {companyInfodata?.[0].company_website}</div>
                      </div>
                    </div>
                  </tr>
                </thead>
              );
            })}

          <div className="invoice_details_temp4">
            <div className="invoice_details_col_temp4  invoice_details_col_1temp4 ">
              <table>
                <tbody className="">
                  {/* {invoice_details1} */}
                  <tr >
                    <td className="widthbillto" >Bill To</td>
                    <td >:</td>
                    <td style={{ fontWeight: 600,paddingLeft:"7px" }}>{billToCust} </td>
                  </tr>
                  <tr>
                    <td className="widthbillto">Job No</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600,paddingLeft:"7px" }}>{jobNo} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="invoice_details_col_temp4 ">
              <table>
                <tbody className="">
                  {/* {invoice_details2} */}
                  <tr>
                    <td className="widthtemp4">Invoice No</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>{invoice_no} </td>
                  </tr>
                  <tr>
                    <td className="widthtemp4">Invoice Date</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>{invoice_date} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="invoice_detailstemp5">
            <div className="invoice_details__coltemp5  invoice_details__col_1temp5">
              <table>
                <tbody className="py-3">
                  {invoice_details1}
                 
                {/* <tr className="invoice_header">
                  <td>Invoice Date</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  18/03/2023
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Terms</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  Cash
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Chargable Weight</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    33
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>No of pieces</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    33
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Gross weight</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    33
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Carrier</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  Carrier
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Mode</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  Mode
                  </td>
                </tr> */}
            
                </tbody>
              </table>
            </div>
            <div className="invoice_details__coltemp5  invoice_details__col_2">
              <table>
                <tbody className="py-3">
                  {invoice_details2}
                  {/* <>
                <tr className="invoice_header">
                  <td>Project Name</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  Project Name
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>MAWB/MBL</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  MAWB/MBL
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Origin</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    origin
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Destination</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  Destination
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Shipper</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  Shipper
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Consignee</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                  Consignee
                  </td>
                </tr>
               
              </> */}
                </tbody>
              </table>
            </div>
          </div>
          {/* {billto && ( */}
          <>
            <tbody>
              {/* listing table start */}
              <table className="details_table invoice_header" cellSpacing={0}>
                <thead className="invoice_header">
                  {invoice_table_header}
                  {/* <>
                    <tr className="invoice_header">
                      <th className="th_center">#</th>
                      <th className="tsk_exp">Task & description</th>
                      <th id="amount">Amount</th>
                    </tr>
                  </> */}
                </thead>
                <tbody>
                  {invoice_table_data}
                  {/* <>
                    {tabledata &&
                  tabledata.length > 0 &&
                  tabledata.map((item, index) => {
                    return (
                    <tr className="invoice_header">
                      <td align="center">1</td>
                      <td className="tsk_exp">data warehousing</td>

                      <td style={{ textAlign: "right" }}>200</td>
                    </tr>
                    );
                  })} 
                  </> */}
                </tbody>
            
              </table>
              
              <div className="invoice_details_tempate4_detailsTable">
            <div className="invoice_details_tempate4_detailsTable__part1">
              <table>
                <tbody>
                <tr className="invoice_header_temp5">
                    <td align="center"></td>
                    <td className="ps-3 py-1">
                      Total In Words ( {defaultCurrency?.currency_code} ) :{" "}
                      <td style={{ fontWeight: 600 }}> {amount_in_words} Only </td>
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
            <div className="invoice_details_tempate4_detailsTable__part2 ps-2">
              <table>
                <tbody>
               {totaldetails}
                  
                </tbody>
              </table>
            </div>
          </div>
            </tbody>
            {bankDetailsView === true ? (
              <footer className="invoice_header">
                <table style={{ paddingLeft: "2%" }} className="">
                  <tbody>
                    <tr>
                      <div className="ps-3">
                        <p>Thanks For your Business</p>
                      </div>
                      <div className=" ps-3">
                        <p>Bank Details</p>

                        <table>
                          <tbody className="">
                            <tr>
                              <td style={{ fontWeight: 600 }} className="temp4bank_details">Name</td>
                              <td>:</td>
                              <td>{defaultbank?.bank_account_name} </td>
                            </tr>
                            <tr>
                              <td style={{ fontWeight: 600 }}>Bank Name</td>
                              <td>:</td>
                              <td>{defaultbank?.bank_name} </td>
                            </tr>
                            <tr>
                              <td style={{ fontWeight: 600 }}>Branch</td>
                              <td>:</td>
                              <td>{defaultbank?.bank_branch} </td>
                            </tr>
                            <tr>
                              <td style={{ fontWeight: 600 }}>Account No</td>
                              <td>:</td>
                              <td>{defaultbank?.bank_account_number} </td>
                            </tr>
                            <tr>
                              <td style={{ fontWeight: 600 }}>IBAN No</td>
                              <td>:</td>
                              <td>{defaultbank?.bank_iban_no} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </footer>
            ) : (
              ""
            )}

            {grandTotalViewPurchaseBill === true ? (
              <>
                <div className="row p-0 m-0 mt-2">
                  <div className="col-9"></div>
                  <div className="col-3">
                    <span className="mt-5">
                      {" "}
                      <span className="grndtot_bold">GrandTotal :</span>{" "}
                      {grandTotalView}{" "}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
          {/* )} */}
        </table>
      </div>
      :"" }
    </div>
  );
}

export default InvoicePrintTemp4;
