import { useEffect, useState } from "react";
import PublicFetch from "../../utils/PublicFetch";
import { GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
import "./printreport.scss";


function PrintReports({
report_name,
table_header_data,
table_print_data
}) {
  const [companyInfodata, setCompanyInfodata] = useState();

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    companyinfo();
  }, []);

  return (
    <>
      <div>
        {/* div to set page border on all pages */}
        <div id="pageborder"></div>
        {/* page border div ends */}
        <table className="">
          {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => {
              return (
                <thead className="">
                  <tr className="">
                    <div className="quotheader">
                      {/* <div className="header__address_wrapper"> */}
                      <div className="">
                        <img
                          style={{ height: "150px", width: "700px" }}
                          src={`${process.env.REACT_APP_BASE_URL}/${item.company_quotation_header}`}
                          alt=""
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </tr>
                </thead>
              );
            })}
          <tbody>
            <div className="text-center ">
              <h2 className="quotation_heading_style">{report_name} </h2>
            </div>
            <br></br>
            {/* <h5 style={{fontWeight:600 ,padding:"10px"}}>Job</h5> */}

            <table className="print_table_data " cellSpacing={0}>
              <thead className="print_table_data">
                {table_header_data}
                {/* <tr className="">
                  <th className="">Sl.No</th>
                  <th className="" style={{ textAlign: "left", }}>
                    Ref No
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    Shipper
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    CBM
                  </th>
                  <th className="tsk_exp" style={{ textAlign: "left" }}>
                    MBL/MAWB No
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    CONTAINER
                  </th>
                  <th style={{ textAlign: "left" }}>Remarks</th>
                </tr> */}
              </thead>
              <tbody>
                {table_print_data}
               
                </tbody>
            </table>



          </tbody>
        </table>
      </div>
    </>
  );
}
export default PrintReports;
