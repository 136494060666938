import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import PublicFetch from "../../../utils/PublicFetch";
import moment from "moment";
import "./receiptprint.scss";
import ReceiptPrinttemp1 from "../../../components/Receipt-Print/ReceiptPrint";
import { camelize } from "../../../utils/camelcaseconvert";
import ReceiptPrinttemp2 from "../../../components/Receipt-Print/Receipt-Print2/ReceiptPrint2";

function ReceiptPrint() {
  var converter = require("number-to-words");
  const { id } = useParams();
  const [compantInfo, setCompanyInfo] = useState();
  const [receiptsData, setReceiptData] = useState();
  const [totalAmt, setTotalAmt] = useState();

  const [grandtotal, setGrandtotal] = useState();
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [noOfDecimal, setNoOfDecimal] = useState("");

  const GetReceiptData = (id) => {
    if (id) {
      PublicFetch.get(`${ACCOUNTS}/account-receipts/${id}`)
        .then((res) => {
          if (res?.data?.success) {
            console.log("receptt datatat", res?.data?.data);
            setReceiptData(res?.data?.data);
            setGrandtotal(res?.data?.data?.acc_receipt_amt_received_lx);
            setCurrencySymbol(
              res?.data?.data?.generalsettings_v1_currency?.currency_code
            );
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const companyData = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        if (res?.data?.success) {
          console.log("noof decimal", res?.data?.data);
          setCompanyInfo(res?.data?.data[0]);
          setNoOfDecimal(res?.data?.data[0]?.number_of_decimal);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  // GrandTotal Cal
  useEffect(() => {
    let GrandTotal = 0;
    receiptsData?.accounts_v1_account_receipt_inv_details &&
      receiptsData?.accounts_v1_account_receipt_inv_details?.length &&
      receiptsData?.accounts_v1_account_receipt_inv_details?.map(
        (item, index) => {
          GrandTotal += item?.acc_rec_inv_det_adjust_amt;
        }
      );
    setTotalAmt(GrandTotal);
  }, [receiptsData?.accounts_v1_account_receipt_inv_details]);

  //for Api call
  useEffect(() => {
    GetReceiptData(id);
    companyData();
  }, [id]);
  useEffect(() => {
    if (compantInfo && receiptsData) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [compantInfo, receiptsData]);
  return (
    <>
    
      {/* <ReceiptPrinttemp1
        voucherno={receiptsData?.acc_receipt_voucher_no}
        receiptdate={moment(receiptsData?.acc_receipt_date).format(
          "DD-MM-YYYY"
        )}
        customername={
          receiptsData?.accounts_v1_account_receipts_acc_receipt_payment_from
            ?.acc_ledger_name
        }
        paymentmode={receiptsData?.acc_receipt_payment_type}
        amountreceivedNumber={
          <>
            <span style={{ fontSize: "18px" }}>
              {currencySymbol}&nbsp;{" "}
              <span>
                {receiptsData?.acc_receipt_amt_received_lx?.toFixed(
                  noOfDecimal
                )}{" "}
              </span>{" "}
            </span>
          </>
        }
        print_details1={
          <>
            <tr>
              <td
                style={{
                  fontSize: "16px",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                }}
                className="receipt_section_width"
              >
                Being
              </td>
              {receiptsData?.accounts_v1_account_receipt_inv_details?.length >
              0 ? (
                <td className="p-0" style={{ fontWeight: 600 }}>
                  Payment against Invoice
                </td>
              ) : (
                <td className="p-0" style={{ fontWeight: 600 }}>
                  Advance Payment
                </td>
              )}
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "16px",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                }}
              >
                Particular
              </td>
              <td className="p-0" style={{ fontWeight: 600 }}>
                {receiptsData?.acc_receipt_particulars}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "16px",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                }}
              >
                Amount in Words
              </td>

              {grandtotal ? (
                <td style={{ fontWeight: 600 }}>
                  {receiptsData?.generalsettings_v1_currency?.currency_code}{" "}
                  {camelize(converter.toWords(grandtotal))} Only
                </td>
              ) : (
                ""
              )}
            </tr>
          </>
        }
        print_details2={
          <>
          
            {receiptsData?.accounts_v1_account_receipt_inv_details?.length >
            0 ? (
              <>
              <p  className="font_receiptPaymnet px-2" style={{fontWeight:600,fontSize:"18px" }}>Payment Details</p>
              <table className="bordered ">
                <thead className="px-2">
                  <tr style={{ backgroundColor: "lightgray" }}>
                    <th className="font_receiptPaymnet">Sl No</th>
                    <th className="font_receiptPaymnet">Invoice No</th>
                    <th className="font_receiptPaymnet">Invoice Date</th>
                    <th style={{ textAlign: "right" }} className="font_receiptPaymnet">Invoice Amount</th>
                   
                    <th style={{ textAlign: "right",paddingRight:"7px" }} className="font_receiptPaymnet">Payment Amount</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {receiptsData?.accounts_v1_account_receipt_inv_details &&
                    receiptsData?.accounts_v1_account_receipt_inv_details
                      ?.length &&
                    receiptsData?.accounts_v1_account_receipt_inv_details?.map(
                      (item, index) => {
                        return (
                          <tr  style={{ borderBottom: "1px solid #e4dada",}}>
                            <th className="">{index + 1}</th>
                            <th className="">{item?.fms_v1_invoice?.invoice_no}</th>
                            <th className="">
                              {moment(
                                item?.fms_v1_invoice?.invoice_date
                              ).format("DD-MM-YYYY")}
                            </th>
                            <th className="" style={{ textAlign: "right" }}>
                              {item?.fms_v1_invoice?.invoice_grand_total?.toFixed(
                                compantInfo?.number_of_decimal
                              )}
                            </th>
                          
                            <th className="" style={{ textAlign: "right",paddingRight:"7px" }}>
                              {item?.acc_rec_inv_det_adjust_amt_fx?.toFixed(
                                compantInfo?.number_of_decimal
                              )}
                            </th>
                            
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
              </>
            ) : (
              ""
            )}
          </>
        }
      /> */}
      <ReceiptPrinttemp2
        voucherno={receiptsData?.acc_receipt_voucher_no}
        receiptdate={moment(receiptsData?.acc_receipt_date).format(
          "DD-MM-YYYY"
        )}
        customername={
          receiptsData?.accounts_v1_account_receipts_acc_receipt_payment_from
            ?.acc_ledger_name
        }
        paymentmode={receiptsData?.acc_receipt_payment_type}
        amountreceivedNumber={
          <>
            <span style={{ fontSize: "18px" }}>
              {currencySymbol}&nbsp;{" "}
              <span>
                {receiptsData?.acc_receipt_amt_received_fx?.toFixed(
                  noOfDecimal
                )}{" "}
              </span>{" "}
            </span>
          </>
        }
        amountInWords={
          <>
            {receiptsData?.generalsettings_v1_currency?.currency_code}
            {/* {camelize(converter.toWords(grandtotal))}   */}
          </>
        }
        // amountInWords={ <>
        //  {receiptsData?.generalsettings_v1_currency?.currency_code}
        //           {camelize(converter.toWords(grandtotal))} Only </>}

        print_details2={
          <>
          
            {receiptsData?.accounts_v1_account_receipt_inv_details?.length >
            0 ? (
              <>
              <p  className="font_receiptPaymnet px-2" style={{fontWeight:600,fontSize:"18px" }}>Payment Details</p>
              <table className="bordered ">
                <thead className="px-2">
                  <tr style={{ backgroundColor: "lightgray" }}>
                    <th className="font_receiptPaymnet">Sl No</th>
                    <th className="font_receiptPaymnet">Invoice No</th>
                    <th className="font_receiptPaymnet">Invoice Date</th>
                    <th style={{ textAlign: "right" }} className="font_receiptPaymnet">Invoice Amount</th>
                   
                    <th style={{ textAlign: "right",paddingRight:"7px" }} className="font_receiptPaymnet">Payment Amount</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {receiptsData?.accounts_v1_account_receipt_inv_details &&
                    receiptsData?.accounts_v1_account_receipt_inv_details
                      ?.length &&
                    receiptsData?.accounts_v1_account_receipt_inv_details?.map(
                      (item, index) => {
                        return (
                          <tr  style={{ borderBottom: "1px solid #e4dada",}}>
                            <th className="">{index + 1}</th>
                            <th className="">{item?.fms_v1_invoice?.invoice_no}</th>
                            <th className="">
                              {moment(
                                item?.fms_v1_invoice?.invoice_date
                              ).format("DD-MM-YYYY")}
                            </th>
                            <th className="" style={{ textAlign: "right" }}>
                              {item?.fms_v1_invoice?.invoice_grand_total?.toFixed(
                                compantInfo?.number_of_decimal
                              )}
                            </th>
                          
                            <th className="" style={{ textAlign: "right",paddingRight:"7px" }}>
                              {item?.acc_rec_inv_det_adjust_amt_fx?.toFixed(
                                compantInfo?.number_of_decimal
                              )}
                            </th>
                            
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
              </>
            ) : (
              ""
            )}
          </>
        }
      />
    </>
  );
}

export default ReceiptPrint;
