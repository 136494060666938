import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { cargo_typeoptions } from "../../../../utils/SelectOptions";
import { DragOutlined, FontColorsOutlined } from "@ant-design/icons";
import dragula from "dragula";
import { CRM_BASE_URL_SELLING } from "../../../../api/bootapi";
import "dragula/dist/dragula.css";
import { CRM_BASE_URL } from "../../../../api/bootapi";
import TableData from "../../../../components/table/table_data";
import { FaTrash } from "react-icons/fa";
import { CRM_BASE_URL_FMS } from "../../../../api/bootapi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GENERAL_SETTING_BASE_URL } from "../../../../api/bootapi";
import FileUpload from "../../../../components/fileupload/fileUploader";
import { Checkbox, Form, InputNumber, message } from "antd";
import Button from "../../../../components/button/button";
import PublicFetch from "../../../../utils/PublicFetch";
import InputType from "../../../../components/Input Type textbox/InputType";
import { Select, Popconfirm } from "antd";
import Custom_model from "../../../../components/custom_modal/custom_model";
import SelectBox from "../../../../components/Select Box/SelectBox";
import { DatePicker } from "antd";
// import "../../Quotations/create quotation/quotation.scss";
import "./purchaseorder.scss";
import "../../../../styles/Status.scss";
import ROUTES from "../../../../routes";
import Input_Number from "../../../../components/InputNumber/InputNumber";
import moment from "moment";
import axios from "axios";
import TextArea from "../../../../components/ InputType TextArea/TextArea";
import { CRM_BASE_URL_PURCHASING } from "../../../../api/bootapi";
import { vendor_Organisation } from "../../../../utils/SelectOptions";
import Layout from "../../../../layouts/layout";
export default function Purchaseorder() {
  const { id } = useParams();
  const { pathname } = useLocation();
  let url = pathname.split("/");
  console.log("urllll", url);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [error, setError] = useState(false);
  const [taxratee, setTaxRatee] = useState();
  const [allvendor, setAllvendor] = useState();
  console.log("all vendors are : ", allvendor);
  const [totalvendor, settotalvendor] = useState("");
  console.log("tax rate ", taxratee);
  const [cargooptions, setCargooptions] = useState(cargo_typeoptions);
  console.log("cargo options : ", cargooptions);
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState();
  const [taxGroup, setTaxGroup] = useState();
  const [currencyDefault, setCurrencyDefault] = useState();
  console.log("curencydefault", currencyDefault);
  const [imgSizeError, setImgSizeError] = useState(false);
  const [img, setImg] = useState([]);
  const [allincoterms, setallincoterms] = useState("");
  const [defaultincoterm, setdefaultincoterm] = useState("");
  const [allcontainertype, setallcontainertype] = useState("");
  const [isFileSizeExceeds, setIsFileSizeExceeds] = useState(false);
  const [isFileSizeError, setIsFileSizeError] = useState(false);
  const [JobExpence, setJobExpence] = useState(0);
  const [AllJobMinimal, setAllJobMinimal] = useState();
  const [AllAwbl, setAllAwbl] = useState();
  console.log("AWbbl no ", AllAwbl);
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const [isService, setIsService] = useState(false);
  const [isAWB, setIsAWB] = useState(false);
  const [isJob, setIsjob] = useState(false);
  const [isAmount, setIsAmount] = useState(false);
  const [filenew, setFilenew] = useState();
  const [noofItems, setNoofItems] = useState("25");
  const [current, setCurrent] = useState(1);
  const [totalCount, setTotalcount] = useState();
  const pageofIndex = noofItems * (current - 1) - 1 + 1;
  const pagesizecount = Math.ceil(totalCount / noofItems);
  console.log("page number isss", pagesizecount);
  useEffect(() => {
    GetAllLeadData();
  }, [noofItems, pageofIndex, pagesizecount]);

  const numofItemsTo = noofItems * current;
  console.log("isTableEmpty", isTableEmpty);

  const [frighttype, setFrighttype] = useState();
  const [services, setServices] = useState([]);
  console.log("Servicesss are :::", services);
  const [allservices, setAllservices] = useState();
  const [allLocations, setAllLocations] = useState();
  console.log("locations ", allLocations);
  const [currencydata, setCurrencydata] = useState();
  const [carrierdata, setCarrierdata] = useState();
  const [OpportunityList, setOpportunityList] = useState([]);
  const [currentcount, setCurrentcount] = useState();
  const [locationType, setLocationType] = useState();
  const [taxGroups, setTaxGroups] = useState();
  const [total, setTotal] = useState(0);
  const [currencyRates, setCurrencyRates] = useState(0);
  const [serialNo, setserialNo] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [oppnew, setOppnew] = useState([]);
  console.log("Opportunities are :::", oppnew);
  const [frightmode, setFrightmode] = useState();
  console.log("change", frightmode);
  const [frighttypemode, setFrighttypemode] = useState();
  console.log("frighttype mode ", frighttypemode);
  const [leadIdEnq, setLeadIdEnq] = useState("");

  console.log("Selected  enquiry lead id is ", leadIdEnq);
  const [allunit, setAllunit] = useState([]);
  const [oppleadid, setOppleadid] = useState();

  console.log("Opportunities lead id :::", oppleadid);
  const [numOfItems, setNumOfItems] = useState("25");
  console.log("all units are : ", allunit);
  const [unitTable, setunitTable] = useState("");
  const [allPaymentTerms, setAllPaymentTerms] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [allLeadList, setAllLeadList] = useState([]);
  const [TaskDetails, setTaskDetails] = useState();
  const [Files, setFiles] = useState([]);
  const [jobId, setJobId] = useState();

  const [isTaxable, setIsTaxable] = useState();
  useEffect(() => {
    let Taxable = JSON.parse(localStorage.getItem("taxable"));
    setIsTaxable(Taxable);
  }, [localStorage.getItem("taxable")]);

  const [Decimals, setDecimals] = useState(
    localStorage.getItem("NumberOfDecimals")
  );

  const [addForm] = Form.useForm();
  const navigate = useNavigate();
  const dateFormatList = ["DD-MM-YYYY", "DD-MM-YY"];
  const dataSource = [
    {
      key: 1,
      po_det_task_id: "",
      po_det_awb_bl_no: "",
      po_det_job_id: "",
      po_det_amount: "",
      po_det_tax_id: "",
      po_det_tax_percentage: "",
      po_det_tax_amount: "",
      po_det_total_amount: "",
      po_det_job_no: "",
      is_po_det_task_id: false,
      is_po_det_job_id: false,
      is_po_det_amount: false,
      is_po_det_awb_bl_no: false,
    },
  ];
  const [Errormsg, setErrormsg] = useState();
  const [newjobNo, setNewjobno] = useState();
  console.log("new job no", newjobNo);
  const close_modal = (mShow, time) => {
    if (!mShow) {
      setTimeout(() => {
        setSuccessPopup(false);
        navigate(ROUTES.PUCHASE_ORDER_LIST);
      }, time);
    }
  };

  const [tableData, setTableData] = useState(dataSource);
  const [newGrandTotal, setNewGrandTotal] = useState();
  const getIndexInParent = (el) =>
    Array.from(el.parentNode.children).indexOf(el);

  console.log("rfnjkrrfn", tableData);

  const handleInputChange = (e, key, col) => {
    console.log("gai guys", e, col);
    let TaxPercentage = 0;
    let TaxGroup = "";
    let TaxAmount = 0;
    let TotalAmount = 0;
    let Istask_id = false;
    // Checking passed value is Same
    allservices.forEach((service, Serindex) => {
      if (e === service?.service_id) {
        TaxGroup = service?.service_taxgroup;
      }
    });
    taxGroups.map((item, index) => {
      if (TaxGroup === item.tax_group_id) {
        item?.fms_v1_tax_types.forEach((taxType, typeIndex) => {
          TaxPercentage += taxType?.tax_type_percentage;
        });

        setTaxRatee(item.tax_type_percentage);
      }
    });

    if (e) {
      Istask_id = false;
    } else {
      Istask_id = true;
    }

    // Set Value To TableData
    setTableData(
      tableData.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            po_det_task_id: e,
            po_det_tax_amount: TaxAmount,
            po_det_tax_percentage: TaxPercentage,
            po_det_tax_id: TaxGroup,
            is_po_det_task_id: Istask_id,
            po_det_total_amount: item?.po_det_amount + TaxAmount,
          };
        }
        return item;
      })
    );
    console.log("tabledata", tableData);
    console.log(
      "TaxPercentage",
      TaxPercentage,
      TaxAmount,
      TaxGroup,
      TotalAmount
    );
  };

  useEffect(() => {
    let grandTotal = 0;
    tableData?.map((item, index) => {
      console.log("deatils totals", item);
      grandTotal += item.po_det_total_amount;
    });
    // }
    addForm.setFieldsValue({ grandtotal: grandTotal });
  }, [tableData]);
  useEffect(() => {
    console.log("rendered23");
  }, [tableData]);

  const handleInputchange1 = (e, key, col) => {
    console.log("allAwb", AllAwbl);
    console.log("reached here ..,.,.,.");
    if (col === "po_det_awb_bl_no") {
      const currentIndex = AllAwbl?.findIndex(
        (item) => item?.job_awb_bl_no === e
      );
      const currentAwb =
        currentIndex !== -1 ? AllAwbl[currentIndex] : { job_id: null };
      // setNewJobNumber([currentAwb]);

      let IsAWB = false;
      if (e) {
        IsAWB = false;
      } else {
        IsAWB = true;
      }
      setTableData(
        tableData.map((item) => {
          if (item.key === key) {
            return {
              ...item,
              po_det_awb_bl_no: e,
              is_po_det_awb_bl_no: IsAWB,
              po_det_job_id: currentAwb?.job_id,
            };
          }
          return item;
        })
      );
    }

    if (col === "po_det_job_id") {
      console.log("job_now");
      let IsJob = false;
      let IsAwb = false;
      if (e) {
        IsJob = false;
      } else {
        IsJob = true;
      }
      let AwbNo;
      setTableData(
        tableData.map((item) => {
          if (item.key === key) {
            AllAwbl?.map((awb, inawb) => {
              if (awb?.job_id === e) {
                AwbNo = awb?.job_id;
                IsAwb = false;
              }
            });
            return {
              ...item,
              po_det_job_id: e,
              po_det_awb_bl_no: AwbNo,
              is_po_det_awb_bl_no: IsAwb,
              is_po_det_job_id: IsJob,
            };
          }
          return item;
        })
      );
    }

    let TaxAmount = 0;
    let TotalAmount = 0;

    if (col === "po_det_amount") {
      let IsAmount = false;
      if (e) {
        IsAmount = false;
      } else {
        IsAmount = true;
      }
      setTableData(
        tableData.map((item) => {
          if (item.key === key) {
            TaxAmount =
              isTaxable === 1 ? (e * item?.po_det_tax_percentage) / 100 : 0;
            TotalAmount = e + TaxAmount;
            return {
              ...item,
              po_det_amount: e,
              po_det_tax_amount: TaxAmount,
              po_det_total_amount: TotalAmount,
              is_po_det_amount: IsAmount,
            };
          }
          return item;
        })
      );
    }
  };
  console.log("tax type ::123", taxGroup);

  const handleInputChange2 = (e, index, col) => {
    setTableData(
      tableData.map((item) => {
        if (item.key === index.key) {
          return { ...item, [col]: e };
        }
        return item;
      })
    );
  };

  const handleEnter = (e, key, task_id, name) => {
    console.log("hellooo datat");
    let temp = false;
    setTableData(
      tableData.map((item, index) => {
        if (item?.key === key) {
          if (!item?.po_det_task_id) {
            temp = true;
            setIsService(true);
            return { ...item, is_po_det_task_id: true };
          } else if (!item?.po_det_awb_bl_no) {
            temp = true;
            setIsAWB(true);
            return { ...item, is_po_det_awb_bl_no: true };
          } else if (!item?.po_det_job_id) {
            setIsjob(true);
            temp = true;
            return { ...item, is_po_det_job_id: true };
          } else if (!item?.po_det_amount) {
            setIsAmount(true);
            temp = true;
            return { ...item, is_po_det_amount: true };
          }
        }
        return item;
      })
    );

    if (name === "button") {
      e.preventDefault();

      !isEmptyRow() &&
        temp === false &&
        setTableData([
          ...tableData,
          {
            key: tableData?.length + 1,
            po_det_task_id: "",
            po_det_amount: "",
            po_det_awb_bl_no: "",
            po_det_job_id: "",
            po_det_tax_amount: 0,
            po_det_tax_id: "",
            po_det_tax_percentage: 0,
            po_det_total_amount: 0,
            po_det_job_no: "",
            is_po_det_task_id: false,
            is_po_det_job_id: false,
            is_po_det_amount: false,
            is_po_det_awb_bl_no: false,
          },
        ]);
    }

    if (e.key === "Tab") {
      !isEmptyRow() &&
        temp === false &&
        setTableData([
          ...tableData,
          {
            key: tableData?.length + 1,
            po_det_task_id: "",
            po_det_amount: "",
            po_det_awb_bl_no: "",
            po_det_job_id: "",
            po_det_tax_amount: 0,
            po_det_tax_id: "",
            po_det_tax_percentage: 0,
            po_det_total_amount: 0,
            is_po_det_task_id: false,
            is_po_det_job_id: false,
            is_po_det_amount: false,
            is_po_det_awb_bl_no: false,
          },
        ]);
    }
    if (e.key === "Enter") e.preventDefault();
  };
  // to check whether the abouve row is filled
  const isEmptyRow = () => {
    const lastRow = tableData[tableData?.length - 1];
    if (
      !lastRow.po_det_task_id &&
      !lastRow.po_det_awb_bl_no &&
      !lastRow.po_det_job_id &&
      !lastRow.po_det_amount
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleReorder = (dragIndex, draggedIndex) => {
    setTableData((oldState) => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex, 1)[0];
      newState.splice(draggedIndex, 0, item);
      return newState;
    });
  };
  React.useEffect(() => {
    let start;
    let end;
    const container = document.querySelector(".ant-table-tbody");
    const drake = dragula([container], {
      moves: (el) => {
        start = getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      end = getIndexInParent(el);
      handleReorder(start, end);
    });
  }, []);

  console.log("Lead names :", allLeadList);
  const getallcarrier = async () => {
    try {
      const getcarrier = await PublicFetch.get(`${CRM_BASE_URL_FMS}/carrier`);
      console.log("Getting all carrier : ", getcarrier.data.data);
      setCarrierdata(getcarrier.data.data);
    } catch (err) {
      console.log("Error in getting carrier : ", err);
    }
  };

  const getallcurrency = async () => {
    try {
      const allcurrency = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/currency`
      );
      console.log("Getting all currency : ", allcurrency.data.data);
      setCurrencydata(allcurrency.data.data);
      let arr = [];
      allcurrency?.data?.data?.forEach((item, index) => {
        if (item.currency_is_default === 1) {
          arr = item?.currency_code;
          setCurrencyDefault(arr);
          getCurrencyRate(
            item?.currency_id,
            item?.currency_code,
            allcurrency?.data?.data
          );
        }
      });
    } catch (err) {
      console.log("Error in getting currency : ", err);
    }
  };

  const getallfrighttype = async () => {
    try {
      const allfrighttypes = await PublicFetch.get(
        `${CRM_BASE_URL_FMS}/freightTypes`
      );
      console.log("Getting all frieght types : ", allfrighttypes.data.data);
      setFrighttype(allfrighttypes.data.data);
      // setFrighttypemode(allfrighttypes.data.data.freight_type_mode);
    } catch (err) {
      console.log("Error in fetching fright types : ", err);
    }
  };

  const getfmssettings = async () => {
    try {
      const allfmssetting = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/fms`
      );
      console.log("all fmssettinggg", allfmssetting.data);
      setdefaultincoterm(allfmssetting.data.data.fms_settings_incorterm);

      addForm.setFieldsValue({
        incoterm: allfmssetting.data.data.fms_settings_incorterm,
      });
    } catch (err) {
      console.log("error while getting the fmssettinggg: ", err);
    }
  };

  const getAllincoterm = async () => {
    try {
      const allCountries = await PublicFetch.get(
        `${CRM_BASE_URL_FMS}/incoterms/minimal`
      );
      console.log("all incotermss", allCountries.data.data);
      setallincoterms(allCountries.data.data);
      // setGetCountries(allCountries.data.data);
    } catch (err) {
      console.log("error while getting the countries: ", err);
    }
  };

  const getAllTaxGroup = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/tax_group`)
      .then((res) => {
        console.log("response");
        if (res.data.success) {
          console.log("successs");
          setTaxGroups(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  const getAllTaxTypes = async () => {
    try {
      const allTxTypes = await PublicFetch.get(
        `${CRM_BASE_URL_FMS}/tax-types/Minimal`
      );
      console.log("all taxtype are", allTxTypes.data.data);
      // setTaxTypes(allTxTypes.data.data);
    } catch (err) {
      console.log("error while getting the tax types: ", err);
    }
  };

  console.log("ratesssss", currencyRates);
  let b;
  const getCurrencyRate = (data, currency_code, AllCurrency) => {
    AllCurrency?.forEach((item) => {
      if (item?.currency_id === data) {
        b = item?.currency_code;
      }
    });
    console.log("code", b);
    console.log(";;;;;;;;;", data);
    axios
      .get(`https://open.er-api.com/v6/latest/${currency_code}`)
      .then(function (response) {
        console.log("currency current rate:", response);
        let a = response.data.rates[b];
        console.log("currency match", a);
        let rate = 1 / a;
        setCurrencyRates(rate);
        addForm.setFieldsValue({ exchnagerate: rate, currency: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallcontainertype = async () => {
    try {
      const allcontainertype = await PublicFetch.get(
        `${CRM_BASE_URL_FMS}/container_type`
      );
      console.log("getting all containertype", allcontainertype);
      setallcontainertype(allcontainertype.data.data);
    } catch (err) {
      console.log("error to fetching  containertypes", err);
    }
  };

  //Awb/bl no Data

  const GetAwblno = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/job/minimal`)
      .then((res) => {
        console.log("Response of Awbbl no", res.data.data);
        if (res.data.success) {
          setAllAwbl(res?.data?.data);
          setNewJobNumber(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  const [newJobNumber, setNewJobNumber] = useState();
  console.log("new job number ", newJobNumber);

  const GetAwbljob = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/job/awb-bl-no/${newjobNo}`)
      .then((res) => {
        console.log("Response of Awbbl no", res.data.data);
        if (res.data.success) {
          setNewJobNumber(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  // // Job minimal Data
  // const GetJobMinimal = () => {
  //   PublicFetch.get(`${CRM_BASE_URL_FMS}/job/minimal`)
  //     .then((res) => {
  //       console.log("Response");
  //       if (res.data.success) {
  //         setAllJobMinimal(res?.data?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error", err);
  //     });
  // };

  useEffect(() => {}, []);
  useEffect(() => {
    if (newjobNo) {
      GetAwbljob();
    }
  }, [newjobNo]);
  useEffect(() => {
    getAllTaxTypes();
    // GetJobMinimal();
    getallcurrency();
    getAllTaxGroup();
    GetAwblno();
  }, []);

  const handleDelete = (key) => {
    const newData = tableData?.filter((item) => item?.key !== key);
    console.log("new dtata", newData.length);
    if (newData.length > 0) {
      setTableData(newData);
    }

    let grandTotal = 0;
    tableData.map((item, index) => {
      grandTotal += item?.po_det_total_amount;
    });
    setNewGrandTotal(grandTotal);
    if (newData.length <= 0) {
      // addForm.setFieldsValue({ quotation_details: newData });
    }

    addForm.setFieldsValue({ grandtotal: grandTotal });
  };

  const handleJOB = (e) => {
    let newAwbArrayByJob = [];
    newJobNumber?.map((item, index) => {
      if (item?.job_id === e) {
        newAwbArrayByJob.push(item);
      }
    });
    console.log("New AwbNo", newAwbArrayByJob);
    setAllAwbl([...newAwbArrayByJob]);
  };

  const TaxPerct = {
    title: "TAX %",
    dataIndex: "po_det_tax_percentage",
    key: "po_det_tax_percentage",
    align: "center",
    width: "8%",
    render: (data, index) => {
      console.log("index is :", index);
      return (
        <div className="tborder ">
          <Form.Item>
            <Input_Number
              className="input_bg"
              value={index?.po_det_tax_percentage}
              precision={Decimals}
              align="right"
            />
          </Form.Item>
          {/* <div className="">
                {index.quotation_details_total &&
                index.quotation_details_total ? (
                  <></>
                ) : (
                  <>
                    <label>Total is required</label>
                  </>
                )}
              </div> */}
        </div>
      );
    },

    align: "right",
  };

  const TaxAmount = {
    title: "TAX AMT",
    dataIndex: "po_det_tax_amount",
    key: "po_det_tax_amount",
    width: "15%",
    render: (data, index) => {
      console.log("index is :", index);
      return (
        <div className="tborder ">
          <Form.Item>
            <Input_Number
              className="text_right input_bg "
              value={index?.po_det_tax_amount}
              onChange={(e) => {}}
              align="right"
              // step={0.01}
              min={0}
              precision={Decimals}
              controlls={false}
              disabled={true}
            />
          </Form.Item>
        </div>
      );
    },

    align: "right",
  };

  const TaxGroup = {
    title: "TAX GROUP",
    dataIndex: "po_det_tax_id",
    key: "po_det_tax_id",
    align: "right",
    width: "10%",
    render: (data, index) => {
      console.log("index is :", index);
      return (
        <div className=" tborder ">
          <Form.Item>
            <SelectBox
              disabled={true}
              allowClear
              showSearch
              optionFilterProp="children"
              className=" input_bg"
              value={index.po_det_tax_id}
              onChange={(e) => {
                console.log("servicess11123", e);
                // handleInputchange1(e, index.key, "quotation_details_tax_type")
                handleInputChange(e, index.key, "po_det_tax_amount");
              }}
              // disabled={true}
            >
              {taxGroups &&
                taxGroups.length > 0 &&
                taxGroups.map((item, index) => {
                  return (
                    <Select.Option
                      key={item.tax_group_id}
                      value={item.tax_group_id}
                    >
                      {item.tax_group_name}
                    </Select.Option>
                  );
                })}
            </SelectBox>
          </Form.Item>
          {/* <div className="">
              {index.quotation_details_total &&
              index.quotation_details_total ? (
                <></>
              ) : (
                <>
                  <label>Total is required</label>
                </>
              )}
            </div> */}
        </div>
      );
    },
  };

  const TotalAmount = {
    title: "TOTAL AMOUNT",
    dataIndex: "po_det_total_amount",
    key: "po_det_total_amount",

    render: (data, index) => {
      console.log("index is :", index);
      return (
        <div className="tborder ">
          <Form.Item>
            <Input_Number
              className="text_right input_bg "
              // value={    index.totalamount=(index.cost + index.taxamount)
              // }
              value={index?.po_det_total_amount}
              onChange={(e) =>
                handleInputChange2(e, index, "quotation_details_total")
              }
              align="right"
              // step={0.01}
              min={0}
              precision={Decimals}
              controlls={false}
              disabled={true}
              // onKeyDown={(e) => handleEnter(e, index.key)}
            />
          </Form.Item>
        </div>
      );
    },

    align: "right",
  };

  const columns = [
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      className: "drag-visible",
      render: (data, record, index) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div className="actionEdit m-0 p-0">
              <DragOutlined className="draggable" type="swap" />
            </div>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <div className="deleteIcon m-0">
                <FaTrash />
              </div>
            </Popconfirm>
          </div>
        );
      },
      width: "5%",
    },
    {
      title: "SL.NO",
      key: "index",
      width: "2%",
      render: (value, item, index) => serialNo + index,
      align: "center",
    },
    // {
    //   title: "",
    //   dataIndex: "operation",
    //   render: (_, record) =>
    //     tableData.length >= 1 ? (
    //       <Popconfirm
    //         title="Sure to delete?"
    //         onConfirm={() => handleDelete(record.key)}
    //       >
    //         <div className="deleteIcon m-0">
    //           <FaTrash />
    //         </div>
    //       </Popconfirm>
    //     ) : null,
    // },
    {
      title: "TASKS",
      dataIndex: "po_task_id",
      key: "po_task_id",
      width: "15%",
      render: (data, index) => {
        console.log("index is :", index);
        return (
          <div className="">
            <Form.Item>
              <SelectBox
                allowClear
                showArrow={false}
                showSearch
                optionFilterProp="children"
                className=" input_bg"
                value={index?.po_det_task_id}
                onChange={(e) => {
                  console.log("servicess11123", e);
                  handleInputChange(e, index.key, "po_det_task_id");
                  setIsTableEmpty(false);
                  setIsService(false);
                }}
              >
                {services &&
                  services.length > 0 &&
                  services.map((item, index) => {
                    console.log("it", item);
                    return (
                      <Select.Option
                        key={item.service_id}
                        value={item.service_id}
                      >
                        {item.service_name}
                      </Select.Option>
                    );
                  })}
              </SelectBox>
            </Form.Item>
            {index?.is_po_det_task_id ? (
              <>
                <small style={{ color: "red" }}>Please Select Task</small>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
      align: "left",
    },
    {
      title: "AWB/BL NO",
      dataIndex: "po_AWB/BL_no",
      key: "po_AWB/BL_no",
      width: "15%",
      align: "left",
      render: (data, index) => {
        console.log("index is :", index);
        return (
          <div className="tborder " key={index.index}>
            <Form.Item>
              <SelectBox
                allowClear
                showSearch
                optionFilterProp="children"
                className=" mb-2 input_bg"
                value={index.po_det_awb_bl_no}
                onChange={(e) => {
                  console.log("event of awbbl no ", e);
                  setNewjobno(e);
                  handleInputchange1(e, index.key, "po_det_awb_bl_no");
                  setIsTableEmpty(false);
                }}
                // disabled={true}
              >
                {AllAwbl &&
                  AllAwbl.length > 0 &&
                  AllAwbl.map((item, index) => {
                    return (
                      <Select.Option
                        key={item.job_id}
                        value={item.job_awb_bl_no}
                      >
                        {item.job_awb_bl_no}
                      </Select.Option>
                    );
                  })}
              </SelectBox>
              {/* <InputType
                value={index?.po_det_awb_bl_no}
                className=" input_bg "
                onChange={(e) => {
                  handleInputchange1(
                    e.target.value,
                    index?.key,
                    "po_det_awb_bl_no"
                  );
                  setIsTableEmpty(false);
                }}
              /> */}
            </Form.Item>
            {index?.is_po_det_awb_bl_no ? (
              <>
                <small style={{ color: "red" }}>
                  Please Enter AWB/BL Number
                </small>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "JOB NO",
      dataIndex: "po_details",
      key: "po_details",
      align: "left",
      width: "10%",
      render: (data, index) => {
        console.log("index is 112:", index);
        return (
          <div className=" tborder">
            <Form.Item>
              <SelectBox
                allowClear
                showSearch
                optionFilterProp="children"
                className=" mb-2 input_bg"
                // value={index.po_det_job_id}
                // value={
                //   index.po_det_job_id ||
                //   (newJobNumber && newJobNumber.length > 0
                //     ? newJobNumber[0]?.job_id
                //     : undefined)
                // }
                value={
                  index.po_det_job_no ||
                  (newJobNumber?.length == 1
                    ? newJobNumber[0]?.job_id
                    : undefined)
                }
                onChange={(e) => {
                  console.log("servicess11123", e);

                  handleInputchange1(e, index.key, "po_det_job_id");
                  setIsTableEmpty(false);
                  setIsjob(false);
                  handleJOB(e);
                }}
                // disabled={true}
              >
                {newJobNumber &&
                  newJobNumber.length > 0 &&
                  newJobNumber.map((item, index) => {
                    return (
                      <Select.Option key={item.job_id} value={item.job_id}>
                        {item.job_number}
                      </Select.Option>
                    );
                  })}
              </SelectBox>
            </Form.Item>
            {/* {index?.is_po_det_job_id ? (
              <>
                <small style={{ color: "red" }}>Please Select Job</small>
              </>
            ) : (
              ""
            )} */}
          </div>
        );
      },
    },
    {
      title: "AMOUNT",
      dataIndex: "po_det_amount",
      key: "po_det_amount",
      align: "right",
      width: "15%",
      render: (data, index) => {
        console.log("index is :", index);
        return (
          <div className=" tborder ">
            <Form.Item>
              <Input_Number
                value={index?.po_det_amount}
                className="text_right input_bg "
                onChange={(e) => {
                  handleInputchange1(e, index.key, "po_det_amount");
                  setIsTableEmpty(false);
                  setIsAmount(false);
                }}
                align="right"
                // step={0.01}
                min={0}
                precision={Decimals}
                controlls={false}
                onKeyDown={(e) =>
                  handleEnter(e, index.key, index?.po_det_task_id)
                }
              />
            </Form.Item>
            {index?.is_po_det_amount ? (
              <>
                <small style={{ color: "red" }}>Please Enter Amount</small>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },

      align: "right",
    },
    ...(isTaxable === 1 ? [TaxGroup] : []),
    ...(isTaxable === 1 ? [TaxPerct] : []),
    ...(isTaxable === 1 ? [TaxAmount] : []),
    ...(isTaxable === 1 ? [TotalAmount] : []),
  ];

  console.log("total ", total);
  const GetAllLeadData = () => {
    PublicFetch.get(`${CRM_BASE_URL}/customer/Minimal`)
      .then((res) => {
        if (res?.data?.success) {
          console.log("All lead data", res?.data?.data);
          // setAllLeadList(res?.data?.data?.leads);
          setTotalcount(res?.data?.data?.totalCount);
          setCurrentcount(res?.data?.data?.currentCount);
          setAllLeadList(res?.data?.data);
        } else {
          console.log("FAILED T LOAD DATA");
        }
      })
      .catch((err) => {
        console.log("Errror while getting data", err);
      });
  };
  console.log("payment terms : ", allPaymentTerms);

  const getallPaymentTerms = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/paymentTerms`)
      .then((res) => {
        console.log("response", res);
        if (res.data.success) {
          console.log("successs", res.data.data);
          setAllPaymentTerms(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getallunits = async () => {
    try {
      const allunits = await PublicFetch.get(`${CRM_BASE_URL_SELLING}/unit`);
      console.log("all units are ::", allunits?.data?.data);
      setAllunit(allunits?.data?.data);
      console.log("all units are : ", allunit);
    } catch (err) {
      console.log("error to getting all units", err);
    }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleLeadIdEnq = (leadIdenq) => {
    addForm.setFieldValue("consignee", leadIdenq);
    setLeadIdEnq(leadIdenq);
  };
  // const handleFirstDropdownChange = (event) => {
  //   setSelectedOption(event);
  // };
  const mode = (e) => {
    if (e) {
      frighttype &&
        frighttype.length > 0 &&
        frighttype.map((item, index) => {
          if (item.freight_type_id === e) {
            console.log("reached", item.freight_type_mode);
            setFrighttypemode(item.freight_type_mode);
            locationBytype(item.freight_type_mode);
          } else {
            locationBytype();
          }
        });
    }
  };

  const GetOpportunityData = () => {
    PublicFetch.get(`${CRM_BASE_URL}/opportunity/Minimal`)
      .then((res) => {
        if (res?.data?.success) {
          console.log("All opportunity dataqqq", res?.data?.data);

          let tempArr = [];
          res?.data?.data?.forEach((item, index) => {
            tempArr.push({
              opportunity_id: item?.opportunity_id,
              opportunity_number: item?.opportunity_number,
              opportunity_type: item?.opportunity_type,
              opportunity_party: item?.crm_v1_contacts?.contact_person_name,
              opportunity_from: item?.opportunity_from,
              lead_customer_name: item?.crm_v1_leads?.lead_customer_name,
              opportunity_created_at: item?.opportunity_created_at,
              opportunity_created_by: item?.opportunity_created_by,
              opportunity_source: item?.opportunity_source,
              opportunity_probability: item?.opportunity_probability,
              opportunity_description: item?.opportunity_description,
              opportunity_amount: item?.opportunity_amount,
              opportunity_status: item?.opportunity_status,
              lead_id: item?.crm_v1_leads?.lead_id,
              assigned_employee: item?.assigned_employee,
            });
            handleLeadIdEnq();
          });
          console.log("hellooooqqqqq", tempArr);
          setOppnew(tempArr);
          setOppleadid(res?.data?.data?.opportunity_lead_id);
          console.log("newwww", res?.data?.data?.opportunity_lead_id);
          setOpportunityList(res?.data?.data);
          setTotalcount(res?.data?.data?.totalCount);
          console.log("totalcount iss", res?.data?.data?.totalCount);
          // let samplearry = [];
          // res?.data?.data?.leads.forEach((item, index) => {
          //   samplearry.push(item.opportunity_id);
          // });
          // console.log("pushedd ", samplearry);

          // setOppurtunityid(samplearry);
        } else {
          console.log("Failed to load data !");
        }
      })
      .catch((err) => {
        console.log("Errror while getting data", err);
      });
  };

  useEffect(() => {
    GetOpportunityData();
  }, [pageofIndex, numOfItems]);

  const getAllLocations = async () => {
    try {
      const locations = await PublicFetch.get(`${CRM_BASE_URL_FMS}/locations`);
      console.log("all locations are", locations.data.data);
      // setAllLocations(locations.data.data);
      let temp = [];
      locations.data.data.forEach((item, index) => {
        temp.push({
          location_id: item.location_id,
          location_code: item.location_code,
          location_name: item.location_name,
          location_type: item.location_type,
          location_country: item.countries.country_name,
        });
        // setAllLocations(temp);
      });
    } catch (err) {
      console.log("error while getting the locations: ", err);
    }
  };

  const locationBytype = (data) => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/locations/type-location/${data}`)
      .then((res) => {
        console.log("response", res);
        if (res.data.success) {
          console.log("success of location type", res.data, data);
          setLocationType(res.data.data.location_type);
          let temp = [];
          res.data.data.forEach((item, index) => {
            temp.push({
              location_id: item.location_id,
              location_code: item.location_code,
              location_name: item.location_name,
              location_type: item.location_type,
              location_country: item.location_country,
            });
            setAllLocations(temp);
          });
        }
      })
      .catch((err) => {
        console.log("Error of location type", err);
      });
  };

  useEffect(() => {
    getallunits();
    quotationDate();
    getallvendors();
  }, []);

  const getallvendors = async () => {
    try {
      const allvendor = await PublicFetch.get(
        `${CRM_BASE_URL_PURCHASING}/vendors/minimal`
      );
      console.log("getting all vendorss", allvendor.data.data);
      settotalvendor(allvendor.data.data);
      setAllvendor(allvendor?.data?.data);

      // setvendortypes(allvendortypes.data.data);
    } catch (err) {
      console.log("error to fetching  vendor", err);
    }
  };
  const getAllservices = () => {
    PublicFetch.get(`${CRM_BASE_URL_SELLING}/service/Minimal`)
      .then((res) => {
        console.log("all services is ", res.data.data);
        if (res?.data?.success) {
          console.log("All services dataawww", res?.data?.data?.services);
          setServices(res.data.data);
          setAllservices(res?.data?.data);

          setTotalcount(res?.data?.data?.totalCount);
          // setCurrentcount(res?.data?.data?.currentCount);
        } else {
          console.log("FAILED T LOAD DATA");
        }
      })
      .catch((err) => {
        console.log("Errror while getting data", err);
      });
  };

  // Purchase order Number
  const GetPoNumber = () => {
    PublicFetch.get(`${CRM_BASE_URL_PURCHASING}/purchase-order/po_number`)
      .then((res) => {
        if (res.data.success) {
          console.log("Success", res?.data?.data);
          if (url[3] === "purchase_order") {
            addForm.setFieldsValue({
              pono: res?.data?.data?.po_number,
            });
          }
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  //Get single Purchase Order
  const GetPurchaseOrder = (id) => {
    PublicFetch.get(`${CRM_BASE_URL_PURCHASING}/purchase-order/${id}`)
      .then((res) => {
        if (res?.data?.success) {
          setTaskDetails(res?.data?.data?.crm_v1_puchase_order_details);
          let value = res?.data?.data;
          let purchase_date = moment(value?.po_date);
          let Due_date = moment(value?.po_due_date);
          let Remarks;
          if (
            value?.po_remarks !== "undefined" &&
            value?.po_remarks !== "null"
          ) {
            Remarks = value?.po_remarks;
          }
          addForm.setFieldsValue({
            purchasedate: purchase_date,
            duedate: Due_date,
            po_vendor: value?.po_vendor_id,
            billno: value?.po_bill_no,
            currency: value?.po_currency,
            exchnagerate: value?.po_exchange_rate,
            grandtotal: value?.po_total_amount,
            po_remarks: Remarks,
            pono: value?.po_no,
          });
          setJobExpence(value?.po_job_expense);
          for (let i = 0; i < value?.po_docs?.length; i++) {
            console.log("same array", res?.data?.data?.po_docs[i]);
            PublicFetch.get(
              `${process.env.REACT_APP_BASE_URL}/${res?.data?.data?.po_docs[i]}`
            ).then((resp) => {
              console.log("enqDocs", i);
              console.log("c--", resp);
              const blob = new Blob([resp.data], {
                type: resp.headers["content-type"],
              });

              console.log("b--", resp);
              console.log("enqDocs", i);

              setFiles((prev) => {
                return [
                  ...prev,
                  {
                    blob,
                    size: blob?.size,
                    uid: uuidv4(),
                    name: res?.data?.data?.po_docs[i]?.split("/")[
                      res?.data?.data?.po_docs[i]?.split("/").length - 1
                    ],

                    status: "done",
                    url: `${process.env.REACT_APP_BASE_URL}/${res?.data?.data?.po_docs[i]}`,
                  },
                ];
              });
            });
          }
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  // UseEffect To store data from Api to TableData
  useEffect(() => {
    if (url[3] === "edit_purchase_order") {
      let DetailsData = [];
      let job_ids = [];
      TaskDetails &&
        TaskDetails?.length > 0 &&
        TaskDetails?.map((item, index) => {
          console.log("njkrfnrjk", item);
          DetailsData.push({
            key: index + 1,
            po_det_amount: item?.po_det_amount,
            po_det_awb_bl_no: item?.po_det_awb_bl_no,
            po_det_job_id: item?.po_det_job_id,
            po_det_job_no: item?.fms_v1_jobs?.job_number,
            po_det_task_id: item?.po_det_task_id,
            po_det_tax_amount: item?.po_det_tax_amount,
            po_det_tax_id: item?.po_det_tax_id,
            po_det_tax_percentage: item?.po_det_tax_percentage,
            po_det_total_amount: item?.po_det_total_amount,
          });
        });
      console.log("rjnk", DetailsData);
      setTableData([...DetailsData]);
    }
  }, [TaskDetails, id]);

  useEffect(() => {
    setIsTableEmpty(
      !tableData?.every(
        (item) =>
          item?.po_det_task_id && item?.po_det_job_id && item?.po_det_tax_amount
      )
    );
  }, [tableData]);
  console.log("theTableData", tableData);
  // Submitting Value
  const OnSubmit = (data) => {
    let Temp = false;
    console.log("submitting data", data);
    const data11 = "noufal12343221";

    const date1 = moment(data.purchasedate).format("YYYY-MM-DD");
    const date2 = moment(data.duedate).format("YYYY-MM-DD");
    const docfile = data?.new?.file?.originFileObj;
    const formData = new FormData();
    // formData.append("quotation_no", qno);
    formData.append("po_vendor_id", data.po_vendor ? data.po_vendor : null);
    formData.append("po_date", date1 ? date1 : null);
    formData.append("po_due_date", date2 ? date2 : null);
    formData.append("po_bill_no", data.billno ? data.billno : null);
    formData.append("po_job_expense", JobExpence ? JobExpence : null);
    formData.append("po_currency", data.currency ? data.currency : null);
    formData.append(
      "po_exchange_rate",
      data.exchnagerate ? data.exchnagerate : null
    );
    formData.append(
      "po_total_amount",
      data.grandtotal ? data.grandtotal : null
    );
    formData.append("po_remarks", data?.po_remarks ? data?.po_remarks : null);
    console.log("data in ", formData);
    // isFileSizeExceeds === true &&
    Files &&
      Files?.map((item, index) => {
        formData.append("attachments", item.blob, item?.name);
      });

    if (
      tableData[0]?.po_det_task_id &&
      tableData[0]?.po_det_job_id
      // &&tableData[0]?.po_det_tax_amount
    ) {
      Temp = true;
    } else {
      // setIsTableEmpty(true);
    }
    console.log("data is tabledata ", tableData);
    tableData.map((item, index) => {
      console.log("xdnbkrtjoi", item);
      // if (!item?.po_det_task_id) {
      //   setIsService(true);
      // }
      // if (!item?.po_det_job_id) {
      //   setIsjob(true);
      // }
      // if (!item?.po_det_amount) {
      //   setIsAmount(true);
      // }
      console.log("userdata task", item);
      if (item.po_det_task_id) {
        item.po_det_task_id &&
          formData.append(
            `po_details[${index}][po_det_task_id]`,
            item?.po_det_task_id
          );
        item.po_det_awb_bl_no &&
          formData.append(
            `po_details[${index}][po_det_awb_bl_no]`,
            item?.po_det_awb_bl_no
          );
        item.po_det_job_id &&
          formData.append(
            `po_details[${index}][po_det_job_id]`,
            item?.po_det_job_id
          );
        item.po_det_tax_id &&
          formData.append(
            `po_details[${index}][po_det_tax_id]`,
            item?.po_det_tax_id
          );
        item.po_det_tax_percentage &&
          formData.append(
            `po_details[${index}][po_det_tax_percentage]`,
            item?.po_det_tax_percentage
          );
        item.po_det_tax_amount &&
          formData.append(
            `po_details[${index}][po_det_tax_amount]`,
            item?.po_det_tax_amount
          );
        item.po_det_total_amount &&
          formData.append(
            `po_details[${index}][po_det_total_amount]`,
            item?.po_det_total_amount
          );
        item.po_det_amount &&
          formData.append(
            `po_details[${index}][po_det_amount]`,
            item?.po_det_amount
          );
      }
    });
    console.log("before sending data", formData);
    if (url[3] === "purchase_order") {
      CreateApiCall(Temp, formData);
    } else if (
      url[3] === "edit_purchase_order"
      // && !isTableEmpty
    ) {
      UpdateApiCall(Temp, formData);
    }
  };
  // Create Purchase Order Api Call
  const CreateApiCall = (Temp, formData) => {
    console.log("allParams", Temp, isJob, isService, isAmount);
    if (
      Temp === true &&
      isJob === false &&
      isService === false &&
      isAmount === false
    ) {
      // setIsTableEmpty(false);
      PublicFetch.post(`${CRM_BASE_URL_PURCHASING}/purchase-order`, formData, {
        "Content-Type": "Multipart/form-Data",
      })
        .then((res) => {
          console.log("data is successfully saved", res.data.success);
          if (res.data.success) {
            setSuccessPopup(true);
            addForm.resetFields();
            close_modal(successPopup, 1000);
          } else {
            setErrormsg(res.data.data);
          }
        })
        .catch((err) => {
          console.log("error", err);
          setError(true);
        });
    } else if (Temp === false) {
      // setIsTableEmpty(true);
    }
  };

  // Update Purchase Order Api Call
  const UpdateApiCall = (Temp, formData) => {
    console.log("allParamsUp", Temp, isJob, isService, isAmount);
    if (
      Temp === true
      // && isJob === false
      // &&  isService === false &&
      // isAmount === false
    ) {
      // setIsTableEmpty(false);
      PublicFetch.patch(
        `${CRM_BASE_URL_PURCHASING}/purchase-order/${id}`,
        formData,
        {
          "Content-Type": "Multipart/form-Data",
        }
      )
        .then((res) => {
          console.log("data is successfully saved", res.data.success);
          if (res.data.success) {
            setSuccessPopup(true);
            // addForm.resetFields();
            close_modal(successPopup, 1000);
          } else {
            setErrormsg(res.data.data);
          }
        })
        .catch((err) => {
          console.log("error", err);
          setError(true);
        });
    } else if (Temp === false) {
      // setIsTableEmpty(true);
    }
  };

  const quotationDate = () => {
    let date = new Date();
    let date1 = moment(date);
    addForm.setFieldsValue({ qdate: date1 });
  };

  const beforeUpload = (file, fileList) => {
    const fileSizeInMB = file.size / 1024 / 1024; // Convert file size to MB
    const maxSizeInMB = 2; // Maximum allowed file size in MB

    if (fileSizeInMB > maxSizeInMB) {
      message.error(`File size should not exceed ${maxSizeInMB}MB`);
      setIsFileSizeExceeds(false);
      setIsFileSizeError(true);
      return false; // Prevent file upload
    } else {
      setIsFileSizeExceeds(true);
      setIsFileSizeError(false);
    }

    return true; // Proceed with file upload
  };

  // useEffect
  useEffect(() => {
    getAllservices();
    GetPoNumber();
    let today = new Date();
    let date = moment(today);
    addForm.setFieldsValue({
      purchasedate: date,
      duedate: date,
    });
    if (url[3] === "edit_purchase_order" && id) {
      GetPurchaseOrder(id);
    }
  }, [id]);

  //logs
  console.log("file uploading in required size", isFileSizeExceeds);
  console.log("TableData", tableData[0]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-md-center mb-2">
          <Form
            name="addForm"
            form={addForm}
            onFinish={(value) => {
              console.log("finalValue", value);

              OnSubmit(value);
            }}
            onFinishFailed={(error) => {
              console.log(error);
            }}
          >
            <div className="container-fluid ms-0 me-2 ">
              <div className="row  mt-3">
                {url[3] === "purchase_order" ? (
                  <>
                    <h5 className="lead_text"> New Purchase Order</h5>
                  </>
                ) : url[3] === "edit_purchase_order" ? (
                  <>
                    <h5 className="lead_text"> Edit Purchase Order</h5>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="row mt-1">
                <div className="content-tabs-new row justify-content mx-1 mb-3 py-2">
                  <div className="row mt-3 ">
                    <h6 className="lead_text">Basic Info</h6>
                  </div>
                  {/* <div className="row mb-2  "> */}
                  {/* <div className="col-md-6 col-12"> */}
                  {/* <div className="row"> */}

                  <div className="col-xl-4 col-sm-12 mt-2 px-3">
                    <label className="mb-1">
                      Vendor<span className="required">*</span>
                    </label>
                    <Form.Item
                      name="po_vendor"
                      rules={[
                        {
                          required: true,
                          message: "Please select Vendor",
                        },
                      ]}
                    >
                      <SelectBox
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => {
                          console.log("ann", e);
                        }}
                      >
                        {allvendor &&
                          allvendor.length > 0 &&
                          allvendor.map((item, index) => {
                            const optionClassName =
                              item?.vendor_status !== 1 ? "active-option" : "";

                            return (
                              <Select.Option
                                value={item?.vendor_id}
                                key={item?.vendor_id}
                                className={optionClassName}
                                disabled={optionClassName}
                              >
                                {item?.vendor_name}
                              </Select.Option>
                            );
                          })}
                      </SelectBox>
                    </Form.Item>
                  </div>
                  <div className="col-xl-4 col-sm-12 mt-2 px-3">
                    <label className="mb-1">
                      PO No<span className="required">*</span>
                    </label>
                    <Form.Item
                      name="pono"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <InputType />
                    </Form.Item>
                  </div>

                  <div className="col-xl-4 col-sm-12 ">
                    <label className=" mt-2">
                      Purchase Date<span className="required">*</span>
                    </label>
                    <Form.Item
                      rules={[
                        { required: true, message: "Please Select a Date" },
                      ]}
                      name="purchasedate"
                    >
                      <DatePicker
                        style={{ borderWidth: 0, marginTop: 5 }}
                        defaultValue={moment(date)}
                        format={dateFormatList}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-xl-4 col-sm-12 mt-2 px-3 ">
                    <label>Due date</label>

                    <Form.Item
                      name="duedate"
                      rules={[
                        {
                          required: true,
                          message: "Please select due date",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ borderWidth: 0, marginTop: 3 }}
                        defaultValue={moment(date)}
                        format={dateFormatList}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-xl-4 col-sm-12 mt-2 px-3">
                    <label className="mb-1">
                      Bill No <span className="required">*</span>
                    </label>
                    <Form.Item
                      name="billno"
                      rules={[
                        {
                          required: true,

                          message: "Please Enter Bill no",
                        },
                      ]}
                    >
                      <InputType />
                    </Form.Item>
                  </div>

                  <div className="col-xl-4 col-sm-12 mt-2 px-3">
                    <label className="mt-1">Job expense </label>
                    <Form.Item
                      name="jobexpense"
                      // rules={[
                      //   {
                      //     required: true,

                      //     message: "Please select job expense",
                      //   },
                      // ]}
                    >
                      <Checkbox
                        value={JobExpence}
                        checked={JobExpence == 1 ? true : false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setJobExpence(1);
                          } else {
                            setJobExpence(0);
                          }
                        }}
                        className="ms-2 mt-2"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-xl-4 col-sm-12 mt-2 px-3">
                    <label className="mb-2">
                      Currency<span className="required">*</span>
                    </label>
                    <Form.Item
                      name="currency"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp("^[A-Za-z0-9 ]+$"),
                          message: "Please select currency",
                        },
                      ]}
                    >
                      <SelectBox
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => {
                          console.log("ann", e);
                          getCurrencyRate(e, currencyDefault, currencydata);
                        }}
                      >
                        {currencydata &&
                          currencydata.length > 0 &&
                          currencydata.map((item, index) => {
                            const optionClassName =
                              item?.currency_status !== 1
                                ? "active-option"
                                : "";

                            return (
                              <Select.Option
                                value={item.currency_id}
                                key={item.currency_id}
                                className={optionClassName}
                                disabled={optionClassName}
                              >
                                {item.currency_name}
                              </Select.Option>
                            );
                          })}
                      </SelectBox>
                    </Form.Item>
                  </div>

                  <div className="col-xl-4 col-sm-12 mt-2 px-3">
                    <label className="mb-2">
                      Exchange Rate<span className="required">*</span>
                    </label>
                    <Form.Item
                      name="exchnagerate"
                      rules={[
                        {
                          required: true,

                          message: "Please enter a Valid Rate",
                        },
                      ]}
                    >
                      <Input_Number
                        className="text_right"
                        value={currencyRates}
                        // onChange={handleChange}
                        align="right"
                        // step={0.01}
                        min={0}
                        precision={7}
                        controlls={false}
                        // disabled={true}
                      />
                    </Form.Item>
                  </div>

                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
              <div className="row mt-1  ">
                <div className="content-tabs-tablenew row justify-content mx-1 mb-3 ">
                  <div className="row mt-2">
                    <div className="col-9">
                      {" "}
                      <h6 className="lead_text">AWB/BL Details</h6>
                    </div>
                    <div className="col-3 d-flex justify-content-end">
                      {" "}
                      {/* <Button
                        btnType="add_borderless"
                        type="default"
                        // onClick={(e) => {
                        //   handleEnter(e, "key", "id", " button");
                        // }}
                      >
                        Add New Row
                      </Button> */}
                    </div>
                  </div>

                  <div className="datatable">
                    <TableData
                      data={tableData}
                      columns={columns}
                      rowKey={(record) => record.key}
                      custom_table_css="table_qtn qtn_table_brdr"
                    />
                    {isTableEmpty
                      ? ""
                      : // <>
                        //   <small style={{ color: "red" }}>
                        //     {/* Please Fill Atleast One Row */}
                        //     Please fill the above row
                        //   </small>
                        // </>
                        ""}
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-end align-items-center mt-3 ms-5">
                      <div className="col-xl-11 col-lg-2 col-sm-8 col-xs-3 d-flex justify-content-end  mt-3">
                        <p style={{ fontWeight: 600 }}>Total :</p>
                      </div>

                      <div className="col-xl-1 col-lg-2 col-sm-2 col-xs-2 me-5 mt-1">
                        <Form.Item name="grandtotal">
                          <Input_Number
                            className="text_right grandtotal"
                            value={total}
                            align="right"
                            min={0}
                            precision={Decimals}
                            controlls={false}
                            disabled={true}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  mt-1 ">
                {/* <div className="col-md-6 col-12"> */}
                <div className="content-tabs-new row justify-content mx-1 mb-3 py-3">
                  <div className="row mt-3">
                    <h6 className="lead_text">Attachments</h6>
                  </div>
                  <div className="col-sm-6 pt-2">
                    <label className="mb-1">Remarks</label>
                    <Form.Item name="po_remarks">
                      <TextArea />
                    </Form.Item>
                  </div>

                  <div className="col-sm-6 pt-2">
                    <label className="mb-1">Attachments</label>
                    <Form.Item name="attachments" className="">
                      <FileUpload
                        name="attachments"
                        //   value={attachments}
                        multiple
                        listType="file"
                        accept=".pdf,.doc,.zip"
                        height={100}
                        fileList={Files}
                        // onPreview={handlePreview}
                        beforeUpload={beforeUpload}
                        onChange={(file) => {
                          console.log("Before upload", file);
                          console.log(
                            "Before upload file size",
                            file.file.size
                          );

                          if (
                            file.file.size > 1000 &&
                            file.file.size < 500000
                          ) {
                            const newFileList = file?.fileList?.map((item) => {
                              if (!item?.blob) {
                                const blob = new Blob([item?.originFileObj], {
                                  type: item?.originFileObj?.type,
                                });
                                const url = URL.createObjectURL(blob);
                                return { ...item, blob, url };
                              } else {
                                return item;
                              }
                            });
                            console.log("newFileList", newFileList);
                            setFiles(newFileList);
                            setImg(file.fileList);
                            setImgSizeError(false);
                            console.log(
                              "Image must be greater than 1 kb and less than 500 kb"
                            );
                          } else {
                            console.log("failed beacuse of large size");
                            setImgSizeError(true);
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                {/* </div> */}

                {/* </div> */}
              </div>
            </div>
            {/* <div className="row justify-content-center">
             
              </div> */}

            {/* <div className="container mb-4 ml-1"  > */}

            {/* </div> */}

            <div className="col-12 d-flex justify-content-center my-4 gap-3">
              {/* <div className="col-lg-1 "> */}
              <Button className="save_button" btnType="save">
                Save
              </Button>
              {/* </div> */}
              {/* <div className="col-lg-1 "> */}
              <Button
                as="input"
                type="reset"
                value="Reset"
                onClick={() => {
                  navigate(`${ROUTES.PUCHASE_ORDER_LIST}`);
                }}
                // className="qtn_save"
                // btnType="save"
              >
                Cancel
              </Button>
              {/* </div> */}
            </div>
          </Form>

          <Custom_model
            size={"sm"}
            show={successPopup}
            onHide={() => setSuccessPopup(false)}
            success
          />
        </div>
      </div>
    </>
  );
}
