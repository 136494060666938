import { CRM_BASE_URL } from "../api/bootapi";
import PublicFetch from "../utils/PublicFetch";
import { useState, useEffect } from "react";
import VersionSettings from "../version_settings.config.json";
import TestCore from "../components/testComponets/TestCore/TestCore";
import TestV1 from "../components/testComponets/TestV1/TestV1";
import ArrivalNoticePdf from "../components/Arrival Notice/arrivalnotice-Pdf/ArrivalNoticePdf";
import { PDFViewer } from "@react-pdf/renderer";
import IconPdf from "../components/IconPfd/IconPdf";
import RFQPdf from "../components/rfq-pdf/rfq-pdf";
import ShippmentStatusPdf from "../components/Job shippment Status-Pdf/ShippmentStatuspdf";

function TestPage() {
  console.log("Crm base url", `${CRM_BASE_URL}/lead?startIndex=0&noOfItems=10`);
  const { version, version_name } = VersionSettings.crm;
  const getAllLeads = async () => {
    try {
      const allLeadsRes = await PublicFetch.get(
        `${CRM_BASE_URL}/lead?startIndex=0&noOfItems=10`
      );
      console.log("All leads res : ", allLeadsRes);
    } catch (err) {
      console.log("error while getting all leads: ", err);
    }
  };

  const inputOneChange = (e) => {
    console.log(e.target.value);
  };

  // useEffect(() => {
  //   getAllLeads();
  // }, []);

  let refData = [
    {
      key: 1,
      name: "noufal",
      from: "Reon",
    },
  ];

  let testingData = [
    1,
    "Anoop",
    "11-09-2023",
    "987562421134",
    "Lorem ispum",

    "Lorem ispum ",
  ];
  const testingHead = [
    "slno",
    "Name",
    "Date",
    "Phone",
    "Description",
    "Remarks",
  ];

  return (
    <div>
      TestPage {version} - {version_name}
      <div>
        {/* <TestCore inputOneChange={inputOneChange}>
          {VersionSettings.crm.version_name === "v1" ? <TestV1></TestV1> : null}
        </TestCore> */}
      </div>
      <PDFViewer width={"100%"} height={"1000vh"}>
        {/* <RFQPdf rfq={testingData}  /> */}
        <ShippmentStatusPdf bookingShipmentHistoryData={refData} />
      </PDFViewer>
      {/* <ArrivalNoticePdf invoiceData={refData} /> */}
    </div>
  );
}

export default TestPage;

{
  /* <IconPdf
data={testingData}
tableHead={testingHead}
Name={"Report Testing"}
/> */
}
// {
//   import { EditOutlined } from "@ant-design/icons";
// import { Checkbox, Form, Select } from "antd";
// import React, { useEffect, useState } from "react";
// import Custom_model from "../../../components/custom_modal/custom_model";
// import InputType from "../../../components/Input Type textbox/InputType";
// import TableData from "../../../components/table/table_data";
// import PublicFetch from "../../../utils/PublicFetch";
// import CheckUnique from "../../../check Unique/CheckUnique";

// import { UniqueErrorMsg } from "../../../ErrorMessages/UniqueErrorMessage";
// import Button from "../../../components/button/button";
// import SelectBox from "../../../components/Select Box/SelectBox";
// import "./permission.scss";

// function Permissions() {
//   const [permissions, setPermissions] = useState(null);
//   const [userPermissions, setUserPermissions] = useState(null);
//   const [roles, setRole] = useState(null);
//   const [selectedRoleId, setSelectedRoleId] = useState(null);
//   const [saveSuccess, setSaveSuccess] = useState(false);
//   const fetchPermissions = () => {
//     PublicFetch.get(
//       `${process.env.REACT_APP_BASE_URL}/permissions/getAllPermissions`
//     )
//       .then((res) => {
//         if (res.data.success) {
//           setPermissions(res.data.data);
//           mapCheckBoxState(res.data.data);
//         }
//       })
//       .catch((err) => {});
//   };
//   const fetchPermissionWithRoleID = (roleId) => {
//     setSelectedRoleId(roleId);
//     PublicFetch.get(
//       `${process.env.REACT_APP_BASE_URL}/permissions/getUserPermissions/${roleId}`
//     )
//       .then((res) => {
//         if (res.data.success) {
//           setUserPermissions(res.data.data);
//           compareAndMapPermissions(res.data.data);
//         }
//       })
//       .catch((err) => {});
//   };
//   const fetchRoles = () => {
//     PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/permissions/roles`)
//       .then((res) => {
//         if (res.data.success) {
//           setRole(res.data.data);
//         }
//       })
//       .catch((err) => {
//         console.log("Error", err);
//       });
//   };

//   const postPermissionData = (permissionDto) => {
//     console.log("post 1", permissionDto);
//     PublicFetch.post(
//       `${process.env.REACT_APP_BASE_URL}/permissions`,
//       permissionDto
//     )
//       .then((res) => {
//         if (res.data.success) {
//           setSaveSuccess(true);
//           setTimeout(() => {
//             setSaveSuccess(false);
//           }, 1200);
//         }
//       })
//       .catch((err) => {
//         console.log("Error", err);
//       });
//   };
//   const mapCheckBoxState = (permissionsData) => {
//     let tmpPermissionData = permissionsData;
//     tmpPermissionData.forEach((mainPermissions) => {
//       mainPermissions["sub_objects"].forEach((subPermission) => {
//         subPermission["fullAccess"] = false;
//         subPermission["create"] = false;
//         subPermission["update"] = false;
//         subPermission["read"] = false;
//         subPermission["delete"] = false;
//       });
//     });
//     setPermissions([...tmpPermissionData]);
//   };
//   const changeCheckBoxState = (subObjectId, state, action) => {
//     let tmpPermissions = permissions;
//     let rowFullPermTmpName = null;

//     tmpPermissions.forEach((permissionsItem) => {
//       permissionsItem["sub_objects"].forEach((subPermissions) => {
//         if (subPermissions["sub_object_id"] === subObjectId) {
//           rowFullPermTmpName = permissionsItem;
//           if (action === "fullAccess") {
//             subPermissions[action] = state;
//             subPermissions["create"] = state;
//             subPermissions["update"] = state;
//             subPermissions["read"] = state;
//             subPermissions["delete"] = state;
//           } else if (!state) {
//             subPermissions["fullAccess"] = state;
//             subPermissions[action] = state;
//           } else {
//             subPermissions[action] = state;
//           }
//           if (checkFullAccessToBeChecked(subPermissions))
//             subPermissions["fullAccess"] = true;
//         }
//       });
//     });
//     if (checkFullAccessRowWiseToBeChecked(rowFullPermTmpName, action, state)) {
//       tmpPermissions.forEach((permissionTmpItem) => {
//         if (permissionTmpItem["id"] === rowFullPermTmpName["id"]) {
//           permissionTmpItem[action] = true;
//         }
//       });
//     } else {
//       tmpPermissions.forEach((permissionTmpItem) => {
//         if (permissionTmpItem["id"] === rowFullPermTmpName["id"]) {
//           permissionTmpItem[action] = false;
//         }
//       });
//     }
//     setPermissions([...tmpPermissions]);
//     checkAndUpdateForColumnWiseFullAccess(tmpPermissions);
//   };

//   const checkFullAccessToBeChecked = (subPermission) => {
//     return !!(
//       subPermission["create"] &&
//       subPermission["update"] &&
//       subPermission["read"] &&
//       subPermission["delete"]
//     );
//   };

//   const checkFullAccessRowWiseToBeChecked = (permissionItem, action, state) => {
//     if (state) {
//       let tmp = true;
//       permissionItem["sub_objects"].forEach((subPermission) => {
//         if (!subPermission[action]) tmp = false;
//       });
//       return tmp;
//     } else {
//       return false;
//     }
//   };

//   const compareAndMapPermissions = (userPermissions) => {
//     let tmpPermissions = permissions;
//     userPermissions["subPermissions"].forEach((item) => {
//       tmpPermissions.forEach((subItem) => {
//         subItem["read"] = false;
//         subItem["create"] = false;
//         subItem["edit"] = false;
//         subItem["delete"] = false;
//         subItem["fullAccess"] = false;
//         subItem["sub_objects"]?.length > 0 &&
//           subItem["sub_objects"].forEach((childItem) => {
//             if (childItem["sub_object_name"] === item.subject) {
//               item.action.includes("create")
//                 ? (childItem["create"] = true)
//                 : (childItem["create"] = false);
//               item.action.includes("update")
//                 ? (childItem["update"] = true)
//                 : (childItem["update"] = false);
//               item.action.includes("read")
//                 ? (childItem["read"] = true)
//                 : (childItem["read"] = false);
//               item.action.includes("delete")
//                 ? (childItem["delete"] = true)
//                 : (childItem["delete"] = false);
//               // if (
//               //   item.action.includes("create") &&
//               //   item.action.includes("update") &&
//               //   item.action.includes("read") &&
//               //   item.action.includes("delete")
//               // )
//               //   childItem["fullAccess"] = true;
//             }
//           });
//       });
//     });
//     checkAndUpdateForColumnWiseFullAccess(tmpPermissions);
//     // setPermissions([...tmpPermissions]);
//   };

//   const checkAndUpdateForRowWiseFullAccess = (tmpPermissions) => {
//     // setPermissions([...tmpPermissions]);
//     tmpPermissions.forEach((item) => {
//       item.read = checkForRowWiseFullPermission(item["sub_objects"], "read");
//       item.update = checkForRowWiseFullPermission(
//         item["sub_objects"],
//         "update"
//       );
//       item.create = checkForRowWiseFullPermission(
//         item["sub_objects"],
//         "create"
//       );
//       item.delete = checkForRowWiseFullPermission(
//         item["sub_objects"],
//         "delete"
//       );
//       item.fullAccess = checkForRowWiseFullPermission(
//         item["sub_objects"],
//         "fullAccess"
//       );
//     });
//     setPermissions([...tmpPermissions]);
//   };
//   const checkAndUpdateForColumnWiseFullAccess = (tmpPermissions) => {
//     tmpPermissions?.forEach((mainPermission) => {
//       mainPermission["sub_objects"].forEach((subPermission) => {
//         if (
//           subPermission.create &&
//           subPermission.update &&
//           subPermission.read &&
//           subPermission.delete
//         ) {
//           subPermission.fullAccess = true;
//         } else {
//           subPermission.fullAccess = false;
//         }
//       });
//     });
//     checkAndUpdateForRowWiseFullAccess(tmpPermissions);
//   };

//   const handleWholePermission = (state, permission) => {
//     let tmpPermissions = permissions;
//     tmpPermissions.forEach((mainPermission) => {
//       if (mainPermission.id === permission.id) {
//         mainPermission.fullAccess = state;
//         mainPermission.read = state;
//         mainPermission.create = state;
//         mainPermission.update = state;
//         mainPermission.delete = state;
//         mainPermission["sub_objects"].forEach((item) => {
//           item.create = state;
//           item.read = state;
//           item.update = state;
//           item.delete = state;
//           item.fullAccess = state;
//         });
//       }
//     });
//     setPermissions([...tmpPermissions]);
//   };

//   const checkForRowWiseFullPermission = (subPermissions, action) => {
//     let tmp = true;
//     subPermissions.forEach((item) => {
//       if (!item[action]) tmp = false;
//     });
//     return tmp;
//   };

//   const handleSavePermission = () => {
//     let permissionPostObject = {
//       roleId: selectedRoleId,
//       permissions: [{ objectId: 1, isWildCard: true }], //Hardcoded dashboard permission.
//     };
//     let tmpArr = [];
//     permissions.forEach((permissions) => {
//       permissions["sub_objects"] &&
//         permissions["sub_objects"].length > 0 &&
//         permissions["sub_objects"].forEach((subPermission) => {
//           let tmpSubPerm = [];
//           if (subPermission["create"]) tmpSubPerm.push("create");
//           if (subPermission["update"]) tmpSubPerm.push("update");
//           if (subPermission["read"]) tmpSubPerm.push("read");
//           if (subPermission["delete"]) tmpSubPerm.push("delete");
//           if (tmpSubPerm.length > 0) {
//             tmpArr.push({
//               objectId: subPermission["sub_object_id"],
//               isWildCard: false,
//               action: tmpSubPerm,
//             });
//           }
//         });
//     });
//     permissionPostObject.permissions = [
//       ...permissionPostObject.permissions,
//       ...tmpArr,
//     ];
//     postPermissionData(permissionPostObject);
//   };

//   const changeStateAllPermissionRowWise = (state, action, permissionName) => {
//     let tmpPermissions = permissions;
//     tmpPermissions.forEach((permission) => {
//       if (permission.name === permissionName) {
//         permission[action] = state;
//         permission["sub_objects"].forEach((subPermission) => {
//           subPermission[action] = state;
//         });
//       }
//     });
//     checkAndUpdateForColumnWiseFullAccess(tmpPermissions);
//   };

//   useEffect(() => {
//     fetchPermissions();
//     fetchRoles();
//   }, []);

//   return (
//     <div className="">
//       <div
//         // style={{ paddingTop: "10%" }}
//         className="container-fluid container_hrms permission_content "
//       >
//         <Custom_model
//           size={"sm"}
//           show={saveSuccess}
//           onHide={() => setSaveSuccess(false)}
//           success
//         />

//         <div className={"row justify-content-start mt-0 pt-0 "}>
//           <div style={{ height: "100%" }} className={"col-12 mt-0 pt-0"}>
//             <div
//               style={{
//                 position: "fixed",
//                 zIndex: 100,
//                 backgroundColor: "white",
//               }}
//               className="row w-100 permission_header  pt-3"
//             >
//               <div className="col-12">
//                 <h4 className="lead_text">Assign Permission</h4>
//               </div>
//               <div className="col-10">
//                 {/* <div className={"row"}>
//                   <div className="col-12"> */}
//                 {/* <div className="row  ">
//                   <div className="col-12"> */}
//                 <div className={"row p-3  m-3  shadow-sm"}>
//                   <div
//                     // style={{ width: "470px" }}
//                     className={"col-4   pt-3 pb-2 start  fs-6 "}
//                   >
//                     <div style={{ width: "300px" }} className="">
//                       <label className="lead_text">Roles</label>
//                       <SelectBox
//                         showSearch
//                         placeholder="Select a Role"
//                         optionFilterProp="children"
//                         onChange={(e) => fetchPermissionWithRoleID(e)}
//                         // style={{ width: "100px" }}
//                         filterOption={(input, option) =>
//                           (option?.label ?? "")
//                             .toLowerCase()
//                             .includes(input.toLowerCase())
//                         }
//                         // options={roles?.map((item) => {
//                         //   return { value: item.id, label: item.name };
//                         // })}
//                       >
//                         {roles?.map((item) => {
//                           return (
//                             <Select.Option key={item.id} value={item.id}>
//                               {item.name}
//                             </Select.Option>
//                           );
//                         })}
//                       </SelectBox>
//                     </div>
//                   </div>

//                   {/* <div className="col-5  ">
//                           <div className="row justify-content-center"> */}
//                   {/* <div className="col-2"></div> */}
//                   <div
//                     className={
//                       "col-1 pt-3 pb-2 ms-0 border text-center fw-bold fs-8"
//                     }
//                   >
//                     <div className={"row"}>
//                       <div className={"col-12 "}>Full Access</div>{" "}
//                     </div>
//                     <div className={"row py-2"}>
//                       <div className={"col-12"}>
//                         {" "}
//                         {/* <Checkbox
//                           checked={permission["fullAccess"]}
//                           onChange={(e) => {
//                             handleWholePermission(e.target.checked, permission);
//                           }}
//                         ></Checkbox> */}
//                       </div>{" "}
//                     </div>
//                   </div>
//                   <div
//                     className={
//                       "col-1 pt-3 pb-2 border text-center fw-bold fs-8 "
//                     }
//                   >
//                     <div className={"row"}>
//                       <div className={"col-12 "}>View</div>{" "}
//                     </div>
//                     <div className={"row py-2"}>
//                       <div className={"col-12"}>
//                         {" "}
//                         {/* <Checkbox
//                           checked={permission["read"]}
//                           onChange={(e) => {
//                             changeStateAllPermissionRowWise(
//                               e.target.checked,
//                               "read",
//                               permission.name
//                             );
//                           }}
//                         ></Checkbox> */}
//                       </div>{" "}
//                     </div>
//                   </div>
//                   <div
//                     className={
//                       "col-1 pt-3 pb-2 border text-center fw-bold fs-8 "
//                     }
//                   >
//                     <div className={"row"}>
//                       <div className={"col-12 "}>Create</div>{" "}
//                     </div>
//                     <div className={"row py-2"}>
//                       <div className={"col-12"}>
//                         {" "}
//                         {/* <Checkbox
//                           checked={permission["create"]}
//                           onChange={(e) => {
//                             changeStateAllPermissionRowWise(
//                               e.target.checked,
//                               "create",
//                               permission.name
//                             );
//                           }}
//                         ></Checkbox> */}
//                       </div>{" "}
//                     </div>
//                   </div>
//                   <div
//                     className={
//                       "col-1 pt-3 pb-2 border text-center fw-bold fs-8 "
//                     }
//                   >
//                     <div className={"row"}>
//                       <div className={"col-12 "}>Edit</div>{" "}
//                     </div>
//                     <div className={"row py-2"}>
//                       <div className={"col-12"}>
//                         {" "}
//                         {/* <Checkbox
//                           checked={permission["update"]}
//                           onChange={(e) => {
//                             changeStateAllPermissionRowWise(
//                               e.target.checked,
//                               "update",
//                               permission.name
//                             );
//                           }}
//                         ></Checkbox> */}
//                       </div>{" "}
//                     </div>
//                   </div>
//                   <div
//                     className={
//                       "col-1 pt-3 pb-2 border text-center fw-bold fs-8 "
//                     }
//                   >
//                     <div className={"row"}>
//                       <div className={"col-12"}>Delete</div>{" "}
//                     </div>
//                     <div className={"row py-2"}>
//                       <div className={"col-12"}>
//                         {" "}
//                         {/* <Checkbox
//                           checked={permission["delete"]}
//                           onChange={(e) => {
//                             changeStateAllPermissionRowWise(
//                               e.target.checked,
//                               "delete",
//                               permission.name
//                             );
//                           }}
//                         ></Checkbox> */}
//                       </div>{" "}
//                     </div>
//                   </div>
//                   {/* </div>
//                         </div> */}
//                   <div
//                     className={
//                       "col-3 d-flex justify-content-center align-items-center"
//                     }
//                   >
//                     <Button btnType="save" onClick={handleSavePermission}>
//                       Save
//                     </Button>
//                   </div>
//                   {/* </div>
//                   </div> */}
//                 </div>
//                 {/* </div>
//                 </div> */}
//               </div>
//             </div>

//             {permissions?.map((permission) => {
//               return (
//                 <div
//                   style={{ position: "relative", top: "50%" }}
//                   className={"row"}
//                 >
//                   <div style={{ height: "100%" }} className={"col-11 "}>
//                     <div className={"row p-3 m-3  shadow-sm"}>
//                       <div className={"col-12 pb-2 "}>
//                         <div
//                           className={
//                             "row border  justify-content-start shadow-sm "
//                           }
//                         >
//                           <div
//                             className={
//                               "col-4 border pt-3 pb-2 start text-uppercase fw-bold fs-6 lead_text"
//                             }
//                           >
//                             {permission.name}
//                           </div>
//                           <div
//                             className={
//                               "col-1 pt-3 pb-2 border text-center fw-bold fs-8"
//                             }
//                           >
//                             <div className={"row"}>
//                               {/* <div className={"col-12 "}>Full Access</div>{" "} */}
//                             </div>
//                             <div className={"row py-2"}>
//                               <div className={"col-12"}>
//                                 {" "}
//                                 <Checkbox
//                                   checked={permission["fullAccess"]}
//                                   onChange={(e) => {
//                                     handleWholePermission(
//                                       e.target.checked,
//                                       permission
//                                     );
//                                   }}
//                                 ></Checkbox>
//                               </div>{" "}
//                             </div>
//                           </div>
//                           <div
//                             className={
//                               "col-1 pt-3 pb-2 border text-center fw-bold fs-8 "
//                             }
//                           >
//                             <div className={"row"}>
//                               {/* <div className={"col-12 "}>View</div>{" "} */}
//                             </div>
//                             <div className={"row py-2"}>
//                               <div className={"col-12"}>
//                                 {" "}
//                                 <Checkbox
//                                   checked={permission["read"]}
//                                   onChange={(e) => {
//                                     changeStateAllPermissionRowWise(
//                                       e.target.checked,
//                                       "read",
//                                       permission.name
//                                     );
//                                   }}
//                                 ></Checkbox>
//                               </div>{" "}
//                             </div>
//                           </div>
//                           <div
//                             className={
//                               "col-1 pt-3 pb-2 border text-center fw-bold fs-8 "
//                             }
//                           >
//                             <div className={"row"}>
//                               {/* <div className={"col-12 "}>Create</div>{" "} */}
//                             </div>
//                             <div className={"row py-2"}>
//                               <div className={"col-12"}>
//                                 {" "}
//                                 <Checkbox
//                                   checked={permission["create"]}
//                                   onChange={(e) => {
//                                     changeStateAllPermissionRowWise(
//                                       e.target.checked,
//                                       "create",
//                                       permission.name
//                                     );
//                                   }}
//                                 ></Checkbox>
//                               </div>{" "}
//                             </div>
//                           </div>
//                           <div
//                             className={
//                               "col-1 pt-3 pb-2 border text-center fw-bold fs-8 "
//                             }
//                           >
//                             <div className={"row"}>
//                               {/* <div className={"col-12 "}>Edit</div>{" "} */}
//                             </div>
//                             <div className={"row py-2"}>
//                               <div className={"col-12"}>
//                                 {" "}
//                                 <Checkbox
//                                   checked={permission["update"]}
//                                   onChange={(e) => {
//                                     changeStateAllPermissionRowWise(
//                                       e.target.checked,
//                                       "update",
//                                       permission.name
//                                     );
//                                   }}
//                                 ></Checkbox>
//                               </div>{" "}
//                             </div>
//                           </div>
//                           <div
//                             className={
//                               "col-1 pt-3 pb-2 border text-center fw-bold fs-8 "
//                             }
//                           >
//                             <div className={"row"}>
//                               {/* <div className={"col-12"}>Delete</div>{" "} */}
//                             </div>
//                             <div className={"row py-2"}>
//                               <div className={"col-12"}>
//                                 {" "}
//                                 <Checkbox
//                                   checked={permission["delete"]}
//                                   onChange={(e) => {
//                                     changeStateAllPermissionRowWise(
//                                       e.target.checked,
//                                       "delete",
//                                       permission.name
//                                     );
//                                   }}
//                                 ></Checkbox>
//                               </div>{" "}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div className={"col-12 border "}>
//                         {permission["sub_objects"].map((sub_permissions) => {
//                           return (
//                             <div>
//                               <div className={"row  justify-content-start"}>
//                                 <div
//                                   className={"col-4 border py-2 text-start "}
//                                 >
//                                   {sub_permissions["sub_object_view_name"]}
//                                 </div>
//                                 <div
//                                   className={"col-1 border py-2 text-center "}
//                                 >
//                                   <Checkbox
//                                     checked={sub_permissions["fullAccess"]}
//                                     value={sub_permissions["sub_object_id"]}
//                                     onChange={(e) => {
//                                       changeCheckBoxState(
//                                         sub_permissions["sub_object_id"],
//                                         e.target.checked,
//                                         "fullAccess"
//                                       );
//                                     }}
//                                   ></Checkbox>
//                                 </div>
//                                 <div
//                                   className={"col-1 border py-2 text-center "}
//                                 >
//                                   <Checkbox
//                                     checked={sub_permissions["read"]}
//                                     value={sub_permissions["sub_object_id"]}
//                                     onChange={(e) => {
//                                       changeCheckBoxState(
//                                         sub_permissions["sub_object_id"],
//                                         e.target.checked,
//                                         "read"
//                                       );
//                                     }}
//                                   ></Checkbox>
//                                 </div>
//                                 <div
//                                   className={"col-1 border py-2 text-center "}
//                                 >
//                                   <Checkbox
//                                     checked={sub_permissions["create"]}
//                                     value={sub_permissions["sub_object_id"]}
//                                     onChange={(e) => {
//                                       changeCheckBoxState(
//                                         sub_permissions["sub_object_id"],
//                                         e.target.checked,
//                                         "create"
//                                       );
//                                     }}
//                                   ></Checkbox>
//                                 </div>
//                                 <div
//                                   className={"col-1 border py-2 text-center "}
//                                 >
//                                   <Checkbox
//                                     checked={sub_permissions["update"]}
//                                     value={sub_permissions["sub_object_id"]}
//                                     onChange={(e) => {
//                                       changeCheckBoxState(
//                                         sub_permissions["sub_object_id"],
//                                         e.target.checked,
//                                         "update"
//                                       );
//                                     }}
//                                   ></Checkbox>
//                                 </div>
//                                 <div
//                                   className={"col-1 border py-2 text-center "}
//                                 >
//                                   <Checkbox
//                                     checked={sub_permissions["delete"]}
//                                     value={sub_permissions["sub_object_id"]}
//                                     onChange={(e) => {
//                                       changeCheckBoxState(
//                                         sub_permissions["sub_object_id"],
//                                         e.target.checked,
//                                         "delete"
//                                       );
//                                     }}
//                                   ></Checkbox>
//                                 </div>
//                               </div>
//                             </div>
//                           );
//                         })}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Permissions;

// }
