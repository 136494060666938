import { Suspense, useContext } from "react";
import { UserContext } from "../../context/user.context";
import { Navigate, Outlet } from "react-router-dom";
import ROUTES from "../../routes/index";
import CustomerLayout from "../../layouts/CustomerLayout";
import LoadingPage from "../../LoadingPage/LoadingPage";
const CustomerProtectedRoute = ({ roles }) => {
  const { isCustomerAuthenticated } = useContext(UserContext);
  console.log("inside proteced route");
  let authenticated = isCustomerAuthenticated();
  return authenticated ? (
    <CustomerLayout>
      <Suspense fallback={<LoadingPage />}>
        <Outlet />
      </Suspense>
    </CustomerLayout>
  ) : (
    <Navigate to={ROUTES.CUSTOMER_LOGIN} />
  );
};

export default CustomerProtectedRoute;
