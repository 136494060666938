import React from "react";
import Layout from "../layouts/layout";

function PageNotFound() {
  return (
    <Layout>
      <div style={{ width: "100vw", height: "100vh" }} className="container  ">
        <div className="row justify-content-center">
          <div className="col-12 ">
            <div className="container  mt-5">
              <h1>OOps Something went Wrong !!</h1>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PageNotFound;
