import { createContext, useContext, useState } from "react";

const StoreDataContext = createContext();

export const StoreDataProvider = ({ children }) => {
  const [StoreData, setStoreData] = useState();

  const setStoredDataProvider = (state) => {
    setStoreData(state);
  };

  return (
    <StoreDataContext.Provider value={{ StoreData, setStoredDataProvider }}>
      {children}
    </StoreDataContext.Provider>
  );
};

export const useStoreData = () => {
  return useContext(StoreDataContext);
};
