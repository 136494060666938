import { useEffect } from "react";
import TableData from "../table/table_data";
import { useLocation } from "react-router-dom";

function Printdata() {
    const {state} = useLocation()
console.log("router data", state);
  useEffect(() => {
    // window.print()
  }, []);

//   console.log("dnjdf",data)
  return (
    <>

      {/* <TableData data={data} columns={column} /> */}
    </>
  );
}
export default Printdata;
