import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ACCOUNTS,
  CRM_BASE_URL_FMS,
  GENERAL_SETTING_BASE_URL,
} from "../../../api/bootapi";
import InvoicePrint from "../../../components/Invoice/InvoicePrint";
import PublicFetch from "../../../utils/PublicFetch";
import moment from "moment";
import { camelize } from "../../../utils/camelcaseconvert";
import Invoicetemp1 from "../../../components/Invoice/invoicetemp1/invoicetemp1";
import Invoicetemp2 from "../../../components/Invoice/invoicetemp2/invoicetemp2";
import Invoicetemplate3 from "../../../components/Invoice/invoicetemp3/invoicetemp3";
import CustomModel from "../../../components/custom_modal/custom_model";
import { Radio, message } from "antd";
import Button from "../../../components/button/button";
import InvoicePrintTemp4 from "../../../components/Invoice/invoicetemp4/invoicetemp4";
import InvoicePrintTemp5 from "../../../components/Invoice/invoicetemp5/invoicetemp5";
import "./invoicedesign.scss";
function PrintInvoice() {  
  var converter = require("number-to-words");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const invoiceId = urlParams.get("invoiceId"); // Get invoiceId from the URL
  const bank = urlParams.get("bank"); // Get extraData from the URL

  console.log("Urls data", invoiceId, bank);
  const { id } = useParams();
  const [tabledata, setTabledata] = useState();
  const [alldata, setAllData] = useState();
  console.log("jjj",alldata);
  const [grandTotal, setGrandTotal] = useState();

  const [defaultTemplate, setdefaultTemplate] = useState();

  const [defaultTemplate4, setdefaultTemplate4] = useState(false);
  const [defaultTemplate2, setdefaultTemplate2] = useState();
  const [defaultTemplate3, setdefaultTemplate3] = useState();
  const [defaultTemplate5, setdefaultTemplate5] = useState(false);

  const [defaultTemp, setDefaultTemp] = useState();
  const [customerName, setCustomerName] = useState();
  const [AllBankDetails, setAllBankDetails] = useState();
  const [ShowBankModal, setShowBankModal] = useState(false);
  const [SelectedBankDet, setSelectedBankDet] = useState();
  const [selectedBank, setSelectedBank] = useState(null);

  const [contentSuccess, setContentSuccess] = useState(false);
  const [cmpnyHeaderImg, setCmpnyHeaderImg] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();
  const [paymentMadeAmt,setPaymentMadeAmt]= useState()

  const [NoofDecimal, setNoOfDecimal] = useState(
    localStorage.getItem("NumberOfDecimals")
  );

  console.log("customer name is : ", customerName);
  const template1 = InvoicePrint;

  const getAllinvoicetemp = async () => {
    try {
      const allinvoice = await PublicFetch.get(
        `${CRM_BASE_URL_FMS}/invoice-template/default`
      );
      console.log("all invoice temp aree", allinvoice.data.data);
      // setTemplates(allinvoice.data.data);
      const templateNameArr =
        allinvoice.data.data.invoice_template_image?.split("/");
      const tempName =
        templateNameArr[templateNameArr.length - 1].split(".")[0];
      setDefaultTemp(tempName);
      console.log("tempName", tempName);
      // allinvoice?.data?.data?.forEach((itm, indx) => {
      //   // (itm.invoice_template_default)
      //   console.log("onee dataa is", itm);
      //   if (itm?.invoice_template_default == 1) {
      //     // setDefaultTemp(itm.invoice_template_image.split('/')[])
      //     console.log("datatsasr", itm);
      //     let a = itm?.invoice_template_image.toString();
      //     let b = defaultTemplate.toString()
      //     console.log("adisdiasdaj",a,b,defaultTemplate2,defaultTemplate3);
      //     if (itm?.invoice_template_image == defaultTemplate) {
      //       console.log("tmpll1234",);
      //       setdefaultTemplate1(true);
      //     } else if(itm?.invoice_template_image == defaultTemplate2){
      //       console.log("tmpll1234", itm?.invoice_template_image);
      //     } else if(itm?.invoice_template_image == defaultTemplate3){
      //       console.log("tmpll1234", itm?.invoice_template_image);

      //     }
      //   }

      //   //  console.log("dattat",itm.)
      // });
    } catch (err) {
      console.log("error while getting the countries: ", err);
    }
  };
  console.log("hfidow", defaultTemplate);

  const Invoicedata_data = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/invoice/${id}`)
      .then((res) => {
        console.log("response of invoiceeee", res?.data?.data);
        if (res.data.success) {
          console.log("Success of invoice", res.data.success);
          let temp = [];
          let grandtotal = 0;
          setContentSuccess(res.data.success);
          res?.data?.data?.fms_v1_invoice_details.forEach((item, index) => {
            console.log("nmdfnms", item);
            temp.push({
              job_task_expense_agent_id:
                item?.fms_v1_job_task_expenses?.job_task_expense_agent_id,
              job_task_expense_cost_amountfx:
                item?.invoice_details_cost_amountfx,
              job_task_expense_cost_subtotalfx:
                item?.invoice_details_cost_subtotalfx,
              job_task_expense_cost_taxfx: item?.invoice_details_cost_taxfx,
              job_task_expense_id:
                item?.fms_v1_job_task_expenses?.job_task_expense_id,
              job_task_expense_job_id:
                item?.fms_v1_job_task_expenses?.job_task_expense_job_id,
              job_task_expense_task_id: item?.invoice_details_task_id,
              job_task_expense_tax_perc: item?.invoice_details_tax_perc,
              job_task_expense_task_name: item?.crm_v1_services?.service_name,
              job_task_expense_tax_group_name:
                item?.fms_v1_tax_groups?.tax_group_name,
              invoice_remarks: item?.invoice_details_remarks,
            });
            grandtotal += item?.invoice_details_cost_subtotalfx;
            grandtotal = Number.parseFloat(grandtotal);
            setGrandTotal(grandtotal?.toFixed(2));
            setTimeout(() => {
              setShowBankModal(true);
            }, 1000);
          });
          setTabledata(temp);
          setAllData(res.data.data);
          

          let totalReceiptAmt=0
          res?.data?.data?.crm_v1_customer?.accounts_v1_account_ledger?.
          account_receipts_acc_receipt_payment_from?.forEach((item,index)=>{
            console.log("detailsssss", item);
            item?.accounts_v1_account_receipt_inv_details?.forEach((itm,indx)=>{
              console.log("mcvnmxcmxnv",itm?.accounts_v1_account_receipts?.acc_receipt_total_fx);
              totalReceiptAmt +=itm?.accounts_v1_account_receipts?.acc_receipt_total_fx
            })

            
          })
          console.log("mvmnvnm",totalReceiptAmt);
          setPaymentMadeAmt(totalReceiptAmt)

          setCustomerName(
            res?.data?.data?.fms_v1_jobs?.crm_v1_customer?.customer_name
          );
          close_modal(1200);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  let BankId = Number(bank);

  console.log("bnvncbv", alldata?.fms_v1_jobs?.job_number);
  // Get All Bank Details
  const GetAllBankDetails = () => {
    PublicFetch.get(`${ACCOUNTS}/bank`)
      .then((res) => {
        if (res?.data?.success) {
          setAllBankDetails(res?.data?.data);
          res?.data?.data?.map((item, index) => {
            if (item?.bank_id === BankId) {
              // message.success("Bank Confirmed");
              setSelectedBankDet(item);
            }
          });
        }
      })
      .catch((err) => {});
  };

  //

  const capitalize = (str) =>
    str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

  const close_modal = (time) => {
    if (time) {
      setTimeout(() => {}, time);
    }
  };

  const getComapanyData = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      setCmpnyHeaderImg(allcmpny.data.data[0].company_logo);

      console.log(" cmpny details is", allcmpny.data.data);

      console.log("dataa", allcmpny.data.data.length);
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  useEffect(() => {
    getComapanyData();
    console.log("quotHeaderImg", cmpnyHeaderImg);
    if (cmpnyHeaderImg) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${cmpnyHeaderImg}`, {
        responseType: "blob",
      })
        .then((resp) => {
          console.log("respBlob".resp?.blob());

          console.log("quotresp", resp);

          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
          console.log("blobconvert", blob);
        })
        .catch((err) => console.log("error123", err));
    }
  }, [cmpnyHeaderImg]);

  // let totalAmount = converter.toWords(grandTotal)
  //  let inWords = camelize(totalAmount)
  // console.log("grand total", inWords);
  useEffect(() => {
    getAllinvoicetemp();
    GetAllBankDetails();
    if (id) {
      Invoicedata_data();
    }
  }, [id]);
  console.log("grndtotll", grandTotal);

  const handleRadioChange = (e) => {
    setSelectedBank(e.target.value);
  };

  const handleConfirmBank = (bankId) => {
    if (bankId) {
    }
  };

  //

  // useEffect(() => {
  //   AllBankDetails?.map((item, index) => {
  //     if (item?.bank_id === BankId) {
  //       // message.success("Bank Confirmed");
  //       setSelectedBankDet(item);
  //     }
  //   });
  // }, [BankId]);

  useEffect(() => {
    console.log("params", SelectedBankDet, ShowBankModal,blobUrl );
    if (SelectedBankDet && ShowBankModal === true ) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [SelectedBankDet, ShowBankModal, contentSuccess]);

  // useEffect(()=>{
  //   // if(contentSuccess=== true ){
  //     setTimeout(() => {
  //       window.print();
  //     }, 1000);
  //   // }

  // },[])
  console.log("jfdjk", contentSuccess);

  return (
    <>
      <div>
        {defaultTemp === "template2" ? (
          <Invoicetemp1
            bankDetails={SelectedBankDet}
            settemplate2={(e) => {
              console.log("temp1", e);
              setdefaultTemplate2(e);
            }}
            shipper={
              alldata?.fms_v1_console_jobs
                ? alldata?.fms_v1_console_jobs?.console_job_shipper
                : alldata?.fms_v1_jobs?.job_shipper
            }
            invoice_no={alldata?.invoice_no}
            invoice_date={moment(alldata?.invoice_date).format("DD-MM-YYYY")}
            payment_tocust={alldata?.crm_v1_customer?.customer_name}
            consignee_name={
              alldata?.fms_v1_console_jobs
                ? alldata?.fms_v1_console_jobs?.console_job_consignee
                : alldata?.fms_v1_jobs?.job_consignee
            }
            // invoice_details1={
            //   <>

            //   </>
            // }
            invoice_details2={
              <>
                <tr>
                  <td className="font_weight"> Project Name </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_no
                      : alldata?.fms_v1_jobs?.job_number}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> MAWB/MBL</td>
                  <td className=""> : </td>
                  <td className=""> {alldata?.fms_v1_jobs?.job_awb_bl_no}</td>
                </tr>
                <tr>
                  <td className="font_weight"> Origin </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {
                      alldata?.fms_v1_jobs
                        ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                        ?.location_name
                    }{" "}
                  </td>
                </tr>

                <tr>
                  <td className="font_weight"> Destination</td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {
                      alldata?.fms_v1_jobs
                        ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                        ?.location_name
                    }{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> No: of Pieces</td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_no_of_pieces
                      : alldata?.fms_v1_jobs?.job_no_of_pieces}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> Gross Weight </td>
                  <td className=""> : </td>
                  {alldata?.fms_v1_console_jobs?.console_job_gross_wt ||
                  alldata?.fms_v1_jobs?.job_gross_wt ? (
                    <td className="">
                      {" "}
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_gross_wt
                        : alldata?.fms_v1_jobs?.job_gross_wt}
                      Kg{" "}
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr>
                  <td className="font_weight"> Chargeable Weight </td>
                  <td className=""> : </td>
                  {alldata?.fms_v1_console_jobs?.console_job_chargable_wt ||
                  alldata?.fms_v1_jobs?.job_chargeable_wt ? (
                    <td className="">
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_chargable_wt
                        : alldata?.fms_v1_jobs?.job_chargeable_wt}{" "}
                      Kg
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr>
                  <td className="font_weight"> Terms</td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.fms_v1_payment_terms
                          ?.payment_term_name
                      : alldata?.fms_v1_jobs?.fms_v1_payment_terms
                          ?.payment_term_name}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> Carrier </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_jobs?.fms_v1_carrier?.carrier_name}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> PO Number </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.invoice_purchase_order_number}
                  </td>
                </tr>
              </>
            }
            invoice_table_data={
              <>
                {tabledata &&
                  tabledata.length > 0 &&
                  tabledata.map((item, index) => {
                    return (
                      <tr className="invoice_header border-0">
                        <td align="center " className="p-2">
                          {index + 1}
                        </td>
                        <td className=" ">
                          {item?.job_task_expense_task_name}
                        </td>
                        {/* <td></td>
                    <td></td>
                    <td></td> */}
                        <td style={{ textAlign: "right" }}>
                          {item?.job_task_expense_cost_subtotalfx?.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </>
            }
            amount_in_words={
              <>
                {grandTotal && <>{camelize(converter.toWords(grandTotal))}</>}
              </>
            }
            total={grandTotal}
          />
        ) : defaultTemp === "template1" ? (
          <InvoicePrint
            invoiceHeaderImg={blobUrl}
            bankDetails={SelectedBankDet}
            settemp1={(e) => {
              console.log("tmpp", e);
              setdefaultTemplate(e);
            }}
            invoice_no
            billto
            billcustomername={alldata?.crm_v1_customer?.customer_name}
            Invoice_type={"Invoice"}
            invoice_number={alldata?.invoice_no}
            invoice_details1={
              <>
                <tr className="invoice_header">
                  <td>Invoice Date</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {moment(alldata?.invoice_date).format("DD-MM-YYYY")}
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Terms</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.fms_v1_payment_terms
                          ?.payment_term_name
                      : alldata?.fms_v1_jobs?.fms_v1_payment_terms
                          ?.payment_term_name}
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Chargable Weight</td>
                  <td>:</td>
                  {alldata?.fms_v1_console_jobs?.console_job_chargable_wt ||
                  alldata?.fms_v1_jobs?.job_chargeable_wt ? (
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_chargable_wt
                        : alldata?.fms_v1_jobs?.job_chargeable_wt}{" "}
                      Kg
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr className="invoice_header">
                  <td>No of pieces</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_no_of_pieces
                      : alldata?.fms_v1_jobs?.job_no_of_pieces}
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Gross weight</td>
                  <td>:</td>
                  {alldata?.fms_v1_console_jobs?.console_job_gross_wt ||
                  alldata?.fms_v1_jobs?.job_gross_wt ? (
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_gross_wt
                        : alldata?.fms_v1_jobs?.job_gross_wt}
                      Kg
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr className="invoice_header">
                  <td>Carrier</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_jobs?.fms_v1_carrier?.carrier_name}
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Mode</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_jobs.fms_v1_freight_types.freight_type_mode}
                  </td>
                </tr>
              </>
            }
            invoice_details2={
              <>
                <tr className="invoice_header">
                  <td>Project Name</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_no
                      : alldata?.fms_v1_jobs?.job_number}
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>MAWB/MBL</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_jobs?.job_awb_bl_no}
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Origin</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {
                      alldata?.fms_v1_jobs
                        ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                        ?.location_name
                    }
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Destination</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {
                      alldata?.fms_v1_jobs
                        ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                        ?.location_name
                    }
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Shipper</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_shipper
                      : alldata?.fms_v1_jobs?.job_shipper}
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>Consignee</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_consignee
                      : alldata?.fms_v1_jobs?.job_consignee}
                  </td>
                </tr>
                <tr className="invoice_header">
                  <td>PO Number</td>
                  <td>:</td>
                  <td style={{ fontWeight: 600 }}>
                    {alldata?.invoice_purchase_order_number}
                  </td>
                </tr>
              </>
            }
            invoice_table_header={
              <>
                <tr className="invoice_header">
                  <th className="th_center">#</th>
                  <th className="tsk_exp">Task & description</th>
                  {/* <th>Tax Type</th>
                <th>Cost</th>
                <th>Tax amount</th> */}
                  <th id="amount">Amount</th>
                </tr>
              </>
            }
            invoice_table_data={
              <>
                {tabledata &&
                  tabledata.length > 0 &&
                  tabledata.map((item, index) => {
                    return (
                      <tr className="invoice_header">
                        <td align="center">{index + 1}</td>
                        <td className="tsk_exp">
                          {item?.job_task_expense_task_name}
                        </td>
                        {/* <td></td>
                      <td></td>
                      <td></td> */}
                        <td style={{ textAlign: "right" }}>
                          {item?.job_task_expense_cost_subtotalfx?.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </>
            }
            amount_in_words={
              <>
                {grandTotal && <>{camelize(converter.toWords(grandTotal))}</>}
              </>
            }
            sub_total={grandTotal}
            total={grandTotal}
          />
        ) : defaultTemp === "template3" ? (
          <Invoicetemp2
            bankDetails={SelectedBankDet}
            settemplate2={(e) => {
              console.log("temp1", e);
              setdefaultTemplate3(e);
            }}
            invoice_no={alldata?.invoice_no}
            Invoice_date={moment(alldata?.invoice_date).format("DD-MM-YYYY")}
            customer_name={alldata?.crm_v1_customer?.customer_name}
            customer_email={alldata?.crm_v1_customer?.customer_email}
            customer_address={alldata?.crm_v1_customer?.customer_address}
            consignee_name={
              alldata?.fms_v1_console_jobs
                ? alldata?.fms_v1_console_jobs?.console_job_consignee
                : alldata?.fms_v1_jobs?.job_consignee
            }
            invoice_details1={
              <>
                <tr>
                  <td className="font_weight"> Project Name </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_no
                      : alldata?.fms_v1_jobs?.job_number}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight">MAWB/MBL </td>
                  <td className=""> : </td>
                  <td className=""> {alldata?.fms_v1_jobs?.job_awb_bl_no} </td>
                </tr>
                <tr>
                  <td className="font_weight"> Origin </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {
                      alldata?.fms_v1_jobs
                        ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                        ?.location_name
                    }{" "}
                  </td>
                </tr>

                <tr>
                  <td className="font_weight"> Destination </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {
                      alldata?.fms_v1_jobs
                        ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                        ?.location_name
                    }{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> Shipper </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_shipper
                      : alldata?.fms_v1_jobs?.job_shipper}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> Terms </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.fms_v1_payment_terms
                          ?.payment_term_name
                      : alldata?.fms_v1_jobs?.fms_v1_payment_terms
                          ?.payment_term_name}{" "}
                  </td>
                </tr>
              </>
            }
            invoice_details2={
              <>
                <tr>
                  <td className="font_weight"> No of Pieces </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_no_of_pieces
                      : alldata?.fms_v1_jobs?.job_no_of_pieces}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight">Gross weight </td>
                  <td className=""> : </td>
                  {alldata?.fms_v1_jobs?.job_gross_wt ||
                  alldata?.fms_v1_console_jobs?.console_job_gross_wt ? (
                    <td className="">
                      {" "}
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_gross_wt
                        : alldata?.fms_v1_jobs?.job_gross_wt}{" "}
                      Kg
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr>
                  <td className="font_weight"> Chargeable weight </td>
                  <td className=""> : </td>
                  {alldata?.fms_v1_jobs?.job_chargeable_wt ||
                    (alldata?.fms_v1_console_jobs?.console_job_chargable_wt ? (
                      <td className="">
                        {alldata?.fms_v1_console_jobs
                          ? alldata?.fms_v1_console_jobs
                              ?.console_job_chargable_wt
                          : alldata?.fms_v1_jobs?.job_chargeable_wt}
                        Kg
                      </td>
                    ) : (
                      ""
                    ))}
                </tr>

                <tr>
                  <td className="font_weight"> Carrier </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_jobs?.fms_v1_carrier?.carrier_name}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> Modes </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.accounts_v1_payment_modes?.pay_mode_name}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> PO Number </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.invoice_purchase_order_number}
                  </td>
                </tr>
              </>
            }
            invoice_table_data={
              <>
                {tabledata &&
                  tabledata.length > 0 &&
                  tabledata.map((item, index) => {
                    return (
                      <tr className="invoice_header">
                        <td align="center" className="p-3">
                          {index + 1}
                        </td>
                        <td className="tsk_exp">
                          {item?.job_task_expense_task_name}
                        </td>
                        {/* <td></td>
                      <td></td>
                      <td></td> */}
                        <td style={{ textAlign: "right" }}>
                          {item?.job_task_expense_cost_subtotalfx?.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                {/* <tr>
                    <td className=" p-3">1 </td>
                    <td className=" ">servicenme </td>

                    <td className="text_align_words">983.00 </td>
                  </tr> */}
              </>
            }
            amount_in_words={
              <>
                {grandTotal && <>{camelize(converter.toWords(grandTotal))}</>}
              </>
            }
            total={grandTotal}
          />
        ) : defaultTemp === "template4" ? (
          <Invoicetemplate3
            settemplate4={(e) => {
              setdefaultTemplate4(e);
            }}
            invoice_no={alldata?.invoice_no}
            invoice_details1={
              <>
                <tr>
                  <td className="font_weight"> Invoice Date </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {moment(alldata?.invoice_date).format("DD-MM-YYYY")}
                  </td>
                </tr>

                <tr>
                  <td className="font_weight"> Project Name </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_no
                      : alldata?.fms_v1_jobs?.job_number}
                  </td>
                </tr>

                <tr>
                  <td className="font_weight"> Chargeable Weight</td>
                  <td className=""> : </td>
                  {alldata?.fms_v1_jobs?.job_chargeable_wt ||
                  alldata?.fms_v1_console_jobs?.console_job_chargable_wt ? (
                    <td className="">
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_chargable_wt
                        : alldata?.fms_v1_jobs?.job_chargeable_wt}
                      Kg
                    </td>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  <td className="font_weight"> Carrier </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_jobs?.fms_v1_carrier?.carrier_name}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> Mode </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.accounts_v1_payment_modes?.pay_mode_name}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> No of pieces </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_no_of_pieces
                      : alldata?.fms_v1_jobs?.job_no_of_pieces}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight">Gross weight </td>
                  <td className=""> : </td>
                  {alldata?.fms_v1_jobs?.job_gross_wt ||
                  alldata?.fms_v1_console_jobs?.console_job_gross_wt ? (
                    <td className="">
                      {" "}
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_gross_wt
                        : alldata?.fms_v1_jobs?.job_gross_wt}
                      Kg{" "}
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr>
                  <td className="font_weight"> PO Number </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.invoice_purchase_order_number}
                  </td>
                </tr>
              </>
            }
            invoice_details2={
              <>
                <tr>
                  <td className="font_weight">Customer </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.crm_v1_customer?.customer_name}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight">Terms </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.fms_v1_payment_terms
                          ?.payment_term_name
                      : alldata?.fms_v1_jobs?.fms_v1_payment_terms
                          ?.payment_term_name}{" "}
                  </td>
                </tr>

                <tr>
                  <td className="font_weight">MAWB/MBL </td>
                  <td className=""> : </td>
                  <td className=""> {alldata?.fms_v1_jobs?.job_awb_bl_no} </td>
                </tr>
                <tr>
                  <td className="font_weight"> Origin </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {
                      alldata?.fms_v1_jobs
                        ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                        ?.location_name
                    }{" "}
                  </td>
                </tr>

                <tr>
                  <td className="font_weight"> Destination </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {
                      alldata?.fms_v1_jobs
                        ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                        ?.location_name
                    }{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight"> Shipper</td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_shipper
                      : alldata?.fms_v1_jobs?.job_shipper}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="font_weight">Consignee </td>
                  <td className=""> : </td>
                  <td className="">
                    {" "}
                    {alldata?.fms_v1_console_jobs
                      ? alldata?.fms_v1_console_jobs?.console_job_consignee
                      : alldata?.fms_v1_jobs?.job_consignee}{" "}
                  </td>
                </tr>
              </>
            }
            invoice_table_data={
              <>
                {tabledata &&
                  tabledata.length > 0 &&
                  tabledata.map((item, index) => {
                    return (
                      <tr className="invoice_header">
                        <td align="center">{index + 1}</td>
                        <td className="tsk_exp">
                          {item?.job_task_expense_task_name}
                        </td>
                        {/* <td></td>
                      <td></td>
                      <td></td> */}
                        <td style={{ textAlign: "right" }}>
                          {item?.job_task_expense_cost_subtotalfx?.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </>
            }
            amount_in_words={
              <>
                {grandTotal && <>{camelize(converter.toWords(grandTotal))}</>}
              </>
            }
            sub_total={grandTotal}
            total={grandTotal}
          />
        ) : defaultTemp === "template5" ? (
          <InvoicePrintTemp4
            contentSucess={contentSuccess}
            //  invoiceHeaderImg={blobUrl}
            bankDetails={SelectedBankDet}
            settemp1={(e) => {
              console.log("tmpp", e);
              setdefaultTemplate5(e);
            }}
            jobNo={
              alldata?.fms_v1_console_jobs
                ? alldata?.fms_v1_console_jobs?.console_job_no
                : alldata?.fms_v1_jobs?.job_number
            }
            billToCust={alldata?.crm_v1_customer?.customer_name}
            invoice_date={moment(alldata?.invoice_date).format("DD-MM-YYYY")}
            Invoice_type={"Invoice"}
            invoice_no={alldata?.invoice_no}
            invoice_details1={
              <>
                <tr className="invoice_header">
                  {alldata?.fms_v1_console_jobs ? (
                    alldata?.fms_v1_console_jobs?.console_job_shipper
                  ) : alldata?.fms_v1_jobs?.job_shipper ? (
                    <>
                      <td className="widthtbl">Shipper</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600 }}>
                      {alldata?.fms_v1_jobs?.job_shipper}
                      </td>
                    </>
                  ) : (
                    ""
                  )}
                </tr>
                {alldata?.fms_v1_console_jobs ? (
                  alldata?.fms_v1_console_jobs?.console_job_consignee
                ) : alldata?.fms_v1_jobs?.job_consignee ? (
                  <tr className="invoice_header">
                    <td className="widthtbl">Consignee</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                    {alldata?.fms_v1_jobs?.job_consignee}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.fms_v1_jobs
                  ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                  ?.location_name ? (
                  <tr className="invoice_header">
                    <td className="widthtbl">Origin</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {
                        alldata?.fms_v1_jobs
                          ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                          ?.location_name
                      }
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.fms_v1_jobs
                  ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                  ?.location_name ? (
                  <tr className="invoice_header">
                    <td className="widthtbl">Destination</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {
                        alldata?.fms_v1_jobs
                          ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                          ?.location_name
                      }
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.accounts_v1_payment_modes?.pay_mode_name ? (
                  <tr className="invoice_header">
                    <td>Mode</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.accounts_v1_payment_modes?.pay_mode_name}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </>
            }
            invoice_details2={
              <>
                {alldata?.fms_v1_jobs?.job_awb_bl_no ? (
                  <tr className="invoice_header">
                    <td className="widthtemp4">MAWB/MBL</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.fms_v1_jobs?.job_awb_bl_no}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.fms_v1_console_jobs ? (
                  alldata?.fms_v1_console_jobs?.console_job_no_of_pieces
                ) : alldata?.fms_v1_jobs?.job_no_of_pieces ? (
                  <tr className="invoice_header">
                    <td className="widthtemp4">No of pieces</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_no_of_pieces
                        : alldata?.fms_v1_jobs?.job_no_of_pieces}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.fms_v1_console_jobs?.console_job_chargable_wt ||
                alldata?.fms_v1_jobs?.job_chargeable_wt ? (
                  <tr className="invoice_header">
                    <td>Chargable Weight</td>
                    <td>:</td>
                    {alldata?.fms_v1_console_jobs?.console_job_chargable_wt ||
                    alldata?.fms_v1_jobs?.job_chargeable_wt ? (
                      <td style={{ fontWeight: 600 }}>
                        {alldata?.fms_v1_console_jobs
                          ? alldata?.fms_v1_console_jobs
                              ?.console_job_chargable_wt
                          : alldata?.fms_v1_jobs?.job_chargeable_wt}{" "}
                        Kg
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.fms_v1_console_jobs?.console_job_gross_wt ||
                alldata?.fms_v1_jobs?.job_gross_wt ? (
                  <tr className="invoice_header">
                    <td>Gross weight</td>
                    <td>:</td>
                    {alldata?.fms_v1_console_jobs?.console_job_gross_wt ||
                    alldata?.fms_v1_jobs?.job_gross_wt ? (
                      <td style={{ fontWeight: 600 }}>
                        {alldata?.fms_v1_console_jobs
                          ? alldata?.fms_v1_console_jobs?.console_job_gross_wt
                          : alldata?.fms_v1_jobs?.job_gross_wt}
                        Kg
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.fms_v1_jobs?.fms_v1_carrier?.carrier_name ? (
                  <tr className="invoice_header">
                    <td>Carrier</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.fms_v1_jobs?.fms_v1_carrier?.carrier_name}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </>
            }
            invoice_table_header={
              <>
                <tr className="invoice_header">
                  <th className="th_center">#</th>
                  <th className="tsk_exp">Task & description</th>
                  {/* <th className="width_remarks">Remarks</th> */}
                  <th id="amount">Amount</th>
                </tr>
              </>
            }
            invoice_table_data={
              <>
                {tabledata &&
                  tabledata.length > 0 &&
                  tabledata.map((item, index) => {
                    console.log("mndf", item);
                    return (
                      <tr className="invoice_header">
                        <td align="center width_slno">{index + 1}</td>
                        <td className=" width_taskandexpenses">
                          {item?.job_task_expense_task_name} {item?.invoice_remarks}
                        </td>
                        {/* <td></td>
                   <td></td>
                   <td></td> */}
                        {/* <td className="width_remarks">
                          {item?.invoice_remarks}{" "}
                        </td> */}
                        <td
                          style={{ textAlign: "right" }}
                          className="width_amount"
                        >
                          {item?.job_task_expense_cost_subtotalfx?.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </>
            }
            amount_in_words={
              <>
                {grandTotal && <>{camelize(converter.toWords(grandTotal))}</>}
              </>
            }
            totaldetails={
              <>
                <tr>
                  <td>SubTotal</td>
                  <td>:</td>
                  <td>{grandTotal} </td>
                </tr>
                {grandTotal ? (
                  <tr>
                    <td style={{ fontWeight: 600 }}>Total(KWD) </td>
                    <td>:</td>
                    <td> {grandTotal} </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.invoice_discount ? (
                  <tr>
                    <td style={{ fontWeight: 600 }}>Discount</td>
                    <td>:</td>
                    <td>{alldata?.invoice_discount?.toFixed(NoofDecimal)} </td>
                  </tr>
                ) : (
                  ""
                )}
                 {paymentMadeAmt ? (
                  <tr>
                    <td style={{ fontWeight: 600 }}>Payment Made</td>
                    <td>:</td>
                    <td>{paymentMadeAmt?.toFixed(NoofDecimal)}  </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.invoice_due_amount ? (
                  <tr>
                    <td style={{ fontWeight: 600 }}>Balance Due </td>
                    <td>:</td>
                    <td>{alldata?.invoice_due_amount?.toFixed(NoofDecimal)} </td>
                  </tr>
                ) : (
                  ""
                )}
              </>
            }
            sub_total={grandTotal}
            total={grandTotal}
          />
        ) : defaultTemp === "template6" ? (
          <InvoicePrintTemp5
            contentSucess={contentSuccess}
            //  invoiceHeaderImg={blobUrl}
            bankDetails={SelectedBankDet}
            invoiceDate={moment(alldata?.invoice_date).format("DD-MM-YYYY")}
            settemp1={(e) => {
              console.log("tmpp", e);
              setdefaultTemplate5(e);
            }}
            job_no={
              alldata?.fms_v1_console_jobs
                ? alldata?.fms_v1_console_jobs?.console_job_no
                : alldata?.fms_v1_jobs?.job_number
            }
            billToCust={alldata?.crm_v1_customer?.customer_name}
            Invoice_type={"Invoice"}
            invoice_no={alldata?.invoice_no}
            invoice_details1={
              <>
                <tr className="invoice_header">
                  {alldata?.fms_v1_console_jobs ? (
                    alldata?.fms_v1_console_jobs?.console_job_shipper
                  ) : alldata?.fms_v1_jobs?.job_shipper ? (
                    <>
                      <td className="widthtbl1">Shipper</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600,paddingLeft:"5px" }}>
                        {alldata?.job_shipper}
                      </td>
                    </>
                  ) : (
                    ""
                  )}
                </tr>
                {alldata?.fms_v1_console_jobs ? (
                  alldata?.fms_v1_console_jobs?.console_job_consignee
                ) : alldata?.fms_v1_jobs?.job_consignee ? (
                  <tr className="invoice_header">
                    <td>Consignee</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600,paddingLeft:"5px" }}>
                      {alldata?.job_consignee}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.fms_v1_jobs
                  ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                  ?.location_name ? (
                  <tr className="invoice_header">
                    <td className="widthtbl">Origin</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600,paddingLeft:"5px" }}>
                      {
                        alldata?.fms_v1_jobs
                          ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                          ?.location_name
                      }
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.fms_v1_jobs
                  ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                  ?.location_name ? (
                  <tr className="invoice_header">
                    <td>Destination</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 ,paddingLeft:"5px"}}>
                      {
                        alldata?.fms_v1_jobs
                          ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                          ?.location_name
                      }
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.fms_v1_jobs?.fms_v1_carrier?.carrier_name ? (
                  <tr className="invoice_header">
                    <td>Carrier</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600,paddingLeft:"5px" }}>
                      {alldata?.fms_v1_jobs?.fms_v1_carrier?.carrier_name}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.fms_v1_console_jobs?.fms_v1_payment_terms
                  ?.payment_term_name ? (
                  <tr className="invoice_header">
                    <td>Terms</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600,paddingLeft:"5px" }}>
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.fms_v1_payment_terms
                            ?.payment_term_name
                        : alldata?.fms_v1_jobs?.fms_v1_payment_terms
                            ?.payment_term_name}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </>
            }
            invoice_details2={
              <>
                {alldata?.fms_v1_jobs?.job_awb_bl_no ? (
                  <tr className="invoice_header">
                    <td className="widthinvoice_temp5">MAWB/MBL</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600, }}>
                      {alldata?.fms_v1_jobs?.job_awb_bl_no}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.fms_v1_console_jobs ? (
                  alldata?.fms_v1_console_jobs?.console_job_no_of_pieces
                ) : alldata?.fms_v1_jobs?.job_no_of_pieces ? (
                  <tr className="invoice_header">
                    <td>No of pieces</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.fms_v1_console_jobs
                        ? alldata?.fms_v1_console_jobs?.console_job_no_of_pieces
                        : alldata?.fms_v1_jobs?.job_no_of_pieces}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.fms_v1_console_jobs?.console_job_chargable_wt ||
                alldata?.fms_v1_jobs?.job_chargeable_wt ? (
                  <tr className="invoice_header">
                    <td>Chargable Weight</td>
                    <td>:</td>
                    {alldata?.fms_v1_console_jobs?.console_job_chargable_wt ||
                    alldata?.fms_v1_jobs?.job_chargeable_wt ? (
                      <td style={{ fontWeight: 600 }}>
                        {alldata?.fms_v1_console_jobs
                          ? alldata?.fms_v1_console_jobs
                              ?.console_job_chargable_wt
                          : alldata?.fms_v1_jobs?.job_chargeable_wt}{" "}
                        Kg
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ) : (
                  ""
                )}

                {alldata?.fms_v1_console_jobs?.console_job_gross_wt ||
                alldata?.fms_v1_jobs?.job_gross_wt ? (
                  <tr className="invoice_header">
                    <td>Gross weight</td>
                    <td>:</td>
                    {alldata?.fms_v1_console_jobs?.console_job_gross_wt ||
                    alldata?.fms_v1_jobs?.job_gross_wt ? (
                      <td style={{ fontWeight: 600 }}>
                        {alldata?.fms_v1_console_jobs
                          ? alldata?.fms_v1_console_jobs?.console_job_gross_wt
                          : alldata?.fms_v1_jobs?.job_gross_wt}
                        Kg
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.accounts_v1_payment_modes?.pay_mode_name ? (
                  <tr className="invoice_header">
                    <td>Mode</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.accounts_v1_payment_modes?.pay_mode_name}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.fms_v1_jobs?.job_shipment_ref_no ? (
                  <tr className="invoice_header">
                    <td>Shipment Ref No</td>
                    <td>:</td>
                    <td style={{ fontWeight: 600 }}>
                      {alldata?.fms_v1_jobs?.job_shipment_ref_no}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </>
            }
            invoice_table_header={
              <>
                <tr className="invoice_header">
                  <th className="th_center">#</th>
                  <th className="tsk_exp">Task & description</th>

                  <th id="amount">Amount</th>
                </tr>
              </>
            }
            invoice_table_data={
              <>
                {tabledata &&
                  tabledata.length > 0 &&
                  tabledata.map((item, index) => {
                    return (
                      <tr className="invoice_header">
                        <td align="center">{index + 1}</td>
                        <td className="tsk_exp">
                          {item?.job_task_expense_task_name}   {item?.invoice_remarks} 
                        </td>
                        {/* <td>{item?.invoice_remarks} </td> */}
                        {/* <td></td>
                             <td></td>
                             <td></td> */}
                        <td style={{ textAlign: "right" }}>
                          {item?.job_task_expense_cost_subtotalfx?.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </>
            }
            totalcalculation={
              <>
                <tr>
                  <td>SubTotal</td>
                  <td>:</td>
                  <td style={{ textAlign: "right" }}>
                    {alldata?.invoice_grand_total?.toFixed(2)}{" "}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 600 }}>Total(KWD)</td>
                  <td>:</td>
                  <td style={{ textAlign: "right" }}>
                    {alldata?.invoice_grand_total?.toFixed(2)}
                  </td>
                </tr>
                {alldata?.invoice_discount ? (
                  <tr>
                    <td style={{ fontWeight: 600 }}>Discount</td>
                    <td>:</td>
                    <td>{alldata?.invoice_discount?.toFixed(NoofDecimal)} </td>
                  </tr>
                ) : (
                  ""
                )}
                 {paymentMadeAmt ? (
                  <tr>
                    <td style={{ fontWeight: 600 }}>Payment Made</td>
                    <td>:</td>
                    <td>{paymentMadeAmt?.toFixed(NoofDecimal)}  </td>
                  </tr>
                ) : (
                  ""
                )}
                {alldata?.invoice_due_amount ? (
                  <tr>
                    <td style={{ fontWeight: 600 }}>Balance Due </td>
                    <td>:</td>
                    <td>{alldata?.invoice_due_amount.toFixed(NoofDecimal)} </td>
                  </tr>
                ) : (
                  ""
                )}
              </>
            }
            amount_in_words={
              <>
                {grandTotal && <>{camelize(converter.toWords(grandTotal))}</>}
              </>
            }
            sub_total={grandTotal}
            total={grandTotal}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default PrintInvoice;
