import { SmileOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import React from "react";
import { FaThList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/header";
import CustomerSideBar from "../components/Sidebar/CustomerSideBar";
import Sidebar from "../components/Sidebar/sidebar";
import ROUTES from "../routes";
import "./layout.scss";

function CustomerLayout({ children }) {
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <label
          className="py-2"
          onClick={() => {
            navigate(`${ROUTES.CUSTOMER_DASHBOARD}`);
          }}
        >
          Dashboard
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </label>
      ),
    },
    {
      key: "2",
      label: (
        <label
          className=""
          onClick={() => {
            navigate(`${ROUTES.CUSTOMER_JOBS}`);
          }}
        >
          Jobs
        </label>
      ),
    },
    {
      key: "3",
      label: (
        <label
          className="py-2"
          onClick={() => {
            navigate(`${ROUTES.CUSTOMER_INVOICES}`);
          }}
        >
          Invoices
        </label>
      ),
    },
  ];
  return (
    <div>
      <div className="">
        <div className="row m-0 ">
          <div className="col-12 cust_header">
            <Header />
          </div>
          <div className="col-12 d-flex cust_children">
            <div style={{ width: "13%" }} className="sidebarStyle1">
              <CustomerSideBar />
            </div>
            <div className="mobileViewSidebar">
              <Dropdown
                menu={{
                  items,
                }}
              >
                <FaThList size={"60%"} color="lightgray" />
              </Dropdown>
            </div>
            <div style={{ width: "87%" }} className="childrenStyle1">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerLayout;
