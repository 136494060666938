import { useEffect, useState } from "react";
import {
  CRM_BASE_URL_FMS,
  GENERAL_SETTING_BASE_URL,
  CRM_BASE_URL_HRMS,
} from "../../../../api/bootapi";
import PublicFetch from "../../../../utils/PublicFetch";
import "./quotation.scss";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableData from "../../../../components/table/table_data";
import InvoicePrint from "../../../../components/Invoice/InvoicePrint";
import ROUTES from "../../../../routes";
import { camelize } from "../../../../utils/camelcaseconvert";
import QuotationPrint from "../../../../components/Invoice/quotationPrint/quotationPrint";
import { string } from "yup";
import QuotationPrintNew from "../../../../components/Invoice/quotationprintNew/quotationPrintNew";
// import axios from 'axios';

function Quotationinvoice() {
  var converter = require("number-to-words");

  const { id } = useParams();
  console.log("id :::::", id);
  const { pathname } = useLocation();
  console.log("jobPathname", pathname.split("/"));
  let url = pathname.split("/");

  console.log("locationn", url[2]);
  const [allqoutation, setAllQuotations] = useState();
  const [tabledata, setTableData] = useState();
  const [admin, setAdmin] = useState();

  const [companyname, setCompanyname] = useState();
  const [companyaddress, setCompanyaddress] = useState();
  const [companyphone, setCompanyphone] = useState();
  const [companyemail, setCompanyemail] = useState();
  const [companycountry, setCompanycountry] = useState();

  const [companyzipcode, setcompanyzipcode] = useState();
  const [companylogo, setCompanylogo] = useState();
  const [cmpnyinfo, setcmpnyinfo] = useState();
  const [isCompanyInfoFilled, setIsCompanyInfoFilled] = useState(false);
  const [grandTotal, setGrandTotal] = useState();
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [quotHeaderImg, setQuotHeaderImg] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();

  const [quotHeaderSuccess, setQuotHeaderSuccess] = useState(false);
  const [quotContentSuccess, setQuotContentSuccess] = useState(false);

  const [contactPname,setContactPname]= useState()

  // const [noOfDecimal, setNoOfDecimal] = useState();

  const [noOfDecimal, setNoOfDecimal] = useState();
  console.log("no of decimal", noOfDecimal);

  console.log("quotHeaderSuccess", quotHeaderSuccess);
  const navigate = useNavigate();
  const progress = [
    {
      title: "TASKS",
      dataIndex: "service_name",
      key: "service_name",
      align: "center",
      // render: (value, item, indx) => count + indx,
    },
    {
      title: "COST",
      dataIndex: "quotation_details_cost",
      key: "quotation_details_cost",
      align: "center",
    },
    {
      title: "TAX TYPE",
      dataIndex: "tax_type_name",
      key: "tax_type_name",
      align: "center",
    },
    {
      title: "TAX AMOUNT",
      dataIndex: "quotation_details_tax_amount",
      key: "quotation_details_tax_amount",
      width: "35%",
      align: "center",
      // render: (opportunity_update_next_date_contact) => {
      //   return (
      //     <label>
      //       {moment(opportunity_update_next_date_contact).format("DD-MM-YYYY")}
      //     </label>
      //   );
      // },
    },
    {
      title: "TOTAL AMOUNT",
      dataIndex: "quotation_details_total",
      key: "quotation_details_total",

      align: "center",
    },
  ];

  function setPageSize() {
    const page = document.querySelector(".print-page");
    // page.style.width = "210mm";
    // page.style.height = "297mm";
    // page.style.margin = "10mm";
    page.style.width = "21cm";
    page.style.height = "29.7cm";
    page.style.margin = "auto";
    page.style.padding = "2rem";
  }
  const handlePrint = () => {};

  const fetchData = () => {
    PublicFetch.get(`${CRM_BASE_URL_HRMS}/employees/2`)
      .then((res) => {
        console.log("response of quotation from fectch data", res);
        console.log(res?.data?.data);
        setAdmin(res?.data?.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  const allCurrency = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/currency`)
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of cuurency", res.data.data);
          res?.data?.data?.forEach((item, index) => {
            if (item.currency_is_default === 1) {
              console.log("default currency", item);
              setDefaultCurrency(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    // handlePrint();
    allCurrency();
    fetchData();
    getallcmpny();
    if (id && isCompanyInfoFilled === true) {
      getSingleQuotation();
    }
  }, [id, isCompanyInfoFilled]);

  useEffect(() => {
    console.log("quotHeaderImg", quotHeaderImg);
    if (quotHeaderImg) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${quotHeaderImg}`, {
        responseType: "blob",
      })
        .then((resp) => {
          console.log("respBlob".resp?.blob());

          console.log("quotresp", resp);

          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
          console.log("blobconvert", blob);
        })
        .catch((err) => console.log("error123", err));
    }
  }, [quotHeaderImg]);
  //   useEffect(()=> {
  // if(blob){
  //     const orgUrl = URL.createObjectURL(blob)
  //     setBlobUrl(orgUrl)
  //        return () => {
  //       URL.revokeObjectURL(orgUrl);
  //     };
  // }

  //   }, [blob])

  const getallcmpny = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      setQuotHeaderImg(allcmpny.data.data[0].company_quotation_header);

      console.log(" cmpny details is", allcmpny.data.data);
      setcmpnyinfo(allcmpny.data.data);
      // setcmpnyupdate(allcmpny.data.data.length)
      console.log("dataa", allcmpny.data.data.length);
      let arry = [];
      allcmpny.data.data.forEach((i, indx) => {
        arry.push(i);
      });
      console.log("all cmpny are", arry);
      // setcmpnyupdate(arry)
      // setQuotHeaderSuccess(true)
      arry.map((itm, indx) => {
        console.log("all cmpny maped", itm);
        // setcmpnyid(itm.company_id)
        setQuotHeaderImg(itm.company_quotation_header);
        setCompanyname(itm.company_name);
        setCompanyaddress(itm.company_address);
        setCompanyemail(itm.company_email);
        setCompanyphone(itm.company_phone);
        // setCountryis(itm.company_country)
        setcompanyzipcode(itm.company_zip_code);
        setCompanylogo(itm.company_logo);
        setCompanycountry(itm.company_country);
        setNoOfDecimal(itm.number_of_decimal);
      });
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  // const close_modal = (time) => {
  //   if (time) {
  //     setTimeout(() => {
  //         handlePrint();

  //       // navigate(ROUTES.QUATATIONS)
  //     }, time);
  //   }
  // };
  console.log("blobUrl", blobUrl);
  useEffect(() => {
    console.log("blobUrlIn", blobUrl);
    if (quotContentSuccess === true && blobUrl) {
      handlePrint();
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [blobUrl, quotContentSuccess]);

  useEffect(() => {
    if (url[2] === "quotation_invoice") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [url[2] === "quotation_invoice"]);
  console.log("dataa iss", tabledata);

  // style.................................

  // window.onafterprint = function () {
  //   // Code to run after printing or if the user cancels the print operation
  //   if (window.matchMedia("print").matches) {
  //     console.log("Printing completed.");
  //   } else {
  //     console.log("Print operation canceled.");
  //     navigate(`${ROUTES.VIEW_QUOTATION}/${id}`);
  //   }
  // };
  const getSingleQuotation = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/quotation/${id}`)
      .then((res) => {
        console.log("response of quotation", res);
        if (res.data.success) {
          console.log(
            "Success of single quotation",
            res?.data?.data?.quotation
          );
          setAllQuotations(res.data.data.quotation);
          // console.log("temp", res?.data?.data?.quotation?.crm_v1_customer?.crm_v1_contacts?.);
          let contact=[]
          res?.data?.data?.quotation?.crm_v1_customer?.crm_v1_contacts.forEach((item,index)=>{
            console.log("xzmncbm",item);
           if( item?.contact_primary === 1){
            contact.push({
              cname:item?.contact_person_name
            })
            
           }
           
         
          })
          console.log("mxcbm",contact[0]?.cname);
          setContactPname(contact[0]?.cname)
         


          let total;
          res.data.data.quotation.fms_v1_quotation_details.forEach(
            (item, index) => {
              total += item.quotation_details_total_amt_lx;

              // setTableData(temp11);
            }
          );
          setGrandTotal(total?.toFixed(2));

          // let tmp=[]
          // res.data.data.quotation?.fms_v1_quotation_quotation_salesperson.forEach((i,indx)=>{
          //    tmp.push(i)
          // })
          // console.log("dfhkd",tmp);
          // allqoutation?.fms_v1_quotation_quotation_salesperson
          let temp11 = [];
          res.data.data.quotation.fms_v1_quotation_task_locations.forEach(
            (item, index) => {
              console.log("locations cargotypedetailss", item);
              temp11.push(item);
              // temp11.push({
              //   quotation_details_cost:
              //     item?.quotation_details_cost?.toFixed(2),
              //   quotation_details_id: item?.quotation_details_id,
              //   quotation_details_quotation_id:
              //     item?.quotation_details_quotation_id,
              //   quotation_details_service_id:
              //     item?.quotation_details_service_id,
              //   quotation_details_status: item?.quotation_details_status,
              //   quotation_details_tax_amount:
              //     item?.quotation_details_tax_amount?.toFixed(2),
              //   quotation_details_tax_type: item?.quotation_details_tax_type,
              //   quotation_details_total:
              //     item?.quotation_details_total?.toFixed(2),
              //   service_name: item?.crm_v1_services?.service_name,
              //   tax_type_name: item?.fms_v1_tax_types?.tax_type_name,
              //   quotation_details_remarks: item?.quotation_details_remarks,
              //   quotation_task_currency_code:
              //     item?.generalsettings_v1_currency?.currency_name,
              //     quotation_details_total_amt_lx:item?.quotation_details_total_amt_lx
              // });
              setTableData(temp11);
              console.log("temp11", temp11);
              // setQuotation(res.data.success);
            }
          );
          setQuotContentSuccess(true);
          // close_modal(500);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <>
      {/*---------------  quatation print -new ------------------ */}

      <QuotationPrintNew
        // cargodescription={allqoutation?.quotation_cargo_desc}
        // setQuotHeaderSuccess={setQuotHeaderSuccess}
        setDataCompanyFilled={setIsCompanyInfoFilled}
        quotationHeaderimg={blobUrl}
        invoice_details1={
          <>
            <tr className="qtn_qtn_header">
              <td>Customer Name</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {allqoutation?.crm_v1_customer.customer_name}
              </td>
            </tr>
            <tr className="qtn_qtn_header">
              <td>Contact Person</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {allqoutation?.crm_v1_customer?.crm_v1_contacts[0]
                    ?.contact_person_name}
              </td>
            </tr>
            <tr className="qtn_qtn_header">
              <td>Email</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {" "}
                {allqoutation?.crm_v1_customer.customer_email}
              </td>
            </tr>
            <tr className="qtn_qtn_header">
              <td>Phone No</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {allqoutation?.crm_v1_customer.customer_phone}
              </td>
            </tr>
          </>
        }
        invoice_details2={
          <>
            <tr className="qtn_qtn_header">
              <td>Quotation No</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>{allqoutation?.quotation_no}</td>
            </tr>
            <tr className="qtn_qtn_header">
              <td>Date</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {" "}
                {moment(allqoutation?.quotation_date).format("DD-MM-YYYY")}
              </td>
            </tr>
            <tr className="qtn_qtn_header">
              <td>Validity Date</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {" "}
                {moment(allqoutation?.quotation_validity).format("DD-MM-YYYY")}
              </td>
            </tr>
          </>
        }
        // grandTotal={
        //   allqoutation?.quotation_visible_grand_total === 0 ? (
        //     <div className="grandTotal">
        //       <p className="grandTotal_label">
        //         {`Grand Total : ${
        //           defaultCurrency?.currency_code
        //         }  ${allqoutation?.quotation_grand_total.toFixed(2)}`}{" "}
        //       </p>
        //     </div>
        //   ) : (
        //     ""
        //   )
        // }
        invoice_details3={
          <>
            {allqoutation?.fms_v1_quotation_quotation_origin_country
              ?.country_name ? (
              <tr className="">
                <td style={{ width: "130px" }}>Origin Country</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {" "}
                  {
                    allqoutation?.fms_v1_quotation_quotation_origin_country
                      ?.country_name
                  }
                </td>
              </tr>
            ) : (
              ""
            )}

            {allqoutation?.fms_v1_incoterms?.incoterm_short_name ? (
              <tr className="">
                <td>Incoterm</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {" "}
                  {allqoutation?.fms_v1_incoterms?.incoterm_short_name}
                </td>
              </tr>
            ) : (
              ""
            )}

            {allqoutation?.quotation_no_of_pieces ? (
              <tr className="">
                <td>No of Pieces</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {allqoutation?.quotation_no_of_pieces}
                </td>
              </tr>
            ) : (
              ""
            )}
            {allqoutation?.quotation_chargeable_wt ? (
              <tr className="">
                <td>Chargeable Weight</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {allqoutation?.quotation_chargeable_wt
                    ? `${allqoutation?.quotation_chargeable_wt} ${allqoutation?.crm_v1_units?.unit_name}`
                    : null}{" "}
                </td>
              </tr>
            ) : (
              ""
            )}
            {allqoutation?.quotation_pickup_location ? (
              <tr className="">
                <td>Pick Up Location</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {allqoutation?.quotation_pickup_location}
                </td>
              </tr>
            ) : (
              ""
            )}
          </>
        }
        invoice_details4={
          <>
            {allqoutation?.fms_v1_quotation_quotation_destination_country
              ?.country_name ? (
              <tr className="">
                <td style={{ width: "150px" }}>Destination Country</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {" "}
                  {
                    allqoutation?.fms_v1_quotation_quotation_destination_country
                      ?.country_name
                  }
                </td>
              </tr>
            ) : (
              ""
            )}
            {allqoutation?.fms_v1_freight_types.freight_type_mode ? (
              <tr className="">
                <td>Mode</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {allqoutation?.fms_v1_freight_types.freight_type_mode}{" "}
                </td>
              </tr>
            ) : (
              ""
            )}
            {allqoutation?.quotation_volume ? (
              <tr className="">
                <td>CBM</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {allqoutation?.quotation_volume}
                </td>
              </tr>
            ) : (
              ""
            )}
            {allqoutation?.fms_v1_cargo_types?.cargo_type_name ? (
              <tr className="">
                <td>Cargo Type</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {allqoutation?.fms_v1_cargo_types?.cargo_type_name}
                </td>
              </tr>
            ) : (
              ""
            )}
            {allqoutation?.quotation_new_volume ? (
              <tr className="">
                <td>Volume</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>
                  {`${allqoutation?.quotation_new_volume}  `
                    ? ` ${allqoutation?.quotation_new_volume} ${allqoutation?.crm_v1_units?.unit_name} `
                    : null}
                </td>
               
              </tr>
            ) : (
              ""
            )}
          </>
        }
        invoice_details5={
          <>
            {/* {allqoutation?.quotation_cargo_desc ?:""} */}
            <tr className=" qtn_qtn_header">
              <td style={{ width: "120px" }}> Cargo Description</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {allqoutation?.quotation_cargo_desc}
              </td>
            </tr>
          </>
        }
        termsandcondition={
          <>
            <tr className="qtn_qtn_header ">
              {/* <div dangerouslySetInnerHTML={{__html: `${allqoutation?.terms_and_conditions}`}}></div> */}
              {allqoutation?.terms_and_conditions && (
                <td
                  dangerouslySetInnerHTML={{
                    __html: `${allqoutation?.terms_and_conditions}`,
                  }}
                ></td>
              )}
            </tr>
          </>
        }
        customer_name={
          allqoutation?.fms_v1_quotation_quotation_origin_country?.country_name
        }
        transpotetion_mode={
          allqoutation?.fms_v1_freight_types.freight_type_mode
        }
        frightType_type={allqoutation?.fms_v1_freight_types?.freight_type_type}
        // invoice_table_header={
        //   <>
        //     <tr className="invoice_header">
        //       <th className="">#</th>
        //       <th className="tsk_exp" style={{ textAlign: "left" }}>
        //         Charges
        //       </th>
        //       {/* <th>Tax Type</th>
        //     <th>Cost</th>
        //     <th>Tax amount</th> */}
        //       <th style={{ textAlign: "left" }}>Remarks</th>
        //     </tr>
        //   </>
        // }

        quot_table_data={
          <>
            {tabledata &&
              tabledata.map((itm, indx) => {
                console.log("quot detailss", itm);
                return (
                  <>
                    <tr className="py-4">
                      <th className="p-3">Origin / POL </th>
                      <th className="p-3">:</th>
                      <th className="p-3">
                        {
                          itm
                            ?.fms_v1_quotation_task_locations_quotation_task_loc_origin
                            ?.location_name
                        }{" "}
                      </th>
                      <th style={{ width: "100px" }}></th>
                      <th style={{ width: "100px" }}></th>
                      <th className="p-3">Destination / POD </th>
                      <th className="p-3">:</th>
                      <th className="p-3">
                        {
                          itm
                            ?.fms_v1_quotation_task_locations_quotation_task_loc_destination
                            ?.location_name
                        }{" "}
                      </th>
                    </tr>

                    <table className="quot_details_table  qtn_qtn_header ">
                      <thead style={{ border: "1px solid black" }} className="">
                        <tr style={{}} className="">
                          <th className="slnoWidthquot">Sl No.</th>
                          <th className="tsk_exp" style={{ textAlign: "left" }}>
                            Charges
                          </th>

                          <th style={{ textAlign: "left" }}>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itm?.fms_v1_quotation_details?.map((item, index) => {
                          console.log("quot fnjnd", item);
                          return (
                            <>
                              <tr className="qtn_qtn_header">
                                <td
                                  align="center"
                                  className="quotation_print_td"
                                >
                                  {index + 1}{" "}
                                </td>
                                <td className="">
                                  {item?.crm_v1_services?.service_name}
                                </td>

                                <td>
                                  <tr className="quotborder">
                                    {item?.generalsettings_v1_currency
                                      ?.currency_code && (
                                      <>
                                        {
                                          item?.generalsettings_v1_currency
                                            ?.currency_code
                                        }
                                      </>
                                    )}
                                    {item?.quotation_details_total && (
                                      <td className="quotborder">
                                        {item?.quotation_details_total?.toFixed(
                                          noOfDecimal
                                        )}
                                      </td>
                                    )}

                                    <td className="quotborder">
                                      {item?.quotation_details_remarks}
                                    </td>
                                  </tr>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="col-12 ">
                      {allqoutation?.quotation_visible_grand_total === 0 ? (
                        <span className="txtbold p-3">
                          Grand Total: {defaultCurrency?.currency_code}{" "}
                          {itm?.quotation_task_loc_total_amt?.toFixed(
                            noOfDecimal
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="py-2"></div>
                  </>
                );
              })}
          </>
        }
        prepared_by={
          allqoutation?.fms_v1_quotation_quotation_salesperson?.employee_name
        }
        // salesperson_mobileno={"91+9937383837"}

        salesperson_address={
          allqoutation?.fms_v1_quotation_quotation_salesperson?.users[0]
            ?.user_email
        }
        salesperson_mobileno={
          allqoutation?.fms_v1_quotation_quotation_salesperson?.employee_phone
        }
      />

      {/* quatation print----------old */}
      {/* <QuotationPrint
        first_table={
          <>
            <tr>
              <td onClick={() => handlePrint()} className=" q_heading">
                To
              </td>
              <td className=" q_data">
                {allqoutation?.crm_v1_customer.customer_name}
              </td>
              <td className="q_heading ">Qtn No</td>
              <td className="q_data">{allqoutation?.quotation_no}</td>
            </tr>
            <tr>
              <td className="q_heading">Contact Person</td>
              <td className="q_data">
                {allqoutation?.crm_v1_customer?.crm_v1_contacts?.length > 0 &&
                  allqoutation?.crm_v1_customer?.crm_v1_contacts[0]
                    ?.contact_person_name}
              </td>
              <td className="q_heading">Customer Ref</td>
              <td className="q_data"></td>
            </tr>
            <tr>
              <td className="q_heading">Fax No</td>
              <td className="q_data"></td>
              <td className="q_heading">Date</td>
              <td className="q_data">
                {" "}
                {moment(allqoutation?.quotation_date).format("DD-MM-YYYY")}
              </td>
            </tr>
            <tr>
              <td className="q_heading">Email</td>
              <td className="q_data">
                {allqoutation?.crm_v1_customer.customer_email}
              </td>
              <td className="q_heading">Valid Until</td>
              <td className="q_data">
                {moment(allqoutation?.quotation_validity).format("DD-MM-YYYY")}
              </td>
            </tr>
          </>
        }
        customer_name={
          allqoutation
            ?.fms_v1_locations_fms_v1_quotation_quotation_origin_idTofms_v1_locations
            .location_name
        }
        transpotetion_mode={
          allqoutation?.fms_v1_freight_types.freight_type_mode
        }
        quotation_second={
          <>
            <tr>
              <td className="second-heading">Origin</td>
              <td className="full second-data">
                {
                  allqoutation
                    ?.fms_v1_locations_fms_v1_quotation_quotation_origin_idTofms_v1_locations
                    .location_name
                }
              </td>
            </tr>
            <tr>
              <td className="second-heading">Destination</td>
              <td className="full second-data">
                {
                  allqoutation
                    ?.fms_v1_locations_fms_v1_quotation_quotation_destination_idTofms_v1_locations
                    .location_name
                }
              </td>
            </tr>
          </>
        }
        third_table={
          <>
            <tr>
              <td className="top second-heading">INCO Terms</td>
              <td className="top second-data">
                {allqoutation?.fms_v1_incoterms?.incoterm_full_name}
              </td>
              <td className="top second-subheading">IMCO Status</td>
              <td className="top second-data">
                {allqoutation?.fms_v1_inco_status?.inco_status_name}
              </td>
            </tr>
            <tr>
              <td className="second-heading">No Of Peices</td>
              <td className="second-data">
                {allqoutation?.quotation_no_of_pieces}
              </td>
              <td className="second-subheading">Mode</td>
              <td className="second-data">
                {allqoutation?.fms_v1_freight_types.freight_type_mode}
              </td>
            </tr>
            <tr>
              <td className="botom second-heading">Weight\C.Weight</td>
              <td className="botom second-data">
                {allqoutation?.quotation_chargeable_wt}
              </td>
              <td className="botom second-subheading">Volume</td>
              <td className="botom second-data">
                {allqoutation?.quotation_volume}
              </td>
            </tr>
          </>
        }
        weight_volume={
          <>
            <tr>
              <td className="second-heading height">Weight/Volume Details</td>
              <td className="second-data"></td>
            </tr>

            <tr>
              <td className="second-heading">Pickup Location</td>
              <td className="second-data">
                {allqoutation?.quotation_pickup_location}
              </td>
            </tr>
            <tr>
              <td className="second-heading">Cargo Details</td>
              <td className="second-data">
                {allqoutation?.quotation_cargo_type}-
                {allqoutation?.quotation_cargo_desc}
              </td>
            </tr>
          </>
        }
        country={
          allqoutation
            ?.fms_v1_locations_fms_v1_quotation_quotation_origin_idTofms_v1_locations
            .location_name
        }
        charges_discription={
          <>
            {tabledata &&
              tabledata.map((itm, indx) => (
                <tr>
                  <td className="b charge-discriptiondata">
                    {" "}
                    {itm.service_name}{" "}
                  </td>
                  <td className="b currency ">
                    {allqoutation?.generalsettings_v1_currency?.currency_code}
                  </td>
                  <td className="b  charges amount_display">
                    {itm.quotation_details_total}
                  </td>
                  <td className="b unit amount_display">
                    {" "}
                    {allqoutation?.quotation_uom}
                  </td>
                </tr>
              ))}

          
          </>
        }
        prepared_by={admin?.employee_name}
        aproved_by={admin?.employee_name}
      /> */}

      {/* old quatationn-----------------

<InvoicePrint 
invoice_no
billto
Invoice_type="QUOTATION" 
invoice_number= {allqoutation?.quotation_no}
invoice_details1={ <>
  <tr className="p-2 ">
 <td>Quotation No </td>
 <td>: </td>
 <td className="quotation_p_name" > {allqoutation?.quotation_no}</td>
 </tr>
 <tr className="p-2">
 <td>Quotation Date </td>
 <td>: </td>
 <td className="quotation_p_name"> {moment(allqoutation?.quotation_date).format("DD-MM-YYYY")}</td>
 </tr>
 <tr className="p-2">
 <td>Validity Date</td>
 <td>: </td>
 <td className="quotation_p_name"> {moment(allqoutation?.quotation_validity).format("DD-MM-YYYY")}</td>
 </tr>


 <tr className="p-2 ">
 <td>Freight type </td>
 <td>: </td>
 <td className="quotation_p_name"> {allqoutation?.fms_v1_freight_types?.freight_type_name}</td>
 </tr>
 <tr className="p-2">
 <td>Payment Terms </td>
 <td>: </td>
 <td className="quotation_p_name">{allqoutation?.fms_v1_payment_terms?.payment_term_name}</td>
 </tr>
 <tr className="p-2">
 <td>No of Pieces </td>
 <td>: </td>
 <td className="quotation_p_name"> {allqoutation?.quotation_no_of_pieces}</td>
 </tr>
 <tr className="p-2">
 <td>Chargeable wt </td>
 <td>: </td>
 <td className="quotation_p_name"> {allqoutation?.quotation_chargeable_wt}</td>
 </tr>
 <tr className="p-2">
 <td>Gross wt </td>
 <td>: </td>
 <td className="quotation_p_name"> {allqoutation?.quotation_gross_wt}</td>
 </tr>

</> }

invoice_details2={
  <>
   <tr className="p-2 ">
 <td>Shipper </td>
 <td>: </td>
 <td className="quotation_p_name">{allqoutation?.quotation_shipper}</td>
 </tr>
 <tr className="p-2">
 <td>Consignee </td>
 <td>: </td>
 <td className="quotation_p_name">   {allqoutation?.crm_v1_leads?.lead_customer_name}</td>
 </tr>
 <tr className="p-2">
 <td>Origin </td>
 <td>: </td>
 <td className="quotation_p_name">{ allqoutation?.fms_v1_locations_fms_v1_quotation_quotation_origin_idTofms_v1_locations.location_name
                    }</td>
 </tr>
 <tr className="p-2">
 <td>Destination </td>
 <td>: </td>
 <td className="quotation_p_name"> {
                      allqoutation
                        ?.fms_v1_locations_fms_v1_quotation_quotation_destination_idTofms_v1_locations
                        .location_name
                    }</td>
 </tr>
 <tr className="p-2">
 <td>Cargo Type </td>
 <td>: </td>
 <td className="quotation_p_name">   {allqoutation?.quotation_cargo_type}</td>
 </tr>
 <tr className="p-2">
 <td>Currency </td>
 <td>: </td>
 <td className="quotation_p_name">  {allqoutation?.generalsettings_v1_currency?.currency_name}</td>
 </tr>
 <tr className="p-2">
 <td>Exchange Rate </td>
 <td>: </td>
 <td className="quotation_p_name"> {allqoutation?.quotation_exchange_rate.toFixed(2)}</td>
 </tr>
 <tr className="p-2">
 <td>UOM </td>
 <td>: </td>
 <td className="quotation_p_name"> {allqoutation?.crm_v1_units?.unit_name}</td>
 </tr>
  </>
}
invoice_table_header={
 
   <>
  <th scope="col"className="font_weight_qt border_right" >#</th>
  <th scope="col" className="font_weight_qt border_right task_width text_align_words">TASKS</th>
  <th scope="col" className="font_weight_qt  border_right text_align_number">COST</th>
  <th scope="col" className="font_weight_qt border_right text_align_words">TAX TYPE</th>
  <th scope="col" className="font_weight_qt border_right text_align_number">TAX AMOUNT</th>
  <th scope="col" className="font_weight_qt text_align_number">TOTAL AMOUNT</th>
  </>

}
invoice_table_data={
  <>
    {tabledata && tabledata.map((itm,indx)=> (
 
 <tr>
   <td  className="border_right">{indx+1} </td>
   <td className="border_right text_align_words">{itm.service_name} </td>
   <td className="border_right text_align_number"> {itm.quotation_details_cost} </td>
   <td className="border_right text_align_words"> {itm.tax_type_name} </td>
   <td className="border_right text_align_number"> {itm.quotation_details_tax_amount} </td>
   <td className="text_align_number">{itm.quotation_details_total} </td>
 </tr>


)) }
  </>
}
amount_in_words=   {
  <>
  {allqoutation && (
    <>
    {camelize(converter.toWords(allqoutation?.quotation_grand_total))}
    </>
  )}
  </>
}


sub_total= {allqoutation?.quotation_grand_total.toFixed(2)}
total= {allqoutation?.quotation_grand_total.toFixed(2)}


/> */}

      {/* <div className=" print-page container ">
      

        <div className="row   ">
          <table className="quotation_border">
            <thead>
             
              <div className="d-flex  justify-content-start align-items-center gap-3 mt-4 py-3 ">
                <div>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${companylogo}`}
                    height={"200px"}
                    width={"190px"}
                    className="imgquotation"
                  />
                </div>
                <div className="mt-4">
                  <h5 className="headcolorquot ">{companyname} </h5>
                  <div className="">
                    <label>{companyaddress} </label>
                  </div>
                  <div className="">
                    <label>{companycountry} </label>
                  </div>
                  <div className="">
                    <label>{companyphone} </label>
                  </div>
                  <div className="">
                    <label>{companyemail}</label>
                  </div>
                </div>
              </div>
            </thead>


            <div className=" row mt-4 m-0 p-0  quotation_border_bottom">
              <h5 className="headcolorquot d-flex justify-content-center  mb-4">
                QUOTATION
              </h5>
              <div className="col-6 d-flex">
                <div className="col-4">Quotation No</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.quotation_no}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Shipper</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.quotation_shipper}
                  </p>
                </div>
              </div>
              <div className="col-6 d-flex">
                <div className="col-4">Quotation Date</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data  quotation_p_name">
                    {moment(allqoutation?.quotation_date).format("DD-MM-YYYY")}
                  </p>
                </div>
              </div>
              <div className="col-6 d-flex">
                <div className="col-4">Consignee</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.crm_v1_leads?.lead_customer_name}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Quotation validity </div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {moment(allqoutation?.quotation_validity).format(
                      "DD-MM-YYYY"
                    )}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Origin</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {
                      allqoutation
                        ?.fms_v1_locations_fms_v1_quotation_quotation_origin_idTofms_v1_locations
                        .location_name
                    }
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Freight type</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.fms_v1_freight_types?.freight_type_name}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Destination</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {
                      allqoutation
                        ?.fms_v1_locations_fms_v1_quotation_quotation_destination_idTofms_v1_locations
                        .location_name
                    }
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Payment Terms</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.fms_v1_payment_terms?.payment_term_name}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Cargo Type</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.quotation_cargo_type}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">No of pieces </div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.quotation_no_of_pieces}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Currency </div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.generalsettings_v1_currency?.currency_name}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Chargeable wt </div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.quotation_chargeable_wt}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Exchange Rate </div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.quotation_exchange_rate.toFixed(2)}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">Gross wt </div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.quotation_gross_wt}
                  </p>
                </div>
              </div>

              <div className="col-6 d-flex">
                <div className="col-4">UOM</div>
                <div className="col-1">:</div>

                <div className="col-7">
                  <p className="modal-view-data quotation_p_name">
                    {allqoutation?.crm_v1_units?.unit_name}
                  </p>
                </div>
              </div>
            </div>




            <div className="row m-0 p-0 quotation_border_bottom">
              <p className="font_weight_qt">Bill To</p>
            </div>
            <div className="quotation_border_bottom">
              <h6 className="quotation_p_name p-1">France & Middle East</h6>
            </div>

            <div className="p-0 m-0">
             

              <table class="table   p-0 m-0 mb-0">
                <thead className="">
                  <tr className="tr_bgcolor">
                    <th scope="col" className="font_weight_qt border_right">
                      #
                    </th>
                    <th
                      scope="col"
                      className="font_weight_qt border_right task_width text_align_words"
                    >
                      TASKS
                    </th>
                    <th
                      scope="col"
                      className="font_weight_qt  border_right text_align_number"
                    >
                      COST
                    </th>
                    <th
                      scope="col"
                      className="font_weight_qt border_right text_align_words"
                    >
                      TAX TYPE
                    </th>
                    <th
                      scope="col"
                      className="font_weight_qt border_right text_align_number"
                    >
                      TAX AMOUNT
                    </th>
                    <th
                      scope="col"
                      className="font_weight_qt text_align_number"
                    >
                      TOTAL AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tabledata &&
                    tabledata.map((itm, indx) => (
                      <tr>
                        <th scope="row" className="border_right">
                          {indx + 1}{" "}
                        </th>
                        <th className="border_right text_align_words">
                          {itm.service_name}{" "}
                        </th>
                        <th className="border_right text_align_number">
                          {itm.quotation_details_cost}{" "}
                        </th>
                        <th className="border_right text_align_words">
                          {itm.tax_type_name}{" "}
                        </th>
                        <th className="border_right text_align_number">
                          {itm.quotation_details_tax_amount}{" "}
                        </th>
                        <th className="text_align_number">
                          {itm.quotation_details_total}{" "}
                        </th>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>


            <div className="row mt-2 p-2 py-5">
              <div className="col-6">
                <p> Total in Words</p>
              </div>
              <div className="col-6  ">
                <div className="row">
                  <div className="col-4  "></div>
                  <div className="col-4  ">
                
                  </div>
                  <div className="col-4 ">
            
                  </div>
                </div>
              </div>
            </div>

            <div className="row  p-2">
              <div className="col-6">
                {allqoutation && (
                  <p className="font_weight_qt">
                    {converter.toWords(allqoutation?.quotation_grand_total)}{" "}
                  </p>
                )}
              </div>
              <div className="col-6  ">
                <div className="row">
                  <div className="col-4  "></div>
                  <div className="col-4  ">
                    <p className="quotation_p_name">Total</p>
                  </div>
                  <div className="col-4 ">
                    <p className="text_align_number d-flex justify-content-end">
                      <p>
                       
                        {
                          allqoutation?.generalsettings_v1_currency
                            ?.currency_code
                        }
                      </p>
                      &nbsp;
                      {allqoutation?.quotation_grand_total.toFixed(2)}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
          

          </table>
        </div>
      </div> */}
    </>
  );
}
export default Quotationinvoice;
