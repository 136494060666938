import React, { useEffect, useState } from "react";
import "./Receiptprint2.scss";
import { GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import PublicFetch from "../../../utils/PublicFetch";

function ReceiptPrinttemp2({
  print_details1,
  print_details2,
  invoiceHeaderImg,
  headingName,
  voucherno,
  receiptdate,
  amountreceivedNumber,
  paymentmode,
  customername,
  amountInWords
}) {
  const [companyInfodata, setCompanyInfodata] = useState();
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [clocation, setclocation] = useState();
  const [arrivalNotAddress, setArrivalNotAddress] = useState();
  const [companyPhone, setCompanyPhone] = useState();
  const [companyEmail, setCompanyEmail] = useState();
  const [companyWebsite, setCompanyWebsite] = useState();

  // const [temp1,settemp1] =useState()

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
          setclocation(res.data.data[0]?.company_location);
          setArrivalNotAddress(res.data.data[0]?.company_address);
          setCompanyPhone(res.data.data[0]?.company_phone);
          setCompanyEmail(res.data.data[0]?.company_email);
          setCompanyWebsite(res.data.data[0]?.company_website);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    companyinfo();
    // getfmssettings()
  }, []);

  return (
    <div>
      <div>
        <div id="pageborderReceipttemp2"></div>

        <table className="">
          <>
            {companyInfodata &&
              companyInfodata.length > 0 &&
              companyInfodata?.map((item, index) => {
                return (
                  <thead className="">
                    <tr className="receipt_header2">
                      <div className="">
                        <div className="header__address_logo2 d-flex justify-content-center">
                          <img
                            // src={`${process.env.REACT_APP_BASE_URL}/${item.company_logo}`}
                            // style={{ height: "120px", width: "650px" }}
                            src={`${process.env.REACT_APP_BASE_URL}/${companyInfodata[0]?.company_quotation_header}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </tr>
                  </thead>
                );
              })}
          </>

          <div className="d-flex justify-content-center">
            <h5 style={{ fontWeight: "bold" }}>BANK RECEIPT VOUCHER</h5>
          </div>

          <div className="d-flex justify-content-end gap-2 px-4">
            <div
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                fontWeight: "bold",
              }}
            >
              BRV
            </div>
            <div className="vnumber_col_height">
              <span
                className="d-flex justify-content-center "
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
              >
                {voucherno}
              </span>
            </div>
          </div>
          <br></br>
          <div className="d-flex justify-content-end gap-2 px-4">
            <div
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                fontWeight: "bold",
              }}
            >
              DATE
            </div>
            <div className="vnumber_col_height">
              <span
                className="d-flex justify-content-center "
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
              >
                {receiptdate}
              </span>
            </div>
          </div>

          <br></br>
          <br></br>
          <div className="d-flex justify-content-start gap-4 px-3">
            <div
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                width: "180px",
              }}
            >
              <span style={{ fontWeight: "bold" }}> AMOUNT RECEIVED</span>
            </div>
            <div className="vnumber_col_height">
              <span
                className="d-flex justify-content-center "
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
              >
              {amountreceivedNumber}
              </span>
            </div>
          </div>
          <br></br>
          <div className="content_receipt_print px-2">
            <div className="receipt_print_details ">
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>RECEIVED FROM</td>

                    <td>{customername} </td>
                  </tr>
                  <tr>
                    <td>AMOUNT IN WORDS</td>

                    <td>{amountInWords} </td>
                  </tr>
                  <tr>
                    <td>PAYMENT MODE</td>

                    <td>{paymentmode} </td>
                  </tr>
                  <tr>
                    <td>BEING</td>

                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="content_receipttemp2_sectionpaymentDetails px-2">
            <div className="content_receipttemp2_paydetails_part1">
              <table>
                <tbody>
                  {print_details2}
                </tbody>
              </table>
            </div>
          </div>

          <footer
            style={{
              backgroundColor: "white",
              zIndex: 999,
              position: "fixed",
              // marginTop:'700px',

              bottom: 0,
              // border: "1px solid black",
              width: "100%",
              height: "9%",
              marginBottom: 0,

              borderTop: "1px solid #e4dada",
            }}
          >
            <p
              style={{
                paddingTop: "10px",
                paddingLeft: "10px",
                lineHeight: ".5",
                textAlign: "center",
                fontWeight: 400,
              }}
              dangerouslySetInnerHTML={{
                __html: `${arrivalNotAddress}`,
              }}
            ></p>
            {/* {arrivalNotAddress} */}

            <div className="col-12 d-flex justify-content-center ">
              <p className="p-0 m-0">
                Phone :{companyPhone} &nbsp; Email :{companyEmail}&nbsp;
              </p>
            </div>
            <div className="col-12 d-flex justify-content-center">
              Website :{companyWebsite}
            </div>
          </footer>
        </table>
      </div>
    </div>
  );
}

export default ReceiptPrinttemp2;
