import React, { useState, useEffect } from "react";
import "./layout.scss";
import { AiOutlineMenu } from "react-icons/ai";
import Header from "../components/Header/header";
import Sidebar from "../components/Sidebar/sidebar";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "../components/button/button";
import ROUTES from "../routes";
import { checkPermission, checkSubPermission } from "../utils/check_permission";
import {
  useFormActionTrigger,
  useUnsavedChanges,
} from "../utils/UnsavedChangesProvider";
import { message } from "antd";
import CustomModel from "../components/custom_modal/custom_model";

export default function Layout(props) {
  const { children } = props;
  const { unsavedChanges, setUnsavedChangesStatus } = useUnsavedChanges();
  const { formActionTrigger, setFormActionTriggerStatus } =
    useFormActionTrigger();

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const path = location.pathname;
  console.log("location path", path);
  const [showMenu, setShowMenu] = useState(true);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 550px)").matches
  );
  const [warningModal, setWarningModal] = useState(false);
  const [clickedRoute, setClickedRoute] = useState();
  const [ClickedMenu, setClickedMenu] = useState();
  const [KeyLeftClick, setKeyLeftClick] = useState(false);
  const [KeyRightClick, setKeyRightClick] = useState(false);
  const [clickedItem, setClickedItem] = useState();
  const [isDashboard, setIsDashboard] = useState(false);

  const subMenuData = [
    {
      id: 1,
      name: "DASHBOARD",
      pass: "dashboard",
      route: ROUTES.DASHBOARD_CRM,
    },
    {
      id: 2,
      name: "CRM",
      pass: "crm",
      route: ROUTES.CRM_LEADLIST,
    },
    {
      id: 3,
      name: "HRMS",
      pass: "hrms",
      route: ROUTES.ROLES_SCREEN,
    },
    // {
    //   id: 4,
    //   name: "SERVICE",
    //   pass: "service",
    // },
    {
      id: 5,
      name: "OPERATIONS",
      pass: "operations",
      route: ROUTES.LIST_JOB_BOOKING,
    },

    {
      id: 6,
      name: "PURCHASE",
      pass: "purchase",
      route: ROUTES.LIST_RFQ,
    },
    {
      id: 7,
      name: "ACCOUNTS",
      pass: "accounts",
      route: ROUTES.DAILY_EXPENSE,
    },
    {
      id: 8,
      name: "REPORTS",
      pass: "reports",
      // route: ROUTES.AGENT_REPORT,
      route:ROUTES.ENQUIRY_REPORT
    },
    {
      id: 9,
      name: "MASTERS",
      pass: "masters",
      route: ROUTES.SERVICES,
    },
    {
      id: 10,
      name: "SETTINGS",
      pass: "settings",
      route: ROUTES.COMPANYINFO,
    },
  ];

  const SubMenu = () => {
    return (
      <>
        <div
          // style={{ backgroundColor: "whitesmoke" }}
          className="container-fluid w-100 shadow-sm py-2"
        >
          <div className="row justify-content-center">
            <div className="col-12  d-flex justify-content-center">
              {subMenuData &&
                subMenuData.length > 0 &&
                subMenuData.map((item, index) => {
                  return (
                    <>
                      {unsavedChanges === true ? (
                        <NavLink
                          className={
                            item?.pass === pathname[1]
                              ? "active_button"
                              : "button_nav"
                          }
                          // to={item?.route}
                          onClick={() => {
                            handleClickMenuFormModelShow(item?.route);
                          }}
                        >
                          {item?.name}
                        </NavLink>
                      ) : (
                        <NavLink
                          className={
                            item?.pass === pathname[1]
                              ? "active_button"
                              : "button_nav"
                          }
                          to={item?.route}
                        >
                          {item?.name}
                        </NavLink>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    );
  };

  console.log("clicked menus", ClickedMenu);

  const handleClickMenuFormModelShow = (path) => {
    setWarningModal(true);
    setClickedRoute(path);
  };

  const handleModelLeaveTrigger = () => {
    setWarningModal(false);
    setUnsavedChangesStatus(false);
    // if (KeyLeftClick === true) {
    //   navigate(-1);
    // } else if (KeyRightClick === true) {
    //   navigate(+1);
    // } else {
    navigate(clickedRoute);
    // }
  };

  const handleClickSubMenu = (item) => {
    localStorage.setItem("MenuClicked", item.pass);

    if (item.pass === "dashboard") {
      setIsDashboard(true);
      setClickedMenu(item.pass);
      setShowMenu(true);
      if (item.pass == "dashboard" && pathname[1] !== "dashboard") {
        if (checkPermission("dashboard")) {
          if (checkSubPermission("dashboard_crm")) {
            console.log("Item passed", item);
            navigate(`${ROUTES.DASHBOARD_CRM}`);
            setClickedMenu(pathname[1]);
            setShowMenu(true);
          } else {
            navigate(`${ROUTES.PERMISSIONDEN}`);
          }
        }
      }
      localStorage.setItem("MenuClicked", item.pass);
    } else if (item.pass == "crm") {
      if (checkPermission("dashboard")) {
        if (checkSubPermission("dashboard_crm")) {
          navigate(`${ROUTES.CRM_LEADLIST}`);
          setShowMenu(true);
          setClickedMenu(pathname[1]);
          localStorage.setItem("MenuClicked", item.pass);
          let a = item.pass;
        } else {
          navigate(`${ROUTES.PERMISSIONDEN}`);
        }

        console.log("hi crm", checkSubPermission("dashboard_crm"));
      }
      // nameToPass(a);
    } else if (item.pass == "hrms") {
      navigate(`${ROUTES.ROLES_SCREEN}`);
      setShowMenu(true);
      setClickedMenu(pathname[1]);
      let a = item.pass;
      // nameToPass(a);
      console.log("hi hrm");

      localStorage.setItem("MenuClicked", item.pass);
    } else if (item.pass == "operations") {
      navigate(`${ROUTES.LIST_JOB_BOOKING}`);
      setShowMenu(true);
      setClickedMenu(pathname[1]);
      let a = item.pass;
      // nameToPass(a);
      localStorage.setItem("MenuClicked", item.pass);
    } else if (item.pass == "purchase") {
      navigate(`${ROUTES.LIST_RFQ}`);
      setShowMenu(true);
      setClickedMenu(pathname[1]);
      let a = item.pass;
      // nameToPass(a);
      localStorage.setItem("MenuClicked", item.pass);
    } else if (item.pass == "masters") {
      navigate(`${ROUTES.SERVICES}`);
      setShowMenu(true);
      setClickedMenu(pathname[1]);
      let a = item.pass;
      // nameToPass(a);
      localStorage.setItem("MenuClicked", item.pass);
    } else if (item.pass == "settings") {
      navigate(`${ROUTES.COMPANYINFO}`);
      setShowMenu(true);
      setClickedMenu(pathname[1]);
      let a = item.pass;
      // nameToPass(a);
      localStorage.setItem("MenuClicked", item.pass);
    } else if (item.pass == "accounts") {
      navigate(`${ROUTES.DAILY_EXPENSE}`);
      setShowMenu(true);
      setClickedMenu(pathname[1]);
      let a = item.pass;
      // nameToPass(a);
      localStorage.setItem("MenuClicked", item.pass);
    } else if (item.pass == "reports") {
      navigate(`${ROUTES.AGENT_REPORT}`);
      setShowMenu(true);
      setClickedMenu(pathname[1]);
      let a = item.pass;
      // nameToPass(a);
      localStorage.setItem("MenuClicked", item.pass);
    } else if (item.pass == "dashboardnew") {
      navigate(`${ROUTES.DASHBOARDNEW}`);
      setShowMenu(true);
      setClickedMenu(pathname[1]);
      let a = item.pass;
    }
    setClickedItem(item.pass);
  };

  useEffect(() => {
    let a = localStorage.getItem("MenuClicked");
    setClickedItem(pathname[1]);
    setClickedMenu(pathname[1]);
  }, [localStorage.getItem("MenuClicked"), pathname[1]]);

  useEffect(() => {
    if (pathname[1] == "dashboard") {
      setClickedItem("dashboard");
      setShowMenu(true);
      // navigate(`${ROUTES.DASHBOARD_CRM}`);
      setClickedMenu("dashboard");
      // setShowMenu(false);
    } else if (pathname[1] === "permission_denined") {
      setClickedMenu("dashboard");
      setShowMenu(true);
      setClickedItem("dashboard");
    }

    window
      .matchMedia("(min-width: 550px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    const handlePopstate = (event) => {
      console.log("Popstate event detected", event);
      setWarningModal(true);
      setKeyLeftClick(true);
    };

    const handleBeforeUnload = (event) => {
      console.log("Beforeunload event detected");
      event.returnValue = ""; // Standard for most browsers, but not all
      setWarningModal(true);
      setKeyLeftClick(true);
    };

    const handleKeyDown = (event) => {
      if (event.altKey) {
        if (event.code === "ArrowLeft") {
          // setWarningModal(true);
          // setKeyLeftClick(true);
        } else if (event.code === "ArrowRight") {
          // setWarningModal(true);
          // setKeyRightClick(true);
        } else {
          // setKeyLeftClick(false);
          // setKeyRightClick(false);
        }
      }
    };

    if (unsavedChanges === true) {
      window.addEventListener("popstate", handlePopstate);
      window.addEventListener("beforeunload", handleBeforeUnload);
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("popstate", handlePopstate);
        window.removeEventListener("beforeunload", handleBeforeUnload);
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [unsavedChanges]);

  useEffect(() => {
    if (
      path !== "/reports/shipment_status_report" &&
      path !== "/reports/shipment_status_report/print_shipmentstatus_report"
    ) {
      localStorage.removeItem("JobDataId");
      localStorage.removeItem("BookingDataId");
    }
  }, [path]);

  console.log("Log for page re rendering", 123);
  return (
    <>
      <div className="m-0 p-0 ">
        <div className="row p-0 m-0 header_layout">
          <div className="col-12 m-0 ">
            <Header />
            <SubMenu />
          </div>
        </div>
        <div className=" screen_orientation children_style ">
          <div className="d-flex ">
            <div
              className={` ${
                showMenu
                  ? "sidebarWrapper sideBarPrint  "
                  : "d-none sidebarclose sideBarPrint"
              }  `}
            >
              <Sidebar clickedMenu={ClickedMenu} />
            </div>
            <div
              className={` ${
                showMenu ? "contentWrapper content-scroll" : "col-12 mt-2"
              }`}
            >
              {children}
            </div>
          </div>
        </div>
        <CustomModel
          show={warningModal}
          onHide={() => {
            setWarningModal(false);
          }}
          View_list={true}
          list_content={
            <>
              <div className="">
                <div className="row p-2">
                  <div className="col-12">
                    <h4>Leave this Page ?</h4>
                  </div>
                  <div className="col-12">
                    <p style={{ fontWeight: "600" }}>
                      You have Unsaved changes on this Page, Are you sure you
                      want to leave ?
                    </p>
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center gap-2">
                    <div className="">
                      <Button
                        btnType="cancel"
                        type="button"
                        onClick={() => {
                          setWarningModal(false);
                        }}
                      >
                        Stay
                      </Button>
                    </div>
                    <div className="">
                      <Button
                        btnType="save"
                        type="button"
                        onClick={() => handleModelLeaveTrigger()}
                      >
                        Leave
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
