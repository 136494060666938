import { useEffect, useState } from "react";
import PublicFetch from "../../utils/PublicFetch";
import { GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
import "./shipstatusreport.scss";

function ShipStatusReportPrint({
  shipment_table_data1,
  shipment_table_data2,
  quotationHeaderImg,
  shipmentStatusBookingData,
  shipmentStatusJobData,
  bookCustomerName,
  bookTodayDate,
  jobCustomerName,
  jobTodayDate,
}) {
  const [companyInfodata, setCompanyInfodata] = useState();

  useEffect(() => {
    const printStyle =
      '<style type="text/css" media="print">@page { size: A4 landscape !important; }</style>'
      document.head.insertAdjacentHTML('afterbegin',printStyle)
  }, []);

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    companyinfo();
    if (companyInfodata) {
      window.open("", "_blank");
    }
  }, []);

  return (
    <>
      <div className="Shipmentlandscape">
        {/* div to set page border on all pages */}
        <div id="pageborder_shipment"></div>
        {/* page border div ends */}
        <table className="shipment_header ">
          {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => {
              return (
                <thead className="">
                  <tr className="">
                    <div className="quotheader">
                      {/* <div className="header__address_wrapper"> */}
                      <div className="">
                        <img
                          style={{ height: "150px", width: "700px" }}
                          // src={`${process.env.REACT_APP_BASE_URL}/${item.company_quotation_header}`}
                          src={quotationHeaderImg}
                          alt=""
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </tr>
                </thead>
              );
            })}
          <tbody>
            <div className="text-center ">
              <h2 className="quotation_heading_style">
                SHIPMENT STATUS REPORT
              </h2>
            </div>
            <br></br>
            {shipmentStatusBookingData &&
            shipmentStatusBookingData?.length > 0 ? (
              <div className="d-flex justify-content-between px-2">
                <h5>Customer: {bookCustomerName} </h5>
                <h5>Date: {bookTodayDate} </h5>
              </div>
            ) : (
              ""
            )}

            {shipmentStatusBookingData &&
            shipmentStatusBookingData?.length > 0 ? (
              <>
                <h5 style={{ fontWeight: 600, padding: "10px" }}>Booking</h5>

                <table className="print_table " cellSpacing={0}>
                  <thead className="print_table ">
                    <tr className="">
                      <th className="">SL.NO</th>
                      <th
                        className=""
                        style={{ textAlign: "left",  }}
                      >
                        REF NO
                      </th>
                      <th className="" style={{ textAlign: "left" }}>
                        SHIPPER
                      </th>
                      <th className="" style={{ textAlign: "left" }}>
                        VOLUME
                      </th>
                      <th
                        className="tsk_exp"
                        style={{ textAlign: "left",  }}
                      >
                        BL/AWB No
                      </th>
                      <th
                        className="tsk_exp"
                        style={{ textAlign: "left",  }}
                      >
                        POL
                      </th>
                      <th
                        className="tsk_exp"
                        style={{ textAlign: "left",  }}
                      >
                        POD
                      </th>
                      <th className="" style={{ textAlign: "left" }}>
                        CONTAINER
                      </th>
                      <th className="th" style={{ textAlign: "left" }}>
                        ETD
                      </th>
                      <th className="th" style={{ textAlign: "left" }}>
                        ETA
                      </th>
                      <th style={{ textAlign: "left",  }}>
                        REMARKS
                      </th>
                      <th style={{ textAlign: "left",  }}>
                        SHIPMENT REF NO
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">{shipment_table_data1}</tbody>
                </table>
              </>
            ) : (
              ""
            )}
            {/* <div className="print_content"></div> */}

            <br></br>
            <br></br>
            {shipmentStatusJobData && shipmentStatusJobData?.length > 0 ? (
              <div className="d-flex justify-content-between px-2">
                <h5>Customer: {jobCustomerName} </h5>
                <h5>Date:{jobTodayDate} </h5>
              </div>
            ) : (
              ""
            )}
            {shipmentStatusJobData && shipmentStatusJobData?.length > 0 ? (
              <>
                <h5 style={{ fontWeight: 600, padding: "10px" }}>Job</h5>
                <table className="print_table " cellSpacing={0}>
                  <thead className="print_table ">
                    <tr className="">
                      <th className="">Sl.No</th>
                      <th
                        className="th"
                        style={{ textAlign: "left",  }}
                      >
                        REF No
                      </th>
                      <th className="th" style={{ textAlign: "left" }}>
                        SHIPPER
                      </th>
                      <th className="th" style={{ textAlign: "left" }}>
                        VOLUME
                      </th>
                      <th
                        className="tsk_exp"
                        style={{ textAlign: "left",  }}
                      >
                        BL/AWB No
                      </th>
                      <th
                        className="tsk_exp"
                        style={{ textAlign: "left", }}
                      >
                        POL
                      </th>
                      <th
                        className="tsk_exp"
                        style={{ textAlign: "left",  }}
                      >
                        POD
                      </th>
                      <th className="th" style={{ textAlign: "left" }}>
                        CONTAINER
                      </th>
                      <th className="th" style={{ textAlign: "left" }}>
                        ETD
                      </th>
                      <th className="th" style={{ textAlign: "left" }}>
                        ETA
                      </th>
                      <th style={{ textAlign: "left",  }}>
                        REMARKS
                      </th>
                      <th style={{ textAlign: "left",  }}>
                        SHIPMENT REF NO
                      </th>
                    </tr>
                  </thead>
                  <tbody className="" style={{ marginBottom: "2vh" }}>
                    {shipment_table_data2}
                  </tbody>
                </table>
              </>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default ShipStatusReportPrint;
