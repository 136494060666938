import { useEffect, useState } from "react";
// import PublicFetch from "../../../utils/PublicFetch";
import styles from "./suppliercreditnote.module.scss";
import PublicFetch from "../../utils/PublicFetch";
import { GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
// import { GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
// import Quotfooter from "../../../assets/logo/cargoquot.png";

function SupplierNotePrint({
  invoice_details1,
  invoice_details2,
  
  showGrandTotal,
  quot_table_data,
  // setQuotHeaderSuccess,
  quotationHeaderimg,
  supplierCreditNote= false,
  supplier_table_data,
  creditNoteData= false,
  creditnoteTableData,
  debitNoteData=false,
  debitNoteTableData,
  totalFxAmt,
  totalLxAmt,

}) {
  console.log("showGrandTotal", showGrandTotal);
  const [companyInfodata, setCompanyInfodata] = useState();
  // const [quotHeaderSuccess,setQuotHeaderSuccess] = useState(false)
  console.log("headerImage", quotationHeaderimg);
  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
          // setQuotHeaderSuccess(true)
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    companyinfo();
  }, []);

  // useEffect(() => {
  //   // Function to apply dynamic print styles based on orientation
  //   const applyPrintStyles = (orientation) => {
  //     const styleSheet = document.styleSheets[28];
  //     styleSheet.crossorigin = "anonymous";
  //     console.log("stylesSheets", styleSheet);
  //     const rules = styleSheet.cssRules || styleSheet.rules;

  //     for (const rule of rules) {
  //       if (rule.selectorText === `@page `) {
  //         styleSheet.deleteRule(rules.indexOf(rule));
  //         styleSheet.insertRule(
  //           `@page { size: ${orientation}; margin: 1cm; }`,
  //           rules.length
  //         );
  //         break;
  //       }
  //     }
  //   };

  //   // Function to print with specified orientation
  //   const printWithOrientation = (orientation) => {
  //     applyPrintStyles(orientation);
  //   };

  //   // Example: print with portrait orientation
  //   printWithOrientation("landscape");
  // }, []); // Run this effect once on component mount

  return (
    <>
      <div className={styles.portrait_page} style={{}}>
        {/* div to set page border on all pages */}
        <div id={styles.pageborder_Qtn}></div>
        {/* page border div ends */}
        <table className=" ">
          {/* {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => {
              return ( */}
          <thead className={styles.quotation_header}>
            <tr className={styles.quotation_header}>
              <div className={styles.quotheader}>
                {/* <div className="header__address_wrapper"> */}
                <div className="">
                  {quotationHeaderimg && (
                    <img
                      style={{ height: "150px", width: "700px" }}
                      src={quotationHeaderimg}
                      alt="e"
                    />
                  )}
                  {/* <img
                          style={{ height: "150px", width: "700px" }}
                          src={`${process.env.REACT_APP_BASE_URL}/${item.company_quotation_header}`}
                          alt=""
                        /> */}
                </div>
                {/* </div> */}
              </div>
            </tr>
          </thead>
          {/* );
            })} */}
          <tbody>
            <div className="text-center ">
              <h2 className={styles.quotation_heading_style}>SUPPLIERCREDIT NOTES</h2>
            </div>

            <div className={styles.invoice_quot_details}>
              <div
                className={`${styles.invoice_quot_details__col} ${styles.invoice_quot_details__col_1} py-2`}
              >
                <table>
                  <tbody>{invoice_details1}</tbody>
                </table>
              </div>
              <div
                className={`${styles.invoice_quot_details__col} ${styles.invoice_details__col_2} py-2`}
              >
                <table>
                  <tbody>{invoice_details2}</tbody>
                </table>
              </div>
            </div>

            {/* <div
              className={`${styles.invoice_detailsquot}`}
              style={{ borderBottom: "1px solid black" }}
            >
              <div
                className={`${styles.invoice_details__quot}  ${styles.invoice_details__quot_col} py-2`}
              >
                <table>
                  <tbody>{invoice_details5}</tbody>
                </table>
              </div>
            </div> */}

            {/* new changes */}
            {/* <div className="">{quot_table_data} </div> */}
            
            {/* {grandTotal} */}

            {supplierCreditNote && (
            <table className={`${styles.print_supplier_table}`} cellSpacing={0}>
              <thead className={`${styles.print_supplier_table}`}>
                <tr className="">
                  <th className="">Sl.No</th>
                  <th className="" style={{ textAlign: "left" }}>
                  LEDGER
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                 PURCHASE BILL NO
                  </th>
                  <th className="" style={{ textAlign: "right" }}>
                  AMOUNT FX
                  </th>
                  <th className="" style={{ textAlign: "right" }}>
                  AMOUNT LX
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                   REMARKS
                  </th>
                </tr>
              </thead>
              <tbody className="">{supplier_table_data}</tbody>
            </table>
            )}

{creditNoteData && (
            <table className={`${styles.print_supplier_table}`} cellSpacing={0}>
              <thead className={`${styles.print_supplier_table}`}>
                <tr className="">
                  <th className="">Sl.No</th>
                  <th className="" style={{ textAlign: "left" }}>
                  LEDGER
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                 INOVOICE NO
                  </th>
                  <th className="" style={{ textAlign: "right" }}>
                  AMOUNT FX
                  </th>
                  <th className="" style={{ textAlign: "right" }}>
                  AMOUNT LX
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                   REMARKS
                  </th>
                </tr>
              </thead>
              <tbody className="">{creditnoteTableData}</tbody>
            </table>
            )}
            
            {debitNoteData && (
              <table className={`${styles.print_supplier_table}`} cellSpacing={0}>
                <thead className={`${styles.print_supplier_table}`}>
                  <tr className="">
                    <th className="">Sl.No</th>
                    <th className="" style={{ textAlign: "left" }}>
                    LEDGER
                    </th>
                    <th className="" style={{ textAlign: "left" }}>
                   BILL NO
                    </th>
                    <th className="" style={{ textAlign: "right" }}>
                    AMOUNT FX
                    </th>
                    <th className="" style={{ textAlign: "right" }}>
                    AMOUNT LX
                    </th>
                    <th className="" style={{ textAlign: "left" }}>
                     REMARKS
                    </th>
                  </tr>
                </thead>
                <tbody className="">{debitNoteTableData}</tbody>
              </table>
              )}
              <div className="d-flex justify-content-end p-2 gap-2">
              <span>Total Fx  :<span>{totalFxAmt} </span> </span>
              <span>Total Lx  : <span>{totalLxAmt} </span></span>
              </div>


          </tbody>
        </table>
      </div>
    </>
  );
}
export default SupplierNotePrint;
