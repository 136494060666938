import React, { useEffect, useState } from "react";
import PrintReports from "../../../../components/Reports-Print/Printreport";
import { useSearchParams } from "react-router-dom";
import PublicFetch from "../../../../utils/PublicFetch";
import { CRM_BASE_URL_FMS } from "../../../../api/bootapi";
import moment from "moment";

function InvoicereportPrint() {
  const [searchParams] = useSearchParams();

  const [invoiceId, setInvoiceId] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [jobId, setJobId] = useState();
  const [allReports,setAllReports]= useState()

  console.log("searchParaminvoiceid", searchParams.get("invoiceid"));
  console.log("searchParamdatefrm", searchParams.get("datefrom"));
  console.log("searchParamdateto", searchParams.get("dateto"));
  console.log("searchParamjobid", searchParams.get("jobid"));

  useEffect(() => {
    setInvoiceId(searchParams.get("invoiceid"));
    setJobId(searchParams.get("jobid"));
    setDateFrom(searchParams.get("datefrom"));
    setDateTo(searchParams.get("dateto"));
    if(dateFrom || dateTo){
        SearchinvoiceReport()
    }
  }, [dateFrom, dateTo]);


  const SearchinvoiceReport = () => {
    let date_f = "";
    let date_t = "";
    if (dateFrom) {
      date_f = moment(dateFrom);
    }
    if (dateTo) {
      date_t = moment(dateTo);
    }

    let data1 = {
      invoice_no: invoiceId,
      date_from: date_f,
      date_to: date_t,
      job_no: jobId,
    };
  

    PublicFetch.post(`${CRM_BASE_URL_FMS}/reports/invoice-report`, data1)
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of response", res.data.data);
          let temp = [];
          res.data.data.forEach((item, index) => {
            temp.push({
              slno: index + 1,
              invoice_no: item.invoice_no,
              invoice_date: moment(item.invoice_date).format("DD-MM-YYYY"),
              job_no: item.job_no,
              customer: item?.customer?.customer_name,
              currency: item?.currency?.currency_name,
              cost_fx: item.cost_fx.toFixed(2),
              const_lx: item.const_lx.toFixed(2),
            });
          });
          setAllReports(temp);
          handleprint()
          // console.log("deu", temp);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleprint=()=>{
    setTimeout(() => {
      window.print()
    }, 1000);
  }

  return (
    <div>
      <PrintReports
        report_name={"INVOICE REPORT"}
        table_header_data={
          <>
            <tr className="">
              <th className="head_border" style={{ textAlign: "left" }}>
                SL NO
              </th>
              <th className="" style={{ textAlign: "left" }}>
                INVOICE NO
              </th>
              <th className="" style={{ textAlign: "left" }}>
                DATE
              </th>
              <th className="tsk_exp" style={{ textAlign: "left" }}>
                JOB NO
              </th>
              <th className="" style={{ textAlign: "left" }}>
                CUSTOMER
              </th>
              <th className="" style={{ textAlign: "left" }}>
                CURRENCY
              </th>
              <th style={{ textAlign: "left" }}> TOTAL COST(Fx) </th>
              <th style={{ textAlign: "left" }}> TOTAL COST(Lx) </th>
            </tr>
          </>
        }
        table_print_data={
            <>
            {allReports &&
              allReports?.map((item, indx) => {
                console.log("dattata",item);
                return (
                  <tr>
                    <td>{indx + 1} </td>
                    <td>{item?.invoice_no} </td>
                    <td>{item?.invoice_date} </td>
                    <td>{item?.job_no} </td>
                    <td>{item?.customer} </td>
                    <td>{item?.currency} </td>
                    <td>{item?.cost_fx} </td>
                    <td>{item?.const_lx} </td>
                  </tr>
                );
              })}
          </>
        }
      />
    </div>
  );
}

export default InvoicereportPrint;
