import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import PublicFetch from "../../utils/PublicFetch";
import { CRM_BASE_URL_FMS, CRM_BASE_URL_HRMS } from "../../api/bootapi";
import logo from "../../assets/logo/cargoplusnew.png";
import { useEffect, useState } from "react";
import moment from "moment";


const RFQPdf = ({ rfq,}) => {
  const personName = localStorage.getItem("UserID");
  const [empname, setEmpname] = useState();
  console.log("personName of loged in", empname);
  useEffect(() => {
    if (personName) {
      getOneEmployee();
    }
  }, [personName]);

  const getOneEmployee = () => {
    PublicFetch.get(`${CRM_BASE_URL_HRMS}/employees/${personName}`)
      .then((res) => {
        console.log("Response of employee", res,rfq);
        if (res.data.success) {
          console.log("Success of employee", res.data.data);
          //   setTotalcount(res?.data?.data?.totalCount);
          //   setstartcount(res?.data?.data?.startIndex);

          setEmpname(res?.data?.data?.employee_name);
          //   console.log(" newww array data ::", array);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  console.log("user id of employee log in ", personName);
  const styles = StyleSheet.create({
    main: {
      margin: 20,
      border: "1px solid black",
      height: "95%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "center",
    },
    header_text: {
      fontSize: 30,
      marginTop: 20,
      textDecoration: "underline",
    },
    subHeader_text: {
      fontSize: 15,
      marginTop: 40,
      textDecoration: "underline",
    },
    page: {
      //   border: "3px solid blue",
      flexDirection: "column",
    },
    headerRfq: {
      marginLeft: 5,
      marginTop: 50,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerRfq_left: {
      gap: 8,
      width: "40%",
      fontSize: 12,
      flexDirection: "column",
      alignContent: "flex-start",
    },
    headerRfq_right: {
      gap: 8,
      width: "40%",
      fontSize: 12,
      flexDirection: "column",
      alignContent: "flex-start",
    },
    tasks: {
      marginTop: 30,
      marginLeft: 20,
      fontSize: 12,
      flexDirection: "column",
      gap: 8,
    },
    footer: {
      marginTop: 70,
      flexDirection: "row",
      justifyContent: "space-around",
      fontSize: 13,
    },
    footerName: {
      fontSize: 12,
      marginTop: 20,
      flexDirection: "row",
      justifyContent: "space-around",
    },
    imageView: {
      marginTop: 10,
      marginRight: 10,
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    image: {
      height: 40,
      width: 120,
    },

    headerRfq_sub_text: {},
  });
  return (
    rfq && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.main}>
            <View style={styles.imageView}>
              <Image style={styles.image} src={logo} />
            </View>

            <View style={styles.header}>
              <Text style={styles.header_text}>Request for Quotation</Text>
            </View>
            <View style={styles.headerRfq}>
              <View style={styles.headerRfq_left}>
                <Text style={styles.headerRfq_sub_text}>
                  RFQ No : {rfq.rfq_number}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Agent &nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  {rfq.agents?.crm_v1_vendors?.vendor_name}
                </Text>
              </View>
              <View style={styles.headerRfq_right}>
                <Text style={styles.headerRfq_sub_text}>
                  {/* Date : {rfq.rfq_date.split("T")[0]} */}
                  Date:{" "}
                  {/* {rfq.rfq_date.split("T")[0].split("-").reverse().join("-")} */}
                </Text>
              </View>
            </View>
            <View style={styles.headerRfq}>
              <View style={styles.headerRfq_left}>
                <Text style={styles.headerRfq_sub_text}>
                  Origin
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  {" "}{rfq.fms_v1_locations_origin?.location_name}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Origin Country
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  {" "}{rfq.crm_v1_rfq_rfq_origin_country?.country_name}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  cargo Type
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  {" "}{rfq?.fms_v1_cargo_types?.cargo_type_name}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  UOM
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  {" "} {rfq?.crm_v1_units?.unit_name}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Expected Delivery Date
                  &nbsp; :{" "}
                  {moment(rfq?.rfq_exp_date).format('DD-MM-YYYY')}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Volume
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  {" "} {rfq?.rfq_new_volume}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Chargeable Weight &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{" "} {rfq?.rfq_chargeable_wt}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Cargo Description &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  {rfq?.crm_v1_opportunities?.opportunity_cargo_desc}
                </Text>
              </View>
              <View style={styles.headerRfq_right}>
                <Text style={styles.headerRfq_sub_text}>
                  Destination &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  {rfq.fms_v1_locations_destination?.location_name}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Destination Country :{" "}
                  {rfq.crm_v1_rfq_rfq_destination_country?.country_name}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Number of Pieces &nbsp;&nbsp;&nbsp;: {" "}{rfq?.rfq_no_of_pieces}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  CBM &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "} {rfq?.rfq_volume}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Height &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "} {rfq?.rfq_height}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Gross Weight&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {" "}{rfq?.rfq_gross_wt}
                </Text>
              </View>
            </View>
            <View style={styles.header}>
              <Text style={styles.subHeader_text}>Task and Description</Text>
            </View>
            <View style={styles.tasks}>
              {rfq?.crm_v1_rfq_task_details?.map((item, index) => (
                <Text style={styles.tasks_text}>
                  {`${index + 1}. ${item?.crm_v1_services?.service_name} - ${
                    item?.rfq_task_detail_description ?? " "
                  }`}
                </Text>
              ))}
            </View>
            <View style={styles.footer}>
              <Text style={styles.tasks_text}>Prepared By</Text>
              <Text style={styles.tasks_text}>Approved By</Text>
            </View>
            <View style={styles.footerName}>
              <Text style={styles.tasks_text}>{empname}</Text>
              <Text style={styles.tasks_text}>{empname}</Text>
            </View>
          </View>
        </Page>
      </Document>
    )
   
  );
};

export default RFQPdf;
