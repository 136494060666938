import { useEffect, useState } from "react";
import PublicFetch from "../../utils/PublicFetch";
import { GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
import "./SPendingBookingprint.scss";

function SPendingBooking({
  shipment_table_data1,
  //   shipment_table_data2
  quotationHeaderImg,
  shipmentPendingBooking= false,
  customerWiseReport = false,
  customerwise_table_data,
  supplierCreditNote=false
}) {
  const [companyInfodata, setCompanyInfodata] = useState();

  useEffect(() => {
    const printStyle =
      '<style type="text/css" media="print">@page { size: A4 landscape !important; }</style>';
    document.head.insertAdjacentHTML("afterbegin", printStyle);
  }, []);

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    companyinfo();
    if (companyInfodata) {
      // window.print("", "_blank");
    }
  }, []);

  return (
    <>
      <div className="spending-booking-print" id="spending-booking-print">
        {/* div to set page border on all pages */}
        <div id={"pageborder_shipment"}></div>
        {/* page border div ends */}
        <table className={`pendingbooking_header landscape`}>
          {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => {
              return (
                <thead className="">
                  <tr className="">
                    <div className={"quotheader"}>
                      {/* <div className="header__address_wrapper"> */}
                      <div className="">
                        <img
                          style={{ height: "150px", width: "700px" }}
                          // src={`${process.env.REACT_APP_BASE_URL}/${item.company_quotation_header}`}
                          src={quotationHeaderImg}
                          alt=""
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </tr>
                </thead>
              );
            })}
          <tbody>
            <div className="text-center ">
              {shipmentPendingBooking && (
                <h2 className={"quotation_heading_style"}>
                  SHIPMENT PENDING BOOKING
                </h2>
              )}

              {customerWiseReport && (
                <h2 className={"quotation_heading_style"}>
                  CUSTOMERWISE REPORT
                </h2>
              )}
              {supplierCreditNote  && (
                 <h2 className={"quotation_heading_style"}>
                SUPPLIER CREDITNOTE REPORT
               </h2>
              ) }
            </div>
            <br></br>
            {/* <h5 style={{fontWeight:600 ,padding:"10px"}}>Shipment Pending Booking</h5> */}

{shipmentPendingBooking && (
            <table className={"print_table"} cellSpacing={0}>
              <thead className={"print_table"}>
                <tr className="">
                  <th className="">Sl.No</th>
                  <th className="" style={{ textAlign: "left" }}>
                    Ref No
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    DATE
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    FREIGHT TYPE
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    CUSTOMER
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    SHIPPER
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    ORIGIN COUNTRY
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    DESTINATION COUNTRY
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    POL
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    POD
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    STATUS
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    SHIPMENT REF NO
                  </th>
                </tr>
              </thead>
              <tbody className="">{shipment_table_data1}</tbody>
            </table>
            )}
           
           {customerWiseReport && (
            <table className={"print_table"} cellSpacing={0}>
              <thead className={"print_table"}>
                <tr className="">
                  <th className="">Sl.No</th>
                  <th className="" style={{ textAlign: "left" }}>
                    DATE
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    SALES PERSON
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    ACTION
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    REF NO
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                   REMARKS
                  </th>
                </tr>
              </thead>
              <tbody className="">{customerwise_table_data}</tbody>
            </table>
            )}
            <br></br>
            <br></br>
          </tbody>
        </table>
      </div>
    </>
  );
}
export default SPendingBooking;
