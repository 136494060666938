import React, { useEffect, useState } from "react";
import Arrivalnotprint from "../../../../components/Arrival Notice/arrivalnotice-Print/Arrivalnoticeprint";
import {
  CRM_BASE_URL_FMS,
  GENERAL_SETTING_BASE_URL,
} from "../../../../api/bootapi";
import PublicFetch from "../../../../utils/PublicFetch";
import { useParams } from "react-router-dom";
import moment from "moment";
import { camelize } from "../../../../utils/camelcaseconvert";
function Arrivalnoticeprint() {
  const { id } = useParams();

  const [allJob, setAllJob] = useState("");
  const [taskandExpences, setTaskandExpences] = useState("");
  const [grandTotal, setGrandTotal] = useState();

  const [arrivalLogo, setArrivalLogo] = useState();

  const [NoofDecimal, setNoO0fDecimal] = useState(
    localStorage.getItem("NumberOfDecimals")
  );
  const [defaultCurrency, setDefaultCurrency] = useState();

  var converter = require("number-to-words");

  const [arrivalHeaderSuccess, setArrivalHeaderSuccess] = useState(false);

  const [arrivalContentSuccess, setArrivalContentSuccess] = useState(false);
  const [blobUrl, setBlobUrl] = useState();
  console.log("bloburl", blobUrl, allJob);

  const handlePrint = () => {
    console.log("print called");
    setTimeout(() => {
      window.print();
    }, 1);
  };

  const getSingleJob = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/job/${id}`)
      .then((res) => {
        console.log("print response of job", res?.data?.data);
        if (res.data.success) {
          setArrivalContentSuccess(true);
          console.log(
            "Success of job vieww",
            res.data.data?.fms_v1_freight_types?.freight_type_type
          );
          setAllJob(res?.data?.data);

          setTaskandExpences(res?.data?.data?.fms_v1_job_task_expenses);

          let grandtotal = 0;

          res?.data?.data?.fms_v1_job_task_expenses.map((item, index) => {
            grandtotal += item.job_task_expense_cost_subtotalfx;
            grandtotal = Number.parseFloat(grandtotal);
            setGrandTotal(grandtotal.toFixed(2));
          });
          // close_modal(1000);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const Invoicedata_data = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/invoice/${id}`)
      .then((res) => {
        console.log("response", res);
        if (res.data.success) {
          console.log("Success of invoice", res.data.data);
          let temp = [];
          let grandtotal = 0;

          res?.data?.data?.fms_v1_jobs?.fms_v1_job_task_expenses.forEach(
            (item, index) => {
              temp.push({
                job_task_expense_id: item.job_task_expense_id,
                job_task_expense_cost_subtotalfx:
                  item.job_task_expense_cost_subtotalfx,
                job_task_expense_cost_taxfx: item.job_task_expense_cost_taxfx,
                job_task_expense_cost_amountfx:
                  item.job_task_expense_cost_amountfx,
                job_task_expense_job_id: item.job_task_expense_job_id,
                job_task_expense_task_id: item.job_task_expense_task_id,
                job_task_expense_tax_perc: item.job_task_expense_tax_perc,
                job_task_expense_taxtype_id: item.job_task_expense_taxtype_id,
                job_task_expense_task_name: item?.crm_v1_services?.service_name,
              });
              grandtotal += item.job_task_expense_cost_subtotalfx;
              grandtotal = Number.parseFloat(grandtotal);
              // setGrandTotal(grandtotal.toFixed(2));
            }
          );
          // setTabledata(temp);
          // setAllData(res.data.data);
          // close_modal(1200);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const allCurrency = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/currency`)
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of cuurency", res.data.data);
          res?.data?.data?.forEach((item, index) => {
            if (item.currency_is_default === 1) {
              console.log("default currency", item);
              setDefaultCurrency(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    console.log("quotHeaderImg", arrivalLogo);
    if (arrivalLogo) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${arrivalLogo}`, {
        responseType: "blob",
      })
        .then((resp) => {
          console.log("respBlob".resp?.blob());

          console.log("quotresp", resp);

          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
          console.log("blobconvert", blobUrl);
        })
        .catch((err) => console.log("error123", err));
    }
  }, [arrivalLogo]);

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setArrivalHeaderSuccess(true);
          res?.data?.data.map((item, indx) => {
            console.log("jnef", item);
            setArrivalLogo(item?.company_quotation_header);
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  // useEffect(() => {
  //   if (
  //     arrivalHeaderSuccess === true &&
  //     arrivalContentSuccess === true &&
  //     arrivalLogo
  //   ) {
  //     console.log("success333");
  //     handlePrint();
  //   }
  // }, [arrivalHeaderSuccess, arrivalContentSuccess, arrivalLogo]);

  useEffect(() => {
    companyinfo();
    getSingleJob();
    allCurrency();
  }, []);

  useEffect(() => {
    if (arrivalContentSuccess === true && blobUrl){
      handlePrint()
    }
  }, [blobUrl, arrivalContentSuccess]);

  return (
    <div>
      <Arrivalnotprint
        arrivalheader_img={blobUrl}
        invoice_details1={
          <>
            <tr className="Job_arrive_header">
              <td>Customer Name</td>
            </tr>
            <tr className="Job_arrive_header">
              <td style={{ fontWeight: 600 }}>
                {allJob?.crm_v1_customer?.customer_name}{" "}
              </td>
            </tr>
            <tr className="Job_arrive_header">
              <td style={{ fontWeight: 600 }}>
                {allJob?.crm_v1_customer?.customer_address}{" "}
              </td>
            </tr>
          </>
        }
        invoice_details2={
          <>
            <tr className="Job_arrive_header">
              <td>Date</td>
              <td style={{paddingLeft:"8px"}}>:</td>
              <td style={{ fontWeight: 600 ,paddingLeft:"10px" }}>
                {moment(allJob?.job_date).format("DD-MM-YYYY")}{" "}
              </td>
            </tr>
            <tr className="Job_arrive_header">
              <td>Job No</td>
              <td style={{paddingLeft:"8px"}}>:</td>
              <td style={{ fontWeight: 600,paddingLeft:"10px" }}>{allJob?.job_number} </td>
            </tr>
          </>
        }
        invoice_details3={
          <>
            <tr className="Job_arrive_header">
              <td>Consignee</td>
              {/* <td>:</td>
              <td style={{ fontWeight: 600 }}>consignee name</td> */}
            </tr>

            <tr className="Job_arrive_header">
              <td style={{ fontWeight: 600 }}>
                {allJob?.job_consignee}{" "}
              </td>
            </tr>
            {/* <tr className="invoice_header">
              <td style={{ fontWeight: 600 }}>
                {allJob?.job_consignee_address}{" "}
              </td>
            </tr> */}
          </>
        }
        invoice_details4={
          <>
            {/* <tr className="invoice_header">
              <td>Shipper</td>
             
            </tr>

            <tr className="invoice_header">
              <td style={{ fontWeight: 600 }}>{allJob?.job_shipper} </td>
            </tr> */}
            {/* <tr className="invoice_header">
              <td style={{ fontWeight: 600 }}>
                {allJob?.job_shipper_address}{" "}
              </td>
            </tr> */}
            <tr>
              <td style={{ width: "150px" }} className="Job_arrive_header">
                Shipper
              </td>
              <td style={{ paddingLeft: "5px",  }}>:</td>
              <td style={{ fontWeight: 600,paddingRight: "5px" }}>
                {allJob?.job_shipper}
              </td>
            </tr>
            <tr>
              <td style={{ width: "150px" }} className="Job_arrive_header">
              Country of Origin
              </td>
              <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>:</td>

              <td style={{ fontWeight: 600 }}>
                {allJob?.fms_v1_jobs_job_origin_country?.country_name}
              </td>
            </tr>
            <tr>
              <td style={{ width: "150px" }} className="Job_arrive_header">
              Country of Destination 
              </td>
              <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>:</td>
              <td style={{ fontWeight: 600 }}>
                {allJob?.fms_v1_jobs_job_destination_country?.country_name}
              </td>
            </tr>
          </>
        }
        invoice_details5={
          <>
            <tr className="Job_arrive_header">
              <td style={{ width: "100px" }}>MAWB/MBL</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>{allJob?.job_awb_bl_no} </td>
            </tr>
            <tr className="Job_arrive_header">
              <td>HAWB/HBL</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>{allJob?.job_hawb_hbl_no}</td>
            </tr>
            <tr className="Job_arrive_header">
              <td>ETA</td>
              <td>:</td>
              {allJob?.job_eta && (
                <td style={{ fontWeight: 600 }}>
                  {moment(allJob?.job_eta).format("DD-MM-YYYY")}
                </td>
              )}
            </tr>
            <tr className="Job_arrive_header">
              <td>Port of Origin</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {
                  allJob
                    ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                    ?.location_name
                }{" "}
              </td>
            </tr>
            <tr className="Job_arrive_header">
              <td> Port of Destination</td>
              <td>:</td>
              <td style={{ fontWeight: 600 }}>
                {
                  allJob
                    ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                    ?.location_name
                }{" "}
              </td>
            </tr>
          </>
        }
        invoice_details6={
          <>
            <tr className="Job_arrive_header">
              <td style={{ width: "155px", }}>No: of Packages</td>
              <td>:</td>
              <td style={{ fontWeight: 600 ,paddingLeft:"8px"}}>{allJob?.job_no_of_pieces} </td>
            </tr>
            {allJob?.job_gross_wt && (
              <tr className="Job_arrive_header">
                <td>Gross Weight</td>
                <td>:</td>
                <td style={{ fontWeight: 600 ,paddingLeft:"8px"}}>
                  {allJob?.job_gross_wt} &nbsp; Kg{" "}
                </td>
              </tr>
            )}
            {allJob?.job_chargeable_wt && (
              <tr className="Job_arrive_header">
                <td>Chargeable Weight</td>
                <td>:</td>
                <td style={{ fontWeight: 600,paddingLeft:"8px" }}>
                  {allJob?.job_chargeable_wt}&nbsp; Kg{" "}
                </td>
              </tr>
            )}

            <tr className="Job_arrive_header">
              <td>Carrier</td>
              <td>:</td>
              <td style={{ fontWeight: 600 ,paddingLeft:"8px"}}>
                {allJob?.fms_v1_carrier?.carrier_name}{" "}
              </td>
            </tr>
            <tr className="Job_arrive_header">
              <td>Mode</td>
              <td>:</td>
              <td style={{ fontWeight: 600 ,paddingLeft:"8px"}}>
                {allJob?.fms_v1_freight_types?.freight_type_mode}{" "}
              </td>
            </tr>
            <tr className="Job_arrive_header">
              <td>Volume</td>
              <td>:</td>
              {allJob?.job_new_volume && (
                <td style={{ fontWeight: 600,paddingLeft:"8px" }}>{allJob?.job_new_volume} </td>
              )}
            </tr>
             <tr className="invoice_header">
              <td>Shipment Ref no</td>
              <td>:</td>
              
              <td style={{ fontWeight: 600,paddingLeft:"8px" }}>{allJob?.job_shipment_ref_no} </td>
            </tr> 
          </>
        }
        invoice_table_header={
          <>
          {taskandExpences&& taskandExpences?.length>0 ?(
            <tr className="Job_arrive_header">
              <th className="th_center">#</th>
              <th className="tsk_exp">Task & description</th>
              <th id="amount">Amount</th>
            </tr>
            ):("") }
          </>
        }
        invoice_table_data={
          <>
          {taskandExpences&& taskandExpences?.length>0 ? (
            <>
            {taskandExpences &&
              taskandExpences.length > 0 &&
              taskandExpences.map((item, index) => {
                return (
                  <tr className="">
                    <td align="center">{index + 1} </td>
                    <td className="tsk_exp">
                      {item?.crm_v1_services?.service_name}
                    </td>

                    <td style={{ textAlign: "right" }}>
                      {item.job_task_expense_cost_subtotalfx.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
              </>
              ):("")}
            
          </>
        }
        amount_in_words={
          <>{taskandExpences&& taskandExpences?.length>0? (<>
          { <>{camelize(converter.toWords(grandTotal))}</>}
          </> ):("")  }   </>

        }
        sub_total={<> {taskandExpences&& taskandExpences?.length>0 ?(grandTotal):("")} </> }
        total={  <> {taskandExpences&& taskandExpences?.length>0 ?(grandTotal):("")} </> }
        BankBreak={allJob?.job_consignee_address}
      />
    </div>
  );
}

export default Arrivalnoticeprint;
