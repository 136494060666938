import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
//   import PublicFetch from "../../../utils/PublicFetch";
//   import {
//     CRM_BASE_URL_FMS,
//     CRM_BASE_URL_HRMS,
//     GENERAL_SETTING_BASE_URL,
//   } from "../../../api/bootapi";
import { useEffect, useState } from "react";
import moment from "moment";
import PublicFetch from "../../utils/PublicFetch";
import { camelize } from "../../utils/camelcaseconvert";
import { CRM_BASE_URL_HRMS, GENERAL_SETTING_BASE_URL } from "../../api/bootapi";

const ShippmentStatusPdf = ({
  invoiceData,
  bankDetails,
  shipmentHistoryData,
  bookingShipmentHistoryData,
}) => {
  var converter = require("number-to-words");
  const personName = localStorage.getItem("UserID");
  const [empname, setEmpname] = useState();
  const [companyInfo, setcmpnyupdate] = useState();
  const [GrandTotal, setGrandTotal] = useState();
  console.log("personName of loged in", empname);
  useEffect(() => {
    if (personName) {
      getOneEmployee();
    }
  }, [personName]);
  console.log("shipmentHistoryData", shipmentHistoryData);

  const getOneEmployee = () => {
    PublicFetch.get(`${CRM_BASE_URL_HRMS}/employees/${personName}`)
      .then((res) => {
        console.log("Response of employee", res);
        if (res.data.success) {
          console.log("Success of employee", res.data.data);
          //   setTotalcount(res?.data?.data?.totalCount);
          //   setstartcount(res?.data?.data?.startIndex);

          setEmpname(res?.data?.data?.employee_name);
          //   console.log(" newww array data ::", array);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getallcmpny = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      // console.log("getting all cmpny", allcmpny);
      console.log(" all cmpny", allcmpny.data.data);
      let a = allcmpny?.data?.data;
      setcmpnyupdate(a[0]);
      console.log("dataa", allcmpny.data.data.length);
      let arry = [];
      allcmpny.data.data.forEach((i, indx) => {
        arry.push(i);
      });
      console.log("all cmpny are", arry);
      // setcmpnyupdate(arry)

      // setallseaports(allseaports.data.data)
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  useEffect(() => {
    getallcmpny();
  }, []);

  // grand Total cal
  useEffect(() => {
    let grandTotal = 0;
    invoiceData?.fms_v1_job_task_expenses?.map((item, index) => {
      grandTotal += item?.job_task_expense_cost_subtotalfx;
    });
    setGrandTotal(grandTotal);
  }, [invoiceData]);

  console.log("user id of employee log in ", personName);
  const styles = StyleSheet.create({
    main: {
      margin: 20,
      border: "1px solid black",
      height: "95%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "center",
    },
    header_text: {
      fontSize: 14,

      // marginTop: 20,
      textDecoration: "underline",
    },
    subHeader_text_jobshipmnt_slno: {
      fontSize: 11,
      marginLeft: "1vh",
      width: "5%",
      // borderRight: '1px solid black'
      // marginTop: 40,
      // textDecoration: "underline",
      // borderRight: "1px solid black",
    },
    subHeader_text_slno: {
      fontSize: 11,
      marginLeft: "1vh",
      width: "10%",
      // borderRight: '1px solid black'
      // marginTop: 40,
      // textDecoration: "underline",
      // borderRight: "1px solid black",
    },

    subHeader_text_status: {
      fontSize: 11,
      width: "70%",
      // borderRight: '1px solid black'

      // marginLeft:'2vh'
      // marginTop: 40,
      // textDecoration: "underline",
      // borderRight: "1px solid black",
    },
    subHeader_text_date: {
      fontSize: 11,
      marginLeft: "1vh",
      width: "30%",
      // borderRight: '1px solid black'

      // marginTop: 40,
      // textDecoration: "underline",
      // borderRight: "1px solid black",
    },

    subHeader_text_date_jobshippment: {
      fontSize: 11,
      // marginLeft: "3px",
      marginRight: "4vh",
      width: "5%",
      // borderRight: '1px solid black'

      // marginTop: 40,
      // textDecoration: "underline",
      // borderRight: "1px solid black",
    },
    page: {
      //   border: "3px solid blue",
      flexDirection: "column",
    },
    headerRfq: {
      // marginLeft: 5,

      flexDirection: "row",
      // justifyContent: "",
      // borderTop: "1px solid black",
      // borderBottom: "1px solid black",
      // padding: "10px",
    },
    headershipment: {
      flexDirection: "row",
      // justifyContent: "",
      borderBottom: "1px solid black",
    },
    headerRfq_left: {
      gap: 8,
      width: "50%",
      fontSize: 10,
      flexDirection: "column",
      alignContent: "flex-start",
      borderRight: "1px solid black",
      borderTop: "1px solid black",
      // borderBottom: "1px solid black",
      padding: "10px",
    },
    headerRfq_right: {
      gap: 8,
      width: "50%",
      fontSize: 10,
      flexDirection: "column",
      alignContent: "flex-start",
      // borderLeft: "1px solid black",
      padding: "10px",
      borderTop: "1px solid black",
      // borderBottom: "1px solid black",
    },
    tasks: {
      fontSize: 12,
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 8,
      border: "1px solid black",
      width: "100%",
      alignContent: "flex-start",
    },
    footer: {
      marginTop: 70,
      flexDirection: "row",
      justifyContent: "space-around",
      fontSize: 13,
    },
    footerName: {
      fontSize: 12,
      marginTop: 20,
      flexDirection: "row",
      justifyContent: "space-around",
    },
    imageView: {
      marginTop: 0,
      flexDirection: "row",
      justifyContent: "center",
      borderBottom: "1px solid black",
      padding: "1%",
    },
    imageView1: {
      marginTop: 10,
      flexDirection: "row",
      justifyContent: "center",
    },
    image: {
      marginTop: 0,
      height: 100,
      width: 1000,
    },
    header_style: {
      fontSize: 12,
      marginTop: 8,
      flexDirection: "row",
      justifyContent: "space-around",
      // borderBottom: "1px solid black",
    },
    CompInfo: {
      justifyContent: "start",
      textAlign: "left",
    },

    comp_header_sub_text: {
      fontSize: 16,
      color: "#f3a415",
      marginBottom: 10,
    },
    header_part3: {
      width: "100%",
      border: "1px solid black",
      backgroundColor: "#e6e6e6",
    },
    sub_heading: {
      fontSize: "12px",
      padding: "2%",
    },
    header_part4: {
      width: "100%",
      // border: "1px solid black",
      backgroundColor: "#e6e6e6",
      flexDirection: "row",
      justifyContent: "space-between",
      textAlign: "left",
      // gap: 8,
      // padding: "2%",
    },
    tableCels: {
      // borderBottom: "1px solid black",
      // padding: "5%",
      paddingHorizontal: "7%",
      width: "100px",
      // border: "1px solid black",
      textAlign: "left",
    },
    tableCelsShipDate: {
      // borderBottom: "1px solid black",
      // padding: "5%",
      paddingHorizontal: "7%",
      width: "100px",
      borderRight: "1px solid black",
    },
    tableCels1: {
      // borderBottom: "1px solid black",
      padding: "4%",
      width: "100px",
      // borderRight: "1px solid black",
    },
    job_shipment_slnotableCels1: {
      // borderBottom: "1px solid black",
      padding: "2%",
      width: "5px",
      // borderRight: "1px solid black",
    },

    amtWords: {
      flexDirection: "row",
      justifyContent: "flex-start",
      border: "1px solid black",
      padding: "2px",
    },
    innerAmtStyle1: {
      width: "50%",
      // flexDirection: "row",
      // justifyContent: "flex-start",
      // gap: 8,
      borderRight: "1px solid black",
      padding: "2%",
    },
    innerAmtStyle: {
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 8,
      padding: "2%",
      width: "50%",
    },
    amtFontsSize: {
      fontSize: 12,
      fontWeight: 600,
      fontStyle: "bold",
    },
    totalinWords: {
      fontSize: 10,
    },
    totalinWordst: {
      fontSize: 10,
      paddingBottom: "5px",
    },
    innerStyleofAmt: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tableCels1: {
      padding: "2%",
      // borderRight: "1px solid black",
      justifyContent: "flex-start",
      flexDirection: "row",
      textAlign: "left",
    },
    tableCels11: {
      padding: "2%",
      width: "100px",
    },
    tableCelsShipDate: {
      padding: "5%",
    },
    subHeader_text1: {
      fontSize: 15,
      flexDirection: "row",
      justifyContent: "flex-start ",
      textAlign: "left",
    },
    bnkdetails: {
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: 8,
      padding: "1%",
    },
    bankDet: {
      padding: "2%",
    },
    newRow: {
      marginTop: 10,
      flexDirection: "row",
      justifyContent: "flex-start ",
    },
    tableCol: {
      width: "50%",
      padding: "1%",
    },
    heading11: {
      fontSize: 10,

      padding: "2%",
    },
    subValue: {
      fontSize: 10,
      padding: "2%",
      fontWeight: "800",
    },
    header_shipmthistory: {
      width: "100%",

      backgroundColor: "#e6e6e6",
      flexDirection: "row",
      justifyContent: "space-between",
      //  textAlign:"left"
      alignItems: "flex-start",
      textAlign: "left",
      borderTop: "1px solid black",
      // gap: 8,
      // padding: "2%",
    },
    shipmthistory_tableCels11: {
      padding: "1%",
      width: "100px",
      textAlign: "left",
      // borderRight: '1px solid black',
      // borderTop:'1px solid black',
    },
    shipmthistory_slno: {
      width: "50px",
      textAlign: "left",
      padding: "20px",
    },
    shipmthistory_tasks: {
      fontSize: 10,
      flexDirection: "row",
      justifyContent: "space-between",
      // gap: 8,
      border: "1px solid black",
      width: "100%",
      alignContent: "flex-start",
      textAlign: "left",
    },
    shipmthistory_tableCels: {
      justifyContent: "space-between",
      // padding: "5%",
      paddingHorizontal: "7%",
      width: "100px",
      // borderRight: "1px solid black",
      textAlign: "left",
    },
    shipmthistory_tasks_text: {
      padding: 0,
      textAlign: "left",
      width: "100px",
    },
    shipmthistory_slno_tableCels11: {
      padding: "1%",
      width: "100px",
      textAlign: "left",
      // borderRight: '1px solid black',
      // borderTop:'1px solid black',
    },

    shipmthistory_date_tableCels11: {
      padding: "1%",
      width: "8px",
      textAlign: "left",
      marginRight: "190px",
      // borderRight: '1px solid black',
      // borderTop:'1px solid black',
    },
    job_shipment_datetableCels1: {
      // borderBottom: "1px solid black",
      padding: "2%",
      width: "10px",
      // borderRight: "1px solid black",
    },
    shipmthistory_tasks_text_date: {
      padding: 0,
      textAlign: "left",
      width: "8px",
    },
    shipmthistory_tasks_text_date: {
      padding: 0,
      textAlign: "left",
      width: "10px",
    },

    shipmthistory_status_tableCels11: {
      padding: "1%",
      width: "50px",
      textAlign: "left",
      marginRight: "100px",
      // borderRight: '1px solid black',
      // borderTop:'1px solid black',
    },
    subHeader_text_date_jobshippment: {
      fontSize: 11,
      // marginLeft: "3px",
      marginRight: "4vh",
      width: "5%",
      // borderRight: '1px solid black'

      // marginTop: 40,
      // textDecoration: "underline",
      // borderRight: "1px solid black",
    },
  });

  return (
    invoiceData && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.main}>
            <View style={styles.header_style}>
              <View style={styles.imageView}>
                <Image
                  style={styles.image}
                  src={`${process.env.REACT_APP_BASE_URL}/${companyInfo?.company_quotation_header}`}
                />
              </View>
            </View>
            {/* <View style={styles.imageView1}>
              <Text style={styles.header_text}>Shipment Status</Text>
            </View> */}

            <View style={styles.newRow}>
              <View style={styles.tableCol}>
                <Text style={styles.heading11}>
                  Job Number &nbsp;&nbsp;: &nbsp;&nbsp;{" "}
                  {invoiceData?.job_number}
                </Text>
              </View>

              <View style={styles.tableCol}>
                <Text style={styles.subValue}>
                  Freight Type &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;{" "}
                  {invoiceData?.fms_v1_freight_types?.freight_type_name}
                </Text>
                <Text style={styles.subValue}>
                  Customer &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  &nbsp;&nbsp; {invoiceData?.crm_v1_customer?.customer_name}
                </Text>
              </View>
            </View>
            {/* <View style={styles.headerRfq}>
              <View style={styles.headerRfq_left}>
                <Text>Consignee</Text>
                <Text>{invoiceData?.job_consignee}</Text>
                <Text>{invoiceData?.job_consignee_address}</Text>
              </View>
              <View style={styles.headerRfq_right}>
                <Text>Shipper</Text>
                <Text>{invoiceData?.job_shipper}</Text>
                <Text>{invoiceData?.job_shipper_address}</Text>
              </View>
            </View> */}

            <View style={styles.headershipment}>
              <View style={styles.headerRfq}>
                <View style={styles.headerRfq_left}>
                  <Text style={styles.headerRfq_sub_text}>
                    Consignee &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    &nbsp;&nbsp;&nbsp; {invoiceData?.job_consignee}
                  </Text>
                  <Text style={styles.headerRfq_sub_text}>
                    Origin Country &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;{" "}
                    {invoiceData?.fms_v1_jobs_job_origin_country?.country_name}
                  </Text>
                  <Text style={styles.headerRfq_sub_text}>
                    Origin
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    &nbsp;&nbsp;&nbsp;
                    {
                      invoiceData
                        ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                        ?.location_name
                    }
                  </Text>
                  <Text style={styles.headerRfq_sub_text}>
                    ETD
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    &nbsp;&nbsp;&nbsp;
                    {moment(invoiceData?.job_etd).format("DD-MM-YYYY")}
                  </Text>

                  {/* <Text style={styles.headerRfq_sub_text}>
                    Destination &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;{" "}
                    {
                      invoiceData
                        ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                        ?.location_name
                    }
                  </Text> */}
                </View>

                <View style={styles.headerRfq_right}>
                  <Text style={styles.headerRfq_sub_text}>
                    Shipper
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    &nbsp;&nbsp;&nbsp;
                    {invoiceData?.job_shipper}
                  </Text>
                  <Text style={styles.headerRfq_sub_text}>
                    Destination Country &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                    {
                      invoiceData?.fms_v1_jobs_job_destination_country
                        ?.country_name
                    }
                  </Text>
                  <Text style={styles.headerRfq_sub_text}>
                    Destination
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    &nbsp;&nbsp;&nbsp;{" "}
                    {
                      invoiceData
                        ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                        ?.location_name
                    }
                  </Text>
                  <Text style={styles.headerRfq_sub_text}>
                    ETA
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    &nbsp;&nbsp;&nbsp;
                    {moment(invoiceData?.job_eta).format("DD-MM-YYYY")}
                  </Text>
                  {/* <Text style={styles.headerRfq_sub_text}>
                    Chargable Weight &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                    {invoiceData?.job_chargeable_wt}
                  </Text>
                  <Text style={styles.headerRfq_sub_text}>
                    Carrier &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                    {invoiceData?.fms_v1_carrier?.carrier_name}
                  </Text>
                  <Text style={styles.headerRfq_sub_text}>
                    Mode &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                    {invoiceData?.fms_v1_jobs?.job_shipper}
                  </Text> */}
                </View>
              </View>
            </View>

            <View style={styles.imageView1}>
              <Text style={styles.header_text}>Shipment History</Text>
            </View>

            <View style={styles.header_shipmthistory}>
              <View style={styles.shipmthistory_slno_tableCels11}>
                <Text style={styles.subHeader_text_jobshipmnt_slno}>SL.No</Text>
              </View>
              <View style={styles.shipmthistory_date_tableCels11}>
                <Text style={styles.subHeader_text_date_jobshippment}>
                  Date
                </Text>
              </View>
              <View style={styles.shipmthistory_tableCels11}>
                <Text style={styles.subHeader_text_status}>Status</Text>
              </View>
            </View>

            {shipmentHistoryData?.map((item, index) => {
              console.log("shipmentItem", item);
              return (
                <>
                  <View style={styles.shipmthistory_tasks}>
                    <View style={styles.job_shipment_slnotableCels1}>
                      <Text style={styles.shipmthistory_tasks_text}>{`${
                        index + 1
                      }`}</Text>
                    </View>
                    <View style={styles.tableCels1}>
                      <Text style={styles.shipmthistory_tasks_text}>
                        {moment(item?.job_shipment_status_date).format(
                          "DD-MM-YYYY"
                        )}
                      </Text>
                    </View>
                    <View style={styles.tableCels1}>
                      <Text style={styles.shipmthistory_tasks_text}>
                        {item?.job_shipment_status_name}
                      </Text>
                    </View>
                  </View>
                </>
              );
            })}


          <View>
            {bookingShipmentHistoryData && (
            <View style={styles.imageView1}>
              <Text style={styles.header_text}>Booking History</Text>
            </View>
            )}
            </View>

            {bookingShipmentHistoryData && (
              <View style={styles.header_shipmthistory}>
                <View style={styles.shipmthistory_slno_tableCels11}>
                  <Text style={styles.subHeader_text_jobshipmnt_slno}>
                    SL.No
                  </Text>
                </View>
                <View style={styles.shipmthistory_date_tableCels11}>
                  <Text style={styles.subHeader_text_date_jobshippment}>
                    Date
                  </Text>
                </View>
                <View style={styles.shipmthistory_tableCels11}>
                  <Text style={styles.subHeader_text_status}>Status</Text>
                </View>
              </View>
            )}

            {bookingShipmentHistoryData &&
              bookingShipmentHistoryData?.map((item, index) => (
                <>
                  <View style={styles.shipmthistory_tasks}>
                    <View style={styles.job_shipment_slnotableCels1}>
                      <Text style={styles.shipmthistory_tasks_text}>{`${
                        index + 1
                      }`}</Text>
                    </View>
                    <View style={styles.tableCels1}>
                      <Text style={styles.shipmthistory_tasks_text}>
                        {moment(item?.job_booking_shipment_status_date).format(
                          "DD-MM-YYYY"
                        )}
                      </Text>
                    </View>
                    <View style={styles.tableCels1}>
                      <Text style={styles.shipmthistory_tasks_text}>
                        {item?.job_booking_shipment_status_name}
                      </Text>
                    </View>
                  </View>
                </>
              ))}

            <View></View>
          </View>
        </Page>
      </Document>
    )
  );
};
export default ShippmentStatusPdf;
