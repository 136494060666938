import React, { useState, useEffect } from "react";
import "./sidebar.styles.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AiOutlineAppstore,
  AiOutlineCaretDown,
  AiOutlineMenu,
} from "react-icons/ai";
import { BsFillXDiamondFill, BsBookmarkFill } from "react-icons/bs";
import { RiTeamFill } from "react-icons/ri";
import {
  MdEventNote,
  MdSettingsInputComponent,
  MdOutlineSell,
  MdOutlinePointOfSale,
} from "react-icons/md";
import { GiCargoShip } from "react-icons/gi";
import { GrProductHunt, GrServices, GrSettingsOption } from "react-icons/gr";
import { TbReport } from "react-icons/tb";
import ROUTES from "../../routes";
import { NavLink } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import {
  checkPermission,
  // checkSubPermission,
} from "../../utils/check_permission";
import hrmsIcon from "../../components/img/human-resources-icon-png-12.jpg";
import crmIcon from "../../components/img/118211.png";
import fmsIcon from "../../components/img/icons8-cargo-ship-50.png";
import settingsicon from "../../components/img/icons8-gear-50.png";
import { FaRegHandshake } from "react-icons/fa";
import cargologo from "../../assets/logo/cargoplusnew.png";
import Purchaseorder from "../../pages/FMS/Purchase/Purchase Order/purchase_order";
import ListpurchaseOrder from "../../pages/FMS/Purchase/Purchase Order/purchase_orderlist";
import { GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
import PublicFetch from "../../utils/PublicFetch";
import { useUnsavedChanges } from "../../utils/UnsavedChangesProvider";
import Button from "../button/button";
import CustomModel from "../custom_modal/custom_model";

export default function Sidebar({ showSidebar, clickedMenu }) {
  const { unsavedChanges, setUnsavedChangesStatus } = useUnsavedChanges();
  const [CRMproductmaster, setCRMproductmaster] = useState(false);
  const navigate = useNavigate();
  const [CRMproduct, setCRMproduct] = useState(false);
  const [CRMservice, setCRMservice] = useState(false);

  const [permissions, setPermissions] = useState(null);

  const [currentOpen, setCurrentOpen] = useState();
  const [isMainMenu, setIsMainMenu] = useState();
  const [clickedRoute, setClickedRoute] = useState();
  const [warningModal, setWarningModal] = useState(false);

  const location = useLocation();
  const [companyInfodata, setCompanyInfodata] = useState();

  const [clickedfrom, setClickedFrom] = useState();

  const { pathname } = location;

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]);

  function checkSubPermission(name) {
    let permissions = localStorage.getItem("userPermissionsSub");
    if (permissions) {
      console.log("permissions123", name);

      let userPermissions = JSON.parse(permissions);
      let hasPermission = userPermissions.some((item, index) => {
        return item.subject === name;
      });
      console.log("hasPer", name, hasPermission);
      return hasPermission;
    } else {
      return false;
    }
  }

  useEffect(() => {
    let p = localStorage.getItem("userPermissions");
    if (p) {
      setPermissions(JSON.parse(p));
    }
  }, []);
  const splitLocation = pathname.split("/");

  useEffect(() => {
    if (
      splitLocation[1] === "dashboard" ||
      splitLocation[1] === "permission_denined"
    ) {
      let a = splitLocation[1];
      console.log("clicked from local", a, clickedMenu);
      setClickedFrom(a);
    }
    let a = splitLocation[1];
    setClickedFrom(a);
  }, [clickedMenu, localStorage.getItem("MenuClicked"), pathname]);

  const handleClickMenuFormModelShow = (path) => {
    setWarningModal(true);
    setClickedRoute(path);
  };

  const handleModelLeaveTrigger = () => {
    setWarningModal(false);
    setUnsavedChangesStatus(false);

    navigate(clickedRoute);
  };

  return (
    <>
      <div className="navbar p-0">
        <div>
          <nav className={`nav-menu active  nav-menu `}>
            <ul className="nav-menu-items p-0 ">
              {splitLocation[1] === "dashboard" && (
                <>
                  {checkSubPermission("dashboard_crm") ? (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.DASHBOARD_CRM}
                      >
                        <div className="ps-3 subactivelink">CRM</div>
                      </NavLink>
                    </li>
                  ) : (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.PERMISSIONDEN}
                      >
                        <div className="ps-3 subactivelink">CRM </div>
                      </NavLink>
                    </li>
                  )}
                  {checkPermission("dashboard") &&
                  checkSubPermission("dashboard_purchase") ? (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.DASHBOARD_PURCHASE}
                      >
                        <div className="ps-3 subactivelink">Purchase</div>
                      </NavLink>
                    </li>
                  ) : (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.PERMISSIONDEN}
                      >
                        <div className="ps-3 subactivelink">PURCHASE </div>
                      </NavLink>
                    </li>
                  )}
                  {checkPermission("dashboard") &&
                  checkSubPermission("dashboard_operations") ? (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.DASHBOARD_OPERATIONS}
                      >
                        <div className="ps-3 subactivelink">Operations</div>
                      </NavLink>
                    </li>
                  ) : (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.PERMISSIONDEN}
                      >
                        <div className="ps-3 subactivelink">OPERATIONS </div>
                      </NavLink>
                    </li>
                  )}

                  {checkPermission("dashboard") &&
                  checkSubPermission("dashboard_accounts") ? (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.DASHBOARD_ACCOUNTS}
                      >
                        <div className="ps-3 subactivelink">Accounts</div>
                      </NavLink>
                    </li>
                  ) : (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.PERMISSIONDEN}
                      >
                        <div className="ps-3 subactivelink">ACCOUNTS </div>
                      </NavLink>
                    </li>
                  )}
                </>
              )}

              {/* ............... HRMS .............. */}

              {splitLocation[1] === "hrms" ? (
                <>
                  <div
                    className={` ${
                      splitLocation[1] === "hrms" || currentOpen === "hrms"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("hrms_role") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.ROLES_SCREEN);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.ROLES_SCREEN}
                        >
                          <div className=" ms-4  subactivelink">Roles</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("hrms_assignpermission") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.PERMISSIONS);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PERMISSIONS}
                        >
                          <div className=" ms-4 subactivelink">
                            Assign Permissions
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("hrms_employee") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.EMPLOYEES);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.EMPLOYEES}
                        >
                          <div className=" ms-4  subactivelink">Employees</div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {/* ##########  CRM  ######## */}

              {splitLocation[1] === "crm" ? (
                <>
                  <div
                    className={` ${
                      splitLocation[1] === "crm" || currentOpen === "crm"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("crm_customer") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.CRM_LEADLIST);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive || splitLocation[2] == "leadlist"
                              ? "active-link"
                              : "link"
                          }
                          to={ROUTES.CRM_LEADLIST}
                        >
                          <div className="ms-3 ps-1 subactivelink"> Lead</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("crm_customer") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.CUSTOMER_LIST);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive || splitLocation[2] == "customer_list"
                              ? "active-link"
                              : "link"
                          }
                          to={ROUTES.CUSTOMER_LIST}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            {" "}
                            Customer
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("crm_vendor") && (
                      <li
                        className="nav-text"
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.VENDOR);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                            borderBottom: "1px solid lightgrey",
                          }}
                          className={({ isActive }) =>
                            isActive || splitLocation[2] == "vendor"
                              ? "active-link"
                              : "link"
                          }
                          to={ROUTES.VENDOR}
                        >
                          <div className="ms-3 ps-1  subactivelink">Vendor</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("crm_enquiry") && (
                      <li
                        className="nav-text"
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.ENQUIRY_LIST);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive || splitLocation[2] == "enquiry_list"
                              ? "active-link"
                              : "link"
                          }
                          to={ROUTES.ENQUIRY_LIST}
                        >
                          <div className="ms-3 ps-1 subactivelink">Enquiry</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("crm_opportunity") && (
                      <li
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.OPPORTUNITY);
                        }}
                        className="nav-text"
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.OPPORTUNITY}
                        >
                          <div className="subactivelink ms-3 ps-1">
                            Opportunity
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("fms_quotation") && (
                      <li
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.QUATATIONS);
                        }}
                        className="nav-text "
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.QUATATIONS}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Quotation
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {/* product */}

              {splitLocation[1] == "product" ? (
                <>
                  <div
                    className={` ${
                      CRMproduct && splitLocation[1] === "product"
                        ? "nav_active_color "
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("product_product") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PRODUCT}
                        >
                          <div className="ms-3 ps-1 subactivelink">Product</div>
                        </NavLink>
                      </li>
                    )}
                  </div>

                  {splitLocation[1] == "product" ? (
                    <>
                      <div
                        className={` ${
                          CRMproductmaster && currentOpen === "product"
                            ? "nav_active_color"
                            : "nav_active_color_secondary"
                        } `}
                      >
                        {checkSubPermission("product_category") && (
                          <li className="nav-text">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.CATEGORY_LIST}
                            >
                              <div className="ms-4 ps-2 subactivelink">
                                Category
                              </div>
                            </NavLink>
                          </li>
                        )}

                        {checkSubPermission("product_brands") && (
                          <li className="nav-text">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.BRANDS}
                            >
                              <div className=" ms-4 ps-2 subactivelink">
                                Brands
                              </div>
                            </NavLink>
                          </li>
                        )}

                        {checkSubPermission("product_attributes") && (
                          <li className="nav-text">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.ATTRIBUTES}
                            >
                              <div className=" ms-4 ps-2 subactivelink">
                                Attributes
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {checkSubPermission("product_unitandmeasurements") && (
                          <li className="nav-text">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.UNIT_LIST}
                            >
                              <div className="ms-4 ps-2 subactivelink">
                                Unit And Measurements
                              </div>
                            </NavLink>
                          </li>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {/* servicess */}
              {splitLocation[1] == "services" && (
                <>
                  {checkPermission("services") && (
                    <li
                      className={
                        CRMservice && isMainMenu == 4
                          ? "nav-text2  items_hrms open ps-2"
                          : "nav-text  items_hrms ps-2"
                      }
                    >
                      <Link
                        className={
                          CRMservice && isMainMenu == 4
                            ? "active-link_main"
                            : "link"
                        }
                        // to="/"
                        onClick={() => {
                          setCRMservice(!CRMservice);
                          setCurrentOpen("service");
                          setIsMainMenu(4);
                        }}
                      >
                        <div className="d-flex justify-content-between gap-5">
                          <div className="d-flex">
                            {/* <RiTeamFill className="sidebar_icons" />
                             */}
                            {/* <GrServices className="sidebar_icons pt-1" /> */}
                            <div style={{ width: "120px" }} className="">
                              Services
                            </div>
                          </div>

                          <div className="  ">
                            <div className="text-right ">
                              <AiOutlineCaretDown className="toggle_btn " />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  )}
                </>
              )}

              {splitLocation[1] == "service" ? (
                <>
                  <div
                    className={` ${
                      splitLocation[1] == "service" || currentOpen === "service"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("services_service") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.SERVICES);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive || splitLocation[1] == "services"
                              ? "active-link"
                              : "link"
                          }
                          to={ROUTES.SERVICES}
                        >
                          <div className="ms-4 subactivelink"> Service</div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              {/* ##########  FMS  ######## */}

              {splitLocation[1] == "operations" ? (
                <>
                  <div
                    className={` ${
                      splitLocation[1] == "operations" ||
                      currentOpen === "operations"
                        ? "nav_active_color "
                        : "nav_active_color_secondary "
                    } `}
                  >
                    {checkSubPermission("fms_job") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(
                              ROUTES.LIST_JOB_BOOKING
                            );
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LIST_JOB_BOOKING}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Job Booking
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_job") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.LIST_JOB);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LIST_JOB}
                        >
                          <div className="ms-3 ps-1 subactivelink">Job</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_console_job") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.LIST_CONSOLJOB);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LIST_CONSOLJOB}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Consol Job
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_invoice") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.INVOICE_LIST);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.INVOICE_LIST}
                        >
                          <div className="ms-3 ps-1 subactivelink">Invoice</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_invoice") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.JOB_CLOSING);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.JOB_CLOSING}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Job Close
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {/* // reports */}
              {splitLocation[1] === "reports" ? (
                <>
                  <div
                    style={{ borderBottom: "1px solid lightgrey" }}
                    className={` ${
                      splitLocation[1] === "reports"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {/* {checkSubPermission("fms_agentreport") && (
                      <>
                        <li className="nav-text ">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "active-link" : "link"
                            }
                            to={ROUTES.AGENT_REPORT}
                          >
                            <div className="ms-4  subactivelink">
                              Agent Report
                            </div>
                          </NavLink>
                        </li>
                      </>
                    )} */}
                    {/* {checkSubPermission("fms_monthlyreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.MONTHLY_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            Monthly Report
                          </div>
                        </NavLink>
                      </li>
                    )} */}
                    {/* {checkSubPermission("fms_costandexpensereport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.COST_AND_EXPENSE_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            Cost And Expense Report
                          </div>
                        </NavLink>
                      </li>
                    )} */}
                    {checkSubPermission("fms_enquiryreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.ENQUIRY_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            Enquiry Report
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_invoicereport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.INVOICE_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            Invoice Report
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li
                        className="nav-text "
                        // style={{ borderBottom: "1px solid lightgrey" }}
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.AWBBL_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            AWB/BL Report
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("fms_awbblreport") && (
                      <li
                        className="nav-text "
                        // style={{ borderBottom: "1px solid lightgrey" }}
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.DAILY_SALES_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            Daily Sales Report
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li
                        className="nav-text "
                        // style={{ borderBottom: "1px solid lightgrey" }}
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CUSTOMERWISE_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            Customerwise Report
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li
                        className="nav-text "
                        // style={{ borderBottom: "1px solid lightgrey" }}
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.SHIPMENT_PENDING_BOOKINGS}
                        >
                          <div className="ms-4  subactivelink">
                            Pending Bookings
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("fms_awbblreport") && (
                      <li
                        className="nav-text "
                        // style={{ borderBottom: "1px solid lightgrey" }}
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.SHIPMENT_PENDING_JOBS}
                        >
                          <div className="ms-4  subactivelink">
                            Pending Jobs
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("fms_awbblreport") && (
                      <li
                        className="nav-text "
                        // style={{ borderBottom: "1px solid lightgrey" }}
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.SHIPMENT_STATUS_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            Shipment Status Report
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {
                      <li
                        className="nav-text "
                        // style={{ borderBottom: "1px solid lightgrey" }}
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PURCHASE_BILL_REPORT}
                        >
                          <div className="ms-4  subactivelink">
                            Purchase Bill Report
                          </div>
                        </NavLink>
                      </li>
                    }
                    {checkSubPermission("fms_awbblreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.STATEMENT_OF_ACCOUNTS}
                        >
                          <div className="ms-4  subactivelink">
                            Statements of Accounts
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PENDING_INVOICES}
                        >
                          <div className="ms-4  subactivelink">
                            Pending Invoices
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PROFIT_AND_LOSS}
                        >
                          <div className="ms-4  subactivelink">
                            Profit and Loss
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.TRIAL_BALANCE}
                        >
                          <div className="ms-4  subactivelink">
                            Trial Balance
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.AGEING}
                        >
                          <div className="ms-4  subactivelink">Ageing</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.BALANCE_SHEET}
                        >
                          <div className="ms-4  subactivelink">
                            Balance Sheet
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_awbblreport") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CHART_OF_ACCOUNT}
                        >
                          <div className="ms-4 subactivelink">
                            Chart Of Accounts
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                  {splitLocation[1] == "reports" ? (
                    <>
                      <div
                        style={{ borderBottom: "1px solid lightgrey" }}
                        className={` ${
                          splitLocation[1] == "reports"
                            ? "nav_active_color d-none"
                            : "nav_active_color_secondary d-none"
                        } `}
                      >
                        {checkSubPermission("accounts_dailyexpensereport") && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.DAILY_EXPENSE_REPORT}
                            >
                              <div className="subactivelink ms-4 ">
                                Daily Expense Report
                              </div>
                            </NavLink>
                          </li>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {/* purchase order */}

              {splitLocation[1] == "purchase" ? (
                <>
                  <div
                    className={` ${
                      splitLocation[1] == "purchase" ||
                      currentOpen === "purchase" ||
                      splitLocation[1] == "purchase_order_list"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("crm_rfq") && (
                      <li className="nav-text " style={{ marginLeft: "" }}>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LIST_RFQ}
                        >
                          <div className="ms-4  subactivelink">RFQ</div>
                        </NavLink>
                      </li>
                    )}
                    {/* {checkSubPermission("purchase_purchaseorder") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PUCHASE_ORDER_LIST}
                        >
                          <div className="ms-4 subactivelink">
                            {" "}
                            Purchase Order
                          </div>
                        </NavLink>
                      </li>
                    )} */}
                    {checkSubPermission("purchase_purchasebill") && (
                      <li className="nav-text ">
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PURCHASEBILL}
                        >
                          <div className="ms-4 subactivelink">
                            {" "}
                            Purchase Bill
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {/* ######## Accounts ######## */}

              {splitLocation[1] == "accounts" ? (
                <>
                  <div
                    className={` ${
                      splitLocation[1] == "accounts" ||
                      currentOpen === "accounts"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("accounts_purchase") && (
                      <li className="nav-text d-none">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PURCHASE}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Purchase
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("accounts_dailyexpense") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.DAILY_EXPENSE);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.DAILY_EXPENSE}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Daily Expense
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("accounts_jobpayments") && (
                      <li className="nav-text d-none">
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.JOB_PAYMENTS}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Job Payments
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {/* {checkSubPermission("accounts_creditnotes") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.ADVANCE_PAYMENT_LIST}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Advance Payment
                          </div>
                        </NavLink>
                      </li>
                    )} */}
                    {checkSubPermission("accounts_receipts") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.LIST_Receipts);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LIST_Receipts}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Receipts
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("accounts_payments") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.PAYMENTS);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PAYMENTS}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Payments
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("accounts_creditnotes") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(
                              ROUTES.LIST_JOURNAL_ENTRY
                            );
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LIST_JOURNAL_ENTRY}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Journal Entry
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("accounts_creditnotes") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(ROUTES.CREDIT_NOTES);
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CREDIT_NOTES}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Credit Notes
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("accounts_creditnotes") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(
                              ROUTES.LIST_SUPPLIER_CREDITNOTES
                            );
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LIST_SUPPLIER_CREDITNOTES}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Supplier CreditNote
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {/* {checkSubPermission("accounts_debitnotes") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.DEBIT_NOTES}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Debit Notes
                          </div>
                        </NavLink>
                      </li>
                    )} */}
                    {checkSubPermission("accounts_debitnotes") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(
                              ROUTES.BANK_RECONCILIATION
                            );
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.BANK_RECONCILIATION}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Bank Reconciliation
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("accounts_ledger") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(
                              ROUTES.OPENING_BALANCE
                            );
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.OPENING_BALANCE}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Opening Balance
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("chart_of_account") && (
                      <li
                        className="nav-text "
                        onClick={() => {
                          unsavedChanges === true &&
                            handleClickMenuFormModelShow(
                              ROUTES.CHART_OF_ACCOUNT
                            );
                        }}
                      >
                        <NavLink
                          style={{
                            pointerEvents:
                              unsavedChanges === true ? "none" : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CHART_OF_ACCOUNT}
                        >
                          <div className="ms-3 ps-1 subactivelink">
                            Chart Of Accounts
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {/* ########## General Settings ######## */}

              {splitLocation[1] == "settings" ? (
                <>
                  <div
                    className={` ${
                      splitLocation[1] == "settings"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("generalsettings_countries") && (
                      <li
                        // style={{ borderBottom: "1px solid lightgrey" }}
                        className="nav-text "
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.COMPANYINFO}
                        >
                          <div className="ms-3  subactivelink">
                            Company Info
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </div>

                  {splitLocation[1] == "settings" ? (
                    <>
                      <div
                        className={` ${
                          splitLocation[1] == "settings"
                            ? "nav_active_color"
                            : "nav_active_color_secondary"
                        } `}
                      >
                        {checkSubPermission("generalsettings_countries") && (
                          <li className="nav-text">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.COUNTRYSELECT}
                            >
                              <div className="ms-3  subactivelink">
                                Countries
                              </div>
                            </NavLink>
                          </li>
                        )}

                        {checkSubPermission("generalsettings_currency") && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.CURRENCY}
                            >
                              <div className="ms-3  subactivelink">
                                Currency
                              </div>
                            </NavLink>
                          </li>
                        )}

                        {/* {checkSubPermission(
                          "generalsettings_invoicetemplates"
                        ) && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.SELECT_INVOICETEMPLATE}
                            >
                              <div className="ms-3  subactivelink">
                                Invoice Templates
                              </div>
                            </NavLink>
                          </li>
                        )} */}
                        {checkSubPermission("generalsettings_fmssettings") && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.FMSSETTINGS}
                            >
                              <div className="ms-3  subactivelink">
                                Fms Settings
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {checkSubPermission("generalsettings_fmssettings") && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.QUOTATION_TERMS_LIST}
                            >
                              <div className="ms-3  subactivelink">
                                Qutotation Terms
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {checkSubPermission("generalsettings_fmssettings") && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.SELLINGPRICE}
                            >
                              <div className="ms-3  subactivelink">
                                Selling Price Margin
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {checkSubPermission("generalsettings_fmssettings") && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.DIAMENTIONCALCULATION}
                            >
                              <div className="ms-3  subactivelink">
                                Dimension Calculation
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {checkSubPermission(
                          "generalsettings_invoicetemplates"
                        ) && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.SELECT_INVOICETEMPLATE}
                            >
                              <div className="ms-3  subactivelink">
                                Invoice Templates
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {checkSubPermission("accounts_accountsettings") && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.ACCOUNTS_SETTINGS}
                            >
                              <div className="ms-3 subactivelink">
                                Accounts Settings
                              </div>
                            </NavLink>
                          </li>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {splitLocation[1] == "masters" ? (
                <>
                  <div
                    className={` ${
                      splitLocation[1] == "masters" || currentOpen === "masters"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("services_service") && (
                      <li
                        // style={{ borderBottom: "1px solid lightgrey" }}
                        className="nav-text "
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.SERVICES}
                        >
                          <div className="ms-4 subactivelink"> Service</div>
                        </NavLink>
                      </li>
                    )}
                  </div>

                  {splitLocation[1] == "masters" ? (
                    <>
                      <div
                        className={` ${
                          splitLocation[1] == "masters"
                            ? "nav_active_color"
                            : "nav_active_color_secondary"
                        } `}
                      >
                        {checkSubPermission("crm_vendortypes") && (
                          <li className="nav-text ">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.VENDOR_TYPE}
                            >
                              <div className="ms-4  subactivelink">
                                Vendor Type
                              </div>
                            </NavLink>
                          </li>
                        )}

                        {checkSubPermission("crm_enquirysource") && (
                          <li
                            // style={{ borderBottom: "1px solid lightgrey" }}
                            className="nav-text "
                          >
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.ENQUIRY_SOURCE}
                            >
                              <div className="ms-4  subactivelink">
                                Enquiry Source
                              </div>
                            </NavLink>
                          </li>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {/* operations masters */}

              {clickedfrom == "masters" ? (
                <>
                  <div
                    // style={{ borderBottom: "1px solid lightgrey" }}
                    className={` ${
                      clickedfrom == "masters"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("fms_freighttypes") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.FRIGHTLIST}
                        >
                          <div className="ms-4  subactivelink">
                            Freight types
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_carrier") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CARRIER}
                        >
                          <div className="ms-4  subactivelink">Carrier</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_incoterm") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.INCOTERM}
                        >
                          <div className="ms-4  subactivelink">Incoterm</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_incoterm") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CARGO_TYPE}
                        >
                          <div className="ms-4  subactivelink">Cargo Type</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_paymentterms") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.TERMS_OF_PAYMENT}
                        >
                          <div className="ms-4  subactivelink">
                            Payment Terms
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_taxgroup") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.TAX_GROUP}
                        >
                          <div className="ms-4  subactivelink">Tax Group</div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("fms_taxtype") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.TAXTYPE}
                        >
                          <div className="ms-4  subactivelink">Tax Type</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_agents") && (
                      <li className="nav-text d-none ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LISTAGENT}
                        >
                          <div className="ms-4  subactivelink">Agents</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_location") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LOCATIONS}
                        >
                          <div className="ms-4  subactivelink">Location</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_containertypes") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CONTAINER_TYPES}
                        >
                          <div className="ms-4  subactivelink">
                            Container Types
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_containertypes") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.IMCOSTATUS}
                        >
                          <div className="ms-4  subactivelink">
                            Shipment Milestones
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_containertypes") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CLEARANCEDOCUMENTS}
                        >
                          <div className="ms-4  subactivelink">
                            Clearance Documents
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_containertypes") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.TIMESTAMPHEADS}
                        >
                          <div className="ms-4  subactivelink">
                            Timestamp Heads
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("fms_containertypes") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.FINANCIALYEAR}
                        >
                          <div className="ms-4  subactivelink">
                            Financial Year
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                  <div
                    // style={{ borderBottom: "1px solid lightgrey" }}
                    className={` ${
                      splitLocation[1] == "masters"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {/* {checkSubPermission("accounts_expensecategory") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.EXPENSE_CATEGORY}
                        >
                          <div className="ms-4  subactivelink">
                            Expense Category
                          </div>
                        </NavLink>
                      </li>
                    )} */}
                    {/* {checkPermission("credit_note_type") && (
                        <li className="nav-text ">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "active-link" : "link"
                            }
                            to={ROUTES.CREDIT_NOTE_TYPE}
                          >
                            <div className=" ms-5">Credit Note Type</div>
                          </NavLink>
                        </li>
                      )} */}
                    {checkSubPermission("accounts_paymentmode") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.PAYMEMENT_MODE}
                        >
                          <div className="ms-4  subactivelink">
                            Payment Mode
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {/* {checkPermission("add_purchase") && (
                    <li className="nav-text">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.ADD_PURCHASE}
                      >
                        <div className=" ms-3 ps-3">Add Purchase</div>
                      </NavLink>
                    </li>
                    )} */}

                    {/* {checkPermission("purchase") && (
                        <li className="nav-text">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "active-link" : "link"
                            }
                            to={ROUTES.PURCHASE}
                          >
                            <div className=" ms-3 ps-3">Purchase</div>
                          </NavLink>
                        </li>
                      )} */}

                    {checkSubPermission("accounts_bankdetails") && (
                      <li className="nav-text">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.BANK_DETAILS}
                        >
                          <div className="ms-4  subactivelink">
                            Bank Details
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("accounts_creditnotetype") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CREDIT_NOTE_TYPE}
                        >
                          <div className="ms-4  subactivelink">
                            Credit Note Type
                          </div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("accounts_gltype") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.GL_TYPE}
                        >
                          <div className="ms-4  subactivelink">GL Type</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("accounts_accountgroup") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.ACC_GROUP}
                        >
                          <div className="ms-4 subactivelink">
                            Account Group
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("accounts_ledger") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.LEDGER}
                        >
                          <div className="ms-4  subactivelink">Ledger</div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                  <div
                    className={` ${
                      splitLocation[1] == "masters"
                        ? "nav_active_color"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("product_category") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.CATEGORY_LIST}
                        >
                          <div className="ms-4  subactivelink">Category</div>
                        </NavLink>
                      </li>
                    )}

                    {/* {checkSubPermission("product_brands") && (
                          <li className="nav-text">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.BRANDS}
                            >
                              <div className=" ms-4 ps-2 subactivelink">
                                Brands
                              </div>
                            </NavLink>
                          </li>
                        )} */}

                    {/* {checkSubPermission("product_attributes") && (
                          <li className="nav-text">
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active-link" : "link"
                              }
                              to={ROUTES.ATTRIBUTES}
                            >
                              <div className=" ms-4 ps-2 subactivelink">
                                Attributes
                              </div>
                            </NavLink>
                          </li>
                        )} */}
                    {checkSubPermission("product_unitandmeasurements") && (
                      <li
                        // style={{ borderBottom: "1px solid lightgrey" }}
                        className="nav-text "
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.UNIT_LIST}
                        >
                          <div className="ms-4  subactivelink">UOM</div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                  <div
                    className={` ${
                      splitLocation[1] == "masters"
                        ? "nav_active_color pb-5"
                        : "nav_active_color_secondary"
                    } `}
                  >
                    {checkSubPermission("hrms_branch") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.BRANCHES}
                        >
                          <div className=" ms-4 subactivelink">Branches</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("hrms_department") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.DEPARTMENTS}
                        >
                          <div className=" ms-4 subactivelink">Departments</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("hrms_designation") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.DESIGNATION}
                        >
                          <div className=" ms-4 subactivelink">Designation</div>
                        </NavLink>
                      </li>
                    )}
                    {checkSubPermission("hrms_employeetype") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.EMPLOYMENT_TYPE}
                        >
                          <div className=" ms-4 subactivelink">
                            Employee Type
                          </div>
                        </NavLink>
                      </li>
                    )}

                    {checkSubPermission("hrms_employeegrade") && (
                      <li className="nav-text ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active-link" : "link"
                          }
                          to={ROUTES.EMPLOYEEGRADE}
                        >
                          <div className=" ms-4 subactivelink">
                            Employee grade
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
        <CustomModel
          show={warningModal}
          onHide={() => {
            setWarningModal(false);
          }}
          View_list={true}
          list_content={
            <>
              <div className="">
                <div className="row p-2">
                  <div className="col-12">
                    <h4>Leave this Page ?</h4>
                  </div>
                  <div className="col-12">
                    <p style={{ fontWeight: "600" }}>
                      You have Unsaved changes on this Page, Are you sure you
                      want to leave ?
                    </p>
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center gap-2">
                    <div className="">
                      <Button
                        btnType="cancel"
                        type="button"
                        onClick={() => {
                          setWarningModal(false);
                        }}
                      >
                        Stay
                      </Button>
                    </div>
                    <div className="">
                      <Button
                        btnType="save"
                        type="button"
                        onClick={() => handleModelLeaveTrigger()}
                      >
                        Leave
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
