import { useEffect, useState } from "react";
import PublicFetch from "../../utils/PublicFetch";
import { GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
import "./shipmentPendingJobprint.scss";
// import styles from "./shipmentPendingJobprint.module.scss";

function ShipPendingJobPrint({
  shipment_table_data1,
  //   shipment_table_data2
  quotationHeaderImg
}) {
  const [companyInfodata, setCompanyInfodata] = useState();

  useEffect(() => {
    const printStyle =
      '<style type="text/css" media="print">@page { size: A4 landscape !important; }</style>'
      document.head.insertAdjacentHTML('afterbegin',printStyle)
  }, []);

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    companyinfo();
    if (companyInfodata) {
      window.print("", "_blank");
    }
  }, []);

  return (
    <>
      <div className="Shipmentlandscape">
        {/* div to set page border on all pages */}
        <div id={"pageborder_shipment"}></div>
        {/* page border div ends */}
        <table className={`${"pendingjob_header"} ${"landscape"}`}>
          {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => {
              return (
                <thead
                  style={{
                    backgroundColor: "white",
                    zIndex: 999,
                    borderTop: "0.5px solid black",
                    borderLeft: "0.5px solid black",
                    borderRight: "0.5px solid black",
                  }}
                >
                  <tr className="">
                    <div className={"quotheader"}>
                      {/* <div className="header__address_wrapper"> */}
                      <div className="">
                        <img
                          style={{ height: "150px", width: "700px" }}
                          // src={`${process.env.REACT_APP_BASE_URL}/${item.company_quotation_header}`}
                          src={quotationHeaderImg}
                          alt=""
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </tr>
                </thead>
              );
            })}

          <tbody>
            <div className="text-center ">
              <h2 className={"quotation_heading_style"}>
                SHIPMENT PENDING JOBS
              </h2>
            </div>
            <br></br>
            {/* <h5 style={{fontWeight:600 ,padding:"10px"}}>Shipment Pending Booking</h5> */}

            <table className={"print_table"} cellSpacing={0}>
              <thead className={"print_table"}>
                <tr className="">
                  <th className="">SL NO.</th>
                  <th className="" style={{ textAlign: "left" }}>
                    REF NO
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    DATE
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    FREIGHT TYPE
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    CUSTOMER
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    SHIPPER
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    ORIGIN COUNTRY
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    DESTINATION COUNTRY
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    POL
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    POD
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    STATUS
                  </th>
                  <th className="" style={{ textAlign: "left" }}>
                    SHIPMENT REF NO
                  </th>
                </tr>
              </thead>
              <tbody className="">{shipment_table_data1}</tbody>
            </table>

            <br></br>
            <br></br>
          </tbody>
        </table>
      </div>
    </>
  );
}
export default ShipPendingJobPrint;
