export function checkPermission(name) {
  let permissions = localStorage.getItem("userPermissions");
  if (permissions) {
    let userPermissions = JSON.parse(permissions);
    let hasPermission = userPermissions.some((item, index) => {
      return item === name;
    });
    return hasPermission;
    // return true;
  } else {
    return false;
  }
}

export function checkSubPermission(name) {
  let permissions = localStorage.getItem("userPermissionsSub");
  if (permissions) {
    console.log("permissions123", name);
   
    let userPermissions = JSON.parse(permissions);
    let hasPermission = userPermissions.some((item, index) => {
      return item.subject === name;
    });
     console.log("hasPer", name, hasPermission);
    return hasPermission;
    // return true;
  } else {
    return false;
  }
}
