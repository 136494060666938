import React, { useState } from "react";
import PrintReports from "../../../../components/Reports-Print/Printreport";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PublicFetch from "../../../../utils/PublicFetch";
import { ACCOUNTS } from "../../../../api/bootapi";

function AgeingreportPrint() {
  const [searchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const [AllDatas, setAllDatas] = useState();
  const [reportType, setReportType] = useState();
  const [ledgerId, setLedgerId] = useState();
  const [AllObjects, setAllObjects] = useState();
  const [NoofDecimal, setNoOfDecimal] = useState(
    localStorage.getItem("NumberOfDecimals")
  );

  console.log("searchParamsreptype", searchParams.get("ageingreporttype"));
  console.log("searchParamdateto", searchParams.get("ledgerid"));

  useEffect(() => {
    setReportType(searchParams.get("ageingreporttype"));
    setLedgerId(searchParams.get("ledgerid"));

    if (reportType && ledgerId) {
      GetAgeingReport();
    }
  }, [reportType, ledgerId]);

  const GetAgeingReport = () => {
    PublicFetch.post(`${ACCOUNTS}/reports/ageing-report`, {
      ageing_report_type: reportType,
      ageing_report_ledger_id: parseInt(ledgerId),
    })
      .then((res) => {
        if (res?.data?.success) {
          let temp = [];
          temp.push(res?.data?.data?.data);
          console.log("Ageing Report", res?.data?.data?.data);
          setAllDatas(res?.data?.data?.data);
          console.log("Temperery check", Object.keys(res?.data?.data?.data));
          //   setAllObjects(Object.keys(res?.data?.data?.data));
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  //  UseEffect For Table Data Arrange
  useEffect(() => {
    if (AllDatas?.length) {
      let temp = [];
      //   let value = AddForm.getFieldsValue();
      if (reportType === "Receivable") {
        AllDatas &&
          AllDatas?.length > 0 &&
          AllDatas?.map((item, index) => {
            temp.push({
              ageing_name:
                item?.fms_v1_jobs?.crm_v1_customer?.accounts_v1_account_ledger
                  ?.acc_ledger_name,
              ageing_due_amt: item?.invoice_due_amount?.toFixed(NoofDecimal),
              ageing_total_amt: Number(item?.invoice_grand_total)?.toFixed(
                NoofDecimal
              ),
              ageing_range: item.range_name,
            });
          });
        setTableData([...temp]);
      } else if (reportType === "Payable") {
        AllDatas &&
          AllDatas?.length > 0 &&
          AllDatas.map((item, index) => {
            temp.push({
              ageing_name:
                item?.crm_v1_vendors?.accounts_v1_account_ledger
                  ?.acc_ledger_name,
              ageing_due_amt: item?.pur_bill_due_amount?.toFixed(NoofDecimal),
              ageing_total_amt: Number(item?.pur_bill_total_amount)?.toFixed(
                NoofDecimal
              ),
              ageing_range: item.range_name,
            });
          });
        setTableData([...temp]);
        handleprint();
      }
    }
  }, [AllDatas]);

  const handleprint = () => {
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <div>
      <PrintReports
        report_name={"AgeingReport"}
        table_header_data={
          <>
            <tr className="">
              <th className="head_border" style={{ textAlign: "left" }}>
                SL NO.
              </th>
              <th className="" style={{ textAlign: "left" }}>
                NAME
              </th>
              <th className="" style={{ textAlign: "left" }}>
                Lessthan or =30
              </th>
              <th className="tsk_exp" style={{ textAlign: "left" }}>
                31-45
              </th>
              <th className="" style={{ textAlign: "left" }}>
                46-50
              </th>
              <th className="" style={{ textAlign: "left" }}>
                61-90
              </th>
              <th style={{ textAlign: "left" }}> greaterthan 90</th>
            </tr>
          </>
        }
        table_print_data={
          <>
            {tableData &&
              tableData?.map((item, indx) => {
                console.log("dailyydatata", item);
                return (
                  <tr>
                    <td>{indx + 1} </td>
                    <td>{item?.ageing_name} </td>
                    <td>
                      {item?.ageing_range === "range1" ? (
                        <div>{item?.ageing_total_amt} </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      {" "}
                      {item?.ageing_range === "range2" ? (
                        <div>{item?.ageing_total_amt} </div>
                      ) : (
                        ""
                      )}{" "}
                    </td>
                    <td>
                      {item?.ageing_range === "range3" ? (
                        <div>{item?.ageing_total_amt} </div>
                      ) : (
                        ""
                      )}{" "}
                    </td>
                    <td>
                      {" "}
                      {item?.ageing_range === "range4" ? (
                        <div>{item?.ageing_total_amt} </div>
                      ) : (
                        ""
                      )}{" "}
                    </td>
                    <td>
                      {item?.ageing_range === "range5" ? (
                        <div>{item?.ageing_total_amt} </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
          </>
        }
      />
    </div>
  );
}

export default AgeingreportPrint;
