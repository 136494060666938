import { useEffect, useState } from "react";
import SupplierNotePrint from "../../../components/CreditNotesPrints/SupplierCreditNote";
import PublicFetch from "../../../utils/PublicFetch";
import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import { useParams } from "react-router-dom";
import moment from "moment";

function SupplierCreditPrint() {
  const [quotHeaderImg, setQuotHeaderImg] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();
  const { id } = useParams();
  const [supplierCreditData, setSupplierCreditData] = useState();
  const [expTableDetails, setExptTableDetails] = useState([]);
  const [TableDetails, setTableDetails] = useState();
  const [totalLx, setTotalLx] = useState();
  const [totalFx, setTotalFx] = useState();

  const getallcmpny = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      setQuotHeaderImg(allcmpny.data.data[0].company_quotation_header);

      console.log(" cmpny details is", allcmpny.data.data);
      // setcmpnyinfo(allcmpny.data.data);
      // // setcmpnyupdate(allcmpny.data.data.length)
      // console.log("dataa", allcmpny.data.data.length);
      // let arry = [];
      // allcmpny.data.data.forEach((i, indx) => {
      //   arry.push(i);
      // });
      // console.log("all cmpny are", arry);
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  const getsingleSupplierCreditNote = () => {
    PublicFetch.get(`${ACCOUNTS}/supplier-credit-note/${id}`)
      .then((res) => {
        if (res.data.success) {
          console.log("Success of single supplier", res.data.data);
          let item = res?.data?.data;
          let date = moment(item?.daily_expense_date);

          console.log("dailyexpnsee", item);
          setSupplierCreditData(item);
          setTableDetails( item?.accounts_v1_account_supplier_credit_note_details)
          //   setExptTableDetails(item?.accounts_v1_daily_expense_details)
          console.log(
            "rmnfmn",
            item?.accounts_v1_daily_expense_details?.crm_v1_services
              ?.service_name
          );
          let tmp = [];
          item?.accounts_v1_account_supplier_credit_note_details.map((itm, indx) => {
            console.log("fmcmcfk", itm);
            tmp.push({
              ledger: itm?.accounts_v1_account_ledger?.acc_ledger_name,
              bill_no:itm?.crm_v1_purchase_bills?.pur_bill_no,
              acc_dn_det_amount_fx: itm?.acc_supplier_crn_det_amount_fx.toFixed(3) ,
              acc_dn_det_amount_lx: itm?.acc_supplier_crn_det_amount_lx.toFixed(3),
              acc_dn_det_remarks: itm?.acc_supplier_crn_det_remarks,
            });
          });
          setExptTableDetails(tmp);
          //   setTotalAmt(item?.daily_expense_total_amount)
          //   setDetailsData(item?.accounts_v1_daily_expense_details)
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    getallcmpny();
    getsingleSupplierCreditNote()
  }, []);

  useEffect(() => {
    console.log("quotHeaderImg", quotHeaderImg);
    if (quotHeaderImg) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${quotHeaderImg}`, {
        responseType: "blob",
      })
        .then((resp) => {
          console.log("respBlob".resp?.blob());

          console.log("quotresp", resp);

          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
          console.log("blobconvert", blob);
        })
        .catch((err) => console.log("error123", err));
    }
  }, [quotHeaderImg]);

  console.log("blobUrl", blobUrl);
  useEffect(() => {
    console.log("blobUrlIn", blobUrl);
    if (blobUrl) {
      setTimeout(() => {
        window.print();
      }, 10);
    }
  }, [blobUrl]);
  useEffect(() => {
    let TotalFx = 0;
    let TotalLx = 0;
    TableDetails &&
    TableDetails?.length > 0 &&
    TableDetails.map((item, index) => {
      console.log("nmfmn",item);
        TotalFx += item?.acc_supplier_crn_det_amount_fx;
        TotalLx += item?.acc_supplier_crn_det_amount_lx;
      });
    setTotalLx(TotalLx.toFixed(3) );
    setTotalFx(TotalFx.toFixed(3));
  }, [TableDetails]);

  return (
    <>
      <div>
        <SupplierNotePrint
        supplierCreditNote={true}
          quotationHeaderimg={blobUrl}
          totalFxAmt={totalFx}
          totalLxAmt={totalLx}
          invoice_details1={
            <>
              <tr className="qtn_qtn_header">
                <td>Voucher No</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}> {supplierCreditData?.acc_supplier_crn_voucher_no}</td>
              </tr>
              <tr className="qtn_qtn_header">
                <td>Date</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>{moment(supplierCreditData?.acc_supplier_crn_date).format(
                        "DD-MM-YYYY"
                      )}</td>
              </tr>
              <tr className="qtn_qtn_header">
                <td>Vendor</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}> {supplierCreditData?.accounts_v1_account_ledger?.acc_ledger_name}</td>
              </tr>
              
            </>
          }
          invoice_details2={
            <>
              <tr className="qtn_qtn_header">
                <td>Currency</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}>{supplierCreditData?.generalsettings_v1_currency?.currency_name}</td>
              </tr>
              <tr className="qtn_qtn_header">
                <td>Exchange Rate</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}> {supplierCreditData?.acc_supplier_crn_exchange_rate}</td>
              </tr>
              <tr className="qtn_qtn_header">
                <td>Particulars</td>
                <td>:</td>
                <td style={{ fontWeight: 600 }}> {supplierCreditData?.acc_supplier_crn_particulars}</td>
              </tr>
             
            </>
          }
          supplier_table_data={
            <>
            {expTableDetails && expTableDetails?.length>0 && expTableDetails?.map((itm,indx)=>{
              console.log("smbmnv",itm);
              return(
                <>
                 <tr>
                <td className="">{indx+1} </td>
                <td className="">{itm?.ledger} </td>
                <td className="">{itm?.bill_no} </td>
                <td className=""style={{ textAlign: "right" }}>{itm?.acc_dn_det_amount_fx} </td>
                <td className="" style={{ textAlign: "right" }}>{itm?.acc_dn_det_amount_lx} </td>
                <td className="">{itm?.acc_dn_det_remarks} </td>
              </tr>
                </>
              )
            })}
            </>
          }
        />
      </div>
    </>
  );
}
export default SupplierCreditPrint;
