import React, { useEffect, useState } from "react";
import PaymentLayout from "../../../components/Invoice/PaymentPrint/PaymentLayout";
import PublicFetch from "../../../utils/PublicFetch";
import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import { useParams } from "react-router-dom";
import moment from "moment";

function PaymentPrint() {
  const { id } = useParams();
  const [compantInfo, setCompanyInfo] = useState();
  const [paymentData, setPaymentData] = useState();

  const companyData = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        if (res?.data?.success) {
          setCompanyInfo(res?.data?.data[0]);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const GetPaymentData = (id) => {
    if (id) {
      PublicFetch.get(`${ACCOUNTS}/payment/${id}`)
        .then((res) => {
          if (res?.data?.success) {
            console.log("receptt datatat", res?.data?.data);
            setPaymentData(res?.data?.data);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  useEffect(() => {
    GetPaymentData(id);
    companyData();
  }, [id]);

  useEffect(() => {
    if (compantInfo && paymentData) {
      setTimeout(() => {
        window.print();
      }, 1200);
    }
  }, [compantInfo, paymentData]);
  return (
    <div id="pageborder">
      {/* <div id="pageborder"></div> */}
      <div className="receipt-print-container">
        <table>
          <thead
            style={{
              backgroundColor: "white",
              zIndex: 100,
              borderBottom: "1px solid black",
            }}
            className=""
          >
            <tr style={{}}>
              <img
                width={"100%"}
                src={`${process.env.REACT_APP_BASE_URL}/${compantInfo?.company_quotation_header}`}
                alt=""
              />
            </tr>
          </thead>
          <tbody style={{ position: "relative" }} className="receipt-content">
            <tr
              style={{
                width: "100%",
                textAlign: "center",
                borderBottom: "1px solid black",
                paddingTop: "10px",
              }}
            >
              <h2 className="mt-3">Payment</h2>
              <tr style={{ width: "100%" }}>
                <th style={{ width: "1000px", textAlign: "left" }}>
                  <div className="p-2">
                    <table style={{ position: "relative" }}>
                      <tbody>
                        <tr>
                          <th style={{ textAlign: "left" }}>Date</th>
                          <th style={{ textAlign: "left" }}>:</th>
                          <th style={{ textAlign: "left" }}>
                            {moment(paymentData?.acc_payment_date).format(
                              "DD-MM-YYYY"
                            )}
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "left" }}>Vendor Name</th>
                          <th style={{ textAlign: "left" }}>:</th>
                          <th style={{ textAlign: "left" }}>
                            {
                              paymentData
                                ?.accounts_v1_payments_acc_payment_payment_to
                                ?.acc_ledger_name
                            }
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "left" }}>Voucher No</th>
                          <th style={{ textAlign: "left" }}>:</th>
                          <th style={{ textAlign: "left" }}>
                            {paymentData?.acc_payment_voucher_no}
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "left" }}>Payment Type</th>
                          <th style={{ textAlign: "left" }}>:</th>
                          <th style={{ textAlign: "left" }}>
                            {paymentData?.acc_payment_payment_type}
                          </th>
                        </tr>
                        {paymentData?.acc_payment_payment_type === "Bank" ? (
                          <>
                            <tr>
                              <th style={{ textAlign: "left" }}>Cheque No</th>
                              <th style={{ textAlign: "left" }}>:</th>
                              <th style={{ textAlign: "left" }}>
                                {paymentData?.acc_payment_payment_type}
                              </th>
                            </tr>
                            <tr>
                              <th style={{ textAlign: "left" }}>Cheque Date</th>
                              <th style={{ textAlign: "left" }}>:</th>
                              <th style={{ textAlign: "left" }}>
                                {moment(
                                  paymentData?.acc_payment_cheque_date
                                ).format("DD-MM-YYYY")}
                              </th>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </th>
                <th style={{ width: "1000px", textAlign: "left" }}>
                  <div className="p-2">
                    <table>
                      <tbody>
                        <tr>
                          <th style={{ textAlign: "left" }}>Currency</th>
                          <th style={{ textAlign: "left" }}>:</th>
                          <th style={{ textAlign: "left" }}>
                            {
                              paymentData?.generalsettings_v1_currency
                                ?.currency_name
                            }
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "left" }}>Exchange Rate</th>
                          <th style={{ textAlign: "left" }}>:</th>
                          <th style={{ textAlign: "left" }}>
                            {paymentData?.acc_payment_exchange_rate?.toFixed(
                              compantInfo?.number_of_decimal
                            )}
                          </th>
                        </tr>
                        {paymentData?.acc_payment_payment_type === "Bank" ? (
                          <>
                            <tr>
                              <th style={{ textAlign: "left" }}>Bank Name</th>
                              <th style={{ textAlign: "left" }}>:</th>
                              <th style={{ textAlign: "left" }}>
                                {paymentData?.acc_payment_cheque_bank}
                              </th>
                            </tr>
                            <tr>
                              <th style={{ textAlign: "left" }}>Branch</th>
                              <th style={{ textAlign: "left" }}>:</th>
                              <th style={{ textAlign: "left" }}>
                                {paymentData?.acc_payment_cheque_branch}
                              </th>
                            </tr>
                            <tr>
                              <th style={{ textAlign: "left" }}>
                                Payment Method
                              </th>
                              <th style={{ textAlign: "left" }}>:</th>
                              <th style={{ textAlign: "left" }}>
                                {
                                  paymentData?.accounts_v1_payment_modes
                                    ?.pay_mode_name
                                }
                              </th>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </th>
              </tr>
            </tr>
            <tr
              style={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <div className="p-2">
                <p>Particular</p>
                <p>{paymentData?.acc_payment_particulars}</p>
              </div>
            </tr>
            {paymentData?.accounts_v1_account_payment_bill_details?.length >
              0 && (
              <tr
                style={{
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <div className="p-2 ">
                  <p>Invoice Details</p>
                  <table style={{}} className="">
                    <thead>
                      <tr style={{ backgroundColor: "lightgray" }}>
                        <th>Sl No</th>
                        <th>Invoice No</th>
                        <th>Invoice Date</th>
                        <th>Invoice Amount</th>
                        <th>Due Amount</th>
                        <th>Current Amount</th>
                        <th>Balance Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentData?.accounts_v1_account_payment_bill_details &&
                        paymentData?.accounts_v1_account_payment_bill_details
                          ?.length &&
                        paymentData?.accounts_v1_account_payment_bill_details?.map(
                          (item, index) => {
                            return (
                              <tr>
                                <th>{index + 1}</th>
                                <th>
                                  {item?.crm_v1_purchase_bills?.pur_bill_no}
                                </th>
                                <th>
                                  {moment(
                                    item?.crm_v1_purchase_bills?.pur_bill_date
                                  ).format("DD-MM-YYYY")}
                                </th>
                                <th>
                                  {item?.crm_v1_purchase_bills?.pur_bill_total_amount?.toFixed(
                                    compantInfo?.number_of_decimal
                                  )}
                                </th>
                                <th>
                                  {item?.acc_pay_bill_det_bill_due?.toFixed(
                                    compantInfo?.number_of_decimal
                                  )}
                                </th>
                                <th>
                                  {item?.acc_pay_bill_det_adjust_amt?.toFixed(
                                    compantInfo?.number_of_decimal
                                  )}
                                </th>
                                <th>
                                  {item?.acc_pay_bill_det_bal_amt?.toFixed(
                                    compantInfo?.number_of_decimal
                                  )}
                                </th>
                              </tr>
                            );
                          }
                        )}

                      <tr
                        style={{
                          textAlign: "left",
                          borderTop: "1px solid black",
                        }}
                      >
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          <label style={{ fontWeight: "700" }}>Total</label>{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                        </th>
                        <th>
                          <label style={{ fontWeight: "700" }}>
                            {paymentData?.acc_payment_total_fx?.toFixed(
                              compantInfo?.number_of_decimal
                            )}
                          </label>
                        </th>
                        <th></th>
                      </tr>
                      <tr
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          <label style={{ fontWeight: "700" }}>Discount</label>{" "}
                          &nbsp;&nbsp;&nbsp;:
                        </th>
                        <th>
                          <label style={{ fontWeight: "700" }}>
                            {paymentData?.acc_payment_amt_discount?.toFixed(
                              compantInfo?.number_of_decimal
                            )}
                          </label>
                        </th>
                        <th></th>
                      </tr>
                      <tr
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          <label style={{ fontWeight: "700" }}>Net Total</label>{" "}
                          &nbsp;&nbsp;&nbsp;:
                        </th>
                        <th>
                          <label style={{ fontWeight: "700" }}>
                            {paymentData?.acc_payment_net_total?.toFixed(
                              compantInfo?.number_of_decimal
                            )}
                          </label>
                        </th>
                        <th></th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PaymentPrint;
