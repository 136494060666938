import React, { useEffect, useState } from "react";
// import "../invoiceStyle.scss";
import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import PublicFetch from "../../../utils/PublicFetch";
import Invoicetmp4 from "../../../assets/logo/CargoPlusinvoicelogo.png";
import "./invoicetemp5.scss";

function InvoicePrintTemp5({
  invoice_details1,
  invoice_details2,
  invoice_table_header,
  invoice_table_data,
  amount_in_words,
  sub_total,
  total,
  permanent_made,
  balance_due,
  invoice_no,
  Invoice_type,
  invoice_number,
  billto,
  // settemp1,
  billcustomername,
  bankDetails,
  invoiceHeaderImg,
  bankDetailsView = true,
  viewBillDetails = true,
  totalView = true,
  totalInWordsView = true,
  grandTotalViewPurchaseBill = false,
  grandTotalView,
  billToCust,
  contentSucess = false,
  invoiceDate,
  job_no,
  totalcalculation,
}) {
  const [companyInfodata, setCompanyInfodata] = useState();
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [defaultbank, setdefaultbank] = useState();

  // const [temp1,settemp1] =useState()

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const allCurrency = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/currency`)
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of cuurency", res.data.data);
          res?.data?.data?.forEach((item, index) => {
            if (item.currency_is_default === 1) {
              console.log("default currency", item);
              setDefaultCurrency(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getallbanks = async () => {
    try {
      const allbanks = await PublicFetch.get(`${ACCOUNTS}/bank`);
      console.log("getting all bank details", allbanks.data.data);
      // setAllbankdetails(allbanks.data.data)
      allbanks?.data?.data?.forEach((item, index) => {
        if (item.bank_default === 1) {
          console.log("default bankk", item);
          // setdefaultbank(item);
          // setDefaultCurrency(item);
        }
      });
    } catch (err) {
      console.log("error to fetching  bank details", err);
    }
  };

  useEffect(() => {
    if (bankDetails) {
      setdefaultbank(bankDetails);
    }
  }, [bankDetails]);

  // let template12 = "uploads/invoiceTemplate/template1.png"

  // function camelize(str) {
  //   return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
  //     return index === 0 ? word.toLowerCase() : word.toUpperCase();
  //   }).replace(/\s+/g, '');
  // }
  //   const camalize = (str) => {

  //      str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) =>{
  //       console.log("camalize", m ,chr);
  //       chr.toUpperCase()}).replace();
  // }
  // camalize("dfisfhssd")
  // const capitalize = str => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)

  useEffect(() => {
    companyinfo();
    allCurrency();
    getallbanks();
    // settemp1(template12)
  }, []);

  // logo,content,invoicedetails-invoicetemplate-6
  return (
    <div>
      {contentSucess === true ? (
        <div>
          {/* div to set page border on all pages */}

          <div id="pagebordertemp5"></div>
          {/* page border div ends */}
          <table className="invoice_header_temp5">
            {companyInfodata &&
              companyInfodata.length > 0 &&
              companyInfodata?.map((item, index) => {
                return (
                  <thead className="invoice_header_temp5">
                    <tr className="invoice_header_temp5">
                      <div className="">
                        {/* <div className="header__address_wrapper_temp5_width">
                        <div className="">
                          <img
                            // src={`${process.env.REACT_APP_BASE_URL}/${item.company_logo}`}
                            src={Invoicetmp4}
                            alt=""
                            height={130}
                            width={125}
                          />
                        </div>
                        <div className="">
                          <h4>{item.company_name}</h4>{" "}
                          <div className="address_width_temp5">
                            {item.company_address}
                            <br />
                            {item.company_country}
                            <br />
                            Phone :{item.company_phone}
                            <br />
                            {item.company_email}
                            <br />
                            {item.company_website}
                          </div>
                        </div>
                      </div> */}
                        <div className="invoice_details_headernew__template5 py-2">
                          <div className="invoice_details__header_width ">
                            <table>
                              <tbody>
                                <div className="d-flex justify-content-center">
                                <img
                                  // src={`${process.env.REACT_APP_BASE_URL}/${item.company_logo}`}
                                  src={Invoicetmp4}
                                  alt=""
                                  height={130}
                                  width={125}
                                />
                                <div className="px-2">
                                  <h4 className="m-0 color_temp5_cmpnynme">{item.company_name}</h4>{" "}
                                  <div className="address_width_temp5">
                                    {item.company_address}
                                    <br />
                                    {item.company_country}
                                    <br />
                                    Phone :{item.company_phone}
                                    <br />
                                    {item.company_email}
                                    <br />
                                    {item.company_website}
                                  </div>
                                </div>
                                </div>
                              </tbody>
                            </table>
                          </div>
                          <div className="invoice_details_headernew__template5_width ">
                            <table>
                              <tbody>
                                <h1 className="invoice_bold_temp5">INVOICE</h1>
                                <div className="">

                              
                                <tr>
                                  <td className="invoice_width_tmp5" >
                                    
                                    Invoice No
                                  </td>
                                  <td>:</td>
                                  <td style={{ fontWeight: 600, paddingLeft:"3px"}}>
                                    {invoice_no}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="invoice_width_tmp5"> Date</td>
                                  <td>:</td>
                                  <td style={{ fontWeight: 600,paddingLeft:"3px" }}>
                                    {invoiceDate}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="invoice_width_tmp5"> Job No</td>
                                  <td>:</td>
                                  <td style={{ fontWeight: 600,paddingLeft:"3px" }}>{job_no} </td>
                                </tr>
                                </div>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* <div
                          className="header__invoice_temp5 "
                          style={{ paddingTop: "50px" }}
                        >
                          <h1 className="invoice_bold_temp5">INVOICE</h1>

                          <tr>
                            <td style={{ textAlign: "left" }}> Invoice No</td>
                            <td>:</td>
                            <td style={{ fontWeight: 600 }}>{invoice_no} </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}> Date</td>
                            <td>:</td>
                            <td style={{ fontWeight: 600 }}>{invoiceDate} </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}> Job No</td>
                            <td>:</td>
                            <td style={{ fontWeight: 600 }}>{job_no} </td>
                          </tr>
                        </div> */}

                        {/* //   )} */}
                      </div>
                    </tr>
                  </thead>
                );
              })}
            <div className="invoice_details_template5 py-2">
              <div className="invoice_details__col_template5 invoice_details__col_1_template5">
                <table>
                  <tbody>
                    {/* {invoice_details1} */}

                    <tr className="invoice_header_temp5">
                      <td className="widthtemp5billTo" >Bill To</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600,paddingLeft:"5px" }}>{billToCust} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="invoice_details_template5 ">
              <div className="invoice_details__col_template5 invoice_details__col_1_template5">
                <table>
                  <tbody>{invoice_details1}</tbody>
                </table>
              </div>
              <div className="invoice_details__col_template5 ">
                <table>
                  <tbody>
                    {invoice_details2}
                    {/* <>
                    <tr className="invoice_header">
                      <td>Project Name</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600 }}>Project Name</td>
                    </tr>
                    <tr className="invoice_header">
                      <td>MAWB/MBL</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600 }}>MAWB/MBL</td>
                    </tr>
                    <tr className="invoice_header">
                      <td>Origin</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600 }}>origin</td>
                    </tr>
                    <tr className="invoice_header">
                      <td>Destination</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600 }}>Destination</td>
                    </tr>
                    <tr className="invoice_header">
                      <td>Shipper</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600 }}>Shipper</td>
                    </tr>
                    <tr className="invoice_header">
                      <td>Consignee</td>
                      <td>:</td>
                      <td style={{ fontWeight: 600 }}>Consignee</td>
                    </tr>
                  </> */}
                  </tbody>
                </table>
              </div>
            </div>
            {/* {billto && ( */}
            <>
              <tbody>
                {/* listing table start */}
                <table
                  className="temp5_details_table invoice_header_temp5"
                  cellSpacing={0}
                >
                  <thead className="invoice_header_temp5">
                    <>
                      <tr className="py-3">
                        <th className="th_temp5">Slno </th>
                        <th className="invoice_tsk_exp_temp5">
                          Task & description
                        </th>
                        {/* <th className="invoice_temp5_remarks">Remarks</th> */}
                        <th className="invoice_temp5_amount" style={{ textAlign: "right" }}>Amount</th>
                      </tr>
                    </>
                  </thead>
                  <tbody className="temp5_details_table_first">
                    {invoice_table_data}
                  </tbody>
                </table>

                {/* {totalInWordsView === true ? (
                <table>
                  <tbody></tbody>
                  <tbody></tbody>
                  <tr className="invoice_header_temp5">
                    <td align="center"></td>
                    <td className="tsk_exp_totalwords">
                      Total In Words ( {defaultCurrency?.currency_code} ) :{" "}
                      <td> {amount_in_words} Only </td>
                    </td>
                  </tr>
                
                </table>
              ) : (
                ""
              )} */}
                <div className="invoice_details_temp5_details_table_second ">
                  <div className="invoice_details__col_temp5_details_table_second_part1 invoice_details__col_1_temp5_details_table_second">
                    <table>
                      <tbody>
                        <tr className="invoice_header_temp5">
                          <td align="center"></td>
                          <td className="ps-3 py-1">
                            Total In Words ( {defaultCurrency?.currency_code} )
                            :{" "}
                            <td style={{ fontWeight: 600 }}>
                              {" "}
                              {amount_in_words} Only{" "}
                            </td>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="invoice_details__col_temp5_details_table_second_part2 px-2">
                    <table>
                      <tbody>{totalcalculation}</tbody>
                    </table>
                  </div>
                </div>

                {/* listing table end */}
              </tbody>
              {bankDetailsView === true ? (
                <footer className="invoice_header_temp5">
                  <table style={{ paddingLeft: "2%" }} className="">
                    <tbody>
                      <tr>
                        <div className="ps-3">
                          <p>Thanks For your Business</p>
                        </div>
                        <div className=" ps-3">
                          <p>Bank Details</p>

                          <table>
                            <tbody className="">
                              <tr>
                                <td
                                  style={{ fontWeight: 600 }}
                                  className="bankdetails_width"
                                >
                                  Name
                                </td>
                                <td>:</td>
                                <td>{defaultbank?.bank_account_name} </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 600 }}>Bank Name</td>
                                <td>:</td>
                                <td>{defaultbank?.bank_name} </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 600 }}>Branch</td>
                                <td>:</td>
                                <td>{defaultbank?.bank_branch} </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 600 }}>Account No</td>
                                <td>:</td>
                                <td>{defaultbank?.bank_account_number} </td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 600 }}>IBAN No</td>
                                <td>:</td>
                                <td>{defaultbank?.bank_iban_no} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </footer>
              ) : (
                ""
              )}

              {grandTotalViewPurchaseBill === true ? (
                <>
                  <div className="row p-0 m-0 mt-2">
                    <div className="col-9"></div>
                    <div className="col-3">
                      <span className="mt-5">
                        {" "}
                        <span className="grndtot_bold">GrandTotal :</span>{" "}
                        {grandTotalView}{" "}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
            {/* //   )} */}
          </table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default InvoicePrintTemp5;
