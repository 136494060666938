import React, { useEffect, useState } from "react";
import DeliveryNote from "../../../components/DeliveryNote-Print/DeliveryNote";
import { useParams } from "react-router-dom";
import PublicFetch from "../../../utils/PublicFetch";
import { CRM_BASE_URL_FMS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import moment from "moment"

function PrintDeliveryNote() {

    const [alljobs, setAllJobs] = useState();
  const { id } = useParams();
  const [noOfDecimal, setNoOfDecimal] = useState();

  const getallcmpny = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      // console.log("getting all cmpny", allcmpny);
      console.log(" all cmpny", allcmpny.data.data);

      let arry = [];
      allcmpny.data.data.forEach((i, indx) => {
        arry.push(i);
      });
      console.log("all cmpny are", arry);
      // setcmpnyupdate(arry)

      arry.map((itm, indx) => {
     
        setNoOfDecimal(itm.number_of_decimal);
     
      });
      // setallseaports(allseaports.data.data)
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };


  const getSingleJob = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/job/${id}`)
      .then((res) => {
        console.log("response of job", res);
        if (res.data.success) {
            setAllJobs(res?.data?.data);
          console.log(
            "Success of job vieww",
            res?.data?.data?.job_closing_status
          );
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    getSingleJob();
    getallcmpny()
  }, []);

  return (
    <>
      <DeliveryNote
        content_deliverynote={
          <>
            <tr>
              <td style={{ fontWeight: 600, width: "210px" }}>Consignee</td>
              <td>{alljobs?.job_consignee}</td>
            </tr>
            <tr>
             {alljobs?.fms_v1_freight_types?.freight_type_mode === "Air" ? (
               <td style={{ fontWeight: 600 }}>Airway Bill Number</td> ) :alljobs?.fms_v1_freight_types?.freight_type_mode === "Sea"?  (
                <td style={{ fontWeight: 600 }}>Bill of Lading</td>
               ) : (<td style={{ fontWeight: 600 }}>AWB No./B/L</td>)}
             
              <td>{alljobs?.job_awb_bl_no}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 600 }}>Shipper</td>
              <td> {alljobs?.job_shipper}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 600 }}>Job #</td>
              <td>{alljobs?.job_number}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 600 }}>No of Packages</td>
              <td>{alljobs?.job_no_of_pieces}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 600 }}>Chargeable Weight</td>
              <td>{alljobs?.job_chargeable_wt?.toFixed(noOfDecimal)}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 600 }}>Description</td>
              <td>cargo</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 600 }}> Number of Trucks</td>
              <td></td>
            </tr>
           
            <tr>
              <td style={{ fontWeight: 600 }}>Date</td>
              <td> {moment(alljobs?.job_date).format("DD-MM-YYYY")}</td>
            </tr>
          </>
        }
      />
    </>
  );
}
export default PrintDeliveryNote;
