import React, { useEffect, useState } from "react";
import PrintReports from "../../../components/Reports-Print/Printreport";
import { useSearchParams } from "react-router-dom";
import PublicFetch from "../../../utils/PublicFetch";
import { CRM_BASE_URL } from "../../../api/bootapi";
import moment from "moment";

function DailysalesreportPrint() {
  const [searchParams] = useSearchParams();

  const [reportData, setReportData] = useState();

  const [salesPerson, setSalesperson] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [status, setStatus] = useState();

  console.log("searchParamsmonth", searchParams.get("salespersonid"));
  console.log("searchParamsstatus", searchParams.get("status"));
  console.log("searchParamdatefrom", searchParams.get("datefrom"));
  console.log("searchParamdateto", searchParams.get("dateto"));

  useEffect(() => {
    setSalesperson(searchParams.get("salespersonid"));
    setDateFrom(searchParams.get("datefrom"));
    setDateTo(searchParams.get("dateto"));
    setStatus(searchParams.get("status"));

    if (dateFrom && dateTo) {
      SearchBydate();
    }
  }, [dateFrom, dateTo]);

  const SearchBydate = () => {
    // if (Read) {
    let startdate = moment(dateFrom);
    let enddate = moment(dateTo);
    PublicFetch.post(`${CRM_BASE_URL}/sales-report`, {
      status: status,
      sales_person: parseInt(salesPerson),
      date_from: startdate,
      date_to: enddate,
    })
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("data in sales report", res.data.data);

          let temp = [];
          res.data.data.forEach((item, index) => {
            item?.crm_v1_leads?.crm_v1_lead_contacts.map((i, indx) => {
              temp.push({
                slno: index + 1,
                company: item?.crm_v1_leads?.lead_company_name,
                contact: i?.lead_contact_name,
                email: item?.crm_v1_leads?.lead_email,
                contact_date: moment(item?.lead_daily_sale_date).format(
                  "DD-MM-YYYY"
                ),
                next_followup: moment(
                  item?.lead_daily_sale_next_followup_date
                ).format("DD-MM-YYYY"),
                report_status: item?.crm_v1_leads?.lead_lead_status,
                remark: item?.lead_daily_sale_remarks,
              });
            });
          });

          setReportData(temp);
          handleprint();
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    // }
    // else {
    //   let a = true;
    //   if (a) {
    //     // message.error("You have No Permission");
    //   }
    //   setTimeout(() => {
    //     a = false;
    //   }, 5000);
    // }
  };

  const handleprint = () => {
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <div>
      <PrintReports
        report_name={"DailysalesReport"}
        table_header_data={
          <>
            <tr className="">
              <th className="head_border" style={{ textAlign: "left" }}>
                SL No
              </th>
              <th className="" style={{ textAlign: "left" }}>
                COMPANY
              </th>
              <th className="" style={{ textAlign: "left" }}>
                CONTACT
              </th>
              <th className="tsk_exp" style={{ textAlign: "left" }}>
                EMAIL
              </th>
              <th className="" style={{ textAlign: "left" }}>
                RECENT CONTACT DATE
              </th>
              <th className="" style={{ textAlign: "left" }}>
                NEXT FOLLOW UP
              </th>
              <th style={{ textAlign: "left" }}> STATUS</th>
              <th style={{ textAlign: "left" }}> REMARKS</th>
            </tr>
          </>
        }
        table_print_data={
          <>
            {reportData &&
              reportData?.map((item, indx) => {
                console.log("dailyydatata", item);
                return (
                  <tr>
                    <td>{indx + 1} </td>
                    <td>{item?.company} </td>
                    <td>{item?.contact} </td>
                    <td>{item?.email} </td>
                    <td>{item?.contact_date} </td>
                    <td>{item?.next_followup} </td>
                    <td>{item?.report_status} </td>
                    <td>{item?.remark} </td>
                  </tr>
                );
              })}
          </>
        }
      />
    </div>
  );
}

export default DailysalesreportPrint;
