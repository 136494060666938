import React, { useEffect, useState } from "react";
import PrintReports from "../../../components/Reports-Print/Printreport";
import { useSearchParams } from "react-router-dom";
import PublicFetch from "../../../utils/PublicFetch";
import moment from "moment";
import { message } from "antd";

function CostandexpensePrint() {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [customerId, setCustomerId] = useState();
  const [jobId, setJobId] = useState();
  const [reportData, setReportData] = useState();

  const [searchParams] = useSearchParams();

  console.log("searchParamsdatefrom", searchParams.get("dateFrom"));
  console.log("searchParamsdateto", searchParams.get("dateTo"));
  console.log("searchParamsjob", searchParams.get("job"));
  console.log("searchParamscust", searchParams.get("customer"));

  useEffect(() => {
    let a = new Date(searchParams.get("dateFrom"));
    let am = moment(a);
    let b = new Date(searchParams.get("dateTo"));
    let bm = moment(b);
    console.log("from date new", am);

    setDateFrom(searchParams.get("dateFrom"));
    setDateTo(searchParams.get("dateTo"));
    setCustomerId(searchParams.get("customer"));
    setJobId(searchParams.get("job"));
    if (dateFrom && dateTo) {
      Searchbydate();
    }
  }, [dateFrom, dateTo]);

  console.log("jhdewjk", dateFrom);

  const Searchbydate = async () => {
    // if (dateF && dateT) {
    try {
      const searchData = await PublicFetch.post(
        `${process.env.REACT_APP_BASE_URL}/freightManagement/v1/reports/cost-expense-report/report`,
        {
          customerId: customerId ? Number(customerId) : null,
          jobId: jobId ? Number(jobId) : null,
          dateFrom: moment(dateFrom),
          dateTo: moment(dateTo),
        }
      );
      if (searchData?.status === 200) {
        console.log("searchdataaa", searchData);
        let temp = [];
        searchData?.data.data.map((item) => {
          temp.push({
            job_no: item.job_number,
            customer: item.customer.customer_name,
            cost: item.cost != null ? item.cost.toFixed(2) : "",
            expense: item.expense != null ? item.expense.toFixed(2) : "",
            profit_loss:
              item.profitLoss != null ? item.profitLoss.toFixed(2) : "",
          });
        });
        setReportData(temp);
        console.log("fvnfdv", temp);
        handleprint()

      }
    } catch (err) {
      console.log("Error while searching data");
    }
    // }
  };

  const handleprint=()=>{
    setTimeout(() => {
      window.print()
    }, 1000);
  }
  //logs
  console.log("startDate", dateFrom);
  console.log("EndDate", dateTo);

  return (
    <div>
      <PrintReports
      report_name={"CostAndExpense Report"}
        table_header_data={
          <>
            {/* <thead className="print_table"> */}
            <tr className="">
              {/* <th className="">Sl.No</th> */}
              <th className="head_border" style={{ textAlign: "left" }}>
                SL No
              </th>
              <th className="" style={{ textAlign: "left" }}>
                Job No
              </th>
              <th className="" style={{ textAlign: "left" }}>
                Customer
              </th>
              <th className="tsk_exp" style={{ textAlign: "left" }}>
                Total Cost
              </th>
              <th className="" style={{ textAlign: "left" }}>
                Total Expense
              </th>
              <th style={{ textAlign: "left" }}>Total Profit And Loss</th>
            </tr>
            {/* </thead> */}
          </>
        }
        table_print_data={
          <>
            {reportData &&
              reportData?.map((item, indx) => {
                return (
                  <tr>
                    <td>{indx + 1} </td>
                    <td>{item?.job_no} </td>
                    <td>{item?.customer} </td>
                    <td>{item?.cost} </td>
                    <td>{item?.expense} </td>
                    <td>{item?.profit_loss} </td>
                  </tr>
                );
              })}
          </>
        }
      />
    </div>
  );
}

export default CostandexpensePrint;
