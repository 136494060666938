import { Input } from "antd";
import React from "react";
import { forwardRef } from "react";
import "./InputType.scss";

const InputType = forwardRef(function InputType(
  {
    className,
    value,
    onChange,
    onClick,
    rule,
    minLength,
    onBlur,
    onKeyDown,
    align,
    controls,
    disabled,
    autoComplete,
    autoFocus,
    id,
    onFocus,
  },
  ref
) {
  console.log("rendered", ref);
  return (
    <div>
      <div>
        <Input
          onFocus={onFocus}
          bordered={false}
          onClick={onClick}
          onChange={onChange}
          value={value}
          rule={rule}
          align={align}
          controls={controls}
          minLength={minLength}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          disabled={disabled}
          style={{ backgroundColor: "#f5f5f5" }}
          className={`input_type_style w-100  pb-2 ${className}`}
          type="text"
          autoComplete={"off"}
          autoFocus={autoFocus}
          id={id}
          ref={ref}
        />
      </div>
    </div>
  );
});

export default InputType;
