import { useState, useEffect } from "react";
import {
  CRM_BASE_URL_FMS,
  GENERAL_SETTING_BASE_URL,
} from "../../../../api/bootapi";
import PublicFetch from "../../../../utils/PublicFetch";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import "./jobinvoice.scss";
import InvoicePrint from "../../../../components/Invoice/InvoicePrint";
import { camelize } from "../../../../utils/camelcaseconvert";
function Jobinvoice() {
  const { id } = useParams();
  console.log("id :::::", id);
  const [allqoutation, setAllQuotations] = useState();
  const [tabledata, setTableData] = useState();

  const [companyname, setCompanyname] = useState();
  const [companyaddress, setCompanyaddress] = useState();
  const [companyphone, setCompanyphone] = useState();
  const [companyemail, setCompanyemail] = useState();
  const [companycountry, setCompanycountry] = useState();

  const [companyzipcode, setcompanyzipcode] = useState();
  const [companylogo, setCompanylogo] = useState();
  const [cmpnyinfo, setcmpnyinfo] = useState();
  const [qtnno, setQtnno] = useState();
  const [tax, setTax] = useState();
  const [grandtotal, setGrandTotal] = useState();
  const [alljobs, setAllJobs] = useState();
  const [NoofDecimal, setNoOfDecimal] = useState(
    localStorage.getItem("NumberOfDecimals")
  );
  const [isContentData, setIsContentData] = useState(false);
  const [isCompanyInfo, setIscompantInfo] = useState(false);
  var converter = require("number-to-words");

  const [cmpnyHeaderImg, setCmpnyHeaderImg] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();

  const getSingleJob = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/job/${id}`)
      .then((res) => {
        console.log("response of job", res);
        if (res.data.success) {
          console.log("Success of job", res.data.data);
          let newdatas = [];
          let total = 0;
          res?.data?.data?.fms_v1_quotation_jobs.forEach((item, index) => {
            newdatas.push(item.fms_v1_quotation.quotation_no);
            setQtnno(newdatas);
          });
          setAllJobs(res?.data?.data);

          let servdata = [];
          res.data.data.fms_v1_job_task_expenses.forEach((item, index) => {
            servdata.push({
              quotation_details_service_id: item?.crm_v1_services.service_name,
              quotation_details_cost: item?.job_task_expense_cost_amountfx,
              quotation_details_tax_type:
                item?.fms_v1_tax_groups?.tax_group_name,
              quotation_details_tax_amount: item?.job_task_expense_cost_taxfx,
              quotation_details_total: item?.job_task_expense_cost_subtotalfx,
            });
            setTax(servdata);
            total = Number(total);
            total += item?.job_task_expense_cost_subtotalfx;
          });
          setGrandTotal(total?.toFixed(NoofDecimal));
          setIsContentData(true);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    if (id) {
      getSingleJob();
      getallcmpny();
    }
  }, [id]);

  const getallcmpny = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );

      console.log(" cmpny details is", allcmpny.data.data);
      setcmpnyinfo(allcmpny.data.data);
      // setcmpnyupdate(allcmpny.data.data.length)
      console.log("dataa", allcmpny.data.data.length);
      let arry = [];
      allcmpny.data.data.forEach((i, indx) => {
        arry.push(i);
      });
      console.log("all cmpny are", arry);
      // setcmpnyupdate(arry)
      setIscompantInfo(true);
      arry.map((itm, indx) => {
        console.log("all cmpny maped", itm);
        // setcmpnyid(itm.company_id)
        setCompanyname(itm.company_name);
        setCompanyaddress(itm.company_address);
        setCompanyemail(itm.company_email);
        setCompanyphone(itm.company_phone);
        // setCountryis(itm.company_country)
        setcompanyzipcode(itm.company_zip_code);
        setCompanylogo(itm.company_logo);
        setCompanycountry(itm.company_country);
      });
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  const getComapanyData = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      setCmpnyHeaderImg(allcmpny.data.data[0].company_logo);

      console.log(" cmpny details is", allcmpny.data.data);

      console.log("dataa", allcmpny.data.data.length);
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  useEffect(() => {
    getComapanyData();
    console.log("quotHeaderImg", cmpnyHeaderImg);
    if (cmpnyHeaderImg) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${cmpnyHeaderImg}`, {
        responseType: "blob",
      })
        .then((resp) => {
          console.log("respBlob".resp?.blob());

          console.log("quotresp", resp);

          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
          console.log("blobconvert", blob);
        })
        .catch((err) => console.log("error123", err));
    }
  }, [cmpnyHeaderImg]);

  function setPageSize() {
    const page = document.querySelector(".print-page");
    // page.style.width = "210mm";
    // page.style.height = "297mm";
    // page.style.margin = "10mm";
    page.style.width = "21cm";
    page.style.height = "29.7cm";
    page.style.margin = "auto";
    page.style.padding = "2rem";
  }

  useEffect(() => {
    if (isCompanyInfo === true && isContentData === true && blobUrl) {
      window.print();
    }
  }, [isCompanyInfo, isContentData, blobUrl]);

  console.log("all jobs", alljobs);

  return (
    <>
      <InvoicePrint
        invoiceHeaderImg={blobUrl}
        invoice_no
        billto
        Invoice_type="JOB"
        invoice_number={alljobs?.job_number}
        invoice_details1={
          <>
            <tr className="p-2 ">
              <td>Job No </td>
              <td>: </td>
              <td className="quotation_p_name">{alljobs?.job_number}</td>
            </tr>
            <tr className="p-2">
              <td>Job Date </td>
              <td>: </td>
              <td className="quotation_p_name">
                {moment(alljobs?.job_date).format("DD-MM-YYYY")}
              </td>
            </tr>
            <tr className="p-2">
              <td>Job Booking No </td>
              <td>: </td>
              <td className="quotation_p_name">
                {alljobs?.fms_v1_job_bookings?.job_booking_no}
              </td>
            </tr>

            <tr className="p-2 ">
              <td>Freight type </td>
              <td>: </td>
              <td className="quotation_p_name">
                {alljobs?.fms_v1_freight_types?.freight_type_name}
              </td>
            </tr>
            <tr className="p-2">
              <td>Payment Terms </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {alljobs?.fms_v1_payment_terms?.payment_term_name}
              </td>
            </tr>
            <tr className="p-2">
              <td>No of Pieces </td>
              <td>: </td>
              <td className="quotation_p_name"> {alljobs?.job_no_of_pieces}</td>
            </tr>
            <tr className="p-2">
              <td>Chargeable wt </td>
              <td>: </td>
              <td className="quotation_p_name">
                {alljobs?.job_chargeable_wt} Kg
              </td>
            </tr>
            <tr className="p-2">
              <td>Gross wt </td>
              <td>: </td>
              <td className="quotation_p_name">{alljobs?.job_gross_wt} Kg </td>
            </tr>
            <tr className="p-2">
              <td>UOM </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {alljobs?.crm_v1_units?.unit_name}
              </td>
            </tr>
          </>
        }
        invoice_details2={
          <>
            <tr className="p-2 ">
              <td>Shipper </td>
              <td>: </td>
              <td className="quotation_p_name">
                {alljobs?.job_shipper}
              </td>
            </tr>
            <tr className="p-2">
              <td>Consignee </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {alljobs?.job_consignee}
              </td>
            </tr>
            <tr className="p-2">
              <td>Origin </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {
                  alljobs
                    ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                    ?.location_name
                }
              </td>
            </tr>
            <tr className="p-2">
              <td>Destination </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {
                  alljobs
                    ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                    ?.location_name
                }
              </td>
            </tr>
            <tr className="p-2">
              <td>Origin Country</td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {alljobs?.fms_v1_jobs_job_origin_country?.country_name}
              </td>
            </tr>
            <tr className="p-2">
              <td>Destination Country </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {alljobs?.fms_v1_jobs_job_destination_country?.country_name}
              </td>
            </tr>
            <tr className="p-2">
              <td>Cargo Type </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {alljobs?.fms_v1_cargo_types?.cargo_type_name}
              </td>
            </tr>
            <tr className="p-2">
              <td>Currency </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {alljobs?.generalsettings_v1_currency?.currency_name}
              </td>
            </tr>
            <tr className="p-2">
              <td>Exchange Rate </td>
              <td>: </td>
              <td className="quotation_p_name">
                {alljobs?.job_total_cost_exch?.toFixed(8)}
              </td>
            </tr>
          </>
        }
      
        invoice_table_header={
          <>
            <th scope="col" className="font_weight_qt border_right">
              SL NO.
            </th>
            <th
              scope="col"
              className="font_weight_qt border_right task_width text_align_words"
            >
              TASKS
            </th>
            <th
              scope="col"
              className="font_weight_qt  border_right text_align_number"
            >
              COST
            </th>
            <th
              scope="col"
              className="font_weight_qt border_right text_align_words"
            >
              TAX TYPE
            </th>
            <th
              scope="col"
              className="font_weight_qt border_right text_align_number"
            >
              TAX AMOUNT
            </th>
            <th scope="col" className="font_weight_qt text_align_number">
              TOTAL AMOUNT
            </th>
          </>
        }
        invoice_table_data={
          <>
            {tax &&
              tax.map((itm, indx) => (
                <tr>
                  <td className="border_right">{indx + 1} </td>
                  <td className="border_right text_align_words">
                    {itm.quotation_details_service_id}{" "}
                  </td>
                  <td className="border_right text_align_number">
                    {itm.quotation_details_cost?.toFixed(NoofDecimal)}{" "}
                  </td>
                  <td className="border_right text_align_words">
                    {itm.quotation_details_tax_type}{" "}
                  </td>
                  <td className="border_right text_align_number">
                    {itm.quotation_details_tax_amount?.toFixed(NoofDecimal)}
                  </td>
                  <td className="text_align_number">
                    {itm.quotation_details_total?.toFixed(NoofDecimal)}
                  </td>
                </tr>
              ))}
          </>
        }
        amount_in_words={
          <>{grandtotal && <>{camelize(converter.toWords(grandtotal))}</>}</>
        }
        sub_total={grandtotal}
        total={grandtotal}
      />
    </>
  );
}
export default Jobinvoice;
