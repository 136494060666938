import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/override.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/user.context";
import {
  FormActionTriggerProvider,
  UnsavedChangesProvider,
} from "./utils/UnsavedChangesProvider";
import { StoreDataProvider } from "./utils/StoreDataProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <UserProvider>
    <UnsavedChangesProvider>
      <FormActionTriggerProvider>
        <StoreDataProvider>
          <App />
        </StoreDataProvider>
      </FormActionTriggerProvider>
    </UnsavedChangesProvider>
  </UserProvider>
  // </React.StrictMode>
);

reportWebVitals();
