import React, { useEffect, useState } from "react";
import PrintReports from "../../../../components/Reports-Print/Printreport";
import { useSearchParams } from "react-router-dom";
import PublicFetch from "../../../../utils/PublicFetch";
import { CRM_BASE_URL_FMS } from "../../../../api/bootapi";
import moment from "moment";

function PendingInvoicesPrint() {
  const [searchParams] = useSearchParams();

  const [pendInvreportData, setPendInvreportData] = useState();

  const [customerId, setCustomerId] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [invoiceno, setInvoiceno] = useState();

  console.log("searchParamsmonth", searchParams.get("customerid"));
  console.log("searchParamsstatus", searchParams.get("invoiceno"));
  console.log("searchParamdatefrom", searchParams.get("datefrom"));
  console.log("searchParamdateto", searchParams.get("dateto"));

  useEffect(() => {
    setCustomerId(searchParams.get("customerid"));
    setDateFrom(searchParams.get("datefrom"));
    setDateTo(searchParams.get("dateto"));
    setInvoiceno(searchParams.get("invoiceno"));

    if (customerId && invoiceno && dateFrom && dateTo) {
      SearchReport();
    }
  }, [customerId, invoiceno, dateFrom, dateTo]);

  const SearchReport = () => {
    let date_f = "";
    let date_t = "";
    if (dateFrom) {
      date_f = moment(dateFrom);
    }
    if (dateTo) {
      date_t = moment(dateTo);
    }

    let data1 = {
      invoice_no: invoiceno,
      date_from: date_f,
      date_to: date_t,
      customer_id: customerId,
    };

    // console.log("data 1 is",data1);
    PublicFetch.post(
      `${CRM_BASE_URL_FMS}/reports/pending-invoice-report`,
      data1
    )
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of response", res.data.data);
          let temp = [];
          res.data.data.forEach((item, index) => {
            console.log("data of temp is :", item);
            let nestedData = [];
            if (item.accounts_v1_account_receipt_inv_details) {
              item.accounts_v1_account_receipt_inv_details.forEach(
                (nestedItem, nestedIndex) => {
                  // Push data from the nested loop to nestedData

                  nestedData.push({
                    payment_date: nestedItem?.acc_rec_inv_det_created_at,
                    paid_amount: nestedItem?.acc_rec_inv_det_adjust_amt_fx,
                    invoice_due: nestedItem?.acc_rec_inv_det_inv_due_fx,
                  });
                }
              );
            }
            temp.push({
              invoice_date: item?.invoice_date,
              invoice_no: item?.invoice_no,
              // invoice_due: item?.accounts_v1_account_receipt_inv_details?.acc_rec_inv_det_inv_due || 'N/A',
              invoice_amount: item?.invoice_grand_total,
              invoice_days: item?.invoice_credit_days,
              nestedData: nestedData, // Store the nestedData array
            });
          });
          setPendInvreportData(temp);
          console.log("deu", temp);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <div>
      <PrintReports
        report_name={"PendingInvoiceReport"}
        table_header_data={
          <>
            <tr className="">
              <th className="head_border" style={{ textAlign: "left" }}>
                SL NO.
              </th>
              <th className="" style={{ textAlign: "left" }}>
                INV DATE
              </th>
              <th className="" style={{ textAlign: "left" }}>
                INV NO
              </th>
              <th className="tsk_exp" style={{ textAlign: "left" }}>
                INV AMOUNT
              </th>
              <th className="" style={{ textAlign: "left" }}>
                NO OF DAYS
              </th>
              <th className="" style={{ textAlign: "left" }}>
                PAYMENT DATE
              </th>
              <th style={{ textAlign: "left" }}> PAID AMOUNT</th>
              <th style={{ textAlign: "left" }}> AMOUNT DUE</th>
            </tr>
          </>
        }
      />
    </div>
  );
}

export default PendingInvoicesPrint;
