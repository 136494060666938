import { useParams } from "react-router-dom";
import InvoicePrint from "../../../../components/Invoice/InvoicePrint";
import { useEffect, useState } from "react";
import PublicFetch from "../../../../utils/PublicFetch";
import {
  CRM_BASE_URL_PURCHASING,
  GENERAL_SETTING_BASE_URL,
} from "../../../../api/bootapi";
import moment from "moment";
function PrintPurchaseBill() {
  const { id } = useParams();
  const [purchaseBill, setPuchaseBill] = useState("");
  const [TaskDetails, setTaskDetails] = useState();
  const [tabledata, setTabledata] = useState();
  const [cmpnyHeaderImg, setCmpnyHeaderImg] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();
  const [isContentData, setIsContentData] = useState(false);
  const [isCompanyInfo, setIscompanyInfo] = useState(false);

  const getComapanyData = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      setCmpnyHeaderImg(allcmpny.data.data[0].company_quotation_header);
      setIscompanyInfo(allcmpny.data.data)
      console.log(" cmpny details is", allcmpny.data.data);

      console.log("dataa", allcmpny.data.data.length);
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  useEffect(() => {
    getComapanyData();
    console.log("quotHeaderImg", cmpnyHeaderImg);
    if (cmpnyHeaderImg) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${cmpnyHeaderImg}`, {
        responseType: "blob",
      })
        .then((resp) => {
          console.log("respBlob".resp?.blob());

          console.log("quotresp", resp);

          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
          console.log("blobconvert", blob);
        })
        .catch((err) => console.log("error123", err));
    }
  }, [cmpnyHeaderImg]);
  useEffect(() => {
    if (isContentData === true && blobUrl) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [isContentData, blobUrl]);

  const getSinglepurchaseBill = () => {
    PublicFetch.get(`${CRM_BASE_URL_PURCHASING}/purchase-bill/${id}`)
      .then((res) => {
        console.log("response of purchase bill", res);
        if (res.data.success) {
          console.log("Success of purchase bill", res.data.data);

          setPuchaseBill(res?.data?.data);
          setTaskDetails(res?.data?.data.crm_v1_purchase_bill_details);
          setIsContentData(true);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    let DetailsData = [];
    TaskDetails &&
      TaskDetails?.length > 0 &&
      TaskDetails.map((item, index) => {
        DetailsData.push({
          pur_bill_det_amount: item?.pur_bill_det_amount,
          pur_bill_det_awb_bl_no: item?.pur_bill_det_awb_bl_no,
          pur_job_no: item?.fms_v1_jobs?.job_number,
          pur_task_name: item?.crm_v1_services?.service_name,
          pur_tax_group: item?.fms_v1_tax_groups?.tax_group_name,
          pur_bill_det_tax_percentage: item?.pur_bill_det_tax_percentage,
          pur_bill_det_tax_amount: item?.pur_bill_det_tax_amount,
          pur_bill_det_total_amount: item?.pur_bill_det_total_amount,
        });
      });
    setTabledata([...DetailsData]);
  }, [TaskDetails]);

  useEffect(() => {
    getSinglepurchaseBill();
  }, []);

  return (
    <>
      <InvoicePrint
        invoice_headerprint={false}
        purchasebill_header={true}
        purchasebill_header_img={blobUrl}
        // invoiceHeaderImg={blobUrl}
        billto
        bankDetailsView={false}
        viewBillDetails={false}
        totalView={false}
        totalInWordsView={false}
        grandTotalViewPurchaseBill={true}
        invoice_details1={
          <>
            <tr className="p-2 ">
              <td>Vendor </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {purchaseBill?.crm_v1_vendors?.vendor_name}{" "}
              </td>
            </tr>
            <tr className="p-2">
              <td>Purchase Bill No</td>
              <td>: </td>
              <td className="quotation_p_name">{purchaseBill?.pur_bill_no}</td>
            </tr>
            <tr className="p-2">
              <td>Date </td>
              <td>: </td>
              <td className="quotation_p_name">
                {moment(purchaseBill?.pur_bill_date).format("DD-MM-YYYY")}
              </td>
            </tr>

            {/* <tr className="p-2 ">
              <td>Purchase Order No </td>
              <td>: </td>
              <td className="quotation_p_name">
                {purchaseBill?.crm_v1_purchase_orders?.po_no}
              </td>
            </tr> */}
            <tr className="p-2">
              <td>Due Date </td>
              <td>: </td>
              <td className="quotation_p_name">
                {moment(purchaseBill?.pur_bill_due_date).format("DD-MM-YYYY")}
              </td>
            </tr>
            {/* <tr className="p-2">
              <td>Purchase Date </td>
              <td>: </td>
              <td className="quotation_p_name">
                 {moment(purchaseBill?.pur_bill_purchase_date).format("DD-MM-YYYY")}</td>
            </tr>
            <tr className="p-2">
              <td>Job Expense </td>
              <td>: </td>
              <td className="quotation_p_name">
              {purchaseBill?.crm_v1_vendors?.vendor_name}
              </td>
            </tr> */}
          </>
        }
        invoice_details2={
          <>
            <tr className="p-2">
              <td>Vendor Ref No </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {purchaseBill?.pur_bill_ref_no}{" "}
              </td>
            </tr>
            <tr className="p-2">
              <td>Currency </td>
              <td>: </td>
              <td className="quotation_p_name">
                {purchaseBill?.generalsettings_v1_currency?.currency_name}
              </td>
            </tr>
            <tr className="p-2">
              <td>Exchange Rate </td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {purchaseBill?.pur_bill_exchange_rate}
              </td>
            </tr>
            <tr className="p-2">
              <td>Payment mode </td>
              <td>: </td>
              <td className="quotation_p_name">
                {purchaseBill?.accounts_v1_payment_modes?.pay_mode_name}
              </td>
            </tr>
            <tr className="p-2">
              <td>Credit Days </td>
              <td>: </td>
              <td className="quotation_p_name">
                {purchaseBill?.pur_bill_credit_days}
              </td>
            </tr>
          </>
        }
        invoice_table_header={
          <>
            <th scope="col" className="font_weight_qt border_right">
              #
            </th>
            <th
              scope="col"
              className="font_weight_qt border_right task_width text_align_words"
            >
              TASKS
            </th>
            <th
              scope="col"
              className="font_weight_qt  border_right text_align_number"
            >
              AWB/BL NO
            </th>
            <th
              scope="col"
              className="font_weight_qt border_right text_align_words"
            >
              JOB NO
            </th>
            <th
              scope="col"
              className="font_weight_qt border_right text_align_number"
            >
              AMOUNT
            </th>
            {isCompanyInfo[0]?.taxable === 1 ?<>
            <th scope="col" className="font_weight_qt border_right text_align_number">
              TAX GROUP
            </th>
            <th scope="col" className="font_weight_qt border_right text_align_number">
              TAX %
            </th>
            <th scope="col" className="font_weight_qt border_right text_align_number">
              TAX AMT
            </th>
            </>:"" }
            <th scope="col" className="font_weight_qt border_right text_align_number">
              TOTAL AMT
            </th>
          </>
        }
        invoice_table_data={
          <>
            {tabledata &&
              tabledata?.map((item, indx) => {
                console.log("mncvm", item);
                return (
                  <>
                    <tr>
                      <td className="">{indx + 1} </td>
                      <td className=" ">{item?.pur_task_name}</td>
                      <td className="">{item?.pur_bill_det_awb_bl_no}</td>
                      <td className=" ">{item?.pur_job_no}</td>
                      <td className=" text_align_number">
                        {item?.pur_bill_det_amount?.toFixed(3)}
                      </td>
                      {isCompanyInfo[0]?.taxable === 1 ? <>
                      <td className="text_align_number">
                        {item?.pur_tax_group}
                      </td>
                      <td className="text_align_number">
                        {item?.pur_bill_det_tax_percentage}
                      </td>
                      <td className="text_align_number">
                        {item?.pur_bill_det_tax_amount?.toFixed(3)}
                      </td>
                      </> :""}
                      <td className="text_align_number">
                        {item?.pur_bill_det_total_amount?.toFixed(3)}
                      </td>
                    </tr>
                  </>
                );
              })}
          </>
        }
      
        grandTotalView={purchaseBill?.pur_bill_total_amount?.toFixed(4)}
      />
    </>
  );
}
export default PrintPurchaseBill;
