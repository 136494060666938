import { useEffect, useState } from "react";
import PublicFetch from "../../../utils/PublicFetch";
import styles from "./quotationprintNewstyle.module.scss";
import { GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import Quotfooter from "../../../assets/logo/cargoquot.png";

function QuotationPrintNew({
  invoice_details1,
  invoice_details2,
  invoice_details3,
  invoice_details4,
  customer_name,
  transpotetion_mode,
  invoice_table_header,
  invoice_table_data,
  prepared_by,
  aproved_by,
  invoice_details5,
  termsandcondition,
  cargodescription,
  salesperson_mobileno,
  salesperson_address,
  grandTotal,
  showGrandTotal,
  quot_table_data,
  // setQuotHeaderSuccess,
  quotationHeaderimg,
  setDataCompanyFilled,
  frightType_type,
}) {
  console.log("showGrandTotal", showGrandTotal);
  const [companyInfodata, setCompanyInfodata] = useState();
  // const [quotHeaderSuccess,setQuotHeaderSuccess] = useState(false)
  console.log("headerImage", quotationHeaderimg);
  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
          setDataCompanyFilled(true);
          // setQuotHeaderSuccess(true)
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    companyinfo();
  }, []);

  // useEffect(() => {
  //   // Function to apply dynamic print styles based on orientation
  //   const applyPrintStyles = (orientation) => {
  //     const styleSheet = document.styleSheets[28];
  //     styleSheet.crossorigin = "anonymous";
  //     console.log("stylesSheets", styleSheet);
  //     const rules = styleSheet.cssRules || styleSheet.rules;

  //     for (const rule of rules) {
  //       if (rule.selectorText === `@page `) {
  //         styleSheet.deleteRule(rules.indexOf(rule));
  //         styleSheet.insertRule(
  //           `@page { size: ${orientation}; margin: 1cm; }`,
  //           rules.length
  //         );
  //         break;
  //       }
  //     }
  //   };

  //   // Function to print with specified orientation
  //   const printWithOrientation = (orientation) => {
  //     applyPrintStyles(orientation);
  //   };

  //   // Example: print with portrait orientation
  //   printWithOrientation("landscape");
  // }, []); // Run this effect once on component mount

  return (
    <>
      <div className={styles.portrait_page} style={{}}>
        {/* div to set page border on all pages */}
        <div id={styles.pageborder_Qtn}></div>
        {/* page border div ends */}
        <table className=" ">
          {/* {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => {
              return ( */}
          <thead className={styles.quotation_header}>
            <tr className={styles.quotation_header}>
              <div>
                {/* <div className="header__address_wrapper"> */}
                <div
                  className={`${styles.header__quotation_logo} d-flex justify-content-center`}
                >
                  {quotationHeaderimg && (
                    <img
                      // style={{ height: "140px", width: "700px" }}
                      src={quotationHeaderimg}
                      alt="e"
                    />
                  )}
                  {/* <img
                          style={{ height: "150px", width: "700px" }}
                          src={`${process.env.REACT_APP_BASE_URL}/${item.company_quotation_header}`}
                          alt=""
                        /> */}
                </div>
                {/* </div> */}
              </div>
            </tr>
          </thead>
          {/* );
            })} */}
          <tbody>
            <div className="text-center ">
              <h2 className={styles.quotation_heading_style}>QUOTATION</h2>
            </div>

            <div className={styles.invoice_quot_details}>
              <div
                className={`${styles.invoice_quot_details__col} ${styles.invoice_quot_details__col_1} py-2`}
              >
                <table>
                  <tbody>{invoice_details1}</tbody>
                </table>
              </div>
              <div
                className={`${styles.invoice_quot_details__col} ${styles.invoice_details__col_2} py-2`}
              >
                <table>
                  <tbody>{invoice_details2}</tbody>
                </table>
              </div>
            </div>

            <div
              className={`${styles.quotation_text} ${styles.border_paragrph}`}
            >
              <div className="px-3">
                <p>Dear Sir/Madam,</p>
                <span>
                  Thank you very much for your enquiry your regarding{" "}
                  {frightType_type} from&nbsp;{customer_name?.toUpperCase()} by{" "}
                  {transpotetion_mode}. We would like to make your offer for
                  your kind consideration as follows:
                </span>
              </div>
            </div>

            <div className={`${styles.invoice_quot_details}`}>
              <div
                className={`${styles.invoice_quot_details__col} ${styles.invoice_quot_details__col_1}   py-2`}
                style={{ borderRight: "1px solid black" }}
              >
                <table>
                  <tbody>{invoice_details3}</tbody>
                </table>
              </div>
              <div
                className={`${styles.invoice_quot_details__col} ${styles.invoice_details__col_2} py-2`}
              >
                <table>
                  <tbody>{invoice_details4}</tbody>
                </table>
              </div>
            </div>

            <div
              className={`${styles.invoice_detailsquot}`}
              style={{ borderBottom: "1px solid black" }}
            >
              <div
                className={`${styles.invoice_details__quot}  ${styles.invoice_details__quot_col} py-2`}
              >
                <table>
                  <tbody>{invoice_details5}</tbody>
                </table>
              </div>
            </div>

            {/* new changes */}
            <div className="">{quot_table_data} </div>
            {/* <table className="details_table invoice_header" cellSpacing={0}  >
              <thead className="invoice_header">{invoice_table_header}</thead>
              <tbody>{invoice_table_data}</tbody>
            </table> */}
            {grandTotal}

            <div className={styles.invoice_detailsquot}>
              <div
                className={`${styles.invoice_details__quot}  ${styles.invoice_details__quot_col} py-2`}
              >
                <table>
                  <p className="m-0" style={{ fontWeight: 600 }}>
                    Terms and Conditions
                  </p>
                  <tbody>{termsandcondition}</tbody>
                </table>
              </div>
            </div>

            <table>
              <tr className="">
                <th className={styles.text_bold}>Best Regards,</th>
              </tr>
              <tr className="p-0 m-0">
                <th className={`${styles.txt_quot_best} p-0 m-0`}>
                  {prepared_by}
                </th>
              </tr>
            </table>

            <div className="">
              <table className={styles.salesperson_table_width}>
                <tr className="">
                  <td className={styles.txt_quot_best}>Phone</td>
                  <td>:</td>
                  <td className="p-0 m-0">{salesperson_mobileno} </td>
                </tr>

                <tr className=" ">
                  <td className={styles.txt_quot_best}>Email</td>
                  <td>:</td>
                  <td className=" p-0 m-0">{salesperson_address} </td>
                </tr>
              </table>
            </div>
            <table>
              <tr className="">
                {companyInfodata &&
                  companyInfodata.length > 0 &&
                  companyInfodata?.map((itm, indx) => {
                    return (
                      <img
                        className={styles.quotfooter_padding}
                        src={`${process.env.REACT_APP_BASE_URL}/${itm?.company_quotation_footer}`}
                        height={50}
                        width={300}
                      />
                    );
                  })}
              </tr>
            </table>
          </tbody>
        </table>
      </div>
    </>
  );
}
export default QuotationPrintNew;
