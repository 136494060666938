import { Select } from "antd";
import React, { Children, useEffect, useRef, useState } from "react";
import { ref } from "yup";
import { forwardRef } from "react";

const SelectBox = forwardRef(function SelectBox(
  {
    className,
    value,
    onChange,
    onSearch,
    filterOption,
    loading,
    defaultValue,
    onClick,
    children,
    placeholder,
    options,
    disabled,
    onBlur,
    mode,
    maxTagCount,
    onSelect,
    onFocus,
    onKeyDown,
    open,
    autoFocus,
    // ref,
    // selectRef,
  },
  ref
) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMouseInside, setIsMouseInside] = useState(false);
  console.log("mode recevied", mode);

  const handleSelectChange = (selectedValue) => {
    onChange(selectedValue); // Call the provided onChange handler

    // setDropdownVisible(false); // Close the dropdown after a single select
  };

  const handleSelectClick = (e) => {
    console.log("Click event toggled", e);
    if (mode === "multiple" || "tags") {
      // handleOpen();
      setDropdownVisible(!dropdownVisible); // Toggle the dropdown visibility when the select box is clicked
    }
  };

  // const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const closeTimeoutRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (selectRef.current && selectRef.current.contains(event.target)) {
  //       setDropdownVisible(false);
  //     }
  //   };

  //   const handleEscapeKey = (event) => {
  //     if (event.key === "Escape") {
  //       setDropdownVisible(false);
  //     }
  //   };

  //   document.addEventListener("keydown", handleClickOutside);
  //   document.addEventListener("keydown", handleEscapeKey);

  //   return () => {
  //     document.removeEventListener("keydown", handleClickOutside);
  //     document.removeEventListener("keydown", handleEscapeKey);
  //   };
  // }, []);

  // const handleOpen = (data) => {
  //   // onFocus(data);

  //   setDropdownVisible(true);
  // };

  // const handleselect = (data) => {
  //   onSelect(data);
  //   clearTimeout(closeTimeoutRef.current);
  //   handleClose();
  // };

  // const handleClose = (data) => {
  //   closeTimeoutRef.current = setTimeout(() => {
  //     setDropdownVisible(false);
  //   }, 1000);
  // };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!selectRef.current || !selectRef.current.contains(event.target)) {
  //       setDropdownVisible(true);
  //     }
  //   };

  //   const handleEscapeKey = (event) => {
  //     if (event.key === "Escape") {
  //       setDropdownVisible(false);
  //     }
  //   };

  // document.addEventListener("mousedown", handleClickOutside);
  // document.addEventListener("keydown", handleEscapeKey);

  // return () => {
  //   document.removeEventListener("mousedown", handleClickOutside);
  //   document.removeEventListener("keydown", handleEscapeKey);
  // };
  // }, []);

  const handleOpen = () => {
    // setDropdownVisible(true);
    setDropdownVisible(true);
  };

  const handleClose = (data) => {
    console.log("Close clicked ");
    // if (!isMouseInside) {
    // if (data) {
    //   onBlur(data);
    // }
    setDropdownVisible(false);
    // }
  };

  const handleMouseEnter = () => {
    setIsMouseInside(true);
  };

  const handleMouseLeave = () => {
    setIsMouseInside(false);
  };
  console.log("Select box state rendering", dropdownVisible);

  return (
    <div>
      <div
        className=""
        // ref={selectRef}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        <Select
          // onDropdownVisibleChange={setDropdownVisible}
          // onSelect={handleClose}
          // onPopupScroll={handleClose}
          // dropdownRender={(menu) => (
          //   <div
          //     onClick={(event) => {
          //       event.stopPropagation();
          //     }}
          //   >
          //     {menu}
          //   </div>
          // )}
          style={{
            backgroundColor: "whitesmoke",
            borderRadius: "5px",
          }}
          bordered={false}
          className={`w-100 ${className}`}
          onClick={onClick}
          onChange={onChange}
          open={open}
          value={value}
          placeholder={placeholder}
          options={options}
          onSearch={onSearch}
          filterOption={filterOption}
          loading={loading}
          showSearch={true}
          allowClear={true}
          defaultValue={defaultValue}
          optionFilterProp={"children"}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onSelect={onSelect}
          mode={mode}
          maxTagCount={maxTagCount}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          ref={ref}
        >
          {children}
        </Select>
      </div>
    </div>
  );
});

export default SelectBox;
