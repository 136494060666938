import { useLocation, useNavigate } from "react-router-dom";
// import ShipStatusReportPrint from "../../../components/Shipstatusreport-print/shipstatusreportPrint";
import { useEffect, useState } from "react";
// import SPendingBooking from "../../../components/ShipmentPendingBooking/SPendingBookingPrint";
import { useSearchParams } from "react-router-dom";
// import ShipmentPendingJobPrint from "../../../components/ShipmentPendingJobsPrint/ShipmentPendingJobPrint";
import ShipPendingJobPrint from "../../../components/ShipmentPendingJobsPrint/ShipmentPendingJobPrint";
import moment from "moment";
import PublicFetch from "../../../utils/PublicFetch";
import { CRM_BASE_URL_FMS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import ROUTES from "../../../routes";
function PendingJobPrint({ location }) {
  console.log("dhjfjf", location);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  console.log("Statefrom route", state);
  const [jobtblData, setJobtblData] = useState();
  const [IsData, setIsData] = useState(false);

  const [quotHeaderImg, setQuotHeaderImg] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();

  const Date1 = searchParams.get("dateTo");
  const Date2 = searchParams.get("dateFrom");

  const DateTo = Date1 ? new Date(Date1) : null;
  const DateFrom = Date2 ? new Date(Date2) : null;
  const customer1 = searchParams.get("customerId");
  const customer2 = customer1 !== "undefined" ? JSON.parse(customer1) : null
  const temp = searchParams.get("freight").split(",");

  let freight = [];
  temp?.map((item) => {
    if (item !== "null" && item !== "undefined" && item) {
      freight.push(Number(item));
    }
  });
  console.log("data114", freight);

  useEffect(() => {}, []);

  const Values = {
    date_to: DateTo && moment(DateTo),
    date_from: DateFrom && moment(DateFrom),
    customer_id: customer2 ? Number(customer1) : null,
    freight_type_id: freight?.length > 0 ? freight : null,
  };

  const getComapanyData = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      setQuotHeaderImg(allcmpny.data.data[0].company_quotation_header);

      console.log(" cmpny details is", allcmpny.data.data);
    
      console.log("dataa", allcmpny.data.data.length);
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  useEffect(() => {
    getComapanyData()
    console.log("quotHeaderImg", quotHeaderImg);
    if (quotHeaderImg) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${quotHeaderImg}`, {
        responseType: "blob",
      })
        .then((resp) => {
          console.log("respBlob".resp?.blob());

          console.log("quotresp", resp);

          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
          console.log("blobconvert", blob);
        })
        .catch((err) => console.log("error123", err));
    }
  }, [quotHeaderImg]);


  useEffect(() => {
    let data =
      Values?.date_to ||
      Values?.date_from ||
      Values?.customer_id ||
      Values?.freight_type_id?.length > 0
        ? Values
        : {};
        data?.freight_type_id === null && delete data.freight_type_id;
        data?.customer_id === null && delete data.customer_id;
    PublicFetch.post(`${CRM_BASE_URL_FMS}/shipment-pending-jobs-reports`, data)
      .then((res) => {
        if (res?.data?.success) {
          let temp = [];
          res?.data?.data?.data?.forEach((item, index) => {
            console.log("item", item);
            temp?.push({
              slno: index + 1,
              ref_no: item?.job_number,

              date: moment(item?.job_date).format("DD-MM-YYYY"),
              freight: item?.fms_v1_freight_types?.freight_type_name,

              customer: item?.crm_v1_customer?.customer_name,
              shipper: item?.job_shipper,
              origin_country:
                item?.fms_v1_jobs_job_origin_country?.country_name,
              destination_country:
                item?.fms_v1_jobs_job_destination_country?.country_name,
              status:
                item?.fms_v1_job_shipment_status[0]?.job_shipment_status_name,
              jobId: item?.job_id,
              destpod:
                item
                  ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                  ?.location_name,
              orgpol:
                item
                  ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                  ?.location_name,
                  shipmentrefno:item?.job_shipment_ref_no
            });
          });
          setJobtblData(temp);
          setIsData(true);
        }
      })
      .catch((err) => {});
  }, []);

  console.log("values isss",Values);
  useEffect(() => {
    if (jobtblData && IsData === true && blobUrl ) {
      setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [jobtblData, IsData ,blobUrl]);

  window.onafterprint = function () {
    // Code to run after printing or if the user cancels the print operation
    if (window.matchMedia("print").matches) {
      console.log("Printing completed.");
    } else {
      console.log("Print operation canceled.");
      navigate(`${ROUTES.SHIPMENT_PENDING_JOBS}`);
    }
  };

  return (
    <>
      <div>
        <ShipPendingJobPrint
        quotationHeaderImg={blobUrl}
          shipment_table_data1={
            <>
              {jobtblData &&
                jobtblData?.length>0 &&
                jobtblData.map((item, index) => {
                  console.log("flagJobData", item);

                  return (
                    <tr>
                      <td className="">{index + 1} </td>
                      <td className="">{item?.ref_no} </td>
                      <td className="">{item?.date} </td>
                      <td className="">{item?.freight} </td>
                      <td className="">{item?.customer} </td>
                      <td className="">{item?.shipper} </td>
                      <td className="">{item?.origin_country} </td>
                      <td className="">{item?.destination_country} </td>
                      <td className="">{item?.orgpol} </td>
                      <td className="">{item?.destpod} </td>
                      <td className="">{item?.status} </td>
                      <td className="">{item?.shipmentrefno} </td>
                    </tr>
                  );
                })}
            </>
          }
        />
      </div>
    </>
  );
}
export default PendingJobPrint;
