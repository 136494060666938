import { Suspense, useContext } from "react";
import { UserContext } from "../../context/user.context";
import { Navigate, Outlet } from "react-router-dom";
import ROUTES from "../../routes/index";
import Layout from "../../layouts/layout";
import LoadingPage from "../../LoadingPage/LoadingPage";
const ProtectedRoute = ({ roles }) => {
  const { isAuthenticated } = useContext(UserContext);
  console.log("inside proteced route");
  let authenticated = isAuthenticated();
  return authenticated ? (
    <Layout>
      <Suspense fallback={<LoadingPage />}>
        <Outlet />
      </Suspense>
    </Layout>
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};

export default ProtectedRoute;
