import React, { useEffect, useState } from "react";
import PrintReports from "../../../../components/Reports-Print/Printreport";
import { useSearchParams } from "react-router-dom";
import PublicFetch from "../../../../utils/PublicFetch";
import { CRM_BASE_URL_PURCHASING } from "../../../../api/bootapi";
import moment from "moment";

function PurchasebillreportPrint() {
  const [searchParams] = useSearchParams();
  const [NoofDecimal, setNoOfDecimal] = useState(
    localStorage.getItem("NumberOfDecimals")
  );
  const [purchseVendorId, setPurchseVendorId] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [billReportData, setBillReportData] = useState();
  const [billTotal, setbillTotal] = useState(0);
  const [PaidTotal, setPaidTotal] = useState(0);
  const [balTotal, setbalTotal] = useState(0);

  console.log("searchvendorid", searchParams.get("vendorid"));
  console.log("searchParamdatefrom", searchParams.get("datefrom"));
  console.log("searchParamdateto", searchParams.get("dateto"));

  useEffect(() => {
    setPurchseVendorId(searchParams.get("vendorid"));
    setStartDate(searchParams.get("datefrom"));
    setEndDate(searchParams.get("dateto"));
    if (purchseVendorId && startDate && endDate) {
      GetPurchaseBillReport();
    }
  }, [startDate, endDate, purchseVendorId]);

  const GetPurchaseBillReport = () => {
    // console.log("dataa", data);
    // if (data) {
    let date_from = moment(startDate);
    let date_to = moment(endDate);

    //   setPurchaseVendorId(data?.purchase_vendor);
    //   setStartDate(date_from);
    //   setEndDate(date_to);

    PublicFetch.post(`${CRM_BASE_URL_PURCHASING}/purchase-bill-report`, {
      purchase_vendor_id: parseInt(purchseVendorId),
      date_from: date_from,
      date_to: date_to,
    })
      .then((res) => {
        let PbTemp = [];
        let paid_bill_total = 0;
        let balance_total = 0;

        let biltot = 0;
        console.log("purchase bill", res?.data?.data);
        if (res?.data?.success) {
          // setAllData(res?.data?.data?.accData);

          res?.data?.data.forEach((item, index) => {
            console.log("item", item);

            let pur_bill_amt =
              parseFloat(item?.pur_bill_total_amount) -
              parseFloat(item?.pur_bill_due_amount);

            paid_bill_total = paid_bill_total + parseFloat(pur_bill_amt);

            balance_total =
              balance_total + parseFloat(item?.pur_bill_due_amount);

            biltot = biltot + parseFloat(item?.pur_bill_total_amount);

            // setbalTotal(balTotal+parseFloat(item?.pur_bill_due_amount))
            console.log("pur bill", pur_bill_amt);

            PbTemp.push({
              pur_bill_no: item?.pur_bill_no,
              pur_bill_date: moment(item?.pur_bill_date).format("DD-MM-YYYY"),
              pur_bill_vendor_id: item?.crm_v1_vendors?.vendor_name,
              pur_bill_total_amount:
                item?.pur_bill_total_amount?.toFixed(NoofDecimal),
              paid_bill: pur_bill_amt?.toFixed(NoofDecimal),
              pur_bill_due_amount:
                item?.pur_bill_due_amount?.toFixed(NoofDecimal),
            });
          });

          console.log("fjdnk", PbTemp);
          setBillReportData(PbTemp);
         

          setbillTotal(biltot);
          // console.log("purbill total",paid_bill_total);
          setPaidTotal(paid_bill_total);
          // console.log("baltotal", balance_total);
          setbalTotal(balance_total);
          handleprint();
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  const handleprint = () => {
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <div>
      <PrintReports
        report_name={"PurchasebillReport"}
        table_header_data={
          <>
            <tr className="">
              <th className="head_border" style={{ textAlign: "left" }}>
                SL No
              </th>
              <th className="" style={{ textAlign: "left" }}>
                PURCHASE BILL NO
              </th>
              <th className="" style={{ textAlign: "left" }}>
                DATE
              </th>
              <th className="tsk_exp" style={{ textAlign: "left" }}>
                VENDOR
              </th>
              <th className="" style={{ textAlign: "right" }}>
                BILL AMOUNT
              </th>
              <th className="" style={{ textAlign: "right" }}>
                PAID AMOUNT
              </th>
              <th style={{ textAlign: "right" }}> BALANCE AMOUNT</th>
            </tr>
          </>
        }
        table_print_data={
          <>
            <>
              {billReportData &&
                billReportData?.map((item, indx) => {
                  console.log("dailyydatata", item);
                  return (
                    <tr>
                      <td>{indx + 1} </td>
                      <td>{item?.pur_bill_no} </td>
                      <td>{item?.pur_bill_date} </td>
                      <td>{item?.pur_bill_vendor_id} </td>
                      <td style={{textAlign:"right" }}>{item?.pur_bill_total_amount} </td>
                      <td style={{textAlign:"right" }}>{item?.paid_bill} </td>
                      <td style={{textAlign:"right" }}>{item?.pur_bill_due_amount} </td>
                    </tr>
                  );
                })}
            </>
          </>
        }
      />
    </div>
  );
}

export default PurchasebillreportPrint;
