import { useEffect, useState } from "react";
import "./Deliverynote.scss";
import { GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
import PublicFetch from "../../utils/PublicFetch";
// import PublicFetch from "../../../utils/PublicFetch";
// import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";

function DeliveryNote({
  content_deliverynote
}) {
  const [companyInfodata, setCompanyInfodata] = useState("");
  const [defaultCurrency, setDefaultCurrency] = useState("");

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data[0]?.company_name);
          setCompanyInfodata(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const allCurrency = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/currency`)
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of cuurency", res.data.data);
          res?.data?.data?.forEach((item, index) => {
            if (item.currency_is_default === 1) {
              console.log("default currency", item);
              setDefaultCurrency(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  let temp4 = "uploads/invoiceTemplate/template4.png";

  useEffect(() => {
    companyinfo();
    allCurrency();

    // allCurrency();
    // getallbanks();
  }, []);

  useEffect(()=>{
    // if(contentSuccess=== true ){
      setTimeout(() => {
        window.print();
      }, 1000);
    // }
   
  },[])



  return (
    <>
      <div>
        <div>
          <div id="pageborder1st"></div>
          <table className="invoice_header2">
            {companyInfodata &&
              companyInfodata.length > 0 &&
              companyInfodata?.map((item, index) => {
                return (
                  <thead className="invoice_header ">
                    <tr className="invoice_header ">
                      <div className="header_deliverynote">
                        <div className="header__address_wrapper">
                          <div className="header__address_logo_deliverynote">
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/${item.company_quotation_header}`}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </tr>
                    <br></br>
                  </thead>
                );
              })}

            <br />
            <div className=" d-flex justify-content-center">
                <h5 className="h5style text-center">DELIVERY NOTE</h5>
            </div>
            <br></br>
            <div className="content_deliverynote px-2 ">
              <div className="deliverynote_details ">
                <table>
                  <tbody>
                    {content_deliverynote}
                    
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <div className="content2_deliverynote px-2 ">
              <div className="deliverynote_details ">
                <table>
                  <tbody>
                    <tr>
                      <td style={{fontSize:"19px"}} >Received By</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{fontSize:"19px"}}>Signature</td>
                      <td></td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>

            <br />
            <div className="content3_deliverynote px-2 ">
              <div className="deliverynote_details2 ">
                <table>
                  <tbody>
                    
                    <tr>
                      <td style={{fontSize:"17px"}}>Received shipment is good condition from <span style={{fontSize:"20px"}}>{companyInfodata?.[0]?.company_name} </span> </td>
                   
                    </tr>
                   
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <div className="content2_deliverynote px-2 ">
              <div className="deliverynote_details ">
                <table>
                  <tbody>
                  
                    {/* <tr>
                      <td style={{fontSize:"20px"}}>Received shipment is good condition from cargo plus middle east co will</td>
                      <td  ></td>
                    </tr> */}
                    <tr>
                      <td style={{fontSize:"19px"}}>Date</td>
                      <td  ></td>
                    </tr>
                    <tr>
                      <td style={{fontSize:"19px"}}>Time</td>
                      <td  ></td>
                    </tr>
                    <tr>
                      <td style={{fontSize:"19px"}}>Remarks</td>
                      <td  ></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </table>
        </div>
      </div>
    </>
  );
}
export default DeliveryNote;
