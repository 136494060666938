import React, { createContext, useContext, useState } from "react";

const UnsavedChangesContext = createContext();

const FormActionTriggersContext = createContext();

export const UnsavedChangesProvider = ({ children }) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const setUnsavedChangesStatus = (status) => {
    setUnsavedChanges(status);
  };

  return (
    <UnsavedChangesContext.Provider
      value={{ unsavedChanges, setUnsavedChangesStatus }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};

export const useUnsavedChanges = () => {
  return useContext(UnsavedChangesContext);
};

// for if form trigger something => context to return some value

export const FormActionTriggerProvider = ({ children }) => {
  const [formActionTrigger, setFormActionTrigger] = useState(false);

  const setFormActionTriggerStatus = (status) => {
    setFormActionTrigger(status);
  };

  return (
    <FormActionTriggersContext.Provider
      value={{ formActionTrigger, setFormActionTriggerStatus }}
    >
      {children}
    </FormActionTriggersContext.Provider>
  );
};

export const useFormActionTrigger = () => {
  return useContext(FormActionTriggersContext);
};
