import React from 'react'
import PrintReports from '../../../../components/Reports-Print/Printreport'

function StatementofAccountsPrint() {
  return (
    <div>
<PrintReports/>

    </div>
  )
}

export default StatementofAccountsPrint