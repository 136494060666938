import React, { useEffect, useState } from "react";
import { ACCOUNTS, GENERAL_SETTING_BASE_URL } from "../../../api/bootapi";
import PublicFetch from "../../../utils/PublicFetch";

import "./arrivalnoticeprint.scss";

function Arrivalnoticeprint({
  invoice_details1,
  invoice_details2,
  invoice_details3,
  invoice_details4,
  invoice_details5,
  invoice_details6,
  invoice_table_header,
  invoice_table_data,
  amount_in_words,
  sub_total,
  total,
  arrivalheader_img,
  BankBreak,
}) {
  const [companyInfodata, setCompanyInfodata] = useState();
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [defaultbank, setdefaultbank] = useState();
  const [arrivalNotAddress, setArrivalNotAddress] = useState();
  const [clocation, setclocation] = useState();
  const [pageBreak, setPageBreak] = useState(0);
  console.log(
    "invotable",
    invoice_table_data?.props?.children?.length,
    pageBreak,
    invoice_details3?.props?.children?.length,BankBreak
  );

  useEffect(() => {
    if (invoice_table_data?.props?.children?.length) {
      setPageBreak(invoice_table_data?.props?.children?.length);
    }
  }, [invoice_table_data]);

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
          res?.data?.data.map((item, indx) => {
            console.log("jnef", item);
            setclocation(item?.company_location);
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const allCurrency = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/currency`)
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("success of cuurency", res.data.data);
          res?.data?.data?.forEach((item, index) => {
            if (item.currency_is_default === 1) {
              console.log("default currency", item);
              setDefaultCurrency(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getallbanks = async () => {
    try {
      const allbanks = await PublicFetch.get(`${ACCOUNTS}/bank`);
      console.log("getting all bank details", allbanks.data.data);
      // setAllbankdetails(allbanks.data.data)
      allbanks?.data?.data?.forEach((item, index) => {
        if (item.bank_default === 1) {
          console.log("default bankk", item);
          setdefaultbank(item);
          // setDefaultCurrency(item);
        }
      });
    } catch (err) {
      console.log("error to fetching  bank details", err);
    }
  };

  const getfmssettings = async () => {
    try {
      const allfmssetting = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/fms`
      );
      console.log(
        "all fmssettinggg",
        allfmssetting.data.data.fms_settings_arrival_notice_footer
      );
      setArrivalNotAddress(
        allfmssetting.data.data.fms_settings_arrival_notice_footer
      );
    } catch (err) {
      console.log("error while getting the fmssettinggg: ", err);
    }
  };

  useEffect(() => {
    getfmssettings();
    companyinfo();
    allCurrency();
    getallbanks();
  }, []);

  return (
    <div>
      <div>
        {/* div to set page border on all pages */}
        <div id="pageborder"></div>
        {/* page border div ends */}
        {/* <div className="print-header"> */}

        <table className="arrival_table_top">
          {/* {companyInfodata &&
            companyInfodata.length > 0 &&
            companyInfodata?.map((item, index) => {
              return ( */}
          <thead className="arrival_header">
            <tr className="arrival_header">
              <div className="">
                <div className="header__arrivalnotice_logo d-flex justify-content-center">
                  <img
                    // style={{ height: "120px", width: "700px" }}
                    src={arrivalheader_img}
                    alt=""
                  />
                </div>
              </div>
            </tr>
          </thead>
          {/* );
            })} */}
            

          <tbody
            style={{ marginBottom: "2%" ,columnCount: 2,columnGap: '10px'}}
            className=" arrival_content  page-break"
          >
            <div className="text-center ">
              <h2 className="quotation_heading_style">CARGO ARRIVAL NOTICE</h2>
            </div>

            <div className="arrival_not_details">
              <div className="arrival_not_details__col invoice_quot_details__col_1 " style={{ marginTop:'3px'}}>
                <table>
                  <tbody>{invoice_details1}</tbody>
                </table>
              </div>
              <div className="invoice_quot_details__col invoice_details__col_2  px-3"  style={{ marginTop:'3px'}}>
                <table>
                  <tbody className="">{invoice_details2}</tbody>
                </table>
              </div>
            </div>

            <div className="invoice_details_arrival  ">
              <div className="invoice_details__col invoice_details__col_1 ">
                <table>
                  <tbody>{invoice_details3}</tbody>
                </table>
              </div>
              <div className="invoice_details__col invoice_details__col_2 ">
                <table>
                  <tbody>{invoice_details4}</tbody>
                </table>
              </div>
            </div>

            <div className="invoice_details_arrival  ">
              <div className="invoice_details__col invoice_details__col_1 ">
                <table>
                  <tbody>{invoice_details5}</tbody>
                </table>
              </div>
              <div className="invoice_details__col invoice_details__col_2 ">
                <table>
                  <tbody>{invoice_details6}</tbody>
                </table>
              </div>
            </div>

            {/* listing table start */}
            <table className="details_table  " cellSpacing={0}>
              <thead style={{padding:"0px", margin:"0px"}} className="">{invoice_table_header}</thead>
              <tbody>{invoice_table_data}</tbody>

              <tr className="">
                <td align="center"></td>
                <td className="tsk_expp ">
                  Total {defaultCurrency?.currency_code}:{" "}
                </td>

                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "5px",
                    fontWeight: 600,
                  }}
                >
                  {total}{" "}
                </td>
              </tr>
            </table>
            {/* listing table end */}

            <div className="invoice_details_arrival   ">
              <div className="  px-2">
                <table cellSpacing={0}>
                  <tbody>
                    {" "}
                    <tr className="px-1">
                      <td>Total In words </td>
                      &nbsp;
                      <td> {defaultCurrency?.currency_code}</td>
                      &nbsp;
                      <td>:</td>
                      &nbsp;
                      <td className="">{amount_in_words} Only</td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="invoice_details__col invoice_details__col_2 py-2">
                <table>
                  <tbody>
                    <tr
                      className=""
                      style={{ paddingLeft: 50, textAlign: "right" }}
                    >
                      <th
                        style={{ fontWeight: 600 }}
                        className="paddinglefttotal"
                      >
                        Total
                      </th>
                      <td>:</td>
                      <th style={{ fontWeight: 600 }} className="px-5">
                        {defaultCurrency?.currency_code} {total}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
            { invoice_table_data?.props?.children?.length > 9 &&  <div className="page-break"></div>}
            {/* {BankBreak && invoice_table_data?.props?.children?.length > 5 && <div className="page-break"></div>} */}

            <div className="arrival_content  px-2">
              <h6 className="">Bank Details</h6>

              {companyInfodata &&
                companyInfodata.length > 0 &&
                companyInfodata?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td style={{ fontWeight: 600 }}>Name</td>
                        <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                          :
                        </td>
                        <td>{item.company_name} </td>
                      </tr>
                    </>
                  );
                })}
              {/* <div className="page-break"></div> */}

              <tr>
                <td style={{ fontWeight: 600 }}>Bank Name</td>
                <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>:</td>
                <td>{defaultbank?.bank_name} </td>
              </tr>
              <tr>
                <td style={{ fontWeight: 600 }}>Branch</td>
                <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>:</td>
                <td>{defaultbank?.bank_branch} </td>
              </tr>

              <tr>
                <td style={{ fontWeight: 600 }}>Account No</td>
                <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>:</td>
                <td>{defaultbank?.bank_account_number} </td>
              </tr>
              <tr>
                <td style={{ fontWeight: 600 }}>IBAN No</td>
                <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>:</td>
                <td>{defaultbank?.bank_iban_no} </td>
              </tr>
            </div>
            <p className="px-2">Thanks For your Business</p>
          </tbody>
          
          

<tfoot>
          <footer
            style={{
              backgroundColor: "white",
              zIndex: 999,
              position: "fixed",
              // marginTop:'700px',

              bottom: 0,
              border: "1px solid black",
              width: "100%",
              height: "13%",
              marginBottom:0,

              // borderBottom: "1px solid black",
            }}
            
          >
            <p
              style={{
                paddingTop: "10px",
                paddingLeft: "10px",
                lineHeight: ".5",
                textAlign: "center",
                fontWeight: 400,
              }}
              dangerouslySetInnerHTML={{
                __html: `${arrivalNotAddress}`,
              }}
            ></p>
            {/* {arrivalNotAddress} */}

            <div className="col-12 d-flex justify-content-center ">
              <p className="p-0 m-0">
                Our Location :{" "}
                <a href={clocation} style={{ cursor: "pointer" }}>
                  {clocation}{" "}
                </a>
              </p>
            </div>
            <div className="p-0 m-0 col-12 d-flex justify-content-center">
              <span style={{ fontWeight: 600 ,fontSize:12}}>
                WE DON’T HAVE ANY OTHER BRANCHES{" "}
              </span>
            </div>
          </footer>
          </tfoot>
        </table>

        {/* <div className="footer ">
          
          <p className="p-2" style={{ fontWeight: 600 }}>
            {arrivalNotAddress}
          </p>
          <div className="row">
            <div className="col-12 d-flex justify-content-center ">
              <p>
                Our Location :{" "}
                <a href={clocation} style={{ cursor: "pointer" }}>
                  {clocation}{" "}
                </a>
              </p>
            </div>
          </div>

          <h6 style={{ fontWeight: 500 }}>WE DON’T HAVE ANY OTHER BRANCHES </h6>
        </div> */}
      </div>
    </div>
  );
}

export default Arrivalnoticeprint;
