import React, { useEffect, useState, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./lead_list_icon.scss";
import jsPDF from "jspdf";
import { FaFileExcel, FaFileCsv, FaFilePdf, FaBookOpen } from "react-icons/fa";
import { message, Checkbox } from "antd";
import { MdFileCopy } from "react-icons/md";
import { AiFillPrinter } from "react-icons/ai";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx/xlsx.js"; //for xl download
import CopyToClipboard, { copyToClipboard } from "react-copy-to-clipboard"; //copy to clipboard
import { Toaster, toast } from "react-hot-toast"; // copy to clip board
import { Tooltip, Table } from "antd";
import "jspdf-autotable";
import Cargoquotheader from "../../assets/logo/Cargo Plus Logo -Header in Quotation.png";
import PublicFetch from "../../utils/PublicFetch";
import { GENERAL_SETTING_BASE_URL } from "../../api/bootapi";
import * as Excel from "exceljs";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import { FALSE } from "sass";

const Leadlist_Icons = ({
  datas,
  columns,
  items,
  filename,
  chechboxes,
  xlheading,
  csvheading,
  name,
  dataSource,
  printonClick,
  HeaderName,
}) => {
  console.log("vdsfud", datas);
  console.log("1123fud", columns);
  console.log("11234453443", items);
 // console.log("11222",HeaderName);
  const [companyInfo, setCompanyInfo] = useState();

  const columnsKeys = columns?.map((column) => column.key);
  const tableRef = useRef();
 // const [printPage,setprintpage]=useState(false);
 
  // const printPage = () => {
  //   window.print();
  // };

  const [selectedColumns, setSelectedColumns] = useState(columnsKeys);
  const filteredColumns = columns?.filter((column) =>
    selectedColumns?.includes(column.key)
  );
  console.log("filtered columns ", filteredColumns);
  const onChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  // company info
  const CompanyInfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        if (res?.data?.success) {
          setCompanyInfo(res?.data?.data[0]);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  // const exportPDF = () => {
  //  // console.log("inside the export");
  //   console.log("columns", columns, datas);

  //   const unit = "pt";
  //   const size = "A4"; // Use A1, A2, A3 or A4
  //   const orientation = "portrait"; // portrait or landscape

  //   const marginLeft = 40;
  //   const doc = new jsPDF(orientation, unit, size);
  //   const NewImgLog = `${process.env.REACT_APP_BASE_URL}/${companyInfo?.company_quotation_header}`;
  //   // Load your image
  //   const img = new Image();
  //   img.src = NewImgLog; // Replace with the actual image URL

  //   // Wait for the image to load
  //   img.onload = () => {
  //     // Add the image to the PDF
  //     doc.addImage(img, "JPEG", 10, 10, 575, 120); // Adjust the position and size as needed

  //     console.log("ddoocc", doc.addImage);

  //     // Set the header text
  //     const headerText = HeaderName;

  //     // Calculate the width and height of the header text
  //     const textWidth =
  //       doc.getStringUnitWidth(headerText) * doc.internal.getFontSize();
  //     const marginLeft = (doc.internal.pageSize.width - textWidth) / 2;

  //     // Add the header text to the PDF
  //     doc.text(headerText, marginLeft, 150);

  //     doc.setFontSize(15);
  //     const startY = 170;
  //     doc.autoTable({
  //       startY: startY,
  //       columns: columns?.map((col) => ({ ...col, dataIndex: col.key })),
  //       body: datas, // Include the datas variable here
  //     });

  //     doc.save("report.pdf");
  //   };
  // };

  const exportPDF = (e) => {
    //preventDefault()
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const filteredColumns = columns.filter(col => col.title.toLowerCase() !== 'actions'  && col.title.toLowerCase() !== 'sel').map(col => ({
      ...col,
      dataIndex: col.key,
    }));
   
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    const pageWidth = doc.internal.pageSize.getWidth();

    const title = `${name}`; // Change this to your desired title
    const titleWidth = doc.getTextWidth(title);
    const titleX = (pageWidth - titleWidth) / 2;
    const titleY = 50; // Adjust this value to set the title position
    doc.text(title, titleX, titleY);

    doc.setFontSize(15);

    doc.autoTable({
     // columns: columns.map((col) => ({ ...col, dataIndex: col.key })),
     startY: titleY + 20,
     columns :filteredColumns,
      body: items,
    });
    
    if(e === true){
     
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    }else{
     doc.save("report.pdf");}
    
   
  };
  console.log("collll",filteredColumns);
  console.log("SSSSSSSS", xlheading);
  console.log("datasss", datas);

  const handleExport = () => {
    var wb = XLSX.utils.book_new();
    // Add your image as a header
    const NewImgLog = `${process.env.REACT_APP_BASE_URL}/${companyInfo?.company_quotation_header}`;

    const imgPath = NewImgLog; // Replace with the actual image path
    // const imgData = getImageBase64Data(imgPath);
    // const imgData = `data:image/png;base64,${getImageBase64Data(imgPath)}`;

    const getImageBase64Data = (url, callback) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.readyState === FileReader.DONE) {
            callback(reader.result);
          }
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.send();
    };
    // const imgData = ;

    // console.log("logging image", imgData);
    getImageBase64Data(imgPath, (imageBase64) => {
      console.log("ImageBaseUrl", imageBase64);
      var ws = XLSX.utils.json_to_sheet(datas);

      // XLSX.utils.book_append_sheet(
      //   wb,
      //   {
      //     Sheets: {
      //       HeaderImage: {
      //         "!": [{ t: "n", f: "image/png", ref: "A1", v: imgData }],
      //       },
      //     },
      //     SheetNames: ["HeaderImage"],
      //   },
      //   "HeaderImage"
      // );

      // XLSX.utils.book_append_sheet(
      //   wb,
      //   {
      //     Sheets: {
      //       HeaderImage: {
      //         "!": [{ t: "n", f: "image/png", ref: "A1", v: imageBase64 }],
      //       },
      //     },
      //     SheetNames: ["HeaderImage"],
      //   },
      //   "HeaderImage"
      // );

      // XLSX.utils.book_append_sheet(
      //   wb,
      //   imageBase64.Sheets[imageBase64.SheetNames[0]],
      //   "HeaderImage"
      // );
      // ws.A1 = { t: "s", v: imageBase64 };
      // ws["!cols"] = [{ wpx: 100 }]; // Adjust the width as needed

      XLSX.utils.book_append_sheet(wb, ws, "Reports");

      XLSX.utils.sheet_add_aoa(ws, xlheading, { origin: "A4" });
      // ws["!cols"] = [{ wch: 15 }];

      var wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 17 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;

      XLSX.writeFile(wb, `${name}.xlsx`);
      console.log("xlsx data", ws);
      return addStyle();
    });
  };
  const addStyle = () => {
    console.log("xlsx downloaded");
  };

  const handleExcelExport = async () => {
    try {
      // Fetch the PNG image from your database (replace with your own fetch logic)
      if (companyInfo?.company_quotation_header) {
        // Fetch the PNG image from your database (replace with your own fetch logic)
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${companyInfo.company_quotation_header}`
        );
        const imageBlob = await response.blob();

        // Convert the image blob to a data URL
        const imageUrl = URL.createObjectURL(imageBlob);
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          const base64Image = reader.result.split(",")[1];

          // Create a new workbook
          const workbook = new ExcelJS.Workbook();

          // Add a worksheet
          const worksheet = workbook.addWorksheet("Sheet 1");

          console.log("imgae blob");
          const myBase64Image = `data:image/png;base64,${imageUrl}`;

          const imageId2 = workbook.addImage({
            base64: base64Image,
            extension: "png",
          });

          console.log("imgeid2", imageId2);
          worksheet.addImage(imageId2, "A1:K9");
          worksheet.mergeCells("A1:K9");
          worksheet.addRow({});
          let col;

          // Populate the worksheet with data

          xlheading.forEach((header) => {
            col = header;
          });
          const columnHeaders = Object.values(col);
          worksheet.getRow(11).values = columnHeaders;
          worksheet.getRow(11).font = {
            name: "Arial Black",
            bold: true,
          };

          // Auto-size each column based on content
          worksheet.columns.forEach((column, colNumber) => {
            let maxLength = 10;
            column.eachCell({ includeEmpty: true }, (cell) => {
              const columnLength = cell.value
                ? cell.value.toString().length
                : 10;
              if (columnLength > maxLength) {
                maxLength = columnLength;
              }
            });
            column.width = maxLength < 10 ? 10 : maxLength + 10; // Add some padding
            column.alignment = { wrapText: true }; // Enable text wrapping
          });

          // worksheet.columns = xlheading;
          worksheet.addRows(datas);
          // Generate a Blob for the Excel file
          workbook.xlsx.writeBuffer().then((excelData) => {
            const blob = new Blob([excelData], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            // Create a link to download the Excel file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${name}.xlsx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          });
        };
      } else {
        // Handle the case where no image URL is available
        console.warn("No image URL found in companyInfo.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
 
  const csvOptions = {
    headers: csvheading,
    data: datas,
    filename: filename,
    autoWidth: false, // Disable auto width calculation
    columnWidths: [100, 250, 100, 250, 350, 350, 300], // Set the widths for each column
  };

  // useEffect For Api cAll
  useEffect(() => {
    CompanyInfo();
  }, []);

  return (
    <>
      <div className="col-auto">
        <div className="row flex-wrap">
          <ul className="leadlist_icons_panel">
            {/* <li className="icon-border"> */}
            {/* <a className="icon" href="#"> */}
            {/* <CopyToClipboard text={JSON.stringify(datas)}> */}
            {/* message.success(content, [duration], onClose) */}
            {/* <Tooltip title="Copy to Clipboard">
                  <MdFileCopy onClick={() => message.success("Text Copied")} />
                  </Tooltip> */}
            {/* </CopyToClipboard> */}
            {/* </a> */}
            {/* </li> */}
            <li  className="icon-border">
            <div className="icon">
              <Tooltip title="XLSX">
                  <FaFileExcel onClick={handleExcelExport} style={{ height: 11 }} />
              </Tooltip>
              </div>
            </li>
            <li className="icon-border">
              <div className="icon" >
              <Tooltip title="CSV ">
                <CSVLink style={{ height: 11, color: "grey" }} {...csvOptions}>
                  
                    <FaFileCsv style={{ height: 11 }} />
                
                </CSVLink>
                </Tooltip>
              </div>
            </li>
            <li onClick={exportPDF} className="icon-border">
            <div className="icon" >
              <Tooltip title="PDF Download">
                
                  <FaFilePdf style={{ height: 11 }} />
                  
                
              </Tooltip>
              </div>
            </li>
            <li
              onClick={() => {
                
                exportPDF(true);
              }}
              className="icon-border"
            >
              <Tooltip title="Print">
                <div className="icon" href="">
                  <AiFillPrinter
                    style={{ height: 14, width: 14 }}
                   // onClick={printonClick}
                  />
                </div>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Leadlist_Icons;
