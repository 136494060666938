import React, { useEffect, useState } from "react";
import PrintReports from "../../../components/Reports-Print/Printreport";
import PublicFetch from "../../../utils/PublicFetch";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

function MonthlyreportPrint() {
  const [searchParams] = useSearchParams();

  const [reportData, setReportData] = useState();
  const [month,setMonth]= useState()
  const[year,setYear]= useState()

  console.log("searchParamsmonth", searchParams.get("month"));
  console.log("searchParamsyearr", searchParams.get("year"));
 

  useEffect(() => {
   

    setMonth(searchParams.get("month"));
    setYear(searchParams.get("year"));
   
    if (month && year) {
        handleMonthlySearch();
    }
  }, [month, year]);


  const handleMonthlySearch = async () => {
    // if (Read) {
    //   if (!month || !year) {
    //     message.error("All feilds are required");
    //     return;
    //   }
      try {
        const MonthlySearchData = await PublicFetch.get(
          `${process.env.REACT_APP_BASE_URL}/freightManagement/v1/reports/job-report/monthly/${month}/${year}`
        );
        if (MonthlySearchData.status === 200) {
          let temp = [];
          MonthlySearchData.data.data.responseData.map((item, index) => {
            temp.push({
              sl_no: index + 1,
              job_no: item.jobNo ? item.jobNo : "_",
              job_date: item.jobDate
                ? moment(item.jobDate).format("DD-MM-YYYY")
                : "_",
              lead: item?.lead?.customer_name ? item?.lead?.customer_name : "_",
              cost: item.cost ? Number(item.cost).toFixed(2) : 0.0,
              expense: item.expense ? Number(item.expense).toFixed(2) : 0.0,
              profit: item.profitLoss
                ? Number(item.profitLoss).toFixed(2)
                : 0.0,
            });
          });
          setReportData(temp)
handleprint()
        //   let temp2 = [];
        //   temp2.push({
        //     costTotal:
        //       MonthlySearchData.data.data.costTotal != null
        //         ? MonthlySearchData.data.data.costTotal
        //         : "_",
        //     expenseTotal:
        //       MonthlySearchData.data.data.expenseTotal != null
        //         ? MonthlySearchData.data.data.expenseTotal
        //         : "_",
        //     profitLossTotal:
        //       MonthlySearchData.data.data.profitLossTotal != null
        //         ? MonthlySearchData.data.data.profitLossTotal
        //         : "_",
        //   });
        //   if (MonthlySearchData.data.data.responseData.length != 0) {
        //     setTotalSummary(temp2);
        //   } else {
        //     setTotalSummary("null");
        //   }
        //   setMonthlyReportData(temp);
        }
      } catch (err) {
        console.log("Error while fecthing monthly report data");
      }
    // } else {
    //   let a = true;
    //   if (a) {
    //     message.error("You have No Permission");
    //   }
    //   setTimeout(() => {
    //     a = false;
    //   }, 5000);
    // }
  };

  const handleprint=()=>{
    setTimeout(() => {
      window.print()
    }, 1000);
  }

  return (
    <div>
      <PrintReports
        report_name={"Monthly Report"}
        table_header_data={
          <>
            {/* <thead className="print_table"> */}
            <tr className="">
              {/* <th className="">Sl.No</th> */}
              <th className="head_border" style={{ textAlign: "left" }}>
                SL No
              </th>
              <th className="" style={{ textAlign: "left" }}>
                JOB NO
              </th>
              <th className="" style={{ textAlign: "left" }}>
                JOB DATE
              </th>
              <th className="tsk_exp" style={{ textAlign: "left" }}>
                LEAD
              </th>
              <th className="" style={{ textAlign: "left" }}>
                COST
              </th>
              <th className="" style={{ textAlign: "left" }}>
                EXPENSE
              </th>
              <th style={{ textAlign: "left" }}> PROFIT AND LOSS</th>
            </tr>
            {/* </thead> */}
          </>
        }
        table_print_data={
          <>
            {reportData &&
              reportData?.map((item, indx) => {
                console.log("dattata",item);
                return (
                  <tr>
                    <td>{indx + 1} </td>
                    <td>{item?.job_no} </td>
                    <td>{item?.job_date} </td>
                    <td>{item?.lead} </td>
                    <td>{item?.cost} </td>
                    <td>{item?.expense} </td>
                    <td>{item?.profit} </td>
                  </tr>
                );
              })}
          </>
        }
      />
    </div>
  );
}

export default MonthlyreportPrint;
