import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ROUTES from "../../routes";
import { checkPermission } from "../../utils/check_permission";

function CustomerSideBar() {
  const [clickedfrom, setClickedFrom] = useState();
  function checkSubPermission(name) {
    let permissions = localStorage.getItem("userPermissionsSub");
    if (permissions) {
      console.log("permissions123", name);

      let userPermissions = JSON.parse(permissions);
      let hasPermission = userPermissions.some((item, index) => {
        return item.subject === name;
      });
      console.log("hasPer", name, hasPermission);
      return hasPermission;
      // return true;
    } else {
      return false;
    }
  }

  // useEffect
  useEffect(() => {
    let Value = localStorage.getItem("CustomerClicked");
    setClickedFrom(Value);
  }, [localStorage.getItem("CustomerClicked")]);
  return (
    <div>
      <div className="navbar">
        <div>
          <nav className={`nav-menu active  nav-menu `}>
            <ul className="nav-menu-items p-0 ">
              {clickedfrom === "CustomerLogin" && (
                <>
                  {/* {checkSubPermission("dashboard_crm") ? ( */}
                  <li className="nav-text ps-2">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active-link" : "link"
                      }
                      to={ROUTES.CUSTOMER_DASHBOARD}
                    >
                      <div className="ps-3 subactivelink">Dashboard</div>
                    </NavLink>
                  </li>
                  {/* ) : (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.PERMISSIONDEN}
                      >
                        <div className="ps-3 subactivelink">CRM </div>
                      </NavLink>
                    </li>
                  )} */}
                  {/* {checkPermission("dashboard") && (
                    <li className="nav-text  ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.DASHBOARD_PURCHASE}
                      >
                        <div className=" ps-3 subactivelink">PURCHASE</div>
                      </NavLink>
                    </li>
                  )} */}
                  {/* {checkPermission("dashboard") &&
                  checkSubPermission("dashboard_purchase") ? ( */}
                  <li className="nav-text ps-2">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active-link" : "link"
                      }
                      to={ROUTES.CUSTOMER_JOBS}
                    >
                      <div className="ps-3 subactivelink">Jobs</div>
                    </NavLink>
                  </li>
                  {/* ) : (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.PERMISSIONDEN}
                      >
                        <div className="ps-3 subactivelink">PURCHASE </div>
                      </NavLink>
                    </li>
                  )} */}

                  {/* {checkPermission("dashboard") &&
                  checkSubPermission("dashboard_operations") ? ( */}
                  <li className="nav-text ps-2">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active-link" : "link"
                      }
                      to={ROUTES.CUSTOMER_INVOICES}
                    >
                      <div className="ps-3 subactivelink">Invoices</div>
                    </NavLink>
                  </li>
                  {/* ) : (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.PERMISSIONDEN}
                      >
                        <div className="ps-3 subactivelink">OPERATIONS </div>
                      </NavLink>
                    </li>
                  )} */}

                  {/* {checkPermission("dashboard") &&
                  checkSubPermission("dashboard_accounts") ? ( */}
                  {/* <li className="nav-text ps-2">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active-link" : "link"
                      }
                      to={ROUTES.DASHBOARD_ACCOUNTS}
                    >
                      <div className="ps-3 subactivelink">ACCOUNTS</div>
                    </NavLink>
                  </li> */}
                  {/* ) : (
                    <li className="nav-text ps-2">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active-link" : "link"
                        }
                        to={ROUTES.PERMISSIONDEN}
                      >
                        <div className="ps-3 subactivelink">ACCOUNTS </div>
                      </NavLink>
                    </li>
                  )} */}
                </>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default CustomerSideBar;
