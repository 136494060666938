import { useLocation, useNavigate } from "react-router-dom";
import ShipStatusReportPrint from "../../../components/Shipstatusreport-print/shipstatusreportPrint";
import { useEffect, useState } from "react";
import SPendingBooking from "../../../components/ShipmentPendingBooking/SPendingBookingPrint";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import PublicFetch from "../../../utils/PublicFetch";
import {
    CRM_BASE_URL,
  CRM_BASE_URL_FMS,
  GENERAL_SETTING_BASE_URL,
} from "../../../api/bootapi";
import ROUTES from "../../../routes";
function CustomerwiseReportPrint({ location }) {
  console.log("dhjfjf", location);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  console.log("Statefrom route", state);
  const [jobtblData, setJobtblData] = useState();
  const [bookingData, setBookingData] = useState();
  const [IsData, setIsData] = useState(false);

  const [quotHeaderImg, setQuotHeaderImg] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();
  const [customerIds,setCustomerIds]= useState()
  const [custTableData,setCustTableData]= useState()

  const customerID = searchParams.get("customerId");


  console.log("customerid is  ", customerID);

  useEffect(() => {
if (customerID){
    setCustomerIds(customerID)
}
  }, []);

  const getComapanyData = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      setQuotHeaderImg(allcmpny.data.data[0].company_quotation_header);

      console.log(" cmpny details is", allcmpny.data.data);

      console.log("dataa", allcmpny.data.data.length);
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  useEffect(() => {
    getComapanyData();
    console.log("quotHeaderImg", quotHeaderImg);
    if (quotHeaderImg) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${quotHeaderImg}`, {
        responseType: "blob",
      })
        .then((resp) => {
          console.log("respBlob".resp?.blob());

          console.log("quotresp", resp);

          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
          console.log("blobconvert", blob);
        })
        .catch((err) => console.log("error123", err));
    }
  }, [quotHeaderImg]);

useEffect(()=>{
    SearchBydate()
},[customerIds])

  const SearchBydate = () => {
  ;
    PublicFetch.post(`${CRM_BASE_URL}/customer-wise-report`, {
      customer_id: parseInt(customerIds),
    })
      .then((res) => {
        console.log("Response", res);
        if (res.data.success) {
          console.log("data in sales report", res.data.data);
          setCustTableData(res?.data?.data);
          // let temp = [];
          // res.data.data.forEach((item, index) => {
          //   item?.crm_v1_leads?.crm_v1_lead_contacts.map((i, indx) => {
          //     temp.push({
          //       slno: index + 1,
          //       company: item?.crm_v1_leads?.lead_company_name,
          //       contact: item?.crm_v1_lead_contacts?.lead_contact_name,
          //       email: item?.crm_v1_leads?.lead_email,
          //       contact_date: moment(item?.lead_daily_sale_date).format("DD-MM-YYYY"),
          //       next_followup:moment(item?.lead_daily_sale_next_followup_date).format("DD-MM-YYYY")
          //       ,
          //       report_status: item?.crm_v1_leads?.lead_lead_status,
          //       remark: item?.lead_daily_sale_remarks,
          //       salesman:item?.crm_v1_leads?.crm_v1_leads_lead_salesperson?.employee_name
          //     });
          //   });
          // });

          // setallsalesreport(temp);

          // res.data.data.forEach((item,index))

          // setallsalesreport(res.data.data)
          // setReportData(temp);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  //   const Values = {
  //     date_to: DateTo && moment(DateTo),
  //     date_from: DateFrom && moment(DateFrom),
  //     customer_id: customer2 ? Number(customer2) : null,
  //     freight_type_id: freight?.length > 0 ? freight : null,
  //   };

  //   useEffect(() => {
  //     let data =
  //       Values?.date_to ||
  //       Values?.date_from ||
  //       (Values?.customer_id && Values?.customer_id !== null) ||
  //       (Values?.freight_type_id?.length > 0 && Values?.freight_type_id !== null)
  //         ? Values
  //         : {};
  //     data?.freight_type_id === null && delete data.freight_type_id;
  //     data?.customer_id === null && delete data.customer_id;

  //     console.log("datass", data);
  //     PublicFetch.post(
  //       `${CRM_BASE_URL_FMS}/shipment_pending_booking_report`,
  //       data
  //     )
  //       .then((res) => {
  //         if (res?.data?.success) {
  //           let temp = [];
  //           res?.data?.data?.data?.forEach((item, index) => {
  //             console.log("item in booking", item);
  //             temp?.push({
  //               slno: index + 1,
  //               ref_no: item?.job_booking_no,

  //               date: moment(item?.job_booking_date).format("DD-MM-YYYY"),
  //               freight: item?.fms_v1_freight_types?.freight_type_name,

  //               customer: item?.crm_v1_customer?.customer_name,
  //               shipper: item?.job_booking_shipper,
  //               origin_country:
  //                 item?.fms_v1_job_bookings_job_booking_origin_country
  //                   ?.country_name,
  //               destination_country:
  //                 item?.fms_v1_job_bookings_job_booking_destination_country
  //                   ?.country_name,
  //               status:
  //                 item?.fms_v1_job_booking_shipment_status[0]
  //                   ?.job_booking_shipment_status_name,
  //               jobBookingId: item?.job_booking_id,
  //               destpod: item?.fms_v1_locations_destination?.location_name,
  //               orgpol: item?.fms_v1_locations_origin?.location_name,
  //               shipmentRefNo:item?.job_booking_shipment_ref_no
  //             });
  //           });
  //           setJobtblData(temp);
  //           setIsData(true);
  //         }
  //       })
  //       .catch((err) => {});
  //   }, []);
  //   console.log("values isss booking", Values);
    useEffect(() => {
      if (custTableData  && blobUrl) {
        setTimeout(() => {
          window.print();
        }, 100);
      }
    }, [jobtblData, blobUrl]);

    window.onafterprint = function () {
      // Code to run after printing or if the user cancels the print operation
      if (window.matchMedia("print").matches) {
        console.log("Printing completed.");
      } else {
        console.log("Print operation canceled.");
        navigate(`${ROUTES.CUSTOMERWISE_REPORT}`);
      }
    };

  return (
    <>
      <div>
        <SPendingBooking
          customerWiseReport={true}
          quotationHeaderImg={blobUrl}
          customerwise_table_data={
            <>
              {custTableData &&
                custTableData?.length > 0 &&
                custTableData.map((item, index) => {
                  console.log("custtjnfj", item);

                  return (
                    <tr>
                      <td className="">{index + 1} </td>
                      <td className="">{moment(item?.date).format("DD-MM-YYYY")} </td>
                      <td className="">{item?.salesPerson} </td>
                      <td className="">{item?.action} </td>
                      <td className="">{item?.ref_no} </td>
                      <td className="">{item?.remarks} </td>
                    </tr>
                  );
                })}
            </>
          }
        />
      </div>
    </>
  );
}
export default CustomerwiseReportPrint;
