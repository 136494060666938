import React from "react";
import forbidden from "../assets/logo/403(1).webp";
import Layout from "../layouts/layout";

function IsDeniedperm() {
  return (
    <Layout>
      <div style={{ height: "100vh" }} className="container-fluid w-100 pt-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center my-3">
            <h4
              style={{
                color: "Black",
                fontSize: "100px",
                fontWeight: "800",
              }}
            >
              403
            </h4>
            {/* <img src={forbidden} height={"100%"} width={"100%"} alt /> */}
          </div>
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="">
              <p
                style={{
                  color: "lightgray",
                  fontSize: "100px",
                  fontWeight: "800",
                }}
              >
                Oops! Permission Denied
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IsDeniedperm;
