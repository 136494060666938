import { type } from "@testing-library/user-event/dist/type";
import React from "react";
import "./button.scss";

const Button = React.forwardRef((props, ref) => {
  const {
    // loading,
    btnType = "",
    children,
    id,
    onClick,
    className,
    type,
    onKeyDown,
    prependIcon = null,
    appendIcon = null,
    disabled,
    ...rest
  } = props;
  var classNames = "";

  if (btnType === "save") {
    classNames += "btn_save";
  }
  if (btnType === "edit") {
    classNames += "btn_edit";
  }
  if (btnType === "spcl") {
    classNames += "btn_spcl";
  }
  if (btnType === "add") {
    classNames += "btn_add";
  }
  if (btnType === "add_borderless") {
    classNames += "btn_add_borderless";
  }
  if (btnType === "taskexpenses") {
    classNames += "btn_taskexpenses";
  }

  if (prependIcon) {
    classNames += "prepend-icon";
  }

  if (appendIcon) {
    classNames += "append-icon";
  }
  if (btnType === "cancel") {
    classNames += "btn_cancel";
  }
  if (btnType === "email") {
    classNames += "btn_email";
  }
  if (btnType === "approve") {
    classNames += "btn_aprrove";
  }
  if (btnType === "alreadyApprove") {
    classNames += "btn_already_approve";
  }
  return (
    <button
      disabled={disabled}
      ref={ref}
      id={id}
      onClick={onClick}
      className={`px-3  ${classNames} ${className}`}
      {...rest}
      type={type}
      onKeyDown={onKeyDown}
    >
      {prependIcon ? (
        <span ref={ref} className="cif__btn-prepend-icon-wrapper">
          {prependIcon}
        </span>
      ) : null}{" "}
      <span ref={ref} className="">
        {children}
      </span>
      {appendIcon ? (
        <span ref={ref} className="cif__btn-append-icon-wrapper">
          {appendIcon}
        </span>
      ) : null}
    </button>
  );
});

export default Button;
