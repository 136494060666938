import { InputNumber } from "antd";
import React from "react";
import "./InputNumber.scss";

function Input_Number({
  className,
  value,
  onChange,
  onClick,
  rule,
  minLength,
  onBlur,
  onKeyDown,
  align,
  precision,
  min,
  step,
  onPressEnter,
  disabled,
}) {
  const handleKeyDown = (event) => {
    const inputValue = event.target.value.toString();
    const numericValue = inputValue.replace(/[^\d]/g, "");
  
    // Check if the length of the numeric part exceeds 10 digits
    if (numericValue.length >= 10) {
      // Check if the pressed key is a digit (0-9)
      if (/^\d$/.test(event.key)) {
        event.preventDefault(); // Prevent further input of digits
      }
    }
  };
  

  return (
    <div>
      <div>
        <InputNumber
          bordered={false}
          onClick={onClick}
          onChange={onChange}
          value={value}
          rule={rule}
          align={align}
          step={step}
          min={min}
          precision={precision ?? 2}
          minLength={minLength}
          onBlur={onBlur}
          onKeyDown={(e) => {
            handleKeyDown(e);
            if (onKeyDown) onKeyDown(e);
          }}
          style={{ backgroundColor: "#f4f4f7" }}
          className={`input_number_style w-100 pb-1 ${className}`}
          type="number"
          onPressEnter={onPressEnter}
          disabled={disabled}
          max={9999999999}
        />
      </div>
    </div>
  );
}

export default Input_Number;
